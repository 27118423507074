import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SuccessButton from 'component/Buttons/SuccessButton';
import TextInput from 'component/Inputs/TextInput';
import XSmallSpinner from 'component/Spinners/XSmallSpinner';
import Title from 'component/ViewContainer/ViewTitle';
import { setFeedBack } from 'ducks/globalFeedback';
import { UPDATE_USER } from 'graphql/UpdateUser';
import { useUser } from 'hooks';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: '20px'
  },
  button: {
    marginTop: '40px',
    display: 'flex'
  },
  saving: {
    marginLeft: '20px',
    paddingBottom: '3px',
    display: 'flex',
    alignItems: 'flex-end'
  },
  avatarContainer: {
    display: 'flex',
    marginBottom: '30px'
  },
  avatarPic: {
    height: '90px',
    width: '90px',
    borderRadius: '100%',
    backgroundColor: '#dce2ee',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2.2rem',
    color: theme.palette.secondary.main
  },
  avatarTextContainer: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    fontSize: '0.9rem',
    marginLeft: '20px'
  },
  avatarFullName: {
    color: theme.palette.primary.main,
    fontWeight: '500'
  },
  avatarUsername: {
    color: '#A2AAC3'
  }
}));

const PersonalInformation = (props) => {
  const dispatch = useDispatch();
  const { user, setUser } = useUser();
  const classes = useStyles(props);
  const { t } = useTranslation();
  const [inputs, setInputs] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email
  });
  const [disableSave, setDisableSave] = useState(true);

  const [updateUser, { loading, error }] = useMutation(UPDATE_USER, {
    onCompleted(data) {
      if (data.updateUser.success) {
        dispatch(
          setFeedBack({ message: t('userProfile.personalInfo.updateSuccess'), type: 'success' })
        );
        setDisableSave(true);
        let userCopy = { ...user };
        userCopy.firstName = inputs.firstName;
        userCopy.lastName = inputs.lastName;
        setUser(userCopy);
      }
      if (data.updateUser.errors) {
        dispatch(setFeedBack({ message: t('userProfile.personalInfo.updateFail'), type: 'error' }));
      }
    }
  });

  useEffect(() => {
    if (error) {
      dispatch(setFeedBack({ message: t('userProfile.personalInfo.updateFail'), type: 'error' }));
    }
  }, [error]);

  useEffect(() => {
    if (inputs.firstName.length < 1 || inputs.lastName.length < 1) setDisableSave(true);
  }, [inputs]);

  const handleOnChange = (e) => {
    setDisableSave(false);
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = () => {
    updateUser({
      variables: {
        userId: user.id,
        firstName: inputs.firstName,
        lastName: inputs.lastName
      }
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <Title title={t('userProfile.personalInfo.title')} />
      </Grid>
      <Grid item xs={12} className={classes.avatarContainer}>
        <Box className={classes.avatarPic}>{user.firstName[0] + user.lastName[0]}</Box>
        <Box className={classes.avatarTextContainer}>
          <Box className={classes.avatarFullName}>{user.firstName + ' ' + user.lastName}</Box>
          <Box className={classes.avatarUsername}>{user.username}</Box>
        </Box>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item sm={6} xs={12}>
          <TextInput
            required={false}
            label={t('sharedLabels.firstName')}
            value={inputs.firstName}
            name="firstName"
            onChange={(e) => handleOnChange(e)}
            disabled={loading}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInput
            required={false}
            label={t('sharedLabels.lastName')}
            value={inputs.lastName}
            name="lastName"
            onChange={(e) => handleOnChange(e)}
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            required={false}
            label={t('sharedLabels.email')}
            value={inputs.email}
            name="email"
            disabled={true}
            onChange={(e) => handleOnChange(e)}
          />
        </Grid>
      </Grid>
      <Grid item xs={6} className={classes.button}>
        <SuccessButton disabled={disableSave || loading} onClick={handleOnSubmit}>
          {t('userProfile.personalInfo.update')}
        </SuccessButton>
        <Box className={classes.saving}>{loading && <XSmallSpinner />}</Box>
      </Grid>
    </>
  );
};

export default PersonalInformation;
