import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    padding: '5px 10px',
    border: (props) => (props.variant === 'outlined' ? '1px solid #E54C41' : ''),
    color: (props) =>
      props.disabled ? theme.palette.grey['300'] : theme.palette.error.contrastText,
    backgroundColor: (props) => {
      if (props.disabled) {
        return theme.palette.grey['300'];
      } else {
        return theme.palette.error.main;
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.error.light
    }
  }
}));

const ErrorButton = (props) => {
  const classes = useStyles(props);
  return (
    <Button
      disabled={props.disabled}
      size={props.size}
      onClick={props.onClick}
      variant={props.variant}
      className={classes.root}>
      {props.children}
    </Button>
  );
};

export default ErrorButton;

ErrorButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onClick: PropTypes.func
};
