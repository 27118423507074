import { gql } from '@apollo/client';

const TOTAL_SESSION_PER_DAY = gql`
  query totalSessionsPerDay($trainingId: Int!, $userId: Int, $cohortId: Int) {
    totalSessionsPerDay(trainingId: $trainingId, userId: $userId, cohortId: $cohortId, days: 60) {
      dates
      totalAssessment
      totalPractice
    }
  }
`;

export default TOTAL_SESSION_PER_DAY;
