import { gql } from '@apollo/client';

const TOTAL_SESSION_COUNT_GROUPED_BY_TRAINING = gql`
  query totalSessionsByTraining($orgId: Int, $userId: Int, $cohortId: Int) {
    totalSessionsGroupedByTraining(orgId: $orgId, userId: $userId, cohortId: $cohortId) {
      trainingId
      trainingName
      totalSessions
    }
  }
`;

export default TOTAL_SESSION_COUNT_GROUPED_BY_TRAINING;
