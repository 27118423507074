import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Box, makeStyles } from '@material-ui/core';
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';
import { useLogout } from 'api/Logout';
import { AdminIconBlack, AdminIconBlue } from 'assets/images/icons/AdminIcon';
import { CourseIconBlack, CourseIconBlue } from 'assets/images/icons/CourseIcon';
import { DashboardIconBlack, DashboardIconBlue } from 'assets/images/icons/DashboardIcon';
import { PersonIconBlack, PersonIconBlue } from 'assets/images/icons/PersonIcon';
import { PowerIconBlack, PowerIconBlue } from 'assets/images/icons/PowerIcon';
import { UserGroupIconBlack, UserGroupIconBlue } from 'assets/images/icons/UserGroupIcon';
import LogoBlack from 'assets/images/logo-black';
import {
  ADMIN_NAV_ID,
  COURSE_NAV_ID,
  DASH_NAV_ID,
  LOGOUT_NAV_ID,
  SETTINGS_NAV_ID,
  USER_NAV_ID,
  USERS_NAV_ID
} from 'constants/paths';
import { buildBreadcrumbs } from 'helpers/buildBreadcrumbs';
import { useUser } from 'hooks';
import { useOrganization } from 'hooks';

import NavItem from '../NavComponents/NavItem';

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    height: '50px',
    minWidth: '50px',
    maxWidth: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '15px',
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer'
  },
  navItems: {
    paddingLeft: '28px',
    display: 'flex',
    listStyleType: 'none'
  },
  logoContainer: {
    height: '38px',
    display: 'flex',
    justifyContent: 'center'
  }
}));

const Menu = (props) => {
  const classes = useStyles();
  const { logout } = useLogout();
  const { t } = useTranslation();
  let history = useHistory();
  const { organizationData } = useOrganization();
  const { user, restrictedUser } = useUser();

  let nav = [];
  let courseList = [];

  let userNav = [
    {
      name: t('menu.dashboard'),
      icon: <DashboardIconBlack />,
      hoverIcon: <DashboardIconBlue />,
      id: DASH_NAV_ID,
      link: '/dashboard/default',
      dropdown: false,
      action: () => {
        props.setSelected('dashboard');
        history.push({ pathname: '/dashboard/default' });
      }
    },
    {
      name: t('menu.fullReport'),
      icon: <PersonIconBlack />,
      hoverIcon: <PersonIconBlue />,
      id: USER_NAV_ID,
      link: '/user/' + user?.id,
      dropdown: false,
      action: () => {
        props.setSelected('user');
        history.push({ pathname: '/user/' + user?.id });
      }
    }
  ];

  let navList = [
    {
      name: t('menu.dashboard'),
      icon: <DashboardIconBlack />,
      hoverIcon: <DashboardIconBlue />,
      id: DASH_NAV_ID,
      link: '/dashboard/default',
      dropdown: false,
      action: () => {
        props.setSelected('dashboard');
        history.push({ pathname: '/dashboard/default' });
      }
    },
    {
      name: t('menu.users'),
      icon: <UserGroupIconBlack />,
      hoverIcon: <UserGroupIconBlue />,
      id: USERS_NAV_ID,
      link: '/dashboard/users',
      dropdown: false,
      action: () => {
        props.setSelected('users');
        history.push({ pathname: '/dashboard/users' });
      }
    },
    {
      name: t('menu.courses'),
      icon: <CourseIconBlack />,
      hoverIcon: <CourseIconBlue />,
      id: COURSE_NAV_ID,
      link: '/courses',
      dropdown: true,
      noData: t('menu.noData.courses'),
      navItems: courseList
    },
    {
      name: t('menu.title2'),
      icon: <AdminIconBlack />,
      hoverIcon: <AdminIconBlue />,
      id: ADMIN_NAV_ID,
      link: '/admin',
      dropdown: false,
      action: () => {
        props.setSelected('admin');
        history.push({ pathname: '/admin' });
      }
    },
    {
      name: t('menu.fullReport'),
      icon: <PersonIconBlack />,
      hoverIcon: <PersonIconBlue />,
      id: USER_NAV_ID,
      link: '/user/' + user?.id,
      dropdown: false,
      action: () => {
        props.setSelected('user');
        history.push({ pathname: '/user/' + user?.id });
        buildBreadcrumbs('clear');
      }
    }
  ];

  if (user && organizationData) {
    if (restrictedUser) {
      nav = userNav;
    } else {
      user.courses.forEach((course) => {
        if (
          organizationData.organization.courses.find(
            (orgCourse) => Number(orgCourse.id) === Number(course.id)
          )
        ) {
          courseList.push({
            name: course.name,
            id: course.id,
            action: () => {
              props.setSelected('courses');
              history.push({ pathname: '/courses/' + course.id });
            }
          });
        }
      });
      nav = navList;
    }
  }

  let collapsedNav = [
    ...nav,
    {
      icon: <PersonIconBlack />,
      hoverIcon: <PersonIconBlue />,
      name: t('menu.userSettings'),
      value: null,
      id: SETTINGS_NAV_ID,
      category: 'subNav',
      dropdown: false,
      action: () => {
        props.setSelected(SETTINGS_NAV_ID);
        history.push({ pathname: '/profile/user' });
      }
    },
    {
      icon: <PowerIconBlack />,
      hoverIcon: <PowerIconBlue />,
      name: t('menu.logout'),
      value: null,
      id: LOGOUT_NAV_ID,
      category: 'subNav',
      dropdown: false,
      action: () => {
        props.setSelected(LOGOUT_NAV_ID);
        logout();
      }
    }
  ];

  return (
    <>
      {props.collapse && (
        <Box className={classes.menuIcon}>
          <MenuTwoToneIcon onClick={() => props.handleShowNavSlideOut(collapsedNav, 'left')} />
        </Box>
      )}
      {props.orgData &&
        (props.orgData.organization.avatar && props.orgData.organization.avatar.length > 0 ? (
          <img src={props.orgData.organization.avatar} height="45px" width="auto" alt="logo" />
        ) : (
          <LogoBlack />
        ))}
      {!props.collapse && (
        <ul id="nav_list__left" className={classes.navItems}>
          {nav.map((item) => (
            <NavItem key={item.id} nav={item} selected={props.selected} />
          ))}
        </ul>
      )}
    </>
  );
};

export default Menu;
