import { gql } from '@apollo/client';

const UPDATE_TRAININGS = gql`
  mutation UpdateTrainings($data: [TrainingInput]) {
    updateTrainings(data: $data) {
      success
      error
      trainings {
        id
        name
        details
        course {
          id
          name
        }
        user {
          id
          username
        }
      }
    }
  }
`;

export default UPDATE_TRAININGS;
