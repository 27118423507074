import { gql } from '@apollo/client';

const GET_TRAINING_SESSION_STATS = gql`
  query trainingSessionStats($trainingId: Int!) {
    trainingSessionStats(
      id: $trainingId
      startDate: "2021-09-01" #optional
      endDate: "2023-12-01" #optional
    ) {
      userId
      trainingId
      totalUniqueSessions
      estimatedDuration
      totalTimeSpent
      passed
      errorsMade
    }
  }
`;

export default GET_TRAINING_SESSION_STATS;
