const useLocalStorage = () => {
  const organizationId = localStorage.getItem('orgId')
    ? parseInt(localStorage.getItem('orgId'), 10)
    : null;
  const i18nextLng = localStorage.getItem('i18nextLng');

  return {
    organizationId,
    i18nextLng
  };
};

export { useLocalStorage };
