import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import XSmallSpinner from 'component/Spinners/XSmallSpinner';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    color: (props) =>
      props.noData || props.error
        ? theme.palette.secondary.main + '80'
        : theme.palette.secondary.main,
    backgroundColor: (props) =>
      props.noData || props.error
        ? theme.palette.background.paper + '60'
        : theme.palette.background.paper,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: (props) => (props.height ? props.height : ''),
    border: '1px solid ' + theme.palette.grey['200'],
    position: 'relative'
  },
  tooltip: {
    position: 'absolute',
    zIndex: '10',
    top: 0,
    right: 0,
    padding: '10px'
  },
  title: {
    borderBottom: '1px solid ' + theme.palette.grey['200'],
    fontWeight: 500,
    fontSize: '0.9rem',
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    height: '4.8rem',
    padding: '0 20px'
  },
  contentContainer: {
    padding: (props) => (props.error || props.noData ? '25px' : '5px 0'),
    /*[theme.breakpoints.only('md')]: {
			//padding: props => props.error || props.noData ? "30px" : "30px 0",
		},
		[theme.breakpoints.down('sm')]: {
			//padding: props => props.error || props.noData ? "10px" : "30px 0 10px 0",
		},*/
    height: (props) => (props.height ? props.height : '12rem'),
    overflowY: 'auto',
    display: (props) => (props.loading ? 'flex' : 'block'),
    alignItems: (props) => (props.loading ? 'center' : 'flex-start'),
    justifyContent: (props) => (props.loading ? 'center' : 'flex-start')
  },
  content: {
    paddingBottom: '15px'
  },
  loading: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  noData: {
    height: '100%',
    width: '100%',
    padding: '0 30px'
  },
  customHeader: {
    flex: '1 1 0%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const WidgetWrapper = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        {props.title}
        {props.information && (
          <Box className={classes.tooltip}>
            <Tooltip style={{ cursor: 'pointer' }} title={props.information}>
              <InfoOutlined />
            </Tooltip>
          </Box>
        )}
        {props.customHeader && <Box className={classes.customHeader}>{props.customHeader}</Box>}
      </Box>
      <Box className={classes.contentContainer}>
        {props.error ? (
          t('general.errorData')
        ) : props.loading ? (
          <Box className={classes.loading}>
            <XSmallSpinner />
          </Box>
        ) : props.noData ? (
          <Box className={classes.noData}>{props.noData}</Box>
        ) : (
          props.children
        )}
      </Box>
    </Box>
  );
};

export default WidgetWrapper;

WidgetWrapper.propTypes = {
  bgColor: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  customHeader: PropTypes.object
};
