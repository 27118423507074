import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import RowButton from 'component/Table/RowButton';

import Table from '../../../../component/Table/Table';

const TextTable = (props) => {
  const { t } = useTranslation();
  const columns = [
    {
      name: t('sharedLabels.key'),
      options: {
        filter: false
      }
    },
    {
      name: t('sharedLabels.text'),
      options: {
        filter: false,
        customBodyRender: (value) => (
          <Box style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {value}
          </Box>
        )
      }
    },
    {
      name: t('sharedLabels.training'),
      options: {
        filter: false
      }
    },
    {
      name: '',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => (
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <RowButton
              onClick={() => props.handleShowModal(tableMeta.rowData[4], 'text', true)}
              label={t('textManagement.edit')}
            />
          </Box>
        )
      }
    },
    {
      name: 'data',
      options: {
        display: false,
        filter: false
      }
    }
  ];

  let rows = props.textData
    ? props.textData.map((text) => {
        return [text.key, text.text, text.training, '', text];
      })
    : [];

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    props.onSelectedTexts({ rowsSelected });
  };

  const tableConfig = {
    title: '',
    print: false,
    viewColumns: false,
    search: true,
    download: false,
    filter: false,
    rowData: rows,
    columns: columns,
    flexView: true,
    rowsSelected: props.rowsSelected,
    components: {
      TableToolbarSelect: () => null
    },
    options: {
      selectToolbarPlacement: 'none'
    },
    onRowSelectionChange
  };

  return <Table tableConfig={tableConfig} />;
};

export default TextTable;
