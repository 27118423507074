import React from 'react';
import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

const TextArea = (props) => {
  return (
    <FormControl fullWidth error={props.error} margin="normal">
      <TextField
        style={{ backgroundColor: props.disabled && '#d3d3d340', opacity: props.disabled && '0.7' }}
        fullWidth
        size="small"
        margin="none"
        variant={'outlined'}
        multiline
        rows={3}
        rowsMax={3}
        error={props.error}
        required={props.required}
        label={props.label}
        name={props.name}
        placeholder={props.label}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      {props.underText || props.error ? (
        <FormHelperText id="input-helper-text">{props.underText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default TextArea;

TextArea.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  underText: PropTypes.string
};
