import React from 'react';
const SwedenFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 25">
      <defs>
        <clipPath id="clip-path">
          <circle
            id="Ellipse_6"
            data-name="Ellipse 6"
            cx="12.5"
            cy="12.5"
            r="12.5"
            transform="translate(132 465)"
            fill="#012169"
          />
        </clipPath>
      </defs>
      <g id="Sweden" transform="translate(-132 -465)" clipPath="url(#clip-path)">
        <circle
          id="Ellipse_5"
          data-name="Ellipse 5"
          cx="12.5"
          cy="12.5"
          r="12.5"
          transform="translate(132 465)"
          fill="#004b87"
        />
        <g id="Group_12" data-name="Group 12" transform="translate(126.625 459.75)">
          <g id="Group_10" data-name="Group 10" transform="translate(0.125)">
            <rect
              id="Rectangle_9"
              data-name="Rectangle 9"
              width="5.625"
              height="35.5"
              transform="translate(12)"
              fill="#fcd000"
            />
            <rect
              id="Rectangle_10"
              data-name="Rectangle 10"
              width="5.625"
              height="35.5"
              transform="translate(32.5 15) rotate(90)"
              fill="#fcd000"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SwedenFlag;
