import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import ProgressWidget from 'component/Widgets/ProgressWidget';

const Progress = (props) => {
  const { t } = useTranslation();
  const title = t(
    `userDetails.trainingsPassedCard.title.${props.userDetailsViewFilters?.selectedSwitch}`
  );
  return (
    <Grid item xs={12} sm={4}>
      <ProgressWidget
        color={props.widgetColor('#06AC69')}
        title={title}
        subTitle={
          props.performance.passedTrainings +
          ' ' +
          t('general.outOf', { count: props.performance.totalTrainings })
        }
        total={props.performance.totalTrainings}
        progress={props.performance.passedTrainings}
      />
    </Grid>
  );
};

export default Progress;
