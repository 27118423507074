import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import RowButton from 'component/Table/RowButton';
import Table from 'component/Table/Table';

const GroupTable = (props) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: t('sharedLabels.name'),
      options: {
        filter: false
      }
    },
    {
      name: t('admin.manageOrganization'),
      options: {
        filter: false
      }
    },
    {
      name: t('sharedLabels.totalUsers'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          return value ? value.length : '0';
        }
      }
    },
    {
      name: 'data',
      options: {
        display: false,
        filter: false
      }
    },
    {
      name: '',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <RowButton
                onClick={() => props.actionEdit(tableMeta.rowData[3], true)}
                label={t('groupManagement.edit')}
              />
              <RowButton
                onClick={() => props.actionDelete(tableMeta.rowData[3], true)}
                label={t('groupManagement.delete')}
                type="delete"
              />
            </Box>
          );
        }
      }
    }
  ];

  let rows = props.data
    ? props.data.map((group) => {
        return [group.name, group.organization.name, group.user, group, ''];
      })
    : [];

  const tableConfig = {
    title: t('groupManagement.table.title'),
    print: false,
    viewColumns: false,
    search: true,
    download: false,
    filter: false,
    rowData: rows,
    columns: columns,
    flexView: true
  };

  return <Table tableConfig={tableConfig} />;
};

export default GroupTable;
