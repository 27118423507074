import { gql } from '@apollo/client';

const OBJECT_ERRORS = gql`
  query errorStats($trainingId: Int!, $userId: Int, $cohortId: Int, $errorType: String) {
    errorStats(
      trainingId: $trainingId
      userId: $userId
      cohortId: $cohortId
      errorType: $errorType
    ) {
      data {
        name
        value
        errorCause {
          name
          value
        }
      }
    }
  }
`;

export default OBJECT_ERRORS;
