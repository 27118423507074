const RESET_PASSWORD_INVALID_TOKEN = 'RESET_PASSWORD_INVALID_TOKEN';

const initialState = {
  resetPasswordInvalidToken: false
};

export const setResetPasswordInvalidToken = (resetPasswordInvalidToken) => {
  return { type: RESET_PASSWORD_INVALID_TOKEN, resetPasswordInvalidToken };
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_INVALID_TOKEN:
      return {
        ...state,
        resetPasswordInvalidToken: action.resetPasswordInvalidToken
      };
    default:
      return state;
  }
};
