import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import ChartWidget from 'component/Widgets/ChartWidget';
import { ASSESSMENT_COLOR, PRACTICE_COLOR } from 'constants/colors';
import TOTAL_SESSION_PER_DAY from 'graphql/TotalSessionPerDay';

const SessionPerDay = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  let trainingId = params.trainingId;

  const { data, loading } = useQuery(TOTAL_SESSION_PER_DAY, {
    variables: {
      trainingId: Number(trainingId),
      ...(props.restrictedUser && props.userId),
      ...(props.filteredBy && props.cohortId)
    },
    fetchPolicy: 'no-cache'
  });

  let categories = [];

  let series = [
    {
      name: t('sharedLabels.assessment'),
      data: []
    },
    {
      name: t('sharedLabels.practice'),
      data: []
    }
  ];

  if (data) {
    categories = data['totalSessionsPerDay']['dates'];
    series[0].data = data['totalSessionsPerDay']['totalAssessment'];
    series[1].data = data['totalSessionsPerDay']['totalPractice'];
  }

  let seriesColorsBar = [ASSESSMENT_COLOR + 90, PRACTICE_COLOR + 90];
  let seriesColorsBorder = [ASSESSMENT_COLOR, PRACTICE_COLOR];

  return (
    <ChartWidget
      height={props.restrictedUser ? '20rem' : '100%'}
      widgetTitle={t('trainingDetails.sessionsCard.title')}
      chartType="stackedBar"
      series={series}
      categories={categories}
      loading={loading}
      colors={{ bar: seriesColorsBar, border: seriesColorsBorder }}
      xUnits={{ type: 'datetime' }}
      information={t('tooltips.sessionsInfo')}
    />
  );
};

export default SessionPerDay;
