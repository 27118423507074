import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLOR_LIST_ORGANIZATION_ROLES } from 'constants/colors';

const useStyles = makeStyles(() => ({
  root: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    fontSize: '0.8rem'
  },
  name: {
    flex: '1 1 0%',
    padding: '0 10px'
  },
  divider: {
    height: '60%',
    width: '1px'
  },
  text: {
    fontWeight: 600,
    textTransform: 'capitalize',
    padding: '0 10px'
  }
}));

const OrganizationRoles = (props) => {
  const color = props.name.includes('course')
    ? COLOR_LIST_ORGANIZATION_ROLES.courseAdmin
    : props.name.includes('user')
    ? COLOR_LIST_ORGANIZATION_ROLES.user
    : COLOR_LIST_ORGANIZATION_ROLES.admin;

  const classes = useStyles(props);

  return (
    <Box className={classes.root} style={{ border: '1px solid ' + color }}>
      <p className={classes.name}>{props.name}</p>
      <Box className={classes.divider} style={{ backgroundColor: color }} />
      <p className={classes.text} style={{ color: color }}>
        {props.role}
      </p>
    </Box>
  );
};

export default OrganizationRoles;
