import React, { useMemo } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { AppsRounded } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ON_ORGANIZATION_CHANGE } from 'constants';
import * as pathTypes from 'constants/paths';
import { useOrganization } from 'hooks';

import NavDropdown from '../NavComponents/NavDropdown';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: '25px',
    borderRight: '1px solid' + theme.palette.text.hint + 80,
    padding: '0 18px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  text: {
    margin: '0 0.5rem',
    fontSize: '0.9rem'
  },
  iconContainer: {
    height: '50px',
    minWidth: '50px',
    maxWidth: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  icon: {
    fontSize: '2.2em'
  }
}));

const OrgSelect = (props) => {
  const classes = useStyles();
  const { organizationData, setOrganizationData } = useOrganization();
  let history = useHistory();
  const { t } = useTranslation();

  const handleSelectItem = (item) => {
    const foundOrg = organizationData.allOrgs.find((org) => org.id === item.id);
    if (!foundOrg) {
      toast.error(t('organizationManagement.error.notFound'));
      return;
    }
    const orgDataCopy = { ...organizationData };
    orgDataCopy.organization = foundOrg;
    setOrganizationData(orgDataCopy);
    localStorage.setItem('orgId', foundOrg.id);
    localStorage.setItem('orgData', JSON.stringify(orgDataCopy));
    history.push(pathTypes.DASHBOARD_PATH);
    emitCustomEvent(ON_ORGANIZATION_CHANGE, {
      organizationId: foundOrg.id
    });
    window.sessionStorage.setItem('navItem', 'dashboard');
  };

  const orgList = useMemo(() => {
    if (organizationData === null) return [];
    return organizationData.allOrgs.map((org) => ({
      id: org.id,
      value: org.id,
      name: org.name,
      action: handleSelectItem
    }));
  }, [organizationData]);

  return props.collapse ? (
    <Box
      className={classes.iconContainer}
      onClick={() => props.handleShowNavSlideOut(orgList, 'right')}>
      <AppsRounded className={classes.icon} />
    </Box>
  ) : orgList.length !== 0 ? (
    <Box className={classes.root}>
      <NavDropdown collapse={props.collapse} nav={orgList}>
        <Typography className={classes.text}>{organizationData.organization.name}</Typography>
        <ExpandMoreIcon color="inherit" />
      </NavDropdown>
    </Box>
  ) : null;
};

export default OrgSelect;
