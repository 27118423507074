export const ASSESSMENT_COLOR = '#59669D';
export const PRACTICE_COLOR = '#AEB2BF';
export const COLOR_LIST = ['#FC8F2C', '#29356A', '#4CB263', '#644CB2', '#4CB2B2'];
export const COLOR_LIST_CHART = COLOR_LIST.map((color) => color + 95);
export const COLOR_LIST_CHART_BORDER = COLOR_LIST;
export const COLOR_LIST_ORGANIZATION_ROLES = {
  admin: '#4CB2B2',
  courseAdmin: '#B24C4C',
  user: '#644CB2'
};
