import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormSubTitle from 'component/Inputs/FormSubTitle';
import TextArea from 'component/Inputs/TextArea';
import TextInput from 'component/Inputs/TextInput';
import BasicModal from 'component/Modals/BasicModal';
import ADD_COURSES from 'graphql/AddCourses';
import UPDATE_COURSES from 'graphql/UpdateCourses';
import { useOrganization } from 'hooks';

const useStyles = makeStyles({
  paddingBottom: {
    paddingBottom: '1.2rem'
  },
  paddingRight: {
    paddingRight: '1.2rem',
    alignSelf: 'flex-start'
  }
});

const CourseModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { organizationData, setOrganizationData } = useOrganization();
  const [response, setResponse] = useState(null);
  const [courseDetails, setCourseDetails] = useState({
    name: props.selectedCourse ? props.selectedCourse.name : '',
    description: props.selectedCourse ? props.selectedCourse.description : ''
  });
  const [formErrors, setFormErrors] = useState({
    name: false,
    description: false
  });

  let orgDataCopy = JSON.parse(JSON.stringify(organizationData));

  const [updateCourses, { loading: updateCourseLoading }] = useMutation(UPDATE_COURSES, {
    onCompleted(coursesUpdated) {
      setResponse({
        message: coursesUpdated.updateCourses.success
          ? t('courseManagement.responses.updateSuccess')
          : coursesUpdated.updateCourses.error,
        responseType: coursesUpdated.updateCourses.success ? 'success' : 'error'
      });
      if (coursesUpdated.updateCourses.success) {
        let updatedCourses = orgDataCopy.organization.courses.map(
          (obj) =>
            coursesUpdated.updateCourses.courses.find((course) => course.id === obj.id) || obj
        );
        orgDataCopy.organization.courses = [...updatedCourses];
        setOrganizationData(orgDataCopy);
      }
    }
  });

  const [addCourses] = useMutation(ADD_COURSES, {
    onCompleted(courseAdded) {
      setResponse({
        message: courseAdded.addCourses.success
          ? t('courseManagement.responses.createSuccess')
          : courseAdded.addCourses.error,
        responseType: courseAdded.addCourses.success ? 'success' : 'error'
      });
      if (courseAdded.addCourses.success) {
        orgDataCopy.organization.courses.push(courseAdded.addCourses.courses[0]);
        setOrganizationData(orgDataCopy);
      }
    }
  });

  const canSave = () => {
    let error = false;
    let formErrorsCopy = { ...formErrors };
    Object.keys(courseDetails).forEach((key) => {
      if (courseDetails[key].length < 1) {
        formErrorsCopy[key] = true;
        error = true;
      }
    });
    setFormErrors(formErrorsCopy);
    return error;
  };

  const handleUpdateCourse = () => {
    if (!canSave()) {
      updateCourses({
        variables: {
          data: [
            {
              id: props.selectedCourse.id,
              organizationId: Number(organizationData.organization.id),
              name: courseDetails.name,
              description: courseDetails.description
            }
          ]
        }
      });
    }
  };

  const handleAddCourse = () => {
    if (!canSave()) {
      addCourses({
        variables: {
          data: [
            {
              name: courseDetails.name,
              description: courseDetails.description,
              organizationId: Number(organizationData.organization.id)
            }
          ]
        }
      });
    }
  };

  const handleOnChangeInputs = (e) => {
    setCourseDetails({
      ...courseDetails,
      [e.target.name]: e.target.value
    });
    setFormErrors({
      ...formErrors,
      [e.target.name]: null
    });
  };

  return (
    <BasicModal
      icon={props.selectedCourse ? 'edit' : 'add'}
      title={props.selectedCourse ? t('courseManagement.edit') : t('courseManagement.create')}
      buttonText={props.selectedCourse ? t('courseManagement.update') : t('courseManagement.save')}
      handleCloseModal={props.handleCloseModal}
      handleDone={props.selectedCourse ? handleUpdateCourse : handleAddCourse}
      saving={updateCourseLoading}
      response={response}
      width="42rem">
      <Grid container>
        <Grid item xs={12}>
          <FormSubTitle title={t('courseManagement.form.title1')} />
        </Grid>

        <Grid item xs={6} className={classes.paddingRight}>
          <TextInput
            required={true}
            label={t('sharedLabels.courseName')}
            value={courseDetails.name}
            name="name"
            onChange={handleOnChangeInputs}
            underText={formErrors.name}
            error={formErrors.name}
          />
        </Grid>
        <Grid item xs={12} className={classes.paddingRight}>
          <TextArea
            required={true}
            label={t('sharedLabels.description')}
            value={courseDetails.description}
            name="description"
            onChange={handleOnChangeInputs}
            underText={formErrors.description}
            error={formErrors.description}
          />
        </Grid>
      </Grid>
    </BasicModal>
  );
};

export default CourseModal;
