import React from 'react';

export const CourseIconBlue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.78"
      height="17.486"
      viewBox="0 0 18.78 15.486">
      <path
        id="school_FILL0_wght600_GRAD0_opsz48"
        d="M12.449,21.686a2.059,2.059,0,0,1-.425-.044,1.438,1.438,0,0,1-.4-.153L6.909,18.916a1.58,1.58,0,0,1-.676-.633,1.755,1.755,0,0,1-.24-.894V13.18L3.986,12.111a.9.9,0,0,1-.327-1.2.742.742,0,0,1,.327-.305L11.62,6.418a3.839,3.839,0,0,1,.4-.153,1.415,1.415,0,0,1,.851,0q.207.065.425.153l8.572,4.668a.942.942,0,0,1,.338.338.877.877,0,0,1,.12.447v5.736a.782.782,0,0,1-.785.785.784.784,0,0,1-.578-.229.757.757,0,0,1-.229-.556V12.264l-1.832.916v4.21a1.755,1.755,0,0,1-.24.894,1.58,1.58,0,0,1-.676.633L13.278,21.49a1.438,1.438,0,0,1-.4.153A2.059,2.059,0,0,1,12.449,21.686Zm0-6.762h0l6.522-3.577L12.449,7.836h0L5.971,11.347Zm0,5.213h0l4.864-2.683h0V14.139L13.278,16.3a2.657,2.657,0,0,1-.4.142,1.664,1.664,0,0,1-.425.055,1.542,1.542,0,0,1-.414-.055,2.834,2.834,0,0,1-.393-.142L7.585,14.1v3.359h0Zm.022-5.213ZM12.449,17.3Zm0,0Z"
        transform="translate(-3.55 -6.2)"
        fill="#2e3c77"
      />
    </svg>
  );
};

export const CourseIconBlack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.78"
      height="17.486"
      viewBox="0 0 18.78 15.486">
      <path
        id="school_FILL0_wght600_GRAD0_opsz48"
        d="M12.449,21.686a2.059,2.059,0,0,1-.425-.044,1.438,1.438,0,0,1-.4-.153L6.909,18.916a1.58,1.58,0,0,1-.676-.633,1.755,1.755,0,0,1-.24-.894V13.18L3.986,12.111a.9.9,0,0,1-.327-1.2.742.742,0,0,1,.327-.305L11.62,6.418a3.839,3.839,0,0,1,.4-.153,1.415,1.415,0,0,1,.851,0q.207.065.425.153l8.572,4.668a.942.942,0,0,1,.338.338.877.877,0,0,1,.12.447v5.736a.782.782,0,0,1-.785.785.784.784,0,0,1-.578-.229.757.757,0,0,1-.229-.556V12.264l-1.832.916v4.21a1.755,1.755,0,0,1-.24.894,1.58,1.58,0,0,1-.676.633L13.278,21.49a1.438,1.438,0,0,1-.4.153A2.059,2.059,0,0,1,12.449,21.686Zm0-6.762h0l6.522-3.577L12.449,7.836h0L5.971,11.347Zm0,5.213h0l4.864-2.683h0V14.139L13.278,16.3a2.657,2.657,0,0,1-.4.142,1.664,1.664,0,0,1-.425.055,1.542,1.542,0,0,1-.414-.055,2.834,2.834,0,0,1-.393-.142L7.585,14.1v3.359h0Zm.022-5.213ZM12.449,17.3Zm0,0Z"
        transform="translate(-3.55 -6.2)"
        fill="#231f20"
      />
    </svg>
  );
};
