import React from 'react';

export const ArrowLink = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 34.069 35">
      <path
        id="Path_279"
        data-name="Path 279"
        d="M34.068,17.538A17.847,17.847,0,0,0,31.2,7.816,17.143,17.143,0,0,0,23.552,1.37a16.612,16.612,0,0,0-9.841-1A16.9,16.9,0,0,0,4.99,5.163a17.424,17.424,0,0,0-3.7,5.674A17.962,17.962,0,0,0,2.871,27.261a17.143,17.143,0,0,0,7.644,6.445,16.612,16.612,0,0,0,9.841,1,16.9,16.9,0,0,0,8.721-4.789,17.424,17.424,0,0,0,3.7-5.674A17.8,17.8,0,0,0,34.068,17.538ZM17.034,33.221A15.5,15.5,0,0,1,1.766,17.538,15.5,15.5,0,0,1,17.034,1.855,15.5,15.5,0,0,1,32.3,17.538,15.5,15.5,0,0,1,17.034,33.221Z"
        transform="translate(0.001 -0.038)"
        fill="#fc8f2c"
      />
      <path
        id="Path_280"
        data-name="Path 280"
        d="M148.988,136.9l-8.322-7.629a1.061,1.061,0,0,0-1.409,0,.861.861,0,0,0,0,1.292l6.775,6.211H131.124a1.041,1.041,0,0,0-.687.276.86.86,0,0,0,0,1.275,1.041,1.041,0,0,0,.687.276h14.755l-6.831,6.259a.913.913,0,0,0-.216.3.844.844,0,0,0,0,.7.912.912,0,0,0,.216.3,1,1,0,0,0,.323.2,1.079,1.079,0,0,0,.762,0,1.006,1.006,0,0,0,.323-.2l8.581-7.866a.99.99,0,0,0,.262-.7,1,1,0,0,0-.314-.684Z"
        transform="translate(-122.594 -120.239)"
        fill="#fc8f2c"
      />
    </svg>
  );
};

export const ArrowLinkMedium = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 34.069 35">
      <path
        id="Path_279"
        data-name="Path 279"
        d="M34.068,17.538A17.847,17.847,0,0,0,31.2,7.816,17.143,17.143,0,0,0,23.552,1.37a16.612,16.612,0,0,0-9.841-1A16.9,16.9,0,0,0,4.99,5.163a17.424,17.424,0,0,0-3.7,5.674A17.962,17.962,0,0,0,2.871,27.261a17.143,17.143,0,0,0,7.644,6.445,16.612,16.612,0,0,0,9.841,1,16.9,16.9,0,0,0,8.721-4.789,17.424,17.424,0,0,0,3.7-5.674A17.8,17.8,0,0,0,34.068,17.538ZM17.034,33.221A15.5,15.5,0,0,1,1.766,17.538,15.5,15.5,0,0,1,17.034,1.855,15.5,15.5,0,0,1,32.3,17.538,15.5,15.5,0,0,1,17.034,33.221Z"
        transform="translate(0.001 -0.038)"
        fill="#fc8f2c"
      />
      <path
        id="Path_280"
        data-name="Path 280"
        d="M148.988,136.9l-8.322-7.629a1.061,1.061,0,0,0-1.409,0,.861.861,0,0,0,0,1.292l6.775,6.211H131.124a1.041,1.041,0,0,0-.687.276.86.86,0,0,0,0,1.275,1.041,1.041,0,0,0,.687.276h14.755l-6.831,6.259a.913.913,0,0,0-.216.3.844.844,0,0,0,0,.7.912.912,0,0,0,.216.3,1,1,0,0,0,.323.2,1.079,1.079,0,0,0,.762,0,1.006,1.006,0,0,0,.323-.2l8.581-7.866a.99.99,0,0,0,.262-.7,1,1,0,0,0-.314-.684Z"
        transform="translate(-122.594 -120.239)"
        fill="#fc8f2c"
      />
    </svg>
  );
};

export const ArrowLinkSmall = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 34.069 35">
      <path
        id="Path_279"
        data-name="Path 279"
        d="M34.068,17.538A17.847,17.847,0,0,0,31.2,7.816,17.143,17.143,0,0,0,23.552,1.37a16.612,16.612,0,0,0-9.841-1A16.9,16.9,0,0,0,4.99,5.163a17.424,17.424,0,0,0-3.7,5.674A17.962,17.962,0,0,0,2.871,27.261a17.143,17.143,0,0,0,7.644,6.445,16.612,16.612,0,0,0,9.841,1,16.9,16.9,0,0,0,8.721-4.789,17.424,17.424,0,0,0,3.7-5.674A17.8,17.8,0,0,0,34.068,17.538ZM17.034,33.221A15.5,15.5,0,0,1,1.766,17.538,15.5,15.5,0,0,1,17.034,1.855,15.5,15.5,0,0,1,32.3,17.538,15.5,15.5,0,0,1,17.034,33.221Z"
        transform="translate(0.001 -0.038)"
        fill="#fc8f2c"
      />
      <path
        id="Path_280"
        data-name="Path 280"
        d="M148.988,136.9l-8.322-7.629a1.061,1.061,0,0,0-1.409,0,.861.861,0,0,0,0,1.292l6.775,6.211H131.124a1.041,1.041,0,0,0-.687.276.86.86,0,0,0,0,1.275,1.041,1.041,0,0,0,.687.276h14.755l-6.831,6.259a.913.913,0,0,0-.216.3.844.844,0,0,0,0,.7.912.912,0,0,0,.216.3,1,1,0,0,0,.323.2,1.079,1.079,0,0,0,.762,0,1.006,1.006,0,0,0,.323-.2l8.581-7.866a.99.99,0,0,0,.262-.7,1,1,0,0,0-.314-.684Z"
        transform="translate(-122.594 -120.239)"
        fill="#fc8f2c"
      />
    </svg>
  );
};

export const BackArrowLink = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35.235 35.229">
      <g id="Group_263" data-name="Group 263" transform="translate(0 0)">
        <path
          id="Path_279"
          data-name="Path 279"
          d="M0,17.653A17.5,17.5,0,1,1,1.332,24.4,17.617,17.617,0,0,1,0,17.653ZM17.617,33.439A15.786,15.786,0,1,0,1.826,17.653,15.807,15.807,0,0,0,17.617,33.439Z"
          transform="translate(0.001 -0.038)"
          fill="#fc8f2c"
        />
        <path
          id="Path_280"
          data-name="Path 280"
          d="M130.439,136.9l7.629-7.628a.913.913,0,0,1,1.292,1.292l-6.212,6.211h13.669a.914.914,0,0,1,0,1.827H133.29l6.263,6.259a.913.913,0,0,1-1.292,1.292l-7.867-7.866a1.043,1.043,0,0,1,.047-1.386Z"
          transform="translate(-121.428 -120.125)"
          fill="#fc8f2c"
        />
      </g>
    </svg>
  );
};
