import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import XSmallSpinner from 'component/Spinners/XSmallSpinner';

const IconTableCell = withStyles(() =>
  createStyles({
    root: {
      padding: '16px 0 16px 16px'
    }
  })
)(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    color: (props) =>
      props.noData || props.error
        ? theme.palette.secondary.main + '80'
        : theme.palette.secondary.main,
    backgroundColor: (props) =>
      props.noData || props.error
        ? theme.palette.background.paper + '60'
        : theme.palette.background.paper,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid ' + theme.palette.grey['200']
  },
  title: {
    borderBottom: '1px solid ' + theme.palette.grey['200'],
    fontWeight: 500,
    fontSize: '0.9rem',
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    height: '3.5rem',
    padding: '0 20px'
  },
  tableContainer: {
    height: (props) => (props.height ? props.height : '14rem'),
    overflowY: 'auto',
    width: '100%'
  },
  tableHeaderCell: {
    backgroundColor: theme.palette.background.paper,
    fontWeight: '700',
    color: theme.palette.text.secondary
  },
  tableRowEven: {
    backgroundColor: theme.palette.background.default
  },
  tableRowOdd: {
    backgroundColor: theme.palette.background.grey
  },
  click: {
    cursor: 'pointer'
  },
  center: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  footer: {
    height: '20px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '0 0 5px 5px'
  }
}));

const TableWidget = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>{props.title}</Box>
      <TableContainer className={classes.tableContainer}>
        {props.error ? (
          <Box className={classes.center}>{t('general.errorData')}</Box>
        ) : props.loading ? (
          <Box className={classes.center}>
            <XSmallSpinner />
          </Box>
        ) : props.rows.length < 1 ? (
          <Box className={classes.center}>{props.noData}</Box>
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {Object.keys(props.headers).map((key) => {
                  if (props.headers[key].icon) {
                    return (
                      <IconTableCell key={key} className={classes.tableHeaderCell}>
                        {props.headers[key].value}
                      </IconTableCell>
                    );
                  } else {
                    return (
                      <TableCell key={key} className={classes.tableHeaderCell}>
                        {props.headers[key]}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.rows.map((row, index) => (
                <TableRow
                  className={` ${props.rowOnClick && classes.click} 
									${index % 2 === 0 ? classes.tableRowEven : classes.tableRowOdd} `}
                  onClick={() => props.rowOnClick && props.rowOnClick(row)}
                  key={index}>
                  {Object.keys(row).map((key) => {
                    if (key !== 'id') {
                      if (row[key].icon) {
                        return <IconTableCell key={key}>{row[key].value}</IconTableCell>;
                      } else {
                        return <TableCell key={key}>{row[key]}</TableCell>;
                      }
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Box className={classes.footer} />
    </Box>
  );
};

export default TableWidget;
