import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Grid, Typography } from '@material-ui/core';
import Table from 'component/Table/Table';

export const UsersTable = ({ headerTitle, userData, usersSelected, onRowSelectionChange }) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: 'id'
    },
    {
      name: '',
      label: 'Username',
      options: {
        filter: false,
        customBodyRender: (_, tableMeta) => {
          return userData[tableMeta.rowIndex].username;
        }
      }
    },
    {
      name: 'email',
      label: 'Email'
    }
  ];

  const selectedRows = [];
  userData.forEach((user, index) => {
    if (usersSelected.indexOf(user.id) !== -1) {
      selectedRows.push(index);
    }
  });

  const tableConfig = {
    onRowSelectionChange,
    rowsSelected: selectedRows,
    rowData: userData,
    columns: columns,
    title: headerTitle,
    print: false,
    viewColumns: false,
    search: true,
    download: false,
    filter: false,
    flexView: false,
    selectableRows: 'multiple',
    selectableRowsOnClick: true,
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    components: {
      TableToolbarSelect: () => null
    },
    options: {
      selectToolbarPlacement: 'none'
    }
  };

  const RenderChips = () => {
    return userData.map((user, i) => {
      if (usersSelected.includes(user.id)) {
        return (
          <Chip
            key={i}
            id={user.id}
            label={user.username}
            name="users"
            color="primary"
            variant="outlined"
            style={{
              marginRight: '5px',
              marginTop: '5px'
            }}
          />
        );
      }
      return null;
    });
  };
  return (
    <>
      <Table tableConfig={tableConfig} />
      <Grid container item xs={12}>
        {usersSelected.length > 0 ? (
          usersSelected.length !== userData.length ? (
            <RenderChips />
          ) : (
            <Typography style={{ color: '#00000055' }}>All Users</Typography>
          )
        ) : null}
        {usersSelected.length === 0 ? (
          <Typography style={{ color: '#00000055' }}>
            {t('trainingManagement.form.noDataUsers')}
          </Typography>
        ) : null}
      </Grid>
    </>
  );
};

export default UsersTable;
