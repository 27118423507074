import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, useTheme } from '@material-ui/core';
import FormSubTitle from 'component/Inputs/FormSubTitle';
import SelectInput from 'component/Inputs/SelectInputs/SelectInput';
import TextArea from 'component/Inputs/TextArea';
import TextInput from 'component/Inputs/TextInput';
import FullScreenModal from 'component/Modals/FullScreenModal';

const TextModal = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [training, setTraining] = useState('');
  const [textKey, setTextKey] = useState('');
  const [texts, setTexts] = useState([
    {
      languageCode: '',
      text: '',
      error: false
    }
  ]);
  const [errors, setErrors] = useState({
    training: false,
    textKey: false
  });

  useEffect(() => {
    if (props.selectedText) {
      const buildTexts = props.languages.map((language) => {
        const foundTextByLanguage = props.selectedText.texts.find(
          (text) => text.languageCode === language.code
        );
        if (foundTextByLanguage) {
          return {
            languageCode: foundTextByLanguage.languageCode,
            text: foundTextByLanguage.text ? foundTextByLanguage.text : '',
            error: false
          };
        } else {
          return {
            languageCode: language.code,
            text: '',
            error: false
          };
        }
      });
      setTexts(buildTexts);
      setTextKey(props.selectedText.key);
      setTraining(props.selectedText.training);
    } else {
      const buildTexts = props.languages.map((language) => ({
        languageCode: language.code,
        text: '',
        error: false
      }));
      setTexts(buildTexts);
    }
  }, []);

  const handleSave = () => {
    if (handleCanSave()) {
      if (props.selectedText) {
        const textData = texts.map((text) => ({
          text: text.text,
          languageCode: text.languageCode
        }));
        let updateData = {
          key: props.selectedText.key,
          trainingId: Number(props.selectedText.trainingId),
          textData: textData
        };
        if (props.selectedText.key !== textKey) updateData = { ...updateData, newKey: textKey };
        props.updateCreateLabel(updateData);
      } else {
        const textData = texts.map((text) => ({
          ...(text.text.length > 0 && { text: text.text }),
          languageCode: text.languageCode
        }));
        props.addText(training, textKey, textData);
      }
    }
  };

  const onChange = (e, i) => {
    let textsCopy = [...texts];
    textsCopy[i][e.target.name] = e.target.value;
    textsCopy[i].error = false;
    setTexts(textsCopy);
  };

  const handleCanSave = () => {
    let canSave = true;
    let textsCopy = [...texts];
    let errorsCopy = { ...errors };
    textsCopy.forEach((text) => {
      if (text.languageCode.length < 1) {
        text.error = true;
        canSave = false;
      }
    });
    if (textKey.length < 1) {
      canSave = false;
      errorsCopy.textKey = true;
    }
    if (training.length < 1) {
      canSave = false;
      errorsCopy.training = true;
    }
    setErrors(errorsCopy);
    setTexts(textsCopy);
    return canSave;
  };

  return (
    <FullScreenModal
      width="35rem"
      icon={props.selectedText ? 'edit' : 'add'}
      title={props.selectedText ? t('textManagement.edit') : t('textManagement.create')}
      buttonText={props.selectedText ? t('textManagement.update') : t('textManagement.save')}
      type="save"
      handleCloseModal={() => props.handleCloseModal('text')}
      handleDone={handleSave}
      saving={props.saving}
      response={props.response}>
      {props.selectedText ? (
        <FormSubTitle title={training} />
      ) : (
        <Grid container item md={8} xs={12}>
          <SelectInput
            label={t('sharedLabels.training')}
            selected={training}
            name="training"
            onChange={(e) => {
              setTraining(e.target.value);
              setErrors({ ...errors, training: false });
            }}
            menuItems={props.trainings}
            required={true}
            error={errors.training}
            underText={errors.training ? t('textManagement.error.training') : null}
          />
        </Grid>
      )}
      <Grid container item md={8} xs={12} style={{ marginBottom: '20px' }}>
        <TextInput
          label="Key"
          value={textKey}
          name="text"
          required={true}
          error={errors.textKey}
          underText={errors.textKey ? t('textManagement.error.key') : null}
          onChange={(e) => {
            setTextKey(e.target.value);
            setErrors({ ...errors, textKey: false });
          }}
        />
      </Grid>
      <FormSubTitle title={t('sharedLabels.texts')} />
      {texts.map((text, index) => {
        return (
          <Grid
            container
            item
            key={index}
            xs={12}
            style={{ position: 'relative', marginBottom: '50px' }}>
            <div
              style={{
                height: '100%',
                width: '8px',
                backgroundColor: texts[index].error
                  ? theme.palette.error.main
                  : theme.palette.primary.main,
                position: 'absolute',
                marginLeft: '-30px'
              }}
            />
            <Grid item md={8} xs={12}>
              <Typography>
                {props.languages.find((language) => language.code === text.languageCode)?.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextArea
                label={t('sharedLabels.text')}
                value={texts[index].text}
                name="text"
                required={false}
                error={texts[index].error}
                onChange={(e) => onChange(e, index)}
              />
            </Grid>
          </Grid>
        );
      })}
    </FullScreenModal>
  );
};

export default TextModal;
