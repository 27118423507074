import React from 'react';
import { useTranslation } from 'react-i18next';
import FullScreenModal from 'component/Modals/FullScreenModal';

import TrainingTranslations from '../TrainingTranslations';

const TrainingTranslationsModal = (props) => {
  const { t } = useTranslation();
  const buttonText = props.selectedTraining
    ? t('trainingManagement.update')
    : t('trainingManagement.save');

  return (
    <FullScreenModal
      hideFooter
      buttonText={buttonText}
      icon={props.selectedTraining ? 'edit' : 'add'}
      title={
        props.selectedTraining
          ? t('trainingManagement.translation.edit')
          : t('trainingManagement.translation.add')
      }
      handleCloseModal={props.handleCloseModal}
      width="50rem">
      <TrainingTranslations
        training={props.selectedTraining}
        translations={props.selectedTraining ? props.selectedTraining.translations : []}
      />
    </FullScreenModal>
  );
};

export default TrainingTranslationsModal;
