import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import ViewContainer from 'component/ViewContainer/ViewContainer';
import BasicWidget from 'component/Widgets/BasicWidget';
import { ADMIN_NAV_ID } from 'constants/paths';
import { setSelectedNavItem } from 'ducks/nav';
import { buildBreadcrumbs } from 'helpers/buildBreadcrumbs';
import { useUser } from 'hooks';

const AdminPanel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isSuperUser } = useUser();

  useEffect(() => {
    dispatch(setSelectedNavItem(ADMIN_NAV_ID));
    buildBreadcrumbs('clear');
  }, []);

  const adminManagement = [
    {
      name: t('admin.manageGroups'),
      icon: 'icon_GROUPS',
      link: '/admin/groups'
    },
    {
      name: t('admin.manageUsers'),
      icon: 'icon_USER',
      link: '/admin/users'
    },
    {
      name: t('admin.manageCourses'),
      icon: 'icon_COURSES',
      link: '/admin/courses'
    },
    {
      name: t('admin.manageTrainings'),
      icon: 'icon_TRAININGS',
      link: '/admin/trainings'
    },
    {
      name: t('admin.manageLabels'),
      icon: 'icon_TEXTS',
      link: '/admin/texts'
    }
  ];

  if (isSuperUser) {
    adminManagement.splice(0, 0, {
      name: t('admin.manageOrganization'),
      icon: 'icon_GROUPS',
      link: '/admin/organization'
    });
    adminManagement.splice(5, 0, {
      name: t('admin.manageLanguages'),
      icon: 'icon_GROUPS',
      link: '/admin/languages'
    });
  }

  return (
    <ViewContainer title={t('admin.title')} subTitle={t('admin.subTitle')} flexView={true}>
      <Grid
        container
        item
        xs={12}
        md={10}
        lg={8}
        xl={5}
        spacing={2}
        style={{ alignContent: 'flex-start' }}>
        {adminManagement.map((item, id) => {
          return (
            <Grid item key={id} xs={12} sm={6} md={4} style={{ height: '10rem' }}>
              <BasicWidget
                title={item.name}
                hideValue={true}
                link={item.link}
                action={() =>
                  buildBreadcrumbs({ key: 'admin', name: t('admin.title'), viewTitle: item.name })
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </ViewContainer>
  );
};

export default AdminPanel;
