import React, { useCallback, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ConfirmButton from 'component/Buttons/ConfirmButton';
import styled from 'styled-components';
import { getBase64 } from 'util';

const StyledFileNameDisplay = styled.span`
  padding-left: 8px;
  color: grey;
`;

const UploadFile = (props) => {
  const [attachedFile, setAttachedFile] = useState(null);
  const { onFileUpload, disabled, buttonTitle, isValid, invalidFileTypeErrorMessage } = props;
  const fileInput = useRef();
  const handleOnChange = (event) => {
    event.preventDefault();
    const file = fileInput.current.files[0];

    if (!isValid(file.type)) {
      toast.error(invalidFileTypeErrorMessage);
      return;
    }

    getBase64(file, (result) => {
      setAttachedFile(file);
      onFileUpload({ base64: result });
    });
  };

  const handleOnClickUpload = useCallback(() => {
    fileInput.current.click();
  }, [fileInput]);

  return (
    <>
      <input
        style={{ display: 'none' }}
        type="file"
        ref={fileInput}
        onChange={handleOnChange}
        disabled={disabled}
      />
      <ConfirmButton onClick={handleOnClickUpload} disabled={disabled}>
        {buttonTitle}
      </ConfirmButton>
      {attachedFile ? <StyledFileNameDisplay>{attachedFile.name}</StyledFileNameDisplay> : ''}
    </>
  );
};

export default UploadFile;
