import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ProgressListWidget from 'component/Widgets/ProgressListWidget';
import TOTAL_SESSION_COUNT_GROUPED_BY_TRAINING from 'graphql/TotalSessionCountGroupedByTraining';
import { buildBreadcrumbs } from 'helpers/buildBreadcrumbs';
import { useTrainings, useTrainingTranslation } from 'hooks';

const SessionPerTraining = (props) => {
  const theme = useTheme();
  const { trainings, getTrainingDetailsById, loadingTrainings } = useTrainings();
  const { getTranslatedNameFromTraining } = useTrainingTranslation();
  const collapseLG = useMediaQuery(theme.breakpoints.only('lg'));
  const { t } = useTranslation();
  let totalSessions = 0;
  let widgetData = [];

  const { data, error, loading } = useQuery(TOTAL_SESSION_COUNT_GROUPED_BY_TRAINING, {
    fetchPolicy: 'no-cache',
    variables: {
      ...(props.restrictedUser ? props.userId : props.orgId),
      ...(props.filteredBy && props.cohortId)
    }
  });

  if (!loadingTrainings && data && trainings.length !== 0) {
    widgetData = data.totalSessionsGroupedByTraining.map((training) => {
      totalSessions += training.totalSessions;
      const trainingDetails = getTrainingDetailsById({
        id: training.trainingId
      });
      const trainingName = getTranslatedNameFromTraining({ training: trainingDetails });
      return {
        name: trainingName,
        value: training.totalSessions,
        id: training.trainingId,
        link: '/training/' + training.trainingId,
        action: () => buildBreadcrumbs({ key: 'dash', name: 'Dashboard', viewTitle: trainingName })
      };
    });
  }

  if (widgetData.length > 0)
    widgetData = widgetData.map((item) => ({ ...item, total: totalSessions }));

  return (
    <ProgressListWidget
      height={props.restrictedUser ? (collapseLG ? '' : '18.65rem') : ''}
      title={t('dashboard.sessionsCard.title')}
      data={widgetData}
      noData={t('dashboard.sessionsCard.noData')}
      error={error}
      loading={loading}
      outOfValues={false}
    />
  );
};

export default SessionPerTraining;
