import { gql } from '@apollo/client';

const GET_ORGANIZATION = gql`
  query getOrganization($orgId: Int!) {
    organization(id: $orgId) {
      id
      name
      avatar
      courses {
        id
        name
        description
        trainings {
          id
          name
          details
          translations {
            id
            name
            details
            language {
              code
            }
          }
          cohorts {
            id
            name
            user {
              id
              username
            }
          }
          user {
            id
            username
            isSuperuser
            isActive
            email
            cohorts {
              id
            }
          }
        }
      }
    }
  }
`;

export default GET_ORGANIZATION;
