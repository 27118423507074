export const DATE_FORMATS = [
  {
    id: 'YMD',
    name: 'YYYY/MM/DD'
  },
  {
    id: 'DMY',
    name: 'DD/MM/YYYY'
  },
  {
    id: 'MDY',
    name: 'MM/DD/YYYY'
  },
  {
    id: 'YMD2',
    name: 'YYYY-MM-DD'
  },
  {
    id: 'DMY2',
    name: 'DD-MM-YYYY'
  },
  {
    id: 'MDY2',
    name: 'MM-DD-YYYY'
  }
];
