import React from 'react';

export const PowerIconBlue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.427"
      height="17.259"
      viewBox="0 0 14.427 14.259">
      <g id="powerBlue" transform="translate(-55.427 -45.241)">
        <path
          id="Path_36"
          data-name="Path 36"
          d="M142.068,160.4a7.214,7.214,0,1,1-11.909-5.485.7.7,0,0,1,.914,1.066,5.889,5.889,0,0,0-.653.657,5.813,5.813,0,1,0,8.192-.679.7.7,0,0,1,.91-1.072,7.2,7.2,0,0,1,2.546,5.513Z"
          transform="translate(-72.214 -108.107)"
          fill="#2e3c77"
        />
        <path
          id="Path_39"
          data-name="Path 39"
          d="M243.732,131.262l-.018,6.405a.7.7,0,0,1-1.4,0l.016-6.4a.7.7,0,1,1,1.4,0Z"
          transform="translate(-180.363 -85.32)"
          fill="#2e3c77"
        />
      </g>
    </svg>
  );
};

export const PowerIconBlack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.427"
      height="17.259"
      viewBox="0 0 14.427 14.259">
      <g id="powerBlack" transform="translate(-32.427 -45.241)">
        <path
          id="Path_32"
          data-name="Path 32"
          d="M142.068,160.4a7.214,7.214,0,1,1-11.909-5.485.7.7,0,0,1,.914,1.066,5.889,5.889,0,0,0-.653.657,5.813,5.813,0,1,0,8.192-.679.7.7,0,0,1,.91-1.072,7.2,7.2,0,0,1,2.546,5.513Z"
          transform="translate(-95.214 -108.107)"
          fill="#231f20"
        />
        <path
          id="Path_33"
          data-name="Path 33"
          d="M243.732,131.262l-.018,6.405a.7.7,0,0,1-1.4,0l.016-6.4a.7.7,0,1,1,1.4,0Z"
          transform="translate(-203.363 -85.32)"
          fill="#231f20"
        />
      </g>
    </svg>
  );
};
