import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { FormControl, FormHelperText, TextField, Typography } from '@material-ui/core';
import { setResetPasswordInvalidToken } from 'ducks/auth';

import { SEND_PASSWORD_RESET_LINK } from '../../graphql/forgotPassword';

import Container from './Container';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let history = useHistory();
  const [invalidFormat, setInvalidFormat] = useState(false);
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const [sendPasswordResetEmail, { loading: passwordResetLoading }] = useMutation(
    SEND_PASSWORD_RESET_LINK,
    {
      onCompleted(passwordResetData) {
        dispatch(setResetPasswordInvalidToken(false));
        if (passwordResetData.sendPasswordResetEmail.success) {
          setEmailSent(true);
        }
      }
    }
  );

  function onSubmit(e) {
    e.preventDefault();

    if (emailSent) {
      toLogin();
    } else {
      if (email.length > 0) {
        sendPasswordResetEmail({ variables: { email: email } });
      } else {
        setInvalidFormat(true);
      }
    }
  }

  const onChange = (e) => {
    setInvalidFormat(false);
    setEmail(e.target.value);
  };

  const toLogin = () => {
    clearData();
    history.push('/login');
  };

  const clearData = () => {
    setEmailSent(false);
    setEmail('');
  };

  const validateEmail = () => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.length > 0 && !email.match(validRegex)) {
      setInvalidFormat(true);
    }
  };

  const title = (
    <Typography color="textPrimary" gutterBottom variant="h2">
      {t('forgotPassword.title')}
    </Typography>
  );

  return (
    <Container
      title={title}
      buttonText={emailSent ? t('forgotPassword.buttonAlt') : t('forgotPassword.button')}
      buttonColor={emailSent ? 'secondary' : 'primary'}
      onSubmit={onSubmit}
      footerText={emailSent ? '' : t('forgotPassword.buttonAlt')}
      onFooterClick={toLogin}
      loading={passwordResetLoading}
      success={emailSent}>
      <>
        <Typography
          variant="h6"
          color={invalidFormat ? 'error' : 'secondary'}
          style={{
            paddingBottom: '1.1rem',
            paddingTop: '1rem',
            color: emailSent ? '#388e3c' : ''
          }}>
          {emailSent ? t('forgotPassword.success') : t('forgotPassword.subText')}
        </Typography>
        <FormControl error={invalidFormat}>
          <TextField
            style={{ backgroundColor: emailSent && '#d3d3d340', opacity: emailSent && '0.5' }}
            disabled={emailSent}
            fullWidth
            autoFocus
            autoComplete="true"
            label={t('sharedLabels.email')}
            margin="normal"
            name="email"
            type="email"
            variant="outlined"
            id="email"
            error={invalidFormat}
            value={email}
            onChange={(e) => onChange(e)}
            onBlur={validateEmail}
          />
          <FormHelperText style={{ height: '5px' }} id="input-helper-text">
            {invalidFormat && t('inputs.email.invalidFormat')}
          </FormHelperText>
        </FormControl>
      </>
    </Container>
  );
};

export default ForgotPassword;
