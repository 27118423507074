import { createContext, useCallback, useContext, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { ALL_TRAININGS } from 'graphql/Trainings';

const TrainingsContext = createContext({});

export const TrainingsProvider = ({ children }) => {
  const [trainings, setTrainings] = useState([]);
  const [getAllTrainings, { refetch, loading, error }] = useLazyQuery(ALL_TRAININGS, {
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      setTrainings(response.allTrainings);
    }
  });

  const getTrainingDetailsById = useCallback(
    ({ id }) => {
      if (loading) return null;
      const foundTraining = trainings.filter((training) => {
        return parseInt(training.id, 10) === parseInt(id, 10);
      });

      if (foundTraining.length === 0) {
        return null;
      }

      return foundTraining[0];
    },
    [trainings, loading]
  );

  return (
    <TrainingsContext.Provider
      value={{
        trainings,
        getAllTrainings,
        refetchTrainings: refetch,
        loadingTrainings: loading,
        errorTrainings: error,
        getTrainingDetailsById
      }}>
      {children}
    </TrainingsContext.Provider>
  );
};

export const useTrainings = () => useContext(TrainingsContext);
