import { gql } from '@apollo/client';

export const ADD_TOKEN = gql`
  mutation addToken($org: ID, $qty: Int) {
    addToken(orgId: $org, quantity: $qty) {
      success
      error
      token {
        id
        quantity
        organization {
          name
        }
        transactions {
          id
          quantity
          description
          createdAt
          user {
            username
          }
        }
      }
    }
  }
`;

export const UPDATE_TOKEN = gql`
  mutation updateToken($org: ID, $qty: Int) {
    updateToken(orgId: $org, quantity: $qty) {
      success
      error
      token {
        id
        quantity
        organization {
          name
        }
        transactions {
          id
          quantity
          description
          createdAt
          user {
            username
          }
        }
      }
    }
  }
`;

export const GET_TOKEN = gql`
  query token($org: Int!) {
    token(orgId: $org) {
      id
      quantity
      updatedAt
      transactions {
        id
        quantity
        description
        createdAt
        user {
          username
        }
      }
    }
  }
`;

export const GET_TOKEN_TRANSATIONS = gql`
  query tokenTransactions($org: Int!) {
    tokenTransactions(orgId: $org) {
      id
      quantity
      description
      createdAt
      user {
        username
      }
    }
  }
`;
