import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { SET_PASSWORD, UPDATE_PASSWORD } from 'graphql/Auth';

const AuthContext = createContext({});

const SUCCESSFULLY_SET_PASSWORD = 'SUCCESSFULLY_SET_PASSWORD';
const SUCCESSFULLY_UPDATED_PASSWORD = 'SUCCESSFULLY_UPDATED_PASSWORD';
const ERROR_SETTING_PASSWORD = 'ERROR_SETTING_PASSWORD';
const ERROR_SETTING_PASSWORD_EXPIRED_TOKEN = 'ERROR_SETTING_PASSWORD_EXPIRED_TOKEN';
const ERROR_UPDATING_PASSWORD = 'ERROR_UPDATING_PASSWORD';
const ERROR_UPDATING_PASSWORD_EXPIRED_TOKEN = 'ERROR_UPDATING_PASSWORD_EXPIRED_TOKEN';

export const AuthProvider = ({ value, children }) => {
  const { t } = useTranslation();
  const [authenticated, setAuthenticated] = useState(value);
  const [status, setStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const resetStatus = useCallback(() => {
    setStatus(null);
  }, [setStatus]);

  const resetErrorMessage = useCallback(() => {
    setErrorMessage('');
  }, [setErrorMessage]);

  const [passwordResetRequest, { loading: loadingPasswordResetRequest }] = useMutation(
    UPDATE_PASSWORD,
    {
      onCompleted(data) {
        if (data.passwordReset.success) {
          setStatus(SUCCESSFULLY_UPDATED_PASSWORD);
          toast.success(t('resetPassword.subTextSuccess'));
        }
        if (data.passwordReset.errors) {
          let errorMessage =
            data.passwordReset.errors[Object.keys(data.passwordReset.errors)[0]][0].message;
          toast.error(errorMessage);
          setErrorMessage(errorMessage);
          if (
            data.passwordReset.errors[Object.keys(data.passwordReset.errors)[0]][0].code ===
            'expired_token'
          ) {
            setStatus(ERROR_UPDATING_PASSWORD_EXPIRED_TOKEN);
          } else {
            setStatus(ERROR_UPDATING_PASSWORD);
          }
        }
      }
    }
  );

  const [passwordSetRequest, { loading: loadingPasswordSetRequest }] = useMutation(SET_PASSWORD, {
    onCompleted(data) {
      if (data.passwordSet.success) {
        setStatus(SUCCESSFULLY_SET_PASSWORD);
        toast.success(t('setPassword.subTextSuccess'));
      }
      if (data.passwordSet.errors) {
        let errorMessage =
          data.passwordSet.errors[Object.keys(data.passwordSet.errors)[0]][0].message;
        toast.error(errorMessage);
        setErrorMessage(errorMessage);
        if (
          data.passwordSet.errors[Object.keys(data.passwordSet.errors)[0]][0].code ===
          'expired_token'
        ) {
          setStatus(ERROR_SETTING_PASSWORD_EXPIRED_TOKEN);
        } else {
          setStatus(ERROR_SETTING_PASSWORD);
        }
      }
    }
  });

  const passwordReset = useCallback(
    ({ token, password, repeatPassword }) => {
      passwordResetRequest({
        variables: {
          token,
          password,
          repeatPassword
        }
      });
    },
    [passwordResetRequest]
  );

  const passwordSet = useCallback(
    ({ token, password, repeatPassword }) => {
      passwordSetRequest({
        variables: {
          token,
          password,
          repeatPassword
        }
      });
    },
    [passwordSetRequest]
  );

  const loading = useMemo(() => {
    return loadingPasswordResetRequest || loadingPasswordSetRequest;
  }, [loadingPasswordResetRequest, loadingPasswordSetRequest]);

  const isSuccessfullyUpdatedPassword = useMemo(() => {
    return status === SUCCESSFULLY_UPDATED_PASSWORD;
  }, [status]);

  const isSuccessfullySetPassword = useMemo(() => {
    return status === SUCCESSFULLY_SET_PASSWORD;
  }, [status]);

  const isErrorUpdatingPassword = useMemo(() => {
    return status === ERROR_UPDATING_PASSWORD;
  }, [status]);

  const isErrorUpdatingPasswordExpiredToken = useMemo(() => {
    return status === ERROR_UPDATING_PASSWORD_EXPIRED_TOKEN;
  }, [status]);

  const isErrorSettingPassword = useMemo(() => {
    return status === ERROR_SETTING_PASSWORD;
  }, [status]);

  const isErrorSettingPasswordExpiredToken = useMemo(() => {
    return status === ERROR_SETTING_PASSWORD_EXPIRED_TOKEN;
  }, [status]);

  return (
    <AuthContext.Provider
      value={{
        loading,
        loadingPasswordResetRequest,
        loadingPasswordSetRequest,
        authenticated,
        isSuccessfullyUpdatedPassword,
        isSuccessfullySetPassword,
        isErrorUpdatingPassword,
        isErrorUpdatingPasswordExpiredToken,
        isErrorSettingPassword,
        isErrorSettingPasswordExpiredToken,
        errorMessage,
        resetStatus,
        resetErrorMessage,
        setAuthenticated,
        passwordReset,
        passwordSet
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
