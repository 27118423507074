import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_ORGANIZATION, GET_ORGANIZATIONS, UPDATE_ORGANIZATION } from 'graphql/Organization';

import GET_ORGANIZATION from '../graphql/getOrganization';
import { useUser } from '../hooks';

const OrganizationApi = createContext({});

const SUCCESSFULLY_CREATED = 'SUCCESSFULLY_CREATED';
const SUCCESSFULLY_UPDATED = 'SUCCESSFULLY_UPDATED';

export const OrganizationProvider = ({ children }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [organizationData, setOrganizationData] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [status, setStatus] = useState(null);

  const resetStatus = useCallback(() => {
    setStatus(null);
  }, [setStatus]);

  const getOrganizationDetail = useCallback(() => {
    if (organizationData) {
      return {
        id: organizationData.organization.id,
        name: organizationData.organization.name,
        avatar: organizationData.organization.avatar
      };
    }
  }, [organizationData]);

  const setOrganizationNameAvatar = useCallback(
    ({ name, avatar }) => {
      setOrganizationData({
        ...organizationData,
        organization: {
          ...organizationData.organization,
          name,
          avatar
        }
      });
    },
    [organizationData, setOrganizationData]
  );

  const [getOrganizationsRequest, { loading: loadingGetOrganizationsRequest }] = useLazyQuery(
    GET_ORGANIZATIONS,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        setOrganizations(data.allOrganizations);
      }
    }
  );

  const [getOrganizationRequest] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data.organization) {
        setOrganizationData({
          organization: data.organization,
          allOrgs: user.organizations
        });
      }
    }
  });

  const getOrganization = useCallback(() => {
    getOrganizationRequest({
      variables: {
        orgId: localStorage.getItem('orgId')
      }
    });
  }, [getOrganizationRequest]);

  const getAllOrganizations = useCallback(() => {
    getOrganizationsRequest();
  }, [getOrganizationsRequest]);

  const getOrganizationById = useCallback(
    ({ id }) => {
      return organizations.find((org) => org.id === id);
    },
    [organizations]
  );

  const [updateCreateOrganizationRequest, { loading: loadingUpdateCreateOrganizationRequest }] =
    useMutation(UPDATE_ORGANIZATION, {
      onCompleted({ organizationMutation: { success } }) {
        if (success) {
          setStatus(SUCCESSFULLY_UPDATED);
          toast.success(t('organizationManagement.success.updated'));
        } else {
          toast.error(t('organizationManagement.error.failed'));
        }
      }
    });

  const [createOrganizationRequest, { loading: loadingCreateOrganizationRequest }] = useMutation(
    CREATE_ORGANIZATION,
    {
      onCompleted({ organizationMutation: { success } }) {
        if (success) {
          setStatus(SUCCESSFULLY_CREATED);
          toast.success(t('organizationManagement.success.created'));
        } else {
          toast.error(t('organizationManagement.error.failed'));
        }
      }
    }
  );

  const updateOrganization = useCallback(
    ({ id, name, avatarBase64, tokenEnabled }) => {
      const variables = {
        id: parseInt(id, 10),
        name,
        tokenEnabled
      };
      if (avatarBase64) {
        variables['avatarBase64'] = avatarBase64;
      }
      updateCreateOrganizationRequest({
        variables
      });
    },
    [updateCreateOrganizationRequest]
  );

  const createOrganization = useCallback(
    ({ name, avatarBase64, tokenEnabled }) => {
      const variables = {
        name,
        tokenEnabled
      };
      if (avatarBase64) {
        variables['avatarBase64'] = avatarBase64;
      }
      createOrganizationRequest({
        variables
      });
    },
    [createOrganizationRequest]
  );

  const loadingOrganizations = useMemo(() => {
    return loadingGetOrganizationsRequest;
  }, [loadingGetOrganizationsRequest]);

  const updatingOrganization = useMemo(() => {
    return loadingUpdateCreateOrganizationRequest;
  }, [loadingUpdateCreateOrganizationRequest]);

  const creatingOrganization = useMemo(() => {
    return loadingCreateOrganizationRequest;
  }, [loadingCreateOrganizationRequest]);

  const isSuccessfullyCreated = useMemo(() => {
    return status === SUCCESSFULLY_CREATED;
  }, [status]);

  const isSuccessfullyUpdated = useMemo(() => {
    return status === SUCCESSFULLY_UPDATED;
  }, [status]);

  return (
    <OrganizationApi.Provider
      value={{
        resetStatus,
        isSuccessfullyCreated,
        isSuccessfullyUpdated,
        loadingOrganizations,
        updatingOrganization,
        creatingOrganization,
        organizations,
        organizationData,
        createOrganization,
        updateOrganization,
        getAllOrganizations,
        getOrganizationById,
        getOrganization,
        setOrganizationData,
        getOrganizationDetail,
        setOrganizationNameAvatar
      }}>
      {children}
    </OrganizationApi.Provider>
  );
};

export const useOrganization = () => useContext(OrganizationApi);
