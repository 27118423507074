import { gql } from '@apollo/client';

const TOTAL_TIME_SPENT_GROUPED_BY_TRAINING = gql`
  query totalTimeSpent($orgId: Int, $userId: Int, $cohortId: Int) {
    totalMinutesGroupedByTraining(orgId: $orgId, userId: $userId, cohortId: $cohortId) {
      trainingId
      trainingName
      totalMinutes
    }
  }
`;

export default TOTAL_TIME_SPENT_GROUPED_BY_TRAINING;
