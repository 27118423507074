import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import ConfirmButton from 'component/Buttons/ConfirmButton';
import SelectInput from 'component/Inputs/SelectInputs/SelectInput';
import TextInput from 'component/Inputs/TextInput';
import { useLanguages, useTrainings, useTrainingTranslation } from 'hooks';
import styled from 'styled-components';

const StyledTableContainer = styled(TableContainer)`
  overflow-y: overlay;
  margin-top: 10px;
`;

const StyledActionTableCell = styled(TableCell)`
  text-align: right;
`;

const StyledActionInput = styled.div`
  text-align: right;
  margin-bottom: 20px;
`;

const TraningTranslations = (props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    id: props.training.id,
    name: '',
    details: ''
  });
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [errors, setErrors] = useState({
    name: '',
    details: '',
    selectedLanguage: ''
  });
  const [status, setStatus] = useState(null);
  const {
    loadingGetTrainingTranslationsRequest,
    loading,
    trainingTranslations,
    deleteTrainingTranslation,
    getTrainingTranslationsByTrainingId,
    isSuccessfullyDeleted,
    isSuccessfullyAdded,
    isSuccessfullyUpdated,
    refetchTrainingTranslationsByTrainingId,
    resetStatus,
    addTrainingTranslation,
    updateTrainingTranslation
  } = useTrainingTranslation();
  const { languages } = useLanguages();
  const { refetchTrainings } = useTrainings();

  const resetFormData = useCallback(() => {
    setFormData({
      id: props.training.id,
      name: '',
      details: ''
    });
  }, [setFormData]);

  const onDeleteTranslation = useCallback(
    ({ translation }) => {
      deleteTrainingTranslation({
        ids: [translation.id]
      });
    },
    [deleteTrainingTranslation]
  );

  const onEditTranslation = useCallback(
    ({ translation }) => {
      const language = languages.filter((language) => language.code === translation.language.code);
      setStatus('STATUS_EDITING');
      setFormData({
        id: translation.id,
        name: translation.name,
        details: translation.details
      });
      setSelectedLanguage(language[0].id);
    },
    [setStatus, setSelectedLanguage, setFormData, languages]
  );

  const handleOnChangeLanguage = useCallback(
    (e) => {
      setSelectedLanguage(e.target.value);
      setErrors({ ...errors, selectedLanguage: '' });
    },
    [errors, setErrors, setSelectedLanguage]
  );

  const handleOnChangeInput = useCallback(
    (e) => {
      const value = e.target.value;

      setFormData({
        ...formData,
        [e.target.name]: value
      });

      if (value !== '') {
        setErrors({
          ...errors,
          [e.target.name]: ''
        });
      } else {
        const errorMessages = {
          name: 'Name is required.',
          details: 'Details is required.'
        };
        setErrors({
          ...errors,
          [e.target.name]: errorMessages[e.target.name]
        });
      }
    },
    [formData, errors, setFormData, setErrors]
  );

  const validation = useCallback(
    (callback) => {
      const errors = {};
      setErrors(errors);
      if (formData.name === '') {
        errors.name = 'Name is required.';
      }
      if (formData.details === '') {
        errors.details = 'Details is required.';
      }
      if (selectedLanguage === null) {
        errors.selectedLanguage = 'Language is required.';
      }
      let isValid = true;
      if (Object.values(errors).length !== 0) {
        isValid = false;
        setErrors(errors);
      }
      callback(isValid);
    },
    [formData, selectedLanguage, setErrors]
  );

  const handleOnAddLangauge = useCallback(() => {
    validation((isValid) => {
      if (isValid) {
        const { name, details } = formData;
        const language = languages.filter((language) => language.id === selectedLanguage);
        addTrainingTranslation({
          name,
          details,
          languageCode: language[0].code,
          trainingId: props.training.id
        });
      }
    });
  }, [validation, addTrainingTranslation, formData, selectedLanguage, languages]);

  const handleOnEditLanguage = useCallback(() => {
    validation((isValid) => {
      if (isValid) {
        const language = languages.filter((language) => language.id === selectedLanguage);
        updateTrainingTranslation({
          id: formData.id,
          name: formData.name,
          details: formData.details,
          languageCode: language[0].code,
          trainingId: props.training.id
        });
      }
    });
  }, [formData, selectedLanguage, updateTrainingTranslation, validation]);

  const handleOnCancelEdit = useCallback(() => {
    setStatus(null);
    resetFormData();
    setSelectedLanguage(null);
  }, [setStatus, setFormData, setSelectedLanguage]);

  useEffect(() => {
    if (props.training.action === 'EDIT_TRANSLATION') {
      getTrainingTranslationsByTrainingId({
        trainingId: props.training.id
      });
    }
  }, []);

  useEffect(() => {
    if (isSuccessfullyDeleted || isSuccessfullyAdded || isSuccessfullyUpdated) {
      resetStatus();
      refetchTrainingTranslationsByTrainingId({
        trainingId: props.training.id
      });
      refetchTrainings();
      setSelectedLanguage(null);
      resetFormData();
      if (isSuccessfullyUpdated) {
        setStatus(null);
      }
    }
  }, [
    isSuccessfullyDeleted,
    isSuccessfullyAdded,
    isSuccessfullyUpdated,
    refetchTrainingTranslationsByTrainingId,
    resetStatus,
    setSelectedLanguage,
    setFormData
  ]);

  return (
    <div>
      <div>
        <TextInput
          label={t('sharedLabels.name')}
          name="name"
          onChange={handleOnChangeInput}
          value={formData.name}
          error={errors.name}
          underText={errors.name}
          disabled={loading}
        />
        <TextInput
          label={t('sharedLabels.details')}
          name="details"
          onChange={handleOnChangeInput}
          value={formData.details}
          error={errors.details}
          underText={errors.details}
          disabled={loading}
        />
        <SelectInput
          multiple={false}
          label={t('trainingManagement.translation.select')}
          selected={selectedLanguage}
          name="select-language"
          onChange={handleOnChangeLanguage}
          menuItems={languages}
          required={true}
          disabled={loading}
          error={errors.selectedLanguage}
          underText={errors.selectedLanguage}
        />
        <StyledActionInput>
          {status === 'STATUS_EDITING' ? (
            <ConfirmButton onClick={handleOnEditLanguage} disabled={loading}>
              {t('languagesManagement.form.update.title')}
            </ConfirmButton>
          ) : (
            <ConfirmButton onClick={handleOnAddLangauge} disabled={loading}>
              {t('languagesManagement.form.add.title')}
            </ConfirmButton>
          )}{' '}
          {status === 'STATUS_EDITING' ? (
            <ConfirmButton onClick={handleOnCancelEdit} disabled={loading}>
              {t('general.cancel')}
            </ConfirmButton>
          ) : null}
        </StyledActionInput>
      </div>
      {loadingGetTrainingTranslationsRequest ? (
        <Typography style={{ color: '#00000055' }}>
          {t('trainingManagement.translation.fetching')}
        </Typography>
      ) : (
        <>
          {trainingTranslations.length === 0 && !loadingGetTrainingTranslationsRequest ? (
            <Typography style={{ color: '#00000055' }}>
              {t('trainingManagement.translation.noTranslation')}
            </Typography>
          ) : null}
        </>
      )}
      <>
        {trainingTranslations.length !== 0 ? (
          <StyledTableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('sharedLabels.name')}</TableCell>
                  <TableCell>{t('sharedLabels.details')}</TableCell>
                  <TableCell>{t('languagesManagement.form.label.code')}</TableCell>
                  <StyledActionTableCell></StyledActionTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trainingTranslations.map((translation) => {
                  return (
                    <TableRow key={translation.id}>
                      <TableCell>{translation.name}</TableCell>
                      <TableCell>{translation.details}</TableCell>
                      <TableCell>{translation.language.code}</TableCell>
                      <StyledActionTableCell>
                        <ConfirmButton
                          disabled={loading}
                          onClick={() => onDeleteTranslation({ translation })}>
                          {t('trainingManagement.translation.button.delete')}
                        </ConfirmButton>{' '}
                        <ConfirmButton
                          disabled={loading}
                          onClick={() => onEditTranslation({ translation })}>
                          {t('trainingManagement.translation.button.edit')}
                        </ConfirmButton>
                      </StyledActionTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
        ) : null}
      </>
    </div>
  );
};

export default TraningTranslations;
