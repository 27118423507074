import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  dates: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  date: {
    width: '110px',
    textAlign: 'center'
  },
  spacer: {
    fontWeight: '400',
    opacity: '0.7'
  },
  left: {
    opacity: (props) => (props.currentIndex === 0 ? '0.2' : '1'),
    cursor: (props) => (props.currentIndex === 0 ? 'not-allowed' : 'pointer')
  },
  right: {
    opacity: (props) => (props.currentIndex === props.lastIndex ? '0.2' : '1'),
    cursor: (props) => (props.currentIndex === props.lastIndex ? 'not-allowed' : 'pointer')
  }
});

const WeekCarousel = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <ChevronLeft onClick={() => props.onClick('left')} className={classes.left} />
      <Box className={classes.dates}>
        <span className={classes.date}>{props.start}</span>
        <span className={classes.spacer}> {t('general.to')} </span>
        <span className={classes.date}>{props.end}</span>
      </Box>
      <ChevronRight onClick={() => props.onClick('right')} className={classes.right} />
    </Box>
  );
};

export default WeekCarousel;
