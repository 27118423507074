import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import XSmallSpinner from 'component/Spinners/XSmallSpinner';

const useStyles = makeStyles((theme) => ({
  root: {
    height: (props) => (props.height ? props.height : '10rem'),
    padding: '20px 20px 20px 20px',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid ' + theme.palette.grey['200']
  },
  title: {
    fontWeight: 500,
    color: theme.palette.secondary.main,
    fontSize: '0.9rem',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    height: '40px',
    paddingBottom: '15px'
  },
  contentContainer: {
    flex: '1 1 0%'
  },
  center: {
    display: 'flex',
    paddingBottom: '20px',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dateContainer: {
    color: theme.palette.primary.main,
    paddingTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  dayText: {
    fontWeight: 600,
    fontSize: '2rem',
    padding: '20px 5px 0px 0px'
  }
}));

const TotalTimeSpent = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const display = (n) => {
    const hours = n / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    if (n < 60) return n + ' m';
    return rhours + 'h ' + rminutes + 'm';
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>{props.title}</Box>
      <Box className={classes.contentContainer}>
        {props.error ? (
          <Box className={classes.center}>{t('general.errorData')}</Box>
        ) : props.loading ? (
          <Box className={classes.center}>
            <XSmallSpinner />
          </Box>
        ) : (
          <Box className={classes.dateContainer}>
            <Box className={classes.dayText}>{display(props.timeSpent)}</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TotalTimeSpent;
