import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Box, makeStyles } from '@material-ui/core';
import { useLogout } from 'api/Logout';
import { PersonIconBlack, PersonIconBlue } from 'assets/images/icons/PersonIcon';
import { PowerIconBlack, PowerIconBlue } from 'assets/images/icons/PowerIcon';
import { LOGOUT_NAV_ID, SETTINGS_NAV_ID } from 'constants/paths';
import { useUser } from 'hooks';

import NavDropdown from '../NavComponents/NavDropdown';

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    height: '32px',
    width: '32px',
    borderRadius: '100%',
    fontWeight: '700',
    border: '1.5px solid ' + theme.palette.primary.main,
    color: theme.palette.primary.main,
    marginRight: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
}));

const UserActions = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const { t } = useTranslation();
  const { logout } = useLogout();
  const { user } = useUser();

  const handleSelectItem = (item) => {
    if (item.id === LOGOUT_NAV_ID) {
      logout();
    } else {
      history.push({ pathname: item.route });
      props.setSelected(item.id);
    }
  };

  const dropdownItems = [
    {
      icon: <PersonIconBlack />,
      hoverIcon: <PersonIconBlue />,
      name: t('menu.userSettings'),
      value: null,
      id: SETTINGS_NAV_ID,
      route: '/profile/user',
      action: handleSelectItem
    },
    {
      icon: <PowerIconBlack />,
      hoverIcon: <PowerIconBlue />,
      name: t('menu.logout'),
      value: null,
      id: LOGOUT_NAV_ID,
      action: handleSelectItem
    }
  ];

  const icon =
    user?.firstName && user?.lastName ? (
      user.firstName.split('')[0] + user.lastName.split('')[0]
    ) : (
      <PersonIconBlue />
    );

  return (
    <Box
      onClick={() => props.collapse && props.handleShowNavSlideOut(dropdownItems, 'right')}
      className={classes.menuIcon}>
      {props.collapse ? icon : <NavDropdown nav={dropdownItems}>{icon}</NavDropdown>}
    </Box>
  );
};

export default UserActions;
