const USER_DETAILS_VIEW_FILTERS = 'USER_DETAILS_VIEW_FILTERS';
const USER_DETAILS_TABLE_FILTERS = 'USER_DETAILS_TABLE_FILTERS';

const initialState = {
  userDetailsViewFilters: {
    course: '',
    training: '',
    filter: '',
    selectedSwitch: 'assessment'
  },
  userDetailsTableFilters: {
    startDate: { name: 'All', id: 'all' },
    completionDate: { name: 'All', id: 'all' },
    performance: { name: 'All', id: 'all' }
  }
};

export const setViewFilters = (filters) => {
  return { type: USER_DETAILS_VIEW_FILTERS, filters };
};

export const setTableFilters = (filters) => {
  return { type: USER_DETAILS_TABLE_FILTERS, filters };
};

export const userDetails = (state = initialState, action) => {
  switch (action.type) {
    case USER_DETAILS_VIEW_FILTERS:
      return {
        ...state,
        userDetailsViewFilters: action.filters
      };
    case USER_DETAILS_TABLE_FILTERS:
      return {
        ...state,
        userDetailsTableFilters: action.filters
      };
    default:
      return state;
  }
};
