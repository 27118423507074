import React from 'react';

export const PersonIconBlue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.209"
      height="16.626"
      viewBox="0 0 12.209 13.626">
      <g id="personBlue" transform="translate(-56.536 -67.187)">
        <path
          id="Path_38"
          data-name="Path 38"
          d="M1067.056,134.868a3.8,3.8,0,1,1,2.682-1.113A3.8,3.8,0,0,1,1067.056,134.868Zm0-6.183a2.395,2.395,0,1,0,1.689.7A2.393,2.393,0,0,0,1067.056,128.685Z"
          transform="translate(-1004.414 -60.092)"
          fill="#2e3c77"
        />
        <path
          id="Path_37"
          data-name="Path 37"
          d="M1034.132,253.685a.7.7,0,0,1-.7-.7,4.7,4.7,0,1,0-9.405,0,.7.7,0,1,1-1.4,0,6.1,6.1,0,1,1,12.208,0,.7.7,0,0,1-.7.7Z"
          transform="translate(-966.088 -172.892)"
          fill="#2e3c77"
        />
      </g>
    </svg>
  );
};

export const PersonIconBlack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.209"
      height="16.626"
      viewBox="0 0 12.209 13.626">
      <g id="personBlack" transform="translate(-33.536 -67.187)">
        <path
          id="Path_34"
          data-name="Path 34"
          d="M1067.056,134.868a3.8,3.8,0,1,1,2.682-1.113A3.8,3.8,0,0,1,1067.056,134.868Zm0-6.183a2.395,2.395,0,1,0,1.689.7A2.393,2.393,0,0,0,1067.056,128.685Z"
          transform="translate(-1027.414 -60.092)"
          fill="#231f20"
        />
        <path
          id="Path_35"
          data-name="Path 35"
          d="M1034.132,253.685a.7.7,0,0,1-.7-.7,4.7,4.7,0,1,0-9.405,0,.7.7,0,1,1-1.4,0,6.1,6.1,0,1,1,12.208,0,.7.7,0,0,1-.7.7Z"
          transform="translate(-989.088 -172.892)"
          fill="#231f20"
        />
      </g>
    </svg>
  );
};
