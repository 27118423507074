import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import ConfirmButton from 'component/Buttons/ConfirmButton';
import SelectInput from 'component/Inputs/SelectInputs/SelectInput';
import FullScreenModal from 'component/Modals/FullScreenModal';
import IMPORT_LABEL_CSV from 'graphql/ImportLabelCSV';
import styled from 'styled-components';

const StyledTableContainer = styled(TableContainer)`
  max-height: 200px;
  overflow-y: overlay;
  margin-top: 10px;
`;

const StyledForm = styled.form`
  display: flex;
  align-items: center;
`;

const ConfirmButtonWrapper = styled.div`
  margin-right: 10px;
`;

const StyledInputTypeFile = styled.input`
  display: none;
`;

const StyleFormControlInlne = styled(FormControl)`
  flex-direction: row;
  align-items: center;
`;

const LabelImportCSV = ({ onSuccess, trainings, disabled }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedTrainingId, setSelectedTrainingId] = useState(null);
  const [errors, setErrors] = useState({});
  const [isOverwrite, setIsOverwrite] = useState(false);
  const buttonImportCSV = useRef(null);

  const resetState = () => {
    setFile(null);
    setSelectedTrainingId(null);
    setIsOverwrite(false);
    setErrors({});
  };

  const [uploadCSVFile, { loading }] = useMutation(IMPORT_LABEL_CSV, {
    onCompleted({ importLabel }) {
      if (importLabel.success) {
        resetState();
        onSuccess();
        if (importLabel.errors.length === 0) {
          toast.success(t('textManagement.import.response.success'));
          setShowModal(false);
        } else {
          toast.warning(t('textManagement.import.response.warning'));
          setErrors({
            ...errors,
            csv: importLabel.errors
          });
        }
      }
      if (!importLabel.success) {
        console.error(errors);
        if (errors.length === 1 && errors[0].includes('Error occurred during CSV parsing')) {
          toast.error(t('textManagement.import.response.invalidFile'));
        }
      }
    },
    onError(response) {
      toast.error('Something went wrong.');
      console.error(response);
    }
  });

  const handleOnChange = (e) => {
    const file = e.target.files[0];
    if (file.type !== 'text/csv') {
      toast.error(t('textManagement.import.response.invalidFile'));
      return;
    }
    setFile(file);
    setErrors({ ...errors, file: false });
  };

  const isValid = () => {
    let valid = true;
    let mergeErrors = { ...errors };

    if (selectedTrainingId === null) {
      mergeErrors = { ...mergeErrors, trainingId: true };
      valid = false;
    }

    if (file === null) {
      mergeErrors = { ...mergeErrors, file: true };
      valid = false;
    }

    setErrors({ ...mergeErrors });
    return valid;
  };

  const processUpload = useCallback(() => {
    if (!isValid()) {
      toast.error(t('textManagement.import.response.error'));
      return;
    }

    const data = {
      variables: {
        trainingId: selectedTrainingId,
        labelCsv: file,
        isOverwrite: isOverwrite
      }
    };
    uploadCSVFile(data);
  }, [file, selectedTrainingId, errors, isOverwrite, setErrors]);

  const closeModal = () => {
    setShowModal(false);
    resetState();
  };

  const onOverwriteChange = () => {
    setIsOverwrite(!isOverwrite);
  };

  return (
    <>
      {showModal ? (
        <FullScreenModal
          width="35rem"
          icon="add"
          title={t('textManagement.import.title')}
          buttonText={
            loading ? t('textManagement.import.uploading') : t('textManagement.import.title')
          }
          type="save"
          handleCloseModal={closeModal}
          handleDone={() => processUpload()}
          saving={loading}>
          <FormControl disabled={loading}>
            <FormGroup>
              <SelectInput
                label={t('sharedLabels.training')}
                name="training"
                onChange={(e) => {
                  setSelectedTrainingId(e.target.value);
                  setErrors({ ...errors, trainingId: false });
                }}
                menuItems={trainings}
                required={true}
                error={errors.trainingId}
                underText={errors.trainingId ? t('textManagement.import.pleaseSelectTraining') : ''}
              />
            </FormGroup>
          </FormControl>
          <StyleFormControlInlne disabled={loading}>
            <FormGroup row={true}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="overwrite"
                    checked={isOverwrite}
                    onChange={onOverwriteChange}
                  />
                }
                label={t('textManagement.import.overwrite')}
              />
            </FormGroup>
          </StyleFormControlInlne>
          <StyledInputTypeFile
            ref={buttonImportCSV}
            type={'file'}
            id={'csvFileInput'}
            accept={'.csv'}
            onChange={handleOnChange}
          />
          <ConfirmButton
            size="small"
            onClick={() => buttonImportCSV.current.click()}
            disabled={loading}>
            {t('textManagement.import.selectFile')}
          </ConfirmButton>
          {file ? <FormHelperText>{file.name}</FormHelperText> : null}
          {errors.file ? (
            <FormHelperText error>{t('textManagement.import.pleaseUploadFile')}</FormHelperText>
          ) : (
            ''
          )}
          {errors.csv ? (
            <StyledTableContainer>
              <FormHelperText error>
                {t('textManagement.import.response.failedKeyId')}
              </FormHelperText>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('textManagement.import.file.key')}</TableCell>
                    <TableCell>{t('textManagement.import.file.id')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errors.csv.map((error, index) => (
                    <TableRow key={index}>
                      <TableCell>{error.Key}</TableCell>
                      <TableCell>{error.id}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          ) : null}
        </FullScreenModal>
      ) : null}
      <StyledForm>
        <ConfirmButtonWrapper>
          <ConfirmButton
            disabled={disabled || loading}
            size="small"
            onClick={() => setShowModal(true)}>
            {t('textManagement.import.title')}
          </ConfirmButton>
        </ConfirmButtonWrapper>
      </StyledForm>
    </>
  );
};

export default LabelImportCSV;
