import { gql } from '@apollo/client';

export const USER_INFO = gql`
  query getUser($userId: Int!) {
    getUser(id: $userId) {
      id
      username
      firstName
      lastName
      isSuperuser
      isActive
      email
      cohorts {
        id
        name
      }
      organizationRoles {
        id
        permission
        organization {
          id
          name
        }
      }
      courses {
        id
        name
      }
      trainings {
        id
        name
        course {
          id
          name
        }
      }
      profile {
        timezone
        preferredLanguage {
          id
          code
          name
        }
        dateFormat
      }
      organizations {
        id
        name
        avatar
        courses {
          id
          name
          description
          trainings {
            id
            name
            cohorts {
              id
              name
              user {
                id
                username
              }
            }
            user {
              id
            }
          }
        }
      }
    }
  }
`;

export const USER_SELF_INFO = gql`
  query getUserSelfInfo($userId: Int!) {
    getUserSelfInfo(userId: $userId) {
      id
      username
      firstName
      lastName
      isSuperuser
      isActive
      email
      cohorts {
        id
        name
      }
      organizationRoles {
        id
        permission
        organization {
          id
          name
        }
      }
      courses {
        id
        name
      }
      trainings {
        id
        name
        course {
          id
          name
        }
      }
      profile {
        timezone
        preferredLanguage {
          id
          code
          name
        }
        dateFormat
      }
      organizations {
        id
        name
        avatar
        courses {
          id
          name
          description
          trainings {
            id
            name
          }
        }
      }
    }
  }
`;
