import React from 'react';
import { Box, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Alert from '../../Alert';

const useStyles = makeStyles((theme) => ({
  feedback: {
    zIndex: 1,
    margin: theme.spacing(1),
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '0'
  },
  alert: {
    marginBottom: '10px'
  }
}));

const Feedback = (props) => {
  const classes = useStyles(props);

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      classes={{
        paper: classes.feedback
      }}>
      {Array.isArray(props.feedback) ? (
        props.feedback.map((item, i) => {
          return (
            <Box key={i} className={classes.alert}>
              <Alert variant="filled" severity={item.type}>
                {item.message}
              </Alert>
            </Box>
          );
        })
      ) : (
        <Box className={classes.alert}>
          <Alert variant="filled" severity={props.feedback.type}>
            {props.feedback.message}
          </Alert>
        </Box>
      )}
    </Dialog>
  );
};

export default Feedback;
