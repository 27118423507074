export const TRANSLATIONS_EN = {
  //GLOBAL TEXTS THAT HAVE NO REAL CATEGORY//
  general: {
    loadingContent: 'Loading Content',
    save: 'Save',
    delete: 'Delete',
    cancel: 'Cancel',
    done: 'Done',
    yes: 'Yes',
    no: 'No',
    outOf: 'out of {{count}}',
    of: 'of',
    //'To' as in: from 1990 'to' 2010//
    to: 'to',
    daysAgo: '{{count}} days ago',
    dayAgo: '{{count}} day ago',
    today: 'Today',
    errorData: 'Error with retrieving data',
    total: 'Total',
    other: 'Other'
  },
  table: {
    settings: {
      title: 'Table Settings'
    },
    rowSelect: '{{count}} row(s) selected',
    //THESE TWO ARE INBUILT TO MUI DATA TABLE - NOT SURE I CAN CHANGE//
    rowsPerPage: 'Rows per page:',
    noData: 'Sorry, no matching records found'
  },
  //INPUT SUB-TEXTS//
  inputs: {
    required: 'This field is required',
    select: {
      noCoursesSelected: 'No courses are selected',
      noTrainingsSelected: 'No trainings are selected',
      selectNone: 'Select All'
    },
    password: {
      passwordSubText1: 'Password must contain at least 4 characters',
      passwordSubText2: 'Enter same password',
      passwordSubText3: 'Passwords do not match',
      passwordSubText4: 'Invalid password',
      passwordForgot: 'Forgot password?'
    },
    email: {
      invalidFormat: 'Invalid email format'
    }
  },
  //FILTERS
  filters: {
    groups: 'Filter by group',
    users: 'Filter by users',
    searchUser: 'Search User',
    allUsers: 'All Users'
  },
  //TABLE HEADERS/INPUT LABELS//
  sharedLabels: {
    user: 'User',
    totalUsers: 'Total Users',
    users: 'Users',
    username: 'Username',
    name: 'Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    newPassword: 'New Password',
    oldPassword: 'Old Password',
    password: 'Password',
    password2: 'Re-type Password',
    language: 'Language',
    text: 'Text',
    texts: 'Texts',
    key: 'Key',
    course: 'Course',
    courses: 'Courses',
    courseName: 'Course name',
    training: 'Training',
    allTrainings: 'All Trainings',
    trainings: 'Trainings',
    trainingName: 'Training name',
    description: 'Description',
    details: 'Details',
    quiz: 'Quiz',
    object: 'Object',
    sessionType: 'Session Type',
    currentStatus: 'Current Status',
    lastVrActivity: 'Last VR Activity',
    totalUniqueSessions: 'Total Unique Sessions',
    startDate: 'Start Date',
    startTime: 'Start time',
    completionDate: 'Completion Date',
    completionTime: 'Completion Time',
    timeSpent: 'Time Spent',
    totalTimeSpent: 'Total Time Spent',
    time: 'Time',
    hours: 'Hours',
    minutes: 'Minutes',
    timePerformed: 'Time Performed',
    performance: 'Performance',
    assessment: 'Assessment',
    practice: 'Practice',
    session: 'Session',
    sessions: 'Sessions',
    passed: 'Passed',
    notPassed: 'Not Passed',
    failed: 'Failed',
    actionsFailed: 'Actions Failed',
    inProgress: 'In Progress',
    notStarted: 'Not yet started',
    completedCorrectly: 'Completed Correctly',
    completed: 'Completed',
    incomplete: 'Incomplete',
    approved: 'Approved',
    notApproved: 'Not Approved',
    errors: 'Errors',
    errorsMade: 'Errors Made',
    errorType: 'Error Type',
    all: 'All',
    completedSessions: 'Completed Sessions',
    notCompletedSessions: 'Not Completed Sessions',
    groups: 'Groups',
    groupName: 'Group Name',
    clearAll: 'Clear All',
    role: 'Role'
  },
  charts: {
    weekDays: {
      mon: 'Mon',
      tue: 'Tue',
      wed: 'Wed',
      thur: 'Thur',
      fri: 'Fri',
      sat: 'Sat',
      sun: 'Sun'
    }
  },
  tooltips: {
    trainingPassed:
      'A training is considered passed if a user completes at least one Assessment Session without making any mistakes.',
    sessionsInfo:
      'Sessions are the period of time from where a user starts a training until it ends.',
    sessionsPassed: 'The User has gotten to the end of the Training Scenario and has passed.',
    sessionsIncomplete:
      'The user has chosen to terminate the Session before reaching the end of the Training Scenario. This can be caused by multiple factors, but the most common one is that the user quit the application, or decided to restart or change the Training Scenario.',
    sessionsFailed:
      'A session is considered failed either because it was terminated unexpectedly (normally because of a technical issue), or if no new data is received from the VR training over a period of time.'
  },
  modals: {
    warning: 'Warning: This is a permanent action.',
    button: {
      textYes: "Yes, I'm sure"
    }
  },
  menu: {
    userSettings: 'User Settings',
    fullReport: 'My Results',
    logout: 'Logout',
    title1: 'Gleechi VR Metrics',
    dashboard: 'Dashboard',
    users: 'Users',
    courses: 'Courses',
    title2: 'Administration',
    noData: {
      courses: 'No courses'
    }
  },

  //VIEWS//
  //DASHBOARD VIEW//
  dashboard: {
    title: 'Dashboard',
    usersCard: {
      title: 'Users'
    },
    fullReport: {
      title: 'View your full report'
    },
    trainingsCard: {
      title: 'Trainings',
      subText: 'From a total of {{count}} course(s)'
    },
    noTrainingData: 'You are not enrolled on any training', //NEEDS REVIST
    sessionsCard: {
      title: 'Total Sessions completed per Training',
      noData: 'None of the trainees has completed a session in a training yet.'
    },
    usersPassedCard: {
      title: 'Total Users Passed Assessment',
      noData: 'No students have passed in any training yet.'
    },
    activityCard: {
      title: 'Activity per day (last 7 days)',
      subText: 'Total time spent on trainings'
    },
    timeSpentCard: {
      title: 'Time Spent in Trainings',
      noData: 'None of the trainees has spent time in training yet',
      hours: 'hrs',
      hoursShort: 'hrs'
    },
    table: {
      title: 'Latest Activities',
      titleAlt: 'No activity to display yet.'
    }
  },
  //USERS VIEW//
  users: {
    title: 'Users',
    table: {
      title: 'Total Users'
    }
  },
  //TRAINING DETAILS VIEW//
  trainingDetails: {
    performanceCard: {
      title: 'Overall Performance',
      subText:
        'user(s) ({{percentage}}) of a total of {{totalUsers}} have passed at least 1 session.',
      tooltip: 'Passing Users',
      noData: 'None of the trainees have completed a session so far'
    },
    sessionsCard: {
      title: 'Sessions per Day'
    },
    totalTimeSpent: {
      title: 'Total time in VR'
    },
    activityCard: {
      title: 'Activity per day (last 7 days)',
      subText: 'Total time spent on trainings'
    },
    topObjects: {
      title: 'Top 5 objects that cause most mistakes',
      noData: 'No mistakes have been recorded.'
    },
    topMistakes: {
      title: 'Top 5 most common mistakes',
      noData: 'No mistakes have been recorded.'
    },
    table: {
      title: 'Total Users:'
    }
  },
  //COURSE VIEW//
  course: {
    trainingOverviews: 'Training Overviews',
    CourseOverview: 'Course Overview',
    enrolledUsers: 'Enrolled Users',
    noDataEnrolledUsers: 'No users have been active for this course yet.',
    noDataTrainingsTitle: 'There are no trainings for this course',
    noDataTrainingsAdd: 'Add new training'
  },
  //USER DETAILS VIEW//
  userDetails: {
    trainingsPassedCard: {
      title: {
        assessment: 'Assessment Passed',
        practice: 'Practice Passed'
      }
    },
    vrActivityCard: {
      title: 'Last VR Activity',
      subText: '{{count}} days ago',
      noData: 'No Activity'
    },
    sessionsPerformance: {
      passed: 'Sessions Passed',
      incomplete: 'Sessions Incomplete',
      failed: 'Sessions Failed'
    },
    weeklyActivityCard: {
      title: 'Average weekly activity',
      noData: 'No activity was recorded for these days',
      allUsers: 'All Users'
    },
    table: {
      title: 'Sessions Overview'
    }
  },
  //SESSIONS DETAILS VIEW//
  sessionsDetails: {
    //'Back to' as in: Navigate 'back to' user//
    nav: 'Back to',
    table: {
      title: 'Session Details',
      correct: 'Correct Action',
      soft: 'Soft Error',
      hard: 'Hard Error'
    }
  },

  //ADMIN//
  admin: {
    title: 'Administration Panel',
    subTitle: '', //NEEDS CONTENT
    manageTitle: 'Manage',
    manageUsers: 'Users',
    manageCourses: 'Courses',
    manageTrainings: 'Trainings',
    manageQuizzes: 'Quizzes',
    manageLabels: 'Texts',
    manageGroups: 'Groups',
    manageOrganization: 'Organization',
    manageLanguages: 'Languages'
  },
  //USER MANAGEMENT VIEW//
  userManagement: {
    title: 'User Management',
    create: 'Create New User',
    edit: 'Edit User',
    delete: 'Delete User',
    update: 'Update User',
    save: 'Save User',
    saveAlt: 'Save and create another',
    archive: 'Archive User',
    archiveQuestion: 'Are you sure to archive the following users?',
    form: {
      title1: "User's Details",
      title2: "Set User's Password",
      title3: 'Enrol User',
      adminCheckboxTitle: 'Make user an Admin?'
    },
    responses: {
      usernameInUse: 'This username is already in use',
      updateSuccess: 'User successfully updated',
      updateFail: 'Error with updating user',
      createSuccess: 'User successfully created',
      createFail: 'Error with creating user',
      archiveSuccess: 'User successfully archive',
      archiveError: 'Error with archiving the user'
    }
  },
  //COURSE MANAGEMENT VIEW//
  courseManagement: {
    title: 'Course Management',
    create: 'Add new course',
    edit: 'Edit course',
    delete: 'Delete course',
    save: 'Save course',
    update: 'Update course',
    form: {
      title1: 'Course name and description'
    },
    table: {
      title: 'Course List',
      subTitle: 'Note: Limit the number of selections to 5',
      description: 'Enter course description'
    },
    responses: {
      updateSuccess: 'Course successfully updated',
      updateFail: 'Error with updating course',
      createSuccess: 'Course successfully created',
      createFail: 'Error with creating course',
      deleteSuccess: 'Course successfully deleted',
      deleteFail: 'Error with deleting course'
    },
    deleteModal: {
      title: 'Delete Course',
      message: 'Are you sure you want to delete the course: '
    }
  },

  //TRAINING MANAGEMENT VIEW//
  trainingManagement: {
    title: 'Training Management',
    create: 'Add new training',
    edit: 'Edit training',
    delete: 'Delete training',
    save: 'Save training',
    update: 'Update training',
    form: {
      title1: 'Training Details',
      title2: 'Enrol Users',
      allUsers: 'All Users',
      noDataUsers: 'No users selected',
      uploadButton: 'Enroll users from XLS/CSV'
    },
    table: {
      title: 'Trainings List',
      description: 'Enter training details'
    },
    responses: {
      updateSuccess: 'Training successfully updated',
      updateFail: 'Error with updating training',
      createSuccess: 'Training successfully created',
      createFail: 'Error with creating training',
      deleteSuccess: 'Training successfully deleted',
      deleteFail: 'Error with deleting training'
    },
    deleteModal: {
      title: 'Delete Training',
      message: 'Are you sure you want to delete the training: '
    },
    translation: {
      fetching: 'Fetching Translations',
      noTranslation: 'No Translations Found',
      edit: 'Edit Translations',
      add: 'Add Translations',
      select: 'Select Language',
      button: {
        delete: 'Delete',
        edit: 'Edit'
      },
      toast: {
        add: 'Translation successfully added',
        update: 'Translation successfully updated',
        delete: 'Translation successfully deleted'
      }
    }
  },
  //QUIZ MANAGEMENT VIEW//
  quizManagement: {
    title: 'Quiz Management',
    create: 'Create New Quiz',
    edit: 'Edit Quiz',
    update: 'Update Quiz',
    save: 'Save Quiz',
    delete: 'Delete Quiz',
    form: {
      title1: 'Quiz name and training',
      subText1: 'These are required fields',
      title2: 'Quiz questions',
      subText2: 'A quiz must have at least 1 question',
      quizName: 'Quiz Name',
      question: 'Question',
      choice: 'Choice',
      subText3: 'Mark correct choice (A question must have at least one choice)',
      addChoice: 'Add another choice...',
      addQuestion: 'Add new question',
      deleteQuestion: 'Delete question',
      error: 'Please complete all fields before saving'
    },
    responses: {
      updateSuccess: 'Quiz successfully updated',
      updateFail: 'Error with updating quiz',
      createSuccess: 'Quiz successfully created',
      createFail: 'Error with creating quiz',
      deleteSuccess: 'Quiz successfully deleted',
      deleteFail: 'Error with deleting quiz'
    },
    deleteModal: {
      title: 'Delete Quiz',
      message: 'Are you sure you want to delete the quiz: '
    }
  },
  //TEXT MANAGEMENT VIEW//
  textManagement: {
    title: 'Text Management',
    create: 'Create New Text',
    edit: 'Edit Text',
    update: 'Update Text',
    save: 'Save Text',
    delete: 'Delete Text',
    totalLabels: 'Total labels ready for download',
    form: {
      addTranslation: 'Add translation',
      removeTranslation: 'Remove translation'
    },
    responses: {
      updateSuccess: 'Text successfully updated',
      updateFail: 'Error with updating Text',
      createSuccess: 'Text successfully created',
      createFail: 'Error with creating Text',
      deleteSuccess: 'Text successfully deleted',
      deleteFail: 'Error with deleting Text',
      downloadSuccess: 'CSV downloaded successfully'
    },
    deleteModal: {
      title: 'Delete Text',
      message: 'Are you sure you want to delete the text: '
    },
    error: {
      training: 'Please select a training',
      key: 'A valid key is required',
      selectTrainingGroups: 'Please select training groups.'
    },
    export: {
      title: 'Download CSV'
    },
    import: {
      title: 'Upload CSV',
      overwrite: 'Overwrite?',
      uploading: 'Uploading...',
      pleaseSelectTraining: 'Please select a training',
      pleaseUploadFile: 'Please upload a file',
      selectFile: 'Select File',
      file: {
        key: 'Key',
        id: 'Id'
      },
      response: {
        success: 'Succesfully imported',
        warning: 'Some data needs re-import',
        error: 'Something went wrong',
        invalidFile: 'CSV file are only accepted',
        failedKeyId: 'Some of the following key id failed to reflect the changes'
      }
    }
  },

  organizationManagement: {
    title: 'Organization Management',
    name: 'Organization Name',
    logo: 'Organization Logo',
    update: 'Update Information',
    edit: 'Edit Organization',
    create: 'Create Organization',
    save: 'Save Organization',
    error: {
      name: 'Organization Name is required.',
      failed: 'Failed upload.',
      notFound: 'Organization not found'
    },
    success: {
      title: 'Successfully update organization settings',
      created: 'Successfully created organization settings',
      updated: 'Successfully updated organization settings'
    },
    table: {
      title: 'Organization Management',
      edit: 'Edit',
      addToken: 'Add Token'
    },
    modal: {
      add: 'Add Organization'
    },
    token: {
      noTransactions: 'No Transactions',
      loading: 'loading',
      quantity: 'Quantity',
      addToken: 'Add Token',
      updateToken: 'Update Token',
      noTokenAdded: 'No Token Added',
      tokenQuantity: 'Token Quantity',
      tokenHistory: 'Token History',
      manageToken: 'Manage Token',
      error: {
        quantityIsRequired: 'Quantity is required.',
        quantityShouldBeNumber: 'Quantity should be a number.',
        failedFetchingTransactions: 'Failed to fetch token transactions.',
        failedFetchingToken: 'Failed to fetch token details.',
        failedAddToken: 'Failed to add quantity token.',
        failedUpdateQuantity: 'Failed to update quantity token.'
      },
      success: {
        addToken: 'Successfully added a quantity token.',
        updateToken: 'Successfully updated a quantity token.'
      }
    }
  },

  languagesManagement: {
    title: 'Languages Management',
    view: 'Add Language',
    button: {
      delete: 'Delete',
      edit: 'Edit'
    },
    form: {
      label: {
        code: 'Code',
        name: 'Name'
      },
      update: {
        title: 'Update Language',
        button: 'Update'
      },
      add: {
        title: 'Add Language',
        button: 'Save'
      }
    },
    success: {
      create: 'Added language successfully',
      update: 'Language successfully updated',
      delete: 'Language successfully deleted',
      createCodeExist: 'Code added was already declared and successfully updated instead'
    },
    error: {
      notFound: 'Language id not found.',
      fillAllFields: 'Please fill all required fields.',
      required: {
        code: 'Code is required.',
        name: 'Name is required.'
      }
    }
  },

  //USER PROFILE//
  userProfile: {
    enrollment: {
      title: 'My Enrollment',
      subTitle1: 'Organizations',
      subTitle2: 'Groups',
      subTitle3: 'Courses and Trainings',
      noDataGroups: 'You are not part of a group.',
      noDataTrainings: 'You are not enrolled on a training for this course.'
    },
    personalInfo: {
      title: 'Personal Information',
      update: 'Update Information',
      updateSuccess: 'Your information has been updated',
      updateFail: 'Failed to update your information'
    },
    password: {
      title: 'Change Password',
      subTitle1: 'Old Password',
      subTitle2: 'New Password',
      update: 'Update Password',
      updateSuccess: 'Your password has been updated',
      updateFail: 'Failed to update your password',
      forgotModal: {
        title: 'Forgot Password',
        message1: 'A reset link will be sent to the email associated with this account.',
        message2: 'Are you sure you wish to reset your Password? ',
        messageSuccess:
          'A password reset link has been sent to the email associated with this account.',
        messageFail: 'Error with sending reset link to email associated with this account.'
      }
    },
    settings: {
      title: 'Settings',
      subTitle1: 'Language',
      subTitle2: 'Time and Date',
      timeZone: 'Time Zone',
      dateFormat: 'Date Format',
      update: 'Update Settings',
      updateSuccess: 'Your settings has been updated',
      updateFail: 'Failed to update your settings',
      table: 'Update Table Settings'
    }
  },
  //GROUP MANAGEMENT
  groupManagement: {
    title: 'Group Management',
    create: 'Create new group',
    edit: 'Edit group', //NEEDS TRANSLATION
    delete: 'Delete group', //NEEDS TRANSLATION
    save: 'Save group', //NEEDS TRANSLATION
    update: 'Update group', //NEEDS TRANSLATION
    form: {
      title1: 'Group Name and Training', //NEEDS TRANSLATION
      title2: 'Add users to group' //NEEDS TRANSLATION
    },
    table: {
      title: 'Group List',
      subTitle: '',
      description: ''
    },
    responses: {
      updateSuccess: 'Group successfully updated',
      updateFail: 'Error with updating Group',
      createSuccess: 'Group successfully created',
      createFail: 'Error with creating Group',
      deleteSuccess: 'Group successfully deleted',
      deleteFail: 'Error with deleting Group'
    },
    deleteModal: {
      title: 'Delete Group', //NEEDS TRANSLATION
      message: 'Are you sure you want to delete the group: ' //NEEDS TRANSLATION
    }
  },

  //AUTH//
  //LOGIN//
  login: {
    title: 'Sign In',
    subText: 'To stay on top of your VR training',
    errorText1: 'At this moment, the system is only available for admins.',
    errorText2: 'Login failed, wrong credentials.',
    errorText3: 'Required fields missing.',
    errorTextAlt:
      '<strong>Note:</strong> ' +
      "If you're a user of <i>VirtualGrasp</i>, you will <strong>NOT</strong> be able to login here (online version) so please login to the Unity client. ",
    button: 'Login',
    footer: 'Forgot Password'
  },
  forgotPassword: {
    title: 'Forgot \n Password',
    subText: 'Enter the email address associated with your account. We will send you a reset link.',
    button: 'Send Link',
    buttonAlt: 'Back to login',
    success: 'We have sent a reset link to the email address you have provided.'
  },
  resetPassword: {
    title: 'Reset \n Password',
    titleAlt: 'Reset \n Success',
    subTextSuccess: 'Your password has been successfully updated!',
    subTextSuccessAlt:
      '<strong>Note:</strong> ' +
      "If you're a user of <i>VirtualGrasp</i>, you will <strong>NOT</strong> be able to login here (online version) so please login to the Unity client. " +
      "If you're user for the <i>Gleechi Assessment Center</i>, you can continue to login as usual.",
    button: 'Update password',
    buttonAlt: 'Login',
    footer: 'To login',
    invalidToken:
      'Your reset link has expired. To request a new link to reset your password, please re-enter the email address associated with your account below.',
    resetButton: 'Reset',
    error: {
      required: 'This field is required.',
      didntMatch: 'The two password fields didn’t match.'
    }
  },
  setPassword: {
    title: 'Set \n Password',
    titleAlt: 'Set \n Password',
    subTextSuccess: 'Your password has been successfully updated!',
    subTextSuccessAlt:
      '<strong>Note:</strong> ' +
      "If you're a user of <i>VirtualGrasp</i>, you will <strong>NOT</strong> be able to login here (online version) so please login to the Unity client. " +
      "If you're user for the <i>Gleechi Assessment Center</i>, you can continue to login as usual.",
    button: 'Set password',
    buttonAlt: 'Login',
    footer: 'To login',
    fourPin: 'Enter a 4 digit pin.',
    repeatFourPin: 'Repeat 4 digit pin.'
  },

  //TERMS OF SERVICE
  termsOfService: {
    message:
      'This application requires and uses cookies to ensure you get the best experience on our application.',
    link: 'Learn more',
    button: 'Allow Cookies'
  },

  footer: {
    copyright: 'Copyright {{ currentYear }}. All Rights Reserved.',
    deploymentDetails:
      'Last Deployed: {{ commitDate }} | Branch: {{ branchName }} | Hash: {{ commitHash }}'
  }
};
