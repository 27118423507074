import React from 'react';
import { useHistory } from 'react-router';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BackArrowLink } from 'assets/images/icons/ArrowLink';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center'
  },
  titleBreadcrumb: {
    fontWeight: '300',
    padding: '0 15px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  separator: {
    fontWeight: '300'
  },
  title: {
    padding: '0 15px'
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '0.9rem',
    padding: '15px 0 0 0',
    opacity: '0.7'
  },
  back: {
    cursor: 'pointer',
    marginRight: '10px'
  }
}));

const Breadcrumbs = (props) => {
  const classes = useStyles(props);
  let history = useHistory();
  const breadcrumbs = JSON.parse(window.localStorage.getItem('breadcrumb'));

  /*const pushHistory = (value, path) => {
		history.push({ pathname: path });
		buildBreadcrumbs(value)
	}

	const shouldEllipses = (text) => {
		if(breadcrumbs?.length > 1 ){
			return <Tooltip title={text}><Box>...</Box></Tooltip>
		}else{
			return text
		}
	}

	const crumb = breadcrumbs && breadcrumbs[breadcrumbs.length -1];*/

  /*let backLink;
	if(breadcrumbs && breadcrumbs[breadcrumbs.length -1]){
		if(crumb.key === 'dash'){
			backLink = '/dashboard/default'
		}
		if(crumb.key === 'users'){
			backLink = '/dashboard/users'
		}
		if(crumb.key === 'admin'){
			backLink = '/admin'
		}
		if(crumb.key === 'course'){
			backLink = '/courses/' + crumb.id
		}
		if(crumb.key === 'training'){
			backLink = '/training/' + crumb.id
		}
	}



	const parseBreadcrumbs = (crumb) => {
		let breadcrumb;
		if(crumb.key === 'dash'){
			breadcrumb = <Box className={classes.titleBreadcrumb} onClick={() => pushHistory('clear', '/dashboard/default')}>{shouldEllipses(t('dashboard.title'))}</Box>
		}
		if(crumb.key === 'users'){
			breadcrumb = <Box className={classes.titleBreadcrumb} onClick={() => pushHistory('clear', '/dashboard/users')}>{shouldEllipses(t('users.title'))}</Box>
		}
		if(crumb.key === 'admin'){
			breadcrumb = <Box className={classes.titleBreadcrumb} onClick={() => pushHistory('clear', '/admin')}>{shouldEllipses(t('admin.title'))}</Box>
		}
		if(crumb.key === 'course'){
			breadcrumb = <Box className={classes.titleBreadcrumb} onClick={() => pushHistory('clear', '/courses/' + crumb.id)}>{shouldEllipses(crumb.name)}</Box>
		}
		if(crumb.key === 'training'){
			breadcrumb = <Box className={classes.titleBreadcrumb} onClick={() => pushHistory(null, '/training/' + crumb.id)}>{shouldEllipses(crumb.name)}</Box>
		}
		return breadcrumb;
	}*/

  return (
    <>
      <Box className={classes.breadcrumbs}>
        {!props.loading && breadcrumbs && (
          <Box className={classes.back} onClick={() => history.goBack()}>
            <BackArrowLink />
          </Box>
        )}
        {!props.loading && props.title}
      </Box>
      {/*{breadcrumbs ? (
				<Box className={classes.breadcrumbs}>
					<Box className={classes.back} onClick={() => history.goBack()}>
						<BackArrowLink />
					</Box>
					{breadcrumbs.map(crumb => {
						return crumb && (
							<>
								{parseBreadcrumbs(crumb)}
								<Box className={classes.separator}> / </Box>
							</>
						)
					})}
					<Box className={classes.title}>
						{ breadcrumbs[breadcrumbs?.length -1]?.viewTitle ? breadcrumbs[breadcrumbs.length -1].viewTitle : props.title }
					</Box>
				</Box>
			) : props.title}*/}
      {props.subTitle && (
        <Grid container item lg={4} md={6} sm={9} xs={12} className={classes.subtitle}>
          {props.subTitle}
        </Grid>
      )}
    </>
  );
};

export default Breadcrumbs;

//key:'viewKey', name: 'breadcrumb name', id: 'breadcrumb id', viewTitle: 'current view title'
