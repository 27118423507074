import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import ChartWidget from 'component/Widgets/ChartWidget';
import OBJECT_ERRORS from 'graphql/ObjectErrors';

const TopObjects = (props) => {
  const { t } = useTranslation();

  const { data, loading } = useQuery(OBJECT_ERRORS, {
    variables: {
      trainingId: Number(props.trainingId),
      ...(props.filteredBy && props.cohortId),
      ...(props.restrictedUser && props.userId)
    },
    fetchPolicy: 'no-cache'
  });

  let chartData = data ? data?.errorStats[0].data : [];

  let series = [
    {
      data: chartData
        .sort((a, b) => (a.value > b.value ? -1 : 1))
        .map((set) => {
          return {
            x: '',
            y: set.value,
            name: set.name.length > 30 ? set.name.substring(0, 30) + '...' : set.name,
            title: set.name,
            tooltipData: set.errorCause
          };
        })
    }
  ];

  let categories = series[0].data.map((set) => set.name);

  return (
    <ChartWidget
      height="450px"
      widgetTitle={t('trainingDetails.topObjects.title')}
      chartType="hBar"
      series={series}
      categories={categories}
      hideAxis={['x']}
      distributed={true}
      widgetDrawer="chartList"
      loading={loading}
      noDataMessage={chartData.length < 1 && t('trainingDetails.topObjects.noData')}
    />
  );
};

export default TopObjects;
