import moment from 'moment';

const buildUserData = (user) => {
  let userData = [];
  if (user) {
    userData = user.trainings.map((training) => {
      let assessments = [];
      let practices = [];
      let assessmentApproved = false;
      let practiceApproved = false;
      training.sessions.forEach((session) => {
        let duration = 0;
        let estimateDuration = false;
        let startDate = moment(session.createdAt);
        let endDate = moment(session.completedAt);
        if (session.completedAt || (session.completedAt && session.isComplete)) {
          duration = endDate.diff(startDate, 'minutes');
          if (duration === 0) duration++;
        } else {
          if (session.eventSet.length > 0) {
            let firstSession = moment(session.eventSet[0].performedAt);
            let lastSession = moment(session.eventSet[session.eventSet.length - 1].performedAt);
            estimateDuration = true;
            duration = lastSession.diff(firstSession, 'minutes');
            if (duration === 0) duration++;
          }
        }

        let createdAtDate = new Date(session.createdAt);
        let completedAt = new Date(session.completedAt);
        const sessionObj = {
          id: session.id,
          createdAt: Math.round(createdAtDate.getTime() / 1000),
          completedAt: session.completedAt && Math.round(completedAt.getTime() / 1000),
          isComplete: session.isComplete,
          duration,
          estimateDuration,
          performance: 'noData',
          errors: 0
        };

        if (session.eventSet.length > 0) {
          const hardErrors = session.eventSet.filter((eventSet) => eventSet.errorSeverity === 2);
          const softErrors = session.eventSet.filter((eventSet) => eventSet.errorSeverity === 1);
          sessionObj.errors = hardErrors.length + softErrors.length;
          if (session.isComplete && session.completedAt) {
            if (hardErrors.length > 0) {
              sessionObj.performance = 'fail';
            } else {
              sessionObj.performance = 'pass';
              if (session.category === 'assessment') {
                assessmentApproved = true;
              } else {
                practiceApproved = true;
              }
            }
          }
        }

        if (session.category === 'assessment') {
          assessments.push(sessionObj);
        }
        if (session.category === 'practice') {
          practices.push(sessionObj);
        }
      });
      return {
        id: training.id,
        name: training.name,
        assessments,
        practices,
        assessmentApproved,
        practiceApproved,
        course: training.course,
        training: training
      };
    });
  }

  return userData;
};

export default buildUserData;
