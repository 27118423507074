import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import { AddIconLarge } from 'assets/images/icons/AddIcon';
import LinkWidget from 'component/Widgets/LinkWidget';
import NoDataActionWidget from 'component/Widgets/NoDataActionWidget';
import { buildBreadcrumbs } from 'helpers/buildBreadcrumbs';
import { useTrainingTranslation } from 'hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flex: '1 1 0%'
    },
    [theme.breakpoints.down('sm')]: {
      height: '600px'
    },
    overflowY: 'auto'
  },
  bodyContent: {
    paddingTop: '15px',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start'
  },
  widget: {
    paddingBottom: '8px',
    [theme.breakpoints.only('xl')]: {
      width: '30%',
      paddingRight: '8px'
    },
    [theme.breakpoints.only('lg')]: {
      width: '50%',
      paddingRight: '8px'
    },
    [theme.breakpoints.only('md')]: {
      width: '100%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '50%',
      paddingRight: '8px'
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  }
}));

const Trainings = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { getTranslatedNameFromTraining } = useTrainingTranslation();
  return (
    <Box className={classes.root}>
      <Box className={classes.bodyContent}>
        {props.trainings?.length > 0 ? (
          props.trainings.map((training) => {
            const trainingName = getTranslatedNameFromTraining({ training });
            return (
              <Box key={training.id} className={classes.widget}>
                <LinkWidget
                  title={trainingName}
                  link={'/training/' + training.id}
                  footer={training.userTotal + ' ' + t('course.enrolledUsers')}
                  action={() =>
                    buildBreadcrumbs({
                      key: 'course',
                      name: props.courseName,
                      id: props.courseId,
                      viewTitle: trainingName
                    })
                  }
                />
              </Box>
            );
          })
        ) : (
          <Box className={classes.widget}>
            <NoDataActionWidget
              title={t('course.noDataTrainingsTitle')}
              description={t('course.noDataTrainingsAdd')}
              link="/admin/trainings"
              icon={<AddIconLarge />}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Trainings;
