import React from 'react';
const GreyFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 25">
      <defs>
        <clipPath id="clip-path">
          <circle
            id="Ellipse_8"
            data-name="Ellipse 8"
            cx="12.5"
            cy="12.5"
            r="12.5"
            transform="translate(132 465)"
            fill="#f5f5f5"
          />
        </clipPath>
      </defs>
      <g id="NoLanguage" transform="translate(-132 -465)" clipPath="url(#clip-path)">
        <circle
          id="Ellipse_7"
          data-name="Ellipse 7"
          cx="12.5"
          cy="12.5"
          r="12.5"
          transform="translate(132 465)"
          fill="#f5f5f5"
        />
        <g id="Group_20" data-name="Group 20" transform="translate(67.787 463.857) rotate(10)">
          <path
            id="Path_40"
            data-name="Path 40"
            d="M0,0H3.75V35.5H0Z"
            transform="translate(89.215 -13.711) rotate(45)"
            fill="#fff"
          />
          <path
            id="Path_41"
            data-name="Path 41"
            d="M0,0H1.875V35.5H0Z"
            transform="translate(89.815 -13.111) rotate(45)"
            fill="#ebecec"
          />
        </g>
        <g
          id="Group_21"
          data-name="Group 21"
          transform="matrix(0.985, -0.174, 0.174, 0.985, 34.916, 467.966)">
          <path
            id="Path_42"
            data-name="Path 42"
            d="M53.275,56.784h3.75v35.5h-3.75Z"
            transform="translate(14.614 14.785) rotate(-45)"
            fill="#fff"
          />
          <path
            id="Path_43"
            data-name="Path 43"
            d="M50.74,52.392h1.875v35.5H50.74Z"
            transform="translate(20.113 15.372) rotate(-45)"
            fill="#ebecec"
          />
        </g>
        <g id="Group_24" data-name="Group 24" transform="translate(126.625 459.75)">
          <g id="Group_22" data-name="Group 22" transform="translate(0.125)">
            <rect
              id="Rectangle_14"
              data-name="Rectangle 14"
              width="5.625"
              height="35.5"
              transform="translate(15)"
              fill="#fff"
            />
            <rect
              id="Rectangle_15"
              data-name="Rectangle 15"
              width="5.625"
              height="35.5"
              transform="translate(35.5 15) rotate(90)"
              fill="#fff"
            />
          </g>
          <g id="Group_23" data-name="Group 23">
            <rect
              id="Rectangle_16"
              data-name="Rectangle 16"
              width="3.5"
              height="35.5"
              transform="translate(35.5 16) rotate(90)"
              fill="#ebecec"
            />
            <rect
              id="Rectangle_17"
              data-name="Rectangle 17"
              width="3.5"
              height="35.5"
              transform="translate(16)"
              fill="#ebecec"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GreyFlag;
