import { useUser } from 'hooks';
import moment from 'moment-timezone';

export const filterUserTableSessionsData = (data, cols, filters, handleDateFormat) => {
  const { getUserTimezone } = useUser();
  const startDateIndex = cols.indexOf(cols.find((col) => col.id === 'startDate'));
  const compDateIndex = cols.indexOf(cols.find((col) => col.id === 'completionDate'));
  const performanceIndex = cols.indexOf(cols.find((col) => col.id === 'performance'));
  return data.filter((row) => {
    const startDate = moment
      .tz(row[startDateIndex] * 1000, getUserTimezone())
      .format(handleDateFormat(false));
    const compDate =
      row[performanceIndex] === 'noData'
        ? 'noData'
        : moment.tz(row[compDateIndex] * 1000, getUserTimezone()).format(handleDateFormat(false));
    const performance = row[performanceIndex];
    return (
      (filters.startDate.id === 'all' || startDate === filters.startDate.id) &&
      (filters.completionDate.id === 'all' || compDate === filters.completionDate.id) &&
      (filters.performance.id === 'all' || performance === filters.performance.id)
    );
  });
};
