import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Tooltip, Typography, useTheme } from '@material-ui/core';
import { CheckCircleOutline, ErrorOutline, RotateRight } from '@material-ui/icons';
import Table from 'component/Table/Table';
import { filterUserTableSessionsData } from 'component/TableFilters/FilterHelpers/filterUserTableSessionsData';
import { USER_SESSIONS } from 'constants/filters';
import { setTableFilters } from 'ducks/userDetails';
import { useDateFormat } from 'hooks/useDateFormat';
import moment from 'moment-timezone';

import { useTrainingTranslation } from '../../../../hooks/useTrainingTranslation';
import { friendlyTimeDisplayFromMinutes } from '../../../../util';

const SessionsTable = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getTranslatedNameFromTraining } = useTrainingTranslation();
  const { handleDateFormat } = useDateFormat();
  const { userDetailsTableFilters } = useSelector((state) => state.userDetails);

  const columns = [
    {
      name: 'id',
      id: 'id',
      options: {
        filter: false,
        display: false,
        sort: false
      }
    },
    {
      name: t('sharedLabels.course'),
      id: 'course',
      options: {
        display:
          !props.selectedFilter.course.length > 0 || props.selectedFilter.training.length > 0,
        filter: false
      }
    },
    {
      name: t('sharedLabels.training'),
      id: 'training',
      options: {
        display: !props.selectedFilter.training.length > 0,
        filter: false,
        customBodyRender: (value) => {
          return getTranslatedNameFromTraining({ training: value.training });
        }
      }
    },
    {
      name: t('sharedLabels.startDate'),
      id: 'startDate',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return moment.tz(value * 1000, 'Europe/Stockholm').format(handleDateFormat(true));
        }
      }
    },
    {
      name: t('sharedLabels.timeSpent'),
      id: 'timeSpent',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          let duration;
          if (value === 0) duration = 'N/A';
          else duration = friendlyTimeDisplayFromMinutes(value);

          if (tableMeta.rowData[9]) duration = '~ ' + duration;
          return duration;
        }
      }
    },
    {
      name: t('sharedLabels.completionDate'),
      id: 'completionDate',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          if (tableMeta.rowData[8]) {
            return moment.tz(value * 1000, 'Europe/Stockholm').format(handleDateFormat(true));
          } else {
            return (
              <>
                {value === null
                  ? 'No Date'
                  : moment.tz(value * 1000, 'Europe/Stockholm').format(handleDateFormat(true))}
                <Typography variant="body2" style={{ opacity: '0.3' }}>
                  {t('sharedLabels.incomplete')}
                </Typography>
              </>
            );
          }
        }
      }
    },
    {
      name: t('sharedLabels.performance'),
      id: 'performance',
      options: {
        filter: false,
        display: props.selectedSwitch === 'assessment',
        customBodyRender: (value) => (
          <Box
            style={{
              width: '90px',
              display: 'flex',
              maxHeight: '0px',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            {value === 'noData' ? (
              <Tooltip title={t('sharedLabels.incomplete')}>
                <RotateRight style={{ opacity: '0.3' }} />
              </Tooltip>
            ) : value === 'pass' ? (
              <CheckCircleOutline
                style={{ color: props.selectedSwitch === 'assessment' ? '#06AC69' : '#BEBEBE' }}
              />
            ) : (
              <ErrorOutline
                style={{ color: props.selectedSwitch === 'assessment' ? '#E54C41' : '#BEBEBE' }}
              />
            )}
          </Box>
        )
      }
    },
    {
      name: t('sharedLabels.errors'),
      id: 'errors',
      options: {
        filter: false,
        customBodyRender: (value) => (
          <Box
            style={{
              color:
                props.selectedSwitch === 'assessment' ? (value > 0 ? '#E54C41' : '') : '#BEBEBE'
            }}>
            {value}
          </Box>
        )
      }
    },
    {
      name: 'isComplete',
      id: 'isComplete',
      options: {
        filter: false,
        display: false,
        sort: false
      }
    },
    {
      name: 'estimateDuration',
      id: 'estimateDuration',
      options: {
        filter: false,
        display: false,
        sort: false
      }
    }
  ];

  let title = '';
  let textColor = '';

  let customTitle = null;

  const buildCustomTitle = (approved) => {
    return (customTitle = (
      <Box
        style={{
          color: textColor,
          display: 'flex',
          alignItems: 'center',
          fontSize: '1.2rem',
          flex: '1 1 0%',
          justifyContent: 'flex-end'
        }}>
        <Typography variant="subtitle1" style={{ paddingRight: '10px' }}>
          {approved === 'noData' ? (
            <span style={{ color: '#00000060' }}>{t('sharedLabels.notStarted')}</span>
          ) : approved ? (
            t('sharedLabels.approved') + ' '
          ) : (
            t('sharedLabels.notApproved') + ' '
          )}
        </Typography>
        {approved === 'noData' ? null : approved ? <CheckCircleOutline /> : <ErrorOutline />}
      </Box>
    ));
  };

  if (props.selectedFilter.course.length > 0) {
    title = props.filteredTrainings.length > 0 && props.filteredTrainings[0].course.name;
  }

  if (props.selectedFilter.training.length > 0 && props.filteredTrainings[0]) {
    title = getTranslatedNameFromTraining({ training: props.filteredTrainings[0].training });
    if (props.selectedSwitch === 'assessment') {
      if (props.filteredTrainings[0].assessments.length < 1) {
        textColor = '';
        buildCustomTitle('noData');
      } else if (props.filteredTrainings[0].assessmentApproved) {
        //Approved
        textColor = theme.palette.success.main;
        buildCustomTitle(true);
      } else {
        //Failed
        textColor = theme.palette.error.main;
        buildCustomTitle(false);
      }
    } else {
      //Practice
      textColor = theme.palette.text.hint;
    }
  }

  let rows = [];

  props.filteredTrainings.forEach((training) => {
    const data = props.selectedSwitch === 'assessment' ? training.assessments : training.practices;
    data
      .sort((a, b) => b.createdAt - a.createdAt)
      .forEach((session) => {
        rows.push([
          session.id,
          training.course.name,
          training,
          session.createdAt,
          session.duration,
          session.completedAt,
          session.performance,
          session.errors,
          session.isComplete,
          session.estimateDuration
        ]);
      });
  });

  const sortedRows = rows.sort(function (a, b) {
    return b[3] - a[3];
  });

  const filteredData = filterUserTableSessionsData(
    sortedRows,
    columns,
    userDetailsTableFilters,
    handleDateFormat
  );

  const handleUserLink = (data) => {
    props.setSessionId(data[0]);
    props.setShowSessionsModal(true);
  };

  const handleSetFilters = (filters) => {
    dispatch(setTableFilters(filters));
  };

  title = (
    <Box style={{ display: 'flex' }}>
      <Typography variant="h6" style={{ color: textColor }}>
        {title ? title : ' ' + t('userDetails.table.title')}
      </Typography>
      {customTitle}
    </Box>
  );

  const tableConfig = {
    print: false,
    viewColumns: false,
    search: true,
    download: false,
    filter: false,
    responsiveType: 'scroll',
    onRowClick: handleUserLink,
    title: title,
    rowData: filteredData,
    columns: columns,
    customFilter: USER_SESSIONS,
    setFilters: handleSetFilters,
    filters: userDetailsTableFilters,
    originalData: rows
  };

  return (
    <Grid item xs={12}>
      <Table tableConfig={tableConfig} />
    </Grid>
  );
};

export default SessionsTable;
