import { gql } from '@apollo/client';

const LATEST_ACTIVITIES = gql`
  query latestActivities($orgId: Int, $userId: Int, $limit: Int!, $cohortId: Int) {
    latestActivities(orgId: $orgId, limit: $limit, userId: $userId, cohortId: $cohortId) {
      userId
      username
      courseName
      trainingName
      sessionCategory
      sessionId
      createdAt
      completedAt
      isComplete
    }
  }
`;

export default LATEST_ACTIVITIES;
