import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircleOutlined, ErrorOutlined } from '@material-ui/icons';
import ConfirmButton from 'component/Buttons/ConfirmButton';

const useStyles = makeStyles((theme) => ({
  paddingRight: {
    paddingRight: '1.2rem'
  },
  customActionButtons: {
    display: 'flex'
  },
  responseText: {
    display: 'flex',
    alignItems: 'center',
    color: (props) =>
      props.response?.responseType === 'success'
        ? theme.palette.success.main
        : theme.palette.error.main,
    height: '100%'
  },
  responseIcon: {
    margin: '0 1.2rem'
  }
}));

const CustomActionButtons = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  useEffect(() => {
    const timeId = setTimeout(() => {
      if (props.response) {
        props.setResponse(null);
      }
    }, 2500);
    return () => {
      clearTimeout(timeId);
    };
  }, [props.response]);

  const responseIcon =
    props.response?.responseType === 'success' ? (
      <CheckCircleOutlined className={classes.responseIcon} />
    ) : (
      <ErrorOutlined className={classes.responseIcon} />
    );

  return (
    <Box className={classes.customActionButtons}>
      {props.editMode ? (
        <ConfirmButton onClick={() => props.onSubmit({ createAnotherUser: false })} color="primary">
          {t('userManagement.update')}
        </ConfirmButton>
      ) : (
        <>
          {props.response ? (
            <Box className={classes.responseText}>
              {props.response.message}
              {responseIcon}
            </Box>
          ) : (
            <>
              <Box className={classes.paddingRight}>
                <ConfirmButton
                  onClick={() => props.onSubmit({ createAnotherUser: true })}
                  variant="outlined">
                  {t('userManagement.saveAlt')}
                </ConfirmButton>
              </Box>
              <ConfirmButton onClick={() => props.onSubmit({ createAnotherUser: false })}>
                {t('userManagement.save')}
              </ConfirmButton>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default CustomActionButtons;
