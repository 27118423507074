import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    padding: '5px 10px',
    color: (props) =>
      props.variant === 'outlined' ? theme.palette.success.main : theme.palette.background.paper,
    border: (props) => (props.variant === 'outlined' ? '1px solid #06AC69' : ''),
    backgroundColor: (props) =>
      props.disabled
        ? theme.palette.grey['200']
        : props.variant === 'outlined'
        ? ''
        : theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.background.paper
    }
  }
}));

const SuccessButton = (props) => {
  const classes = useStyles(props);
  return (
    <Button
      disabled={props.disabled}
      size={props.size}
      onClick={props.onClick}
      variant={props.variant}
      className={classes.root}>
      {props.children}
    </Button>
  );
};

export default SuccessButton;

SuccessButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};
