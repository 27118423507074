import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FilterList } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) =>
      props.disabled ? theme.palette.grey['200'] + 40 : theme.palette.background.paper,
    border: '1px solid ' + theme.palette.grey['100'] + 90,
    color: (props) => (props.disabled ? theme.palette.text.hint : theme.palette.primary.main),
    '&:hover': {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main
    },
    height: '40px'
  },
  icon: {
    marginLeft: '10px'
  }
}));

const FilterButton = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <Button
        variant="outlined"
        className={classes.root}
        disabled={props.disabled}
        onClick={props.onClick}>
        Filter <FilterList className={classes.icon} fontSize="small" />
      </Button>
    </>
  );
};

export default FilterButton;

FilterButton.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};
