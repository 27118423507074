import { gql } from '@apollo/client';

export const SEND_PASSWORD_RESET_LINK = gql`
  mutation sendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;
