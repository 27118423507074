import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';
import { setResetPasswordInvalidToken } from 'ducks/auth';
import Language from 'layout/NavBar/Language';

const useStyles = makeStyles((theme) => ({
  language: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: '14px 40px',
    [theme.breakpoints.down('md')]: {
      margin: '14px 10px'
    }
  },
  expiredToken: {
    backgroundColor: theme.palette.error.main,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    right: 0,
    top: 0,
    margin: 'auto',
    width: '100%',
    padding: '20px',
    color: theme.palette.background.paper
  },
  expiredTokenText: {
    width: '70%',
    [theme.breakpoints.only('md')]: {
      width: '50%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '35%'
    },
    [theme.breakpoints.only('xs')]: {
      width: '90%'
    }
  }
}));

const LoginViews = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { resetPasswordInvalidToken } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(setResetPasswordInvalidToken(false));
  }, []);

  return (
    <>
      {resetPasswordInvalidToken && (
        <Box className={classes.expiredToken}>
          <Box className={classes.expiredTokenText}>{t('resetPassword.invalidToken')}</Box>
        </Box>
      )}
      {!props.auth && (
        <Box className={classes.language}>
          <Language />
        </Box>
      )}
      {props.children}
    </>
  );
};

export default LoginViews;
