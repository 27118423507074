import React from 'react';

export const AddIconLarge = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35.235 35.229">
      <g id="Group_263" data-name="Group 263" transform="translate(0 0)">
        <path
          id="Path_279"
          data-name="Path 279"
          d="M0,17.653A17.5,17.5,0,1,1,1.332,24.4,17.617,17.617,0,0,1,0,17.653ZM17.617,33.439A15.786,15.786,0,1,0,1.826,17.653,15.807,15.807,0,0,0,17.617,33.439Z"
          transform="translate(0.001 -0.038)"
          fill="#fc8f2c"
        />
        <path
          id="add_FILL0_wght400_GRAD0_opsz48"
          d="M19,28a.935.935,0,0,1-.965-.965V19.967H10.965a.965.965,0,1,1,0-1.929h7.073V10.965a.965.965,0,1,1,1.929,0v7.073H27.04a.965.965,0,1,1,0,1.929H19.967V27.04A.935.935,0,0,1,19,28Z"
          transform="translate(-1.384 -1.387)"
          fill="#fc8f2c"
        />
      </g>
    </svg>
  );
};

export const AddIconMedium = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 35.235 35.229">
      <g id="Group_263" data-name="Group 263" transform="translate(0 0)">
        <path
          id="Path_279"
          data-name="Path 279"
          d="M0,17.653A17.5,17.5,0,1,1,1.332,24.4,17.617,17.617,0,0,1,0,17.653ZM17.617,33.439A15.786,15.786,0,1,0,1.826,17.653,15.807,15.807,0,0,0,17.617,33.439Z"
          transform="translate(0.001 -0.038)"
          fill="#fc8f2c"
        />
        <path
          id="add_FILL0_wght400_GRAD0_opsz48"
          d="M19,28a.935.935,0,0,1-.965-.965V19.967H10.965a.965.965,0,1,1,0-1.929h7.073V10.965a.965.965,0,1,1,1.929,0v7.073H27.04a.965.965,0,1,1,0,1.929H19.967V27.04A.935.935,0,0,1,19,28Z"
          transform="translate(-1.384 -1.387)"
          fill="#fc8f2c"
        />
      </g>
    </svg>
  );
};

export const AddIconSmall = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 35.235 35.229">
      <g id="Group_263" data-name="Group 263" transform="translate(0 0)">
        <path
          id="Path_279"
          data-name="Path 279"
          d="M0,17.653A17.5,17.5,0,1,1,1.332,24.4,17.617,17.617,0,0,1,0,17.653ZM17.617,33.439A15.786,15.786,0,1,0,1.826,17.653,15.807,15.807,0,0,0,17.617,33.439Z"
          transform="translate(0.001 -0.038)"
          fill="#fc8f2c"
        />
        <path
          id="add_FILL0_wght400_GRAD0_opsz48"
          d="M19,28a.935.935,0,0,1-.965-.965V19.967H10.965a.965.965,0,1,1,0-1.929h7.073V10.965a.965.965,0,1,1,1.929,0v7.073H27.04a.965.965,0,1,1,0,1.929H19.967V27.04A.935.935,0,0,1,19,28Z"
          transform="translate(-1.384 -1.387)"
          fill="#fc8f2c"
        />
      </g>
    </svg>
  );
};
