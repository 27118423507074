import React from 'react';
import { Button, ButtonGroup as ButtonGrouping } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: theme.palette.grey['200'] + 40,
    color: theme.palette.text.hint
  },
  default: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    border: '1px solid ' + theme.palette.grey['100'] + 90,
    '&:hover': {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
      border: '1px solid ' + theme.palette.primary.main
    }
  },
  selected: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid ' + theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
      border: '1px solid ' + theme.palette.primary.main
    }
  }
}));

const ButtonGroup = (props) => {
  const classes = useStyles(props);

  return (
    <ButtonGrouping disableElevation variant="outlined">
      {props.options.map((button, i) => {
        return (
          <Button
            disabled={props.disabled}
            key={button.name + i}
            className={
              props.disabled
                ? classes.disabled
                : props.selected === button.id
                ? classes.selected
                : classes.default
            }
            onClick={() => props.onClick(button.id)}>
            {button.name}
          </Button>
        );
      })}
    </ButtonGrouping>
  );
};

export default ButtonGroup;
