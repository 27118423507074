import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) =>
      props.disabled ? theme.palette.grey['200'] + 50 : theme.palette.background.paper,
    opacity: (props) => props.disabled && '0.7',
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.palette.background.paper + ' !important'
    }
  },
  selectRoot: {
    backgroundColor: '#00000009',
    '&:focus': {
      backgroundColor: '#00000009',
      color: theme.palette.secondary.main
    }
  },
  menuPaper: {
    maxHeight: '180px'
  }
}));

const CustomSelectField = styled(Select)(({ theme }) => ({
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid ' + theme.palette.grey['100'] + '!important'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: (props) => !props.disabled && '2px solid ' + theme.palette.primary.main
  },
  '&:focus .MuiSelect-select': {
    backgroundColor: theme.palette.background.paper
  }
}));

const CustomFormHelperText = styled(FormHelperText)(() => ({
  marginLeft: 0,
  marginRight: 0
}));

const SelectInput = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);

  return (
    <FormControl
      fullWidth
      variant="outlined"
      size="small"
      required={props.required}
      error={props.error}
      margin="normal"
      disabled={props.disabled}>
      <InputLabel id={props.name}>{props.label}</InputLabel>
      <CustomSelectField
        disabled={props.disabled}
        className={classes.root}
        multiple={props.multiple}
        labelId={props.name}
        id={props.name}
        label={props.label}
        value={props.selected}
        name={props.name}
        onChange={props.disabled ? null : props.onChange}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: props.topMenu ? 'top' : 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: props.topMenu ? 'bottom' : 'top',
            horizontal: 'left'
          },
          classes: { paper: classes.menuPaper }
        }}>
        {!props.multiple && props.canSelectNone && (
          <MenuItem value="" className={classes.selectRoot}>
            <em>{t('inputs.select.selectNone')}</em>
          </MenuItem>
        )}
        {props.group && props.menuItems.length > 0
          ? props.menuItems.map((group, i) => {
              return [
                <Typography
                  key={i}
                  style={{
                    color: '#00000090',
                    fontSize: '0.78rem',
                    fontWeight: '500',
                    marginTop: i !== 0 && '0.6rem',
                    paddingTop: i !== 0 ? '1rem' : '0.6rem',
                    paddingLeft: '1rem',
                    paddingBottom: '0.2rem',
                    borderTop: i !== 0 && '1px solid #00000020'
                  }}>
                  {group.name}
                </Typography>,
                group.children.length > 0 ? (
                  group.children.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem disabled value="">
                    <em>...</em>
                  </MenuItem>
                )
              ];
            })
          : props.menuItems.length > 0 &&
            props.menuItems.map((item) => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
      </CustomSelectField>
      {props.underText || props.error ? (
        <CustomFormHelperText>{props.underText}</CustomFormHelperText>
      ) : null}
    </FormControl>
  );
};

export default SelectInput;

SelectInput.propTypes = {
  multiple: PropTypes.bool,
  canSelectNone: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  //Selected goes through as an array of number or strings
  //Unfortunately when passing a whole object it is unable to
  //pass referential equality check, that is native Selects behaviour.
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  name: PropTypes.string,
  //Select function
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      //Color for grouping
      color: PropTypes.string,
      //Child needed if grouping in the menu.
      child: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number
      })
    })
  ),
  group: PropTypes.bool
};
