import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import ConfirmButton from 'component/Buttons/ConfirmButton';
import { LabelImportCSV } from 'component/Labels';
import ViewContainer from 'component/ViewContainer/ViewContainer';
import { UPDATE_CREATE_LABEL } from 'graphql/TextManagement';
import { ALL_TRAININGS } from 'graphql/Trainings';
import { useUser } from 'hooks';
import { useLanguages, useOrganization, useTrainings, useTrainingTranslation } from 'hooks';
import styled from 'styled-components';

import DownloadModal from './DownloadModal';
import TextModal from './TextModal';
import TextTable from './TextTable';

const DownloadCSVWrapper = styled.div`
  display: flex;
`;
const DownloadCSVButtonWrapper = styled.div`
  margin-right: 10px;
`;

const AdminTextManagement = () => {
  const { t } = useTranslation();
  const { organizationData } = useOrganization();
  const { user, isCourseAdmin } = useUser();
  const [selectedText, setSelectedText] = useState(null);
  const [showTextModal, setShowTextModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [response, setResponse] = useState(null);
  const [selectedTextIndexes, setSelectedTextIndexes] = useState([]);

  const { getAvailableLanguages, languages } = useLanguages();
  const { getAllTrainings, trainings, refetchTrainings, errorTrainings, loadingTrainings } =
    useTrainings();
  const { getTranslatedNameFromTraining } = useTrainingTranslation();

  useEffect(() => {
    getAvailableLanguages();
    getAllTrainings({
      variables: {
        orgId: organizationData.organization.id
      }
    });
  }, []);

  let textData = [];

  if (trainings.length !== 0) {
    let courseAdminTrainings = [];
    let trainingsList = trainings;
    if (isCourseAdmin) {
      user.trainings.forEach((training) => {
        const foundTraining = trainings.find((t) => t.id === training.id);
        if (foundTraining) courseAdminTrainings.push(foundTraining);
      });
      trainingsList = courseAdminTrainings;
    }

    trainingsList.forEach((training) => {
      training.labels.forEach((text) => {
        let foundText = textData.find(
          (l) => l.key === text.key && l.trainingId === text.training.id
        );
        if (foundText) {
          foundText.texts.push({ id: text.id, languageCode: text.language.code, text: text.text });
        } else {
          textData.push({
            id: text.id,
            key: text.key,
            text: text.text,

            training: getTranslatedNameFromTraining({ training: training }),
            trainingId: text.training.id,
            texts: [{ id: text.id, languageCode: text.language.code, text: text.text }]
          });
        }
      });
    });
  }

  const [updateCreateLabel, { error: updateError }] = useMutation(UPDATE_CREATE_LABEL, {
    refetchQueries: [
      { query: ALL_TRAININGS, variables: { orgId: organizationData.organization.id } }
    ],
    onCompleted(updateData) {
      setResponse({
        message: updateData.updateLabel.success
          ? t('textManagement.responses.updateSuccess')
          : t('textManagement.responses.updateFail'),
        responseType: updateData.updateLabel.success ? 'success' : 'error'
      });
      !updateData.updateLabel.succes && setSaving(false);
    }
  });

  const handleUpdateText = (data) => {
    setSaving(true);
    updateCreateLabel({
      variables: data
    });
  };

  const handleAddText = (trainingId, textKey, textData) => {
    setSaving(true);
    updateCreateLabel({
      variables: {
        key: textKey,
        textData: textData,
        trainingId: trainingId
      }
    });
  };

  const handleClearStates = () => {
    setSaving(false);
    setSelectedText(null);
    setResponse(null);
  };

  const handleShowModal = (text, type, showModal) => {
    if (text) setSelectedText(text);
    if (type === 'text') setShowTextModal(showModal);
    if (!showModal) {
      handleClearStates();
    }
  };

  const handleCloseModal = (type) => {
    handleShowModal(null, type, false);
  };

  const onImportCSVSucess = useCallback(() => {
    refetchTrainings();
  }, []);

  const onSelectedTexts = ({ rowsSelected }) => {
    setSelectedTextIndexes(rowsSelected);
  };

  return (
    <ViewContainer
      title={t('textManagement.title')}
      loading={loadingTrainings}
      flexView={true}
      error={errorTrainings || updateError}
      actionButtons={() => {
        return (
          <div>
            <DownloadCSVWrapper>
              <DownloadCSVButtonWrapper>
                <ConfirmButton
                  disabled={loadingTrainings}
                  size="small"
                  onClick={() => setShowDownloadModal(true)}>
                  {t('textManagement.export.title')}
                </ConfirmButton>
              </DownloadCSVButtonWrapper>
              <LabelImportCSV
                disabled={loadingTrainings}
                onSuccess={onImportCSVSucess}
                trainings={trainings}
              />
              <ConfirmButton
                disabled={loadingTrainings}
                size="medium"
                onClick={() => handleShowModal(null, 'text', true)}>
                {t('textManagement.create')}
              </ConfirmButton>
            </DownloadCSVWrapper>
          </div>
        );
      }}>
      {showTextModal ? (
        <TextModal
          saving={saving}
          response={response}
          languages={languages}
          trainings={trainings}
          selectedText={selectedText}
          handleCloseModal={handleCloseModal}
          updateCreateLabel={handleUpdateText}
          addText={handleAddText}
        />
      ) : null}
      {showDownloadModal ? (
        <DownloadModal
          labels={textData}
          handleCloseModal={() => setShowDownloadModal(false)}
          onSuccess={() => setShowDownloadModal(false)}
        />
      ) : null}

      <TextTable
        textData={textData}
        handleShowModal={handleShowModal}
        onSelectedTexts={onSelectedTexts}
        rowsSelected={selectedTextIndexes}
      />
    </ViewContainer>
  );
};

export default AdminTextManagement;
