import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Radio, Typography, useTheme } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

const QuestionFooter = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <Typography
        variant="caption"
        style={{ margin: '5px 0 0 15px', color: props.error && theme.palette.error.main }}>
        {props.error ? t('quizManagement.form.error') : '* ' + t('quizManagement.form.subText3')}
      </Typography>
      <Grid
        item
        container
        xs={12}
        direction="row"
        alignItems="center"
        style={{
          marginTop: '15px',
          paddingTop: '10px',
          borderTop: '1px solid ' + theme.palette.grey['200']
        }}>
        <Grid item container xs={8}>
          <Box
            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            onClick={() => props.addChoice(props.qIndex)}>
            <Radio color="primary" name="correctAnswer" checked={false} disabled={true} />
            <Typography color="primary">{t('quizManagement.form.addChoice')}</Typography>
          </Box>
        </Grid>
        {props.removeQuestion && (
          <Button
            onClick={() => props.removeQuestion(props.qIndex)}
            style={{ marginLeft: '33px', paddingLeft: '10px' }}>
            {t('quizManagement.form.deleteQuestion')}
            <DeleteForever style={{ fontSize: '1.5rem', margin: '0 0 5px 20px' }} />
          </Button>
        )}
      </Grid>
    </>
  );
};

export default QuestionFooter;
