import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CheckCircleOutline,
  CheckCircleOutlineRounded,
  ErrorOutline,
  WarningOutlined
} from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import FormSubTitle from 'component/Inputs/FormSubTitle';
import FullScreenModal from 'component/Modals/FullScreenModal';
import Table from 'component/Table/Table';
import SESSION_INFO from 'graphql/SessionInfo';
import { useUser } from 'hooks';
import { useDateFormat } from 'hooks/useDateFormat';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    paddingBottom: '15px',
    width: '100%',
    display: 'flex'
  },
  tableTitleContainer: {
    display: 'flex'
  },
  createdAt: {
    marginLeft: '10px'
  },
  completeText: {
    marginLeft: '30px'
  },
  completedAt: {
    marginLeft: '10px',
    color: (data) =>
      data?.session?.completedAt ? theme.palette.success.main : theme.palette.error.main
  },
  tableContainer: {
    flex: '1 1 0%',
    position: 'relative'
  },
  table: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%'
  },
  modalTitleText: {
    width: '60%'
  },
  statusText: {
    textTransform: 'uppercase',
    flex: '1 1 0%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontWeight: 700,
    '& p': {
      paddingLeft: '5px',
      margin: 0
    }
  },
  statusTextPassed: {
    color: theme.palette.success.main
  },
  statusTextNotPassed: {
    color: theme.palette.error.main
  }
}));

const SessionsModal = (props) => {
  const { getUserTimezone } = useUser();
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleDateFormat } = useDateFormat();
  const { data, loading } = useQuery(SESSION_INFO, {
    variables: {
      sessionId: Number(props.sessionId)
    }
  });
  const classes = useStyles(data);

  const columns = [
    {
      name: 'id',
      options: {
        filter: false,
        display: false,
        sort: false
      }
    },
    {
      //MAKES SPACE FOR ERROR ICON IN TABLE
      name: '',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: t('sharedLabels.description'),
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: t('sharedLabels.object'),
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: t('sharedLabels.timePerformed'),
      options: {
        filter: false
      }
    },
    {
      name: t('sharedLabels.errorType'),
      options: {
        filter: false
      }
    }
  ];

  let rows = [];
  let tableTitle = '';
  let passed = false;

  if (data) {
    if (!data.session.eventSet.find((eventSet) => eventSet.errorSeverity === 2)) {
      passed = true;
    }

    data.session.eventSet.forEach((set) => {
      const errorType = (
        <Typography
          variant="body2"
          style={{
            color:
              set.errorSeverity === 1
                ? theme.palette.warning.main
                : set.errorSeverity === 2
                ? theme.palette.error.main
                : theme.palette.success.main
          }}>
          {set.errorSeverity === 1
            ? t('sessionsDetails.table.soft')
            : set.errorSeverity === 2
            ? t('sessionsDetails.table.hard')
            : t('sessionsDetails.table.correct')}
        </Typography>
      );

      const errorIcon =
        set.errorSeverity === 1 ? (
          <WarningOutlined style={{ color: theme.palette.warning.main }} />
        ) : set.errorSeverity === 2 ? (
          <ErrorOutline style={{ color: theme.palette.error.main }} />
        ) : (
          <CheckCircleOutline style={{ color: theme.palette.success.main }} />
        );

      rows.push([
        set.id,
        errorIcon,
        set.description,
        set.object.name,
        moment.tz(set.performedAt, getUserTimezone()).format(handleDateFormat(true)),
        errorType
      ]);
    });

    tableTitle = (
      <Box className={classes.tableTitleContainer}>
        <Box>{t('sharedLabels.startTime')}:</Box>
        <Box className={classes.createdAt}>
          {moment.tz(data.session.createdAt, getUserTimezone()).format(handleDateFormat(true))}
        </Box>
        <Box className={classes.completeText}>{t('sharedLabels.completionTime')}:</Box>
        <Box className={classes.completedAt}>
          {data.session.completedAt
            ? moment.tz(data.session.completedAt, getUserTimezone()).format(handleDateFormat(true))
            : 'Not Completed'}
        </Box>
      </Box>
    );
  }

  const tableConfig = {
    title: tableTitle,
    print: false,
    viewColumns: false,
    search: true,
    download: false,
    filter: false,
    expandableRows: false,
    rowData: rows,
    columns: columns,
    hideBoxShadow: true,
    flexView: true
  };

  const createdAt = moment
    .tz(data?.session.createdAt, getUserTimezone())
    .format(handleDateFormat(true));
  const title = data
    ? data.session.name
      ? data.session.name + ' ' + createdAt
      : t('sharedLabels.session') + ' ' + createdAt
    : '';

  return (
    <FullScreenModal
      width="50rem"
      title={title}
      handleCloseModal={() => props.handleCloseModal()}
      handleDone={() => props.handleCloseModal()}
      saving={props.saving}
      response={props.response}
      hideFooter={true}
      loading={loading}
      hideOverflow={true}>
      <Box className={classes.modalTitle}>
        <Box className={classes.modalTitleText}>
          <FormSubTitle title={t('tooltips.sessionsInfo')} />
        </Box>

        <Box
          className={`${passed ? classes.statusTextPassed : classes.statusTextNotPassed} ${
            classes.statusText
          }`}>
          {data?.session?.eventSet.length < 1 ? null : passed ? (
            <>
              <CheckCircleOutlineRounded />
              <p>{t('sharedLabels.passed')}</p>
            </>
          ) : (
            <>
              <ErrorOutline />
              <p>{t('sharedLabels.notPassed')}</p>
            </>
          )}
        </Box>
      </Box>
      <Box className={classes.tableContainer}>
        <Box className={classes.table}>
          <Table tableConfig={tableConfig} />
        </Box>
      </Box>
    </FullScreenModal>
  );
};

export default SessionsModal;
