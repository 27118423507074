import React from 'react';
import { makeStyles } from '@material-ui/core';
import { buildBreadcrumbs } from 'helpers/buildBreadcrumbs';

import NavDropdown from '../NavDropdown';

const useStyles = makeStyles((theme) => ({
  root: {
    borderLeft: '1px solid' + theme.palette.text.hint + 80,
    padding: '0 25px',
    fontSize: '0.8rem',
    letterSpacing: '-20',
    textDecoration: 'none',
    textTransform: 'uppercase',
    cursor: 'pointer',
    color: theme.palette.text.dark,
    fontWeight: '500',
    '&:hover': {
      color: theme.palette.primary.main,
      fontWeight: '600'
    }
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: '600'
  }
}));

const NavItem = (props) => {
  const classes = useStyles();
  const isSelected =
    window.sessionStorage.getItem('navItem') === props.nav.id || props.selected === props.nav.id;
  return props.nav.dropdown ? (
    <NavDropdown nav={props.nav} noDataText={props.nav.noData}>
      <p className={`${classes.root} ${isSelected && classes.selected}`}>{props.nav.name}</p>
    </NavDropdown>
  ) : (
    <li
      onClick={() => {
        buildBreadcrumbs('clear');
        props.nav.action(props.nav.id);
      }}
      className={`${classes.root} ${isSelected && classes.selected}`}>
      {props.nav.name}
    </li>
  );
};

export default NavItem;
