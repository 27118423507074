import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { COLOR_LIST_CHART } from 'constants/colors';

const DonutChart = (props) => {
  const theme = useTheme();
  const collapseSM = useMediaQuery(theme.breakpoints.down('sm'));

  let chartData = {
    options: {
      dataLabels: {
        enabled: false
      },
      labels: props.chartData.labels,
      chart: {
        height: '100%'
      },
      legend: {
        show: !collapseSM,
        position: collapseSM ? 'bottom' : 'right',
        fontFamily: 'inherit',
        fontSize: '12px',
        horizontalAlign: 'left',
        labels: {
          colors: 'inherit'
        },
        formatter: (seriesName, opts) => {
          return [seriesName, ' - ' + props.chartData.percentages[opts.seriesIndex] + '%'];
        }
      },
      itemMargin: {
        horizontal: 10,
        vertical: 10
      },
      colors: COLOR_LIST_CHART
    },
    series: props.chartData.series
  };

  return props.chartData.series.length > 1 ? (
    <ReactApexChart
      style={{ display: 'flex', justifyContent: '' }}
      {...chartData}
      type="donut"
      height={'100%'}
      width={'100%'}
    />
  ) : (
    props.chartData.singleContent && props.chartData.singleContent
  );
};

export default DonutChart;
