import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useUser } from 'hooks';
import moment from 'moment-timezone';

import { useDateFormat } from '../../../hooks/useDateFormat';
import SelectInput from '../../Inputs/SelectInputs/SelectInput';

const useStyles = makeStyles(() => ({
  input: {
    paddingRight: '20px'
  }
}));

const UserSessionsFilter = (props) => {
  const { getUserTimezone } = useUser();
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { handleDateFormat } = useDateFormat();
  const { userDetailsTableFilters } = useSelector((state) => state.userDetails);
  const [startDateOptions, setStartDateOptions] = useState([]);
  const [compDateOptions, setCompDateOptions] = useState([]);
  const [performanceOptions, setPerformanceOptions] = useState([]);

  const startDateIndex = props.cols.indexOf(props.cols.find((col) => col.id === 'startDate'));
  const compDateIndex = props.cols.indexOf(props.cols.find((col) => col.id === 'completionDate'));
  const performanceIndex = props.cols.indexOf(props.cols.find((col) => col.id === 'performance'));

  useEffect(() => {
    props.setFilters(userDetailsTableFilters);
    let startDateList = [{ name: 'All', id: 'all' }];
    let compDateList = [{ name: 'All', id: 'all' }];
    let performanceList = [{ name: 'All', id: 'all' }];
    props.data.forEach((row) => {
      const startDate = moment
        .tz(row[startDateIndex] * 1000, getUserTimezone())
        .format(handleDateFormat(false));
      const compDate =
        row[performanceIndex] === 'noData'
          ? 'noData'
          : moment.tz(row[compDateIndex] * 1000, getUserTimezone()).format(handleDateFormat(false));
      const performance = row[performanceIndex];

      if (!startDateList.find((option) => option.id === startDate)) {
        startDateList.push({ name: startDate, id: startDate, sort: row[startDateIndex] });
      }
      if (!compDateList.find((option) => option.id === compDate)) {
        compDateList.push({
          name: compDate === 'noData' ? t('sharedLabels.incomplete') : compDate,
          id: compDate,
          sort: row[performanceIndex] === 'noData' ? 'noData' : row[compDateIndex]
        });
      }
      if (!performanceList.find((option) => option.id === performance)) {
        performanceList.push({
          name:
            performance === 'noData'
              ? t('sharedLabels.incomplete')
              : performance === 'fail'
              ? t('sharedLabels.notPassed')
              : t('sharedLabels.passed'),
          id: performance,
          sort: row[performanceIndex]
        });
      }
    });

    startDateList.sort((a, b) => (a.sort > b.sort ? -1 : 1));
    compDateList.sort((a, b) => (a.sort > b.sort ? -1 : 1));
    performanceList.sort((a, b) => (a.sort > b.sort ? -1 : 1));

    setStartDateOptions(startDateList);
    setCompDateOptions(compDateList);
    setPerformanceOptions(performanceList);
  }, []);

  const handleSelect = (e) => {
    let filterObjCopy = { ...props.filters };
    const filterOptions =
      e.target.name === 'performance'
        ? performanceOptions
        : e.target.name === 'completionDate'
        ? compDateOptions
        : startDateOptions;
    filterObjCopy[e.target.name] = filterOptions.find((option) => option.id === e.target.value);
    props.setFilters(filterObjCopy);
  };

  return (
    props.filters && (
      <Box>
        <Grid container>
          <Grid className={classes.input} item xs={6}>
            <SelectInput
              name="startDate"
              label={t('sharedLabels.startDate')}
              selected={props.filters.startDate.id}
              menuItems={startDateOptions}
              onChange={handleSelect}
            />
          </Grid>
          <Grid className={classes.input} item xs={6}>
            <SelectInput
              name="completionDate"
              label={t('sharedLabels.completionDate')}
              selected={props.filters.completionDate.id}
              menuItems={compDateOptions}
              onChange={handleSelect}
            />
          </Grid>
          <Grid className={classes.input} item xs={6}>
            <SelectInput
              name="performance"
              label={t('sharedLabels.performance')}
              selected={props.filters.performance.id}
              menuItems={performanceOptions}
              onChange={handleSelect}
            />
          </Grid>
        </Grid>
      </Box>
    )
  );
};

export default UserSessionsFilter;
