import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FileUpload from 'component/Inputs/FileUpload';
import FormSubTitle from 'component/Inputs/FormSubTitle';
import SelectInput from 'component/Inputs/SelectInputs/SelectInput';
import TextArea from 'component/Inputs/TextArea';
import TextInput from 'component/Inputs/TextInput';
import FullScreenModal from 'component/Modals/FullScreenModal';
import ADD_TRAININGS from 'graphql/AddTrainings';
import UPDATE_TRAININGS from 'graphql/UpdateTrainings';
import Papa from 'papaparse';
import readXlsxFile from 'read-excel-file';
import { UsersTable } from 'views/Admin/components';

const useStyles = makeStyles({
  paddingBottom: {
    paddingBottom: '1.2rem'
  },
  paddingRight: {
    paddingRight: '1.2rem',
    alignSelf: 'flex-start'
  }
});

const TrainingModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [response, setResponse] = useState(null);
  const [trainingDetails, setTrainingDetails] = useState({
    name: props.selectedTraining ? props.selectedTraining.name : '',
    details: props.selectedTraining ? props.selectedTraining.details : '',
    course: props.selectedTraining ? props.selectedTraining.course.id : '',
    users: props.selectedTraining ? props.selectedTraining.user.map((user) => user.id) : []
  });
  const [formErrors, setFormErrors] = useState({
    name: false,
    details: false,
    course: false,
    users: false
  });

  const uploadFile = async (e) => {
    const splitFileName = e.target.files[0].name.split('.');
    const fileType = splitFileName[splitFileName.length - 1];
    let userList = [];
    let filteredUserList;

    const filterOutUsers = () => {
      filteredUserList = props.optionsData.users.filter((user) => {
        if (userList.includes(user.username)) {
          return user;
        }
      });
      setTrainingDetails({
        ...trainingDetails,
        users: filteredUserList.map((user) => user.id)
      });
    };

    if (fileType === 'csv') {
      Papa.parse(e.target.files[0], {
        complete: function (results) {
          results.data.forEach((results) => {
            let filteredResult = results.filter((r) => r.length > 0);
            userList.push(filteredResult[0]);
          });
          filterOutUsers();
        }
      });
    } else {
      await readXlsxFile(e.target.files[0]).then((rows) => {
        rows.forEach((row) => userList.push(row[0]));
      });
      filterOutUsers();
    }
  };

  const [updateTrainings, { loading: updateTrainingLoading }] = useMutation(UPDATE_TRAININGS, {
    onCompleted(trainingUpdated) {
      setResponse({
        message: trainingUpdated.updateTrainings.success
          ? t('trainingManagement.responses.updateSuccess')
          : trainingUpdated.updateTrainings.error,
        responseType: trainingUpdated.updateTrainings.success ? 'success' : 'error'
      });
      let trainingDataCopy = JSON.parse(JSON.stringify(props.trainingData));
      let foundTraining = trainingDataCopy.find(
        (training) => training.id === trainingUpdated.updateTrainings.trainings[0].id
      );
      foundTraining.name = trainingUpdated.updateTrainings.trainings[0].name;
      foundTraining.details = trainingUpdated.updateTrainings.trainings[0].details;
      foundTraining.course = trainingUpdated.updateTrainings.trainings[0].course;
      foundTraining.user = trainingUpdated.updateTrainings.trainings[0].user;
      props.setTrainingData(trainingDataCopy);
      clearErrors();
    }
  });

  const [addTrainings, { loading: addTrainingLoading }] = useMutation(ADD_TRAININGS, {
    onCompleted(trainingAdded) {
      setResponse({
        message: trainingAdded.addTrainings.success
          ? t('trainingManagement.responses.createSuccess')
          : trainingAdded.addTrainings.error,
        responseType: trainingAdded.addTrainings.success ? 'success' : 'error'
      });
      props.setTrainingData([trainingAdded.addTrainings.trainings[0], ...props.trainingData]);
      clearErrors();
    }
  });

  const canSave = () => {
    let error = false;
    let formErrorsCopy = { ...formErrors };
    Object.keys(trainingDetails).forEach((key) => {
      if (trainingDetails[key].length < 1) {
        formErrorsCopy[key] = true;
        error = true;
      }
    });
    setFormErrors(formErrorsCopy);
    return error;
  };

  const handleUpdateTraining = () => {
    if (!canSave()) {
      updateTrainings({
        variables: {
          data: [
            {
              id: Number(props.selectedTraining.id),
              name: trainingDetails.name,
              details: trainingDetails.details,
              courseId: Number(trainingDetails.course),
              userIds: trainingDetails.users.map((user) => Number(user))
            }
          ]
        }
      });
    }
  };

  const handleAddTraining = () => {
    if (!canSave()) {
      addTrainings({
        variables: {
          data: [
            {
              name: trainingDetails.name,
              details: trainingDetails.details,
              courseId: Number(trainingDetails.course),
              userIds: trainingDetails.users.map((user) => Number(user))
            }
          ]
        }
      });
    }
  };

  const handleOnChangeInputs = (e) => {
    if (e.target.value.includes('all') && e.target.name === 'users') {
      setTrainingDetails({
        ...trainingDetails,
        [e.target.name]: props.optionsData.users.map((user) => user.id)
      });
    } else if (e.target.value.includes('clear') && e.target.name === 'users') {
      setTrainingDetails({
        ...trainingDetails,
        [e.target.name]: []
      });
    } else {
      setTrainingDetails({
        ...trainingDetails,
        [e.target.name]: e.target.value
      });
    }

    setFormErrors({
      ...formErrors,
      [e.target.name]: false
    });
  };

  const clearErrors = () => {
    setFormErrors({
      name: false,
      details: false,
      course: false,
      users: false
    });
  };

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    const selectedUsers = [];
    rowsSelected.forEach((row) => {
      props.optionsData.users.forEach((user, index) => {
        if (index === row) {
          selectedUsers.push(user.id);
        }
      });
    });

    setTrainingDetails({
      ...trainingDetails,
      users: selectedUsers
    });
  };

  return (
    <FullScreenModal
      icon={props.selectedTraining ? 'edit' : 'add'}
      title={props.selectedTraining ? t('trainingManagement.edit') : t('trainingManagement.create')}
      buttonText={
        props.selectedTraining ? t('trainingManagement.update') : t('trainingManagement.save')
      }
      handleCloseModal={props.handleCloseModal}
      handleDone={props.selectedTraining ? handleUpdateTraining : handleAddTraining}
      saving={updateTrainingLoading || addTrainingLoading}
      response={response}
      width="82rem">
      <Grid container>
        <Grid item xs={12}>
          <FormSubTitle title={t('trainingManagement.form.title1')} />
        </Grid>
        <Grid item xs={6} className={classes.paddingRight}>
          <TextInput
            required={true}
            label={t('sharedLabels.trainingName')}
            value={trainingDetails.name}
            name="name"
            onChange={handleOnChangeInputs}
            underText={formErrors.name}
            error={formErrors.name}
          />
        </Grid>
        <Grid item xs={6} className={classes.paddingRight}>
          <SelectInput
            multiple={false}
            label={t('sharedLabels.course')}
            selected={trainingDetails.course}
            name="course"
            onChange={handleOnChangeInputs}
            menuItems={props.optionsData.courses}
            required={true}
            disabled={false}
            error={formErrors.course}
          />
        </Grid>
        <Grid item xs={12} className={classes.paddingRight}>
          <TextArea
            required={true}
            label={t('sharedLabels.description')}
            value={trainingDetails.details}
            name="details"
            onChange={handleOnChangeInputs}
            underText={formErrors.details}
            error={formErrors.details}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingTop: '23px' }}>
          <FileUpload
            label={t('trainingManagement.form.uploadButton')}
            onChange={uploadFile}
            accept=".xls,.xlsx,.csv"
          />
        </Grid>
        <Grid container item xs={12} style={{ paddingTop: '23px', paddingBottom: '23px' }}>
          <UsersTable
            headerTitle={t('trainingManagement.form.title2')}
            userData={props.optionsData.users}
            usersSelected={trainingDetails.users}
            onRowSelectionChange={onRowSelectionChange}
          />
        </Grid>
      </Grid>
    </FullScreenModal>
  );
};

export default TrainingModal;
