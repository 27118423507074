import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    color: (props) => (props.link ? theme.palette.background.paper : theme.palette.secondary.main),
    backgroundColor: (props) =>
      props.link ? theme.palette.primary.main : theme.palette.background.paper,
    cursor: (props) => (props.link ? 'pointer' : 'default'),
    borderRadius: '5px',
    height: '10rem',
    width: '100%',
    border: '1px solid ' + theme.palette.grey['200'],
    padding: '20px',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontWeight: 500,
    fontSize: '1.2rem',
    color: (props) => (props.link ? theme.palette.background.paper : theme.palette.secondary.main)
  },
  subText: {
    paddingTop: '8px',
    fontSize: '0.9rem',
    fontWeight: 300,
    color: (props) => (props.link ? theme.palette.background.paper : theme.palette.secondary.main)
  },
  icon: {
    display: 'flex',
    flex: '1 1 0%',
    justifyContent: 'flex-end'
  }
}));

const InfoWidget = (props) => {
  const classes = useStyles(props);
  return (
    <Box className={classes.root}>
      <Box>
        <Box className={classes.title}>{props.title}</Box>
        {props.subText && <Box className={classes.subText}>{props.subText}</Box>}
      </Box>
      {props.icon && <Box className={classes.icon}>{props.icon}</Box>}
    </Box>
  );
};

export default InfoWidget;
