import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_TIMEZONE } from 'hooks';
import moment from 'moment-timezone';
import styled from 'styled-components';

const formatDate = (date) => {
  const currentDate = new Date(date);
  return moment.tz(currentDate, DEFAULT_TIMEZONE).format('MMMM Do YYYY, h:mm:ss a');
};

const removeNewLine = (string) => string.replace(/\r?\n|\r/g, '');

const FooterContainer = styled.div`
  padding: 15px 40px;
  background-color: #231f20;
  color: #ffffff;
`;

const Copyright = styled.div`
  padding-bottom: 5px;
  margin-bottom: 5px;
  ${(props) => {
    return props.isLocalhost ? `border-bottom: 1px solid #ffffff` : '';
  }}
`;

const BaseContainer = ({ children }) => {
  const { t } = useTranslation();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <FooterContainer>
      <Copyright>{t('footer.copyright', { currentYear })}</Copyright>
      {children}
    </FooterContainer>
  );
};

const getBranchDetails = async () => {
  const request = await fetch('/footer.json');
  const response = await request.json();
  return response;
};

const Footer = () => {
  const isLocalhost = location.hostname === 'localhost';
  const isProduction = location.hostname === 'analytics.gleechi.io';
  if (isLocalhost || isProduction) {
    return <BaseContainer isLocalhost={isLocalhost} />;
  }

  const [config, setConfig] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      const { branchName, commitDate, commitHash } = await getBranchDetails();
      setConfig({
        branchName: removeNewLine(branchName),
        commitDate: formatDate(removeNewLine(commitDate)),
        commitHash: removeNewLine(commitHash)
      });
    };
    fetchData();
  }, [setConfig]);

  const { branchName, commitDate, commitHash } = config;

  return (
    <BaseContainer>
      <div>{t('footer.deploymentDetails', { commitDate, branchName, commitHash })}</div>
    </BaseContainer>
  );
};

export default Footer;
