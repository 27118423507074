import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, LinearProgress, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';
import XSmallSpinner from 'component/Spinners/XSmallSpinner';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    height: (props) => (props.height ? props.height : '10rem'),
    padding: '20px 20px 20px 20px',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid ' + theme.palette.grey['200'],
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.success.main
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: theme.palette.success.main
    }
  },
  title: {
    fontWeight: 500,
    color: theme.palette.secondary.main,
    fontSize: '0.9rem',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    height: '40px'
  },
  subText: {
    color: theme.palette.secondary.main,
    fontSize: '0.8rem',
    fontWeight: 400
  },
  contentContainer: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  contentText: {
    color: theme.palette.success.main,
    fontWeight: 600,
    fontSize: '2.8rem',
    textAlign: 'right',
    padding: '20px 0'
  },
  contentBar: {
    borderRadius: 5
  },
  center: {
    display: 'flex',
    paddingBottom: '20px',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tooltipBase: {
    position: 'relative'
  },
  tooltip: {
    position: 'absolute',
    top: -10,
    right: -10,
    cursor: 'pointer'
  }
}));

const ProgressWidget = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <div className={classes.tooltipBase}>
        <Tooltip className={classes.tooltip} title={t('tooltips.trainingPassed')}>
          <InfoOutlined />
        </Tooltip>
      </div>
      <Box className={classes.title}>
        {props.title}
        <Box className={classes.subText}>{props.subTitle}</Box>
      </Box>
      <Box className={classes.contentContainer}>
        {props.error ? (
          <Box className={classes.center}>{t('general.errorData')}</Box>
        ) : props.loading ? (
          <Box className={classes.center}>
            <XSmallSpinner />
          </Box>
        ) : (
          <>
            <Box className={classes.contentText}>{props.progress}</Box>
            <LinearProgress
              className={classes.contentBar}
              variant="determinate"
              value={(props.progress / props.total) * 100}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProgressWidget;

ProgressWidget.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  total: PropTypes.number,
  progress: PropTypes.number
};
