import { gql } from '@apollo/client';

export const SET_PASSWORD = gql`
  mutation passwordSet($token: String!, $password: String!, $repeatPassword: String!) {
    passwordSet(token: $token, newPassword1: $password, newPassword2: $repeatPassword) {
      success
      errors
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation passwordReset($token: String!, $password: String!, $repeatPassword: String!) {
    passwordReset(token: $token, newPassword1: $password, newPassword2: $repeatPassword) {
      success
      errors
    }
  }
`;
