import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query getAllUsers($orgId: Int!) {
    allUsers(orgId: $orgId) {
      id
      username
      email
      firstName
      lastName
      isSuperuser
      isActive
      courses {
        id
      }
      trainings {
        id
      }
      organizationRoles {
        permission
        organization {
          id
          name
        }
      }
    }
  }
`;

/* the only place we keep using isStaff, because this mutation is hard to customize in the BE as it is. In here isStaff
takes the function of isAdmin */
export const REGISTER_NEW_USER = gql`
  mutation register(
    $email: String!
    $username: String!
    $firstName: String!
    $lastName: String!
    $isStaff: String!
  ) {
    register(
      email: $email
      username: $username
      firstName: $firstName
      lastName: $lastName
      isStaff: $isStaff
    ) {
      success
      errors
    }
  }
`;

export const REGISTER_USER_ORG_INFO = gql`
  mutation registerUserOrgInfo(
    $courseIds: [Int]
    $trainingIds: [Int]
    $orgId: Int!
    $username: String!
    $role: String!
  ) {
    registerUserOrgInfo(
      courseIds: $courseIds
      trainingIds: $trainingIds
      orgId: $orgId
      username: $username
      role: $role
    ) {
      success
      error
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $userId: Int!
    $orgId: Int!
    $username: String
    $firstName: String
    $lastName: String
    $role: String!
    $courseIds: [Int]
    $trainingIds: [Int]
  ) {
    updateUser(
      userId: $userId
      orgId: $orgId
      username: $username
      firstName: $firstName
      lastName: $lastName
      role: $role
      courseIds: $courseIds
      trainingIds: $trainingIds
    ) {
      success
      error
    }
  }
`;
