import { gql } from '@apollo/client';

const ALL_TRAININGS = gql`
  query allTrainings($orgId: Int) {
    allTrainings(orgId: $orgId) {
      id
      name
      details
      user {
        id
        username
        email
      }
      course {
        id
        name
      }
      translations {
        id
        name
        details
        language {
          code
        }
      }
      labels {
        id
        key
        text
        language {
          id
          code
          name
        }
        training {
          id
          name
        }
      }
    }
  }
`;

export default ALL_TRAININGS;
