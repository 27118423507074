import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';

import './styles.scss';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    position: 'fixed',
    zIndex: 200,
    background: theme.palette.background.paper,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  filterTitleContainer: {
    padding: '70px 8px 15px 20px'
  },
  filterTitle: {
    fontSize: '1.1rem',
    fontWeight: '600',
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center'
  },
  title: {
    flex: '1 1 0%'
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: '24',
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.secondary.main + 50
  },
  filterBlockTitle: {
    padding: '20px',
    color: '1px solid ' + theme.palette.text.hint,
    fontSize: '1.1rem',
    textTransform: 'capitalize'
  },
  filterBlock: {
    padding: '0px 8px 20px 20px',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'flex-start'
  },
  filterButton: {
    padding: '5px 15px',
    border: '1px solid ' + theme.palette.grey['200'],
    fontSize: '0.9rem',
    borderRadius: '5px',
    marginRight: '8px',
    marginBottom: '8px',
    cursor: 'pointer'
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper
  },
  divider: {
    height: '10px',
    width: '100%',
    borderTop: '1px solid ' + theme.palette.grey['100'],
    borderBottom: '1px solid ' + theme.palette.grey['100'],
    backgroundColor: theme.palette.grey['300']
  }
}));

const CollapsedFilter = (props) => {
  const classes = useStyles(props);

  return (
    <>
      {props.showFilter && (
        <Box className={classes.overlay} onClick={() => props.setShowFilter(!props.showFilter)} />
      )}
      <Box
        className={`${classes.filterContainer} ${
          props.showFilter ? 'filter-slideIn' : 'filter-slideOut'
        }`}>
        <Box className={classes.filterTitleContainer}>
          <Box className={classes.filterTitle}>
            <Box className={classes.title}>Filters</Box>
            <Close fontSize="large" onClick={() => props.setShowFilter(false)} />
          </Box>
        </Box>
        <Box className={classes.divider} />
        {Object.keys(props.filterOptions).map((key) => {
          return (
            <>
              <Box className={classes.filterBlockTitle}>
                {props.filterOptions[key].filterTitle ? props.filterOptions[key].filterTitle : key}
              </Box>
              <Box className={classes.filterBlock}>
                {props.filterOptions[key].options.length > 0 ? (
                  props.filterOptions[key].options.map((filter) => {
                    return (
                      <>
                        <Box
                          className={`
													${classes.filterButton} 
													${props.selectedFilters[props.filterOptions[key].name] === filter.id && classes.selected}
												`}
                          onClick={() => props.setFilters(props.filterOptions[key].name, filter)}>
                          {filter.name}
                        </Box>
                      </>
                    );
                  })
                ) : (
                  <Box> No Filters </Box>
                )}
              </Box>
              <Box className={classes.divider} />
            </>
          );
        })}
      </Box>
    </>
  );
};

export default CollapsedFilter;

CollapsedFilter.propTypes = {
  filterOptions: PropTypes.object
  //EXAMPLE
  //let filterOptions = {
  // 		filterOne: {
  // 			options: [
  // 				{ id: '1', name: "one" },
  // 				{ id: '2', name: "two" },
  // 				{ id: '3', name: "three" }
  // 			],
  // 			name: "filterOne"     ---- // this is what the state name should be
  // 		},
  // 	}
};
