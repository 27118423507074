import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmButton from 'component/Buttons/ConfirmButton';
import ErrorButton from 'component/Buttons/ErrorButton';
import ViewContainer, { ButtonGroupSpacer } from 'component/ViewContainer/ViewContainer';
import { colorList } from 'ducks/constant';
import { buildCourseAdminCourseList } from 'helpers/buildCourseUserRoleCourseList';
import { useUser } from 'hooks';
import { useOrganization } from 'hooks';
import { useLocalStorage, useUsers } from 'hooks';

import UserArchiveModal from './UserArchiveModal';
import UserModal from './UserModal';
import UserTable from './UserTable';

const AdminUserManagement = () => {
  const { organizationId } = useLocalStorage();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { organizationData } = useOrganization();
  const { user, isSuperUser, isCourseAdmin } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [showUserAchiveModal, setShowUserAchiveModal] = useState(false);
  const [courseMenuItems, setCourseMenuItems] = useState([]);
  const [userToEdit, setUserToEdit] = useState(null);

  const { t } = useTranslation();
  const {
    getUsersRequest,
    users,
    activeUsers,
    loading: getUsersLoading,
    error: getUsersLoadingError,
    isSuccessfullyArchive,
    resetStatus
  } = useUsers();

  useEffect(() => {
    getUsersRequest({
      variables: {
        isSuperUser,
        orgId: organizationId
      }
    });
  }, []);

  useEffect(() => {
    buildCourseAdminCourseList(organizationData.organization, user, isCourseAdmin).map(
      (course, i) => {
        const courseObj = {
          id: course.id,
          name: course.name,
          color: colorList[i],
          children: course.trainings?.map((training) => {
            return { id: training.id, name: training.name };
          })
        };
        return setCourseMenuItems((courseMenuItems) => [...courseMenuItems, courseObj]);
      }
    );
  }, []);

  useEffect(() => {
    if (isSuccessfullyArchive) {
      setSelectedUsers([]);
      resetStatus();
      setShowUserAchiveModal(false);
      getUsersRequest({
        variables: {
          isSuperUser,
          orgId: organizationId
        }
      });
    }
  }, [isSuccessfullyArchive, resetStatus, setShowUserAchiveModal]);

  const handleOnEditClick = (user) => {
    setUserToEdit(user);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setUserToEdit(null);
    setShowModal(false);
  };

  const handleOnArchieveUser = useCallback(
    ({ rowIndex }) => {
      if (selectedUsers.indexOf(rowIndex) === -1) {
        setSelectedUsers(selectedUsers.concat(rowIndex));
      } else {
        const filteredUserIndexes = selectedUsers.filter((user) => user !== rowIndex);
        setSelectedUsers(filteredUserIndexes);
      }
      setShowUserAchiveModal(true);
    },
    [setShowUserAchiveModal, setSelectedUsers, selectedUsers]
  );

  const onRowSelectionChange = useCallback(
    (rows) => {
      if (rows.length === 1) {
        const { dataIndex } = rows[0];
        if (selectedUsers.indexOf(dataIndex) === -1) {
          setSelectedUsers(selectedUsers.concat(dataIndex));
        } else {
          const filteredUserIndexes = selectedUsers.filter((user) => user !== dataIndex);
          setSelectedUsers(filteredUserIndexes);
        }
      } else {
        const indexes = rows.map((row) => row.dataIndex);
        setSelectedUsers(indexes);
      }
    },
    [selectedUsers, setSelectedUsers, users]
  );

  const onHandleCloseModal = useCallback(() => {
    setShowUserAchiveModal(false);
  }, [setShowUserAchiveModal]);

  const onSuccess = useCallback(() => {
    setShowModal(false);
    getUsersRequest({
      variables: {
        isSuperUser,
        orgId: organizationId
      }
    });
  }, [setShowModal, getUsersRequest]);

  const filteredUsers = useMemo(() => {
    return selectedUsers
      .map((selectedUser) => activeUsers[selectedUser])
      .filter((user) => !user.isSuperuser);
  }, [activeUsers, selectedUsers]);

  return (
    <ViewContainer
      title={t('userManagement.title')}
      loading={getUsersLoading}
      error={getUsersLoadingError}
      flexView={true}
      actionButtons={() => {
        return (
          <ButtonGroupSpacer>
            <ErrorButton
              disabled={filteredUsers.length === 0 || getUsersLoading}
              size="small"
              onClick={() => setShowUserAchiveModal(true)}>
              {t('userManagement.archive')}
            </ErrorButton>
            <ConfirmButton
              disabled={getUsersLoading}
              size="medium"
              onClick={() => setShowModal(!showModal)}>
              {t('userManagement.create')}
            </ConfirmButton>
          </ButtonGroupSpacer>
        );
      }}>
      {showModal ? (
        <UserModal
          userToEdit={userToEdit}
          courseMenuItems={courseMenuItems}
          handleCloseModal={handleCloseModal}
          onSuccess={onSuccess}
        />
      ) : null}
      {showUserAchiveModal ? (
        <UserArchiveModal users={filteredUsers} handleCloseModal={onHandleCloseModal} />
      ) : null}
      <UserTable
        data={activeUsers}
        onEditClick={handleOnEditClick}
        onArchieveUser={handleOnArchieveUser}
        onRowSelectionChange={onRowSelectionChange}
        selectedFilteredUserIds={selectedUsers}
      />
    </ViewContainer>
  );
};

export default AdminUserManagement;
