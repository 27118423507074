import React from 'react';

export const DashboardIconBlue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.181"
      height="18.181"
      viewBox="0 0 13.181 13.181">
      <path
        id="dashboardBlue"
        d="M13.14,10.394V6.549A.532.532,0,0,1,13.689,6h4.943a.532.532,0,0,1,.549.549v3.844a.532.532,0,0,1-.549.549H13.689a.532.532,0,0,1-.549-.549ZM6,12.59V6.549A.532.532,0,0,1,6.549,6h4.943a.532.532,0,0,1,.549.549V12.59a.532.532,0,0,1-.549.549H6.549A.532.532,0,0,1,6,12.59Zm7.14,6.041V12.59a.532.532,0,0,1,.549-.549h4.943a.532.532,0,0,1,.549.549v6.041a.532.532,0,0,1-.549.549H13.689a.532.532,0,0,1-.549-.549Zm-7.14,0V14.787a.532.532,0,0,1,.549-.549h4.943a.532.532,0,0,1,.549.549v3.844a.532.532,0,0,1-.549.549H6.549A.532.532,0,0,1,6,18.632Zm1.1-6.59h3.844V7.1H7.1Zm7.14,6.041h3.844V13.14H14.238Zm0-8.238h3.844V7.1H14.238ZM7.1,18.082h3.844V15.336H7.1ZM10.943,12.041ZM14.238,9.844ZM14.238,13.14ZM10.943,15.336Z"
        transform="translate(-6 -6)"
        fill="#2e3c77"
      />
    </svg>
  );
};

export const DashboardIconBlack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.181"
      height="18.181"
      viewBox="0 0 13.181 13.181">
      <path
        id="dashboardBlack"
        d="M13.14,10.394V6.549A.532.532,0,0,1,13.689,6h4.943a.532.532,0,0,1,.549.549v3.844a.532.532,0,0,1-.549.549H13.689a.532.532,0,0,1-.549-.549ZM6,12.59V6.549A.532.532,0,0,1,6.549,6h4.943a.532.532,0,0,1,.549.549V12.59a.532.532,0,0,1-.549.549H6.549A.532.532,0,0,1,6,12.59Zm7.14,6.041V12.59a.532.532,0,0,1,.549-.549h4.943a.532.532,0,0,1,.549.549v6.041a.532.532,0,0,1-.549.549H13.689a.532.532,0,0,1-.549-.549Zm-7.14,0V14.787a.532.532,0,0,1,.549-.549h4.943a.532.532,0,0,1,.549.549v3.844a.532.532,0,0,1-.549.549H6.549A.532.532,0,0,1,6,18.632Zm1.1-6.59h3.844V7.1H7.1Zm7.14,6.041h3.844V13.14H14.238Zm0-8.238h3.844V7.1H14.238ZM7.1,18.082h3.844V15.336H7.1ZM10.943,12.041ZM14.238,9.844ZM14.238,13.14ZM10.943,15.336Z"
        transform="translate(-6 -6)"
        fill="#231f20"
      />
    </svg>
  );
};
