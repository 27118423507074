import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    padding: '8px 12px',
    color: theme.palette.background.paper,
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.background.paper
    },
    cursor: 'pointer',
    textTransform: 'uppercase'
  },
  input: {
    display: 'none'
  }
}));

const FileUpload = (props) => {
  const classes = useStyles(props);
  return (
    <form>
      <label className={classes.root}>
        <input
          className={classes.input}
          onChange={props.onChange}
          type="file"
          accept={props.accept}
        />
        {props.label}
      </label>
    </form>
  );
};

export default FileUpload;
