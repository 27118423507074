import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ADD_TRAINING_TRANSLATION,
  DELETE_TRAINING_TRANSLATION,
  GET_TRAINING_TRANSLATION_BY_ID,
  UPDATE_TRAINING_TRANSLATION
} from 'graphql/TrainingTranslation';

const TrainingTranslationContext = createContext({});

const SUCCESSFULLY_ADDED = 'SUCCESSFULLY_ADDED';
const SUCCESSFULLY_DELETED = 'SUCCESSFULLY_DELETED';
const SUCCESSFULLY_UPDATED = 'SUCCESSFULLY_UPDATED';

export const TrainingTranslationProvider = ({ children }) => {
  const { t } = useTranslation();
  const [trainingTranslations, setTrainingTranslations] = useState([]);
  const [status, setStatus] = useState(null);

  const resetStatus = useCallback(() => {
    setStatus(null);
  }, [setStatus]);

  const [getTrainingTranslationsRequest, { loading: loadingGetTrainingTranslationsRequest }] =
    useLazyQuery(GET_TRAINING_TRANSLATION_BY_ID, {
      fetchPolicy: 'no-cache',
      onCompleted(response) {
        setTrainingTranslations(response.training.translations);
      }
    });

  const [addTrainingTranslationRequest, { loading: loadingAddTrainingTranslationRequest }] =
    useMutation(ADD_TRAINING_TRANSLATION, {
      onCompleted(response) {
        if (response.addTranslation.success) {
          toast.success(t('trainingManagement.translation.toast.add'));
          setStatus(SUCCESSFULLY_ADDED);
        } else {
          toast.error(response.addTranslation.error);
        }
      }
    });

  const [updateTrainingTranslationRequest, { loading: loadingUpdateTrainingTranslationRequest }] =
    useMutation(UPDATE_TRAINING_TRANSLATION, {
      onCompleted(response) {
        if (response.updateTranslation.success) {
          toast.success(t('trainingManagement.translation.toast.update'));
          setStatus(SUCCESSFULLY_UPDATED);
        } else {
          toast.error(response.updateTranslation.error);
        }
      }
    });

  const [deleteTrainingTranslationRequest, { loading: loadingDeleteTrainingTranslationRequest }] =
    useMutation(DELETE_TRAINING_TRANSLATION, {
      onCompleted(response) {
        if (response.deleteTranslation.success) {
          toast.success(t('trainingManagement.translation.toast.delete'));
          setStatus(SUCCESSFULLY_DELETED);
        } else {
          toast.error(response.deleteTranslation.error);
        }
      }
    });

  const addTrainingTranslation = useCallback(
    ({ name, details, languageCode, trainingId }) => {
      addTrainingTranslationRequest({
        variables: {
          name,
          details,
          languageCode,
          trainingId
        }
      });
    },
    [addTrainingTranslationRequest]
  );

  const updateTrainingTranslation = useCallback(
    ({ id, name, details, languageCode, trainingId }) => {
      updateTrainingTranslationRequest({
        variables: {
          id,
          name,
          details,
          languageCode,
          trainingId
        }
      });
    },
    [updateTrainingTranslationRequest]
  );

  const deleteTrainingTranslation = useCallback(
    ({ ids }) => {
      deleteTrainingTranslationRequest({
        variables: {
          ids: ids
        }
      });
    },
    [deleteTrainingTranslationRequest]
  );

  const getTrainingTranslationsByTrainingId = useCallback(
    ({ trainingId }) => {
      getTrainingTranslationsRequest({
        variables: {
          trainingId
        }
      });
    },
    [getTrainingTranslationsRequest]
  );

  const refetchTrainingTranslationsByTrainingId = useCallback(
    ({ trainingId }) => {
      getTrainingTranslationsRequest({
        variables: {
          trainingId
        }
      });
    },
    [getTrainingTranslationsRequest]
  );

  const getTranslationByKey = ({ training, key }) => {
    const i18nextLng = localStorage.getItem('i18nextLng');
    if (training && training.translations && training.translations.length === 0) {
      return training[key];
    }
    if (training.translations) {
      const filteredTranslations = training.translations.filter((translation) => {
        return translation.language.code === i18nextLng;
      });
      if (filteredTranslations.length === 0) return training[key];
      return filteredTranslations[0][key];
    }
    return null;
  };

  const getTranslatedNameFromTraining = ({ training }) => {
    if (training) {
      return getTranslationByKey({ training, key: 'name' });
    }
    return null;
  };

  const getTranslatedDetailsFromTraining = ({ training }) => {
    if (training) {
      return getTranslationByKey({ training, key: 'details' });
    }
    return null;
  };

  const loading = useMemo(() => {
    return (
      loadingGetTrainingTranslationsRequest ||
      loadingAddTrainingTranslationRequest ||
      loadingUpdateTrainingTranslationRequest ||
      loadingDeleteTrainingTranslationRequest
    );
  }, [
    loadingGetTrainingTranslationsRequest,
    loadingAddTrainingTranslationRequest,
    loadingUpdateTrainingTranslationRequest,
    loadingDeleteTrainingTranslationRequest
  ]);

  const isSuccessfullyAdded = useMemo(() => {
    return status === SUCCESSFULLY_ADDED;
  }, [status]);

  const isSuccessfullyDeleted = useMemo(() => {
    return status === SUCCESSFULLY_DELETED;
  }, [status]);

  const isSuccessfullyUpdated = useMemo(() => {
    return status === SUCCESSFULLY_UPDATED;
  }, [status]);

  const clearTrainingTranslations = useCallback(() => {
    setTrainingTranslations([]);
  }, [setTrainingTranslations]);

  return (
    <TrainingTranslationContext.Provider
      value={{
        resetStatus,
        clearTrainingTranslations,
        loading,
        loadingGetTrainingTranslationsRequest,
        loadingDeleteTrainingTranslationRequest,
        isSuccessfullyAdded,
        isSuccessfullyDeleted,
        isSuccessfullyUpdated,
        trainingTranslations,
        addTrainingTranslation,
        updateTrainingTranslation,
        deleteTrainingTranslation,
        getTrainingTranslationsByTrainingId,
        getTranslatedNameFromTraining,
        getTranslatedDetailsFromTraining,
        refetchTrainingTranslationsByTrainingId
      }}>
      {children}
    </TrainingTranslationContext.Provider>
  );
};

export const useTrainingTranslation = () => useContext(TrainingTranslationContext);
