export const TRANSLATIONS_SV = {
  //GLOBAL TEXTS THAT HAVE NO REAL CATEGORY//
  general: {
    loadingContent: 'Laddar innehåll',
    save: 'Spara',
    delete: 'Radera',
    cancel: 'Avbryt',
    done: 'Klar',
    yes: 'Ja',
    no: 'Nej',
    outOf: 'av {{count}}',
    of: 'av',
    //'To' as in: from 1990 'to' 2010//
    to: 'till',
    daysAgo: '{{count}} dagar sedan',
    dayAgo: '{{count}} dag sedan',
    today: 'Idag',
    errorData: 'Ett fel uppstod när data skulle hämtas',
    total: 'Total',
    other: 'Övrigt'
  },
  table: {
    settings: {
      title: 'Tabellinställningar'
    },
    rowSelect: '{{count}} rad(er) valda',
    //THESE TWO ARE INBUILT TO MUI DATA TABLE - NOT SURE I CAN CHANGE//
    rowsPerPage: 'Rader per sida:',
    noData: 'Ingen matchande data hittad.'
  },
  //INPUT SUB-TEXTS//
  inputs: {
    required: 'Fältet är obligatoriskt.',
    select: {
      noCoursesSelected: 'Inga kurser valda',
      noTrainingsSelected: 'Inga träningar valda',
      selectNone: 'Avmarkera alla' // This needs in-context verification //RE-TRANSLATE ?
    },
    password: {
      passwordSubText1: 'Lösenordet skall innehålla 4 siffror',
      passwordSubText2: 'Fyll i samma lösenord',
      passwordSubText3: 'Lösenorden matchar inte varandra',
      passwordSubText4: 'Ogiltigt lösenord',
      passwordForgot: 'Glömt lösenord?'
    },
    email: {
      invalidFormat: 'Ogiltig epostadress'
    }
  },
  //FILTERS
  filters: {
    groups: 'Filtrera på grupp',
    users: 'Filtrera efter användare',
    searchUser: 'Sök användare',
    allUsers: 'Alla användare'
  },
  //TABLE HEADERS/INPUT LABELS//
  sharedLabels: {
    user: 'Användare',
    totalUsers: 'Antal Användare',
    users: 'Användare',
    username: 'Användarnamn',
    name: 'Namn',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    email: 'Epostadress',
    newPassword: 'Nytt Lösenord',
    oldPassword: 'Gammalt Lösenord',
    password: 'Lösenord',
    password2: 'Skriv lösenord igen',
    language: 'Språk',
    text: 'Text',
    texts: 'Texter',
    key: 'Nyckel',
    course: 'Kurs',
    courses: 'Kurser',
    courseName: 'Kursnamn',
    training: 'Träning',
    allTrainings: 'Alla träningar',
    trainings: 'Träningar',
    trainingName: 'Träningsnamn',
    description: 'Beskrivning',
    details: 'Detaljer',
    quiz: 'Quiz',
    object: 'Objekt',
    sessionType: 'Sessionstyp',
    currentStatus: 'Nuvarande Status',
    lastVrActivity: 'Senaste Aktivitet i VR',
    totalUniqueSessions: 'Antal Unika Sessioner',
    startDate: 'Startdatum',
    startTime: 'Starttid',
    completionDate: 'Avslutningsdatum',
    completionTime: 'Avslutningstid',
    timeSpent: 'Tid',
    totalTimeSpent: 'Total Tid',
    time: 'Tid',
    hours: 'Timmar',
    minutes: 'Minuter',
    timePerformed: 'Genomförd kl',
    performance: 'Performance',
    assessment: 'Examination',
    practice: 'Träning',
    session: 'Session',
    sessions: 'Sessioner',
    passed: 'Godkänd',
    notPassed: 'Underkänd',
    failed: 'Underkänd',
    actionsFailed: 'Underkända Handlingar',
    inProgress: 'Pågåend',
    notStarted: 'Ej Startad',
    completedCorrectly: 'Genomförd korrekt',
    completed: 'Genomförd',
    incomplete: 'Ofullständig',
    approved: 'Godkänd',
    notApproved: 'Underkänd', // This needs verification
    errors: 'Fel',
    errorsMade: 'Antal Fel',
    errorType: 'Feltyp',
    all: 'Alla',
    completedSessions: 'Avslutade sessioner',
    notCompletedSessions: 'Ej kompletta sessioner',
    groups: 'Grupper',
    groupName: 'Gruppnamn',
    clearAll: 'Rensa val',
    role: 'Roll'
  },
  charts: {
    weekDays: {
      mon: 'Mon',
      tue: 'Tis',
      wed: 'Ons',
      thur: 'Tors',
      fri: 'Fre',
      sat: 'Lör',
      sun: 'Sön'
    }
  },
  tooltips: {
    trainingPassed:
      'En utbildning anses vara godkänd om en användare genomför minst en bedömningssession utan att göra några misstag.',
    sessionsInfo:
      'En session är tiden som går från det att en användare påbörjar en träning, tills dess att den avslutas.',
    sessionsPassed: 'Användaren har tagit sig igenom hela träningen med godkänt resultat.',
    sessionsIncomplete:
      'Användaren har avslutat sessionen innan träningen genomförts till slutet. Den vanligaste anledningen till detta är att användaren valt att avsluta applikationen eller att träningen startades om.',
    sessionsFailed:
      'En session blir underkänd om den antingen oväntat avslutas (vanligen på grund av ett tekniskt problem) eller om ingen ny data tas emot från VR träningen efter en viss tid förflutit.'
  },
  modals: {
    warning: 'Varning: Detn här handlingen går inte att ångra.',
    button: {
      textYes: 'Jag är säker'
    }
  },
  menu: {
    userSettings: 'Inställningar',
    fullReport: 'Mina Resultat',
    logout: 'Logga ut',
    title1: 'Gleechi VR Statistik',
    dashboard: 'Översikt',
    users: 'Användare',
    courses: 'Kurser',
    title2: 'Administration',
    noData: {
      courses: 'Inga kurser'
    }
  },

  //VIEWS//
  //DASHBOARD VIEW//
  dashboard: {
    title: 'Översikt',
    usersCard: {
      title: 'Användare'
    },
    fullReport: {
      title: 'Se all min data'
    },
    trainingsCard: {
      title: 'Träningar',
      subText: 'Från totalt {{count}} kurs(er).'
    },
    noTrainingData: 'Du är inte registrerad på någon träning',
    sessionsCard: {
      title: 'Totalt antal avslutade Sessioner per Träning',
      noData: 'Inga deltagare har avslutat en session i någon träning ännu.'
    },
    usersPassedCard: {
      title: 'Totalt antal deltagare med godkänd examinering',
      noData: 'Inga deltagare är godkända ännu.'
    },
    activityCard: {
      title: 'Aktivitet per Dag (senaste 7 dagarna)',
      subText: 'Total tid spenderad på träning'
    },
    timeSpentCard: {
      title: 'Tid spenderad i träningar',
      noData: 'Inga användare har spenderat tid i någon träning än',
      hours: 'timmar',
      hoursShort: 'h'
    },
    table: {
      title: 'Senaste Aktivitet',
      titleAlt: 'Ingen aktivitet att visa än.',
      noData: 'Inga deltagare är godkända ännu.'
    }
  },
  //USERS VIEW//
  users: {
    title: 'Användare',
    table: {
      title: 'Användare'
    }
  },
  //TRAINING DETAILS VIEW//
  trainingDetails: {
    performanceCard: {
      title: 'Examineringsstatus',
      subText:
        'användare ({{percentage}}) av totalt {{totalUsers}} har blivit godkända på en examinering.',
      tooltip: 'Godkända användare',
      noData: 'Inga användare har startat en examination än'
    },
    sessionsCard: {
      title: 'Sessioner per dag'
    },
    totalTimeSpent: {
      title: 'Total tid i VR'
    },
    activityCard: {
      title: 'Aktivetet per dag (senaste 7 dagarna)',
      subText: 'Total tid spenderad'
    },
    topObjects: {
      title: 'Topp 5 objekt som genererar flest misstag',
      noData: 'Användaren gjorde inga misstag.'
    },
    topMistakes: {
      title: 'Topp 5 vanligaste misstagen',
      noData: 'Användaren gjorde inga misstag.'
    },
    table: {
      title: 'Alla Användare:'
    }
  },
  //COURSE VIEW//
  course: {
    trainingOverviews: 'Träningsöversikt',
    CourseOverview: 'Kursöversikt',
    enrolledUsers: 'Registrerade användare',
    noDataEnrolledUsers: 'Inga användare har startat sessioner i den här kursen ännu.',
    noDataTrainingsTitle: 'Kursen har inga träningar',
    noDataTrainingsAdd: 'Lägg till ny träning'
  },
  //USER DETAILS VIEW//
  userDetails: {
    trainingsPassedCard: {
      title: {
        assessment: 'Bedömning godkänd',
        practice: 'Övning godkänd'
      }
    },
    vrActivityCard: {
      title: 'Senaste Aktivitet i VR',
      subText: '{{count}} dag(ar) sen',
      noData: 'Ingen aktivitet'
    },
    sessionsPerformance: {
      passed: 'Godkända Sessioner',
      incomplete: 'Ofullständiga Sessioner',
      failed: 'Underkända Sessioner'
    },
    weeklyActivityCard: {
      title: 'Genomsnittlig aktivitet',
      noData: 'Ingen aktivitet för valda dagar',
      allUsers: 'Genomsnitt, alla användare'
    },
    table: {
      title: 'Översikt av Sessioner'
    }
  },
  //SESSIONS DETAILS VIEW//
  sessionsDetails: {
    //'Back to' as in: Navigate 'back to' user//
    nav: 'Tillbaka till',
    table: {
      title: 'Sessionsdetajer',
      correct: 'Rätt handling',
      soft: 'Fel',
      hard: 'Allvarligt Fel' // TODO: This needs to be revisited
    }
  },

  //ADMIN//
  admin: {
    title: 'Administrationspanel',
    subTitle: '', //NEEDS TRANSLATION
    manageTitle: 'Hantera',
    manageUsers: 'Användare',
    manageCourses: 'Kurser',
    manageTrainings: 'Träningar',
    manageQuizzes: 'Quizzes',
    manageLabels: 'Texts',
    manageGroups: 'Grupper',
    manageOrganization: 'Organisationer',
    manageLanguages: 'Språk'
  },
  //USER MANAGEMENT VIEW//
  userManagement: {
    title: 'Hantera Användare',
    create: 'Skapa Ny Användare',
    edit: 'Redigera Användare',
    delete: 'Ta bort användare',
    update: 'Uppdatera Användare',
    save: 'Spara Användare',
    saveAlt: 'Spara och Skapa en till',
    archive: 'Arkivera Användare',
    archiveQuestion: 'Är du säker på att arkivera följande användare?',
    form: {
      title1: 'Användaruppgifter', // TODO: This needs to be revisited
      title2: 'Sätt Lösenord', // TODO: This needs to be revisited
      title3: 'Registrera kurser', // TODO: This needs to be revisited
      adminCheckboxTitle: 'Gör till Administratör' // TODO: Needs to be revisited
    },
    responses: {
      usernameInUse: 'Användarnamnet finns redan',
      updateSuccess: 'Användare uppdaterad',
      updateFail: 'Ett fel uppstod när användaren skulle uppdateras',
      createSuccess: 'Användare skapad',
      createFail: 'Ett fel uppstod när användaren skulle skapas',
      archiveSuccess: 'Användaren har lyckats uppnå',
      archiveError: 'Fel vid arkivering av användaren'
    }
  },
  //COURSE MANAGEMENT VIEW//
  courseManagement: {
    title: 'Hantera kurser',
    create: 'Lägg till ny kurs',
    edit: 'Redigera kurs',
    delete: 'Radera kurs',
    save: 'Spara kurs',
    update: 'Uppdatera kurs',
    form: {
      title1: 'Kursnamn och -beskrivning'
    },
    table: {
      title: 'Kurslista',
      subTitle: 'Välj upp till 5 alternativ',
      description: 'Kursbeskrivning'
    },
    responses: {
      updateSuccess: 'Kurs uppdaterad',
      updateFail: 'Ett fel uppstod när kursen skulle uppdateras',
      createSuccess: 'Kurs skapad',
      createFail: 'Ett fel uppstod när kursen skulle skapas',
      deleteSuccess: 'Kurs raderad',
      deleteFail: 'Ett fel uppstod när kursen skulle raderas'
    },
    deleteModal: {
      title: 'Radera Kurs',
      message: 'Är du säker på att du vill radera kursen: '
    }
  },
  //TRAINING MANAGEMENT VIEW//
  trainingManagement: {
    title: 'Hantera träningar',
    create: 'Lägg till ny träning',
    edit: 'Redigera träning',
    delete: 'Radera träning',
    save: 'Spara träning',
    update: 'Uppdatera träning',
    form: {
      title1: 'Träningsdetaljer',
      title2: 'Tilldela användare till träning',
      allUsers: 'Alla Användare',
      noDataUsers: 'Inga användare valda',
      uploadButton: 'Tilldela från XLS/CSV'
    },
    table: {
      title: 'Träningar',
      description: 'Träningsbeskrivning'
    },
    responses: {
      updateSuccess: 'Träning uppdaterad',
      updateFail: 'Ett fel uppstod när träningen skulle uppdateras',
      createSuccess: 'Träning skapad',
      createFail: 'Ett fel uppstod när träningen skulle skapas',
      deleteSuccess: 'Träning raderad',
      deleteFail: 'Ett fel uppstod när träningen skulle raderas'
    },
    deleteModal: {
      title: 'Radera Träning',
      message: 'Är du säker på att du vill radera träningen: '
    },
    translation: {
      fetching: 'Hämtar översättningar',
      noTranslation: 'Inga översättningar hittades',
      edit: 'Redigera översättningar',
      add: 'Lägg till översättningar',
      select: 'Välj språk',
      button: {
        delete: 'Radera',
        edit: 'Redigera'
      },
      toast: {
        add: 'Översättningen har lagts till',
        update: 'Översättningen har uppdaterats',
        delete: 'Översättningen har raderats'
      }
    }
  },
  //QUIZ MANAGEMENT VIEW//
  quizManagement: {
    title: 'Hantera quiz',
    create: 'Skapa nytt quiz',
    edit: 'Redigera quiz',
    update: 'Uppdatera quiz',
    save: 'Spara quiz',
    delete: 'Radera quiz',
    form: {
      title1: 'Namn på quiz och träning',
      subText1: 'Du måste fylla i dessa fält',
      title2: 'Frågor',
      subText2: 'Ett quiz måste ha minst en fråga',
      quizName: 'Namn på quiz',
      question: 'Fråga',
      choice: 'Val',
      subText3: 'Markera ett val som korrekt.',
      addChoice: 'Lägg till ett val till',
      addQuestion: 'Lägg till ny fråga',
      deleteQuestion: 'Radera fråga',
      error: 'Fyll i alla fält innan du sparar'
    },
    responses: {
      updateSuccess: 'Quiz uppdaterat',
      updateFail: 'Ett fel uppstod när quizzet skulle uppdateras',
      createSuccess: 'Quiz skapat',
      createFail: 'Ett fel uppstod när quizzet skulle skapas',
      deleteSuccess: 'Quiz raderat',
      deleteFail: 'Ett fel uppstod när quizzet skulle raderas'
    },
    deleteModal: {
      title: 'Radera quiz',
      message: 'Är du säker på att du vill radera quizet: '
    }
  },
  //LABEL MANAGEMENT VIEW//
  textManagement: {
    title: 'Hantera Text',
    create: 'Skapa Ny Text',
    edit: 'Redigera Text',
    update: 'Uppdatera Text',
    save: 'Spara Text',
    delete: 'Delete Text',
    totalLabels: 'Totalt antal etiketter redo för nedladdning',
    form: {
      addTranslation: 'Lägg till Översättning',
      removeTranslation: 'Ta bort Översättning'
    },
    responses: {
      updateSuccess: 'Text Uppdaterad',
      updateFail: 'Ett fel uppstod när texten skulle uppdateras',
      createSuccess: 'Text Skapad',
      createFail: 'Ett fel uppstod när texten skulle skapas',
      deleteSuccess: 'Text raderad',
      deleteFail: 'Ett fel uppstod när texten skulle raderas',
      downloadSuccess: 'CSV har laddats ned'
    },
    deleteModal: {
      title: 'Radera Text',
      message: 'Är du säker på att du vill radera texten: '
    },
    error: {
      training: 'Välj en utbildning',
      key: 'En giltig nyckel krävs',
      selectTrainingGroups: 'Välj träningsgrupper.'
    },
    export: {
      title: 'Ladda Ner CSV'
    },
    import: {
      title: 'Ladda Upp CSV',
      overwrite: 'Skriva över?',
      uploading: 'Laddar upp...',
      pleaseSelectTraining: 'Välj en utbildning',
      pleaseUploadFile: 'Ladda upp en fil',
      selectFile: 'Välj fil',
      file: {
        key: 'Nyckel',
        id: 'Id'
      },
      response: {
        success: 'Har importerats',
        warning: 'Vissa data behöver återimporteras',
        error: 'Något gick fel',
        invalidFile: 'CSV-filer accepteras endast',
        failedKeyId: 'Några av följande nyckel-id kunde inte återspegla ändringarna'
      }
    }
  },

  organizationManagement: {
    title: 'Organisationsledning',
    name: 'Organisations Namn',
    logo: 'Organization Logo',
    update: 'Organisationens logotyp',
    edit: 'Redigera organisation',
    create: 'Skapa organisation',
    save: 'Spara organisation',
    error: {
      name: 'Organisationsnamn krävs.',
      failed: 'Det gick inte att ladda upp.',
      notFound: 'Organisationen hittades inte'
    },
    success: {
      title: 'Uppdatera organisationsinställningarna framgångsrikt',
      created: 'Organisationsinställningar har skapats',
      updated: 'Organisationsinställningarna har uppdaterats'
    },
    modal: {
      add: 'Lägg till organisation'
    },
    token: {
      noTransactions: 'Inga transaktioner',
      loading: 'läser in',
      quantity: 'Kvantitet',
      addToken: 'Lägg till token',
      updateToken: 'Uppdatera token',
      noTokenAdded: 'Ingen token tillagd',
      tokenQuantity: 'Token kvantitet',
      tokenHistory: 'token historia',
      manageToken: 'Hantera token',
      error: {
        quantityIsRequired: 'Kvantitet krävs.',
        quantityShouldBeNumber: 'Kvantitet ska vara en siffra.',
        failedFetchingTransactions: 'Det gick inte att hämta tokentransaktioner.',
        failedFetchingToken: 'Det gick inte att hämta tokendetaljer.',
        failedAddToken: 'Det gick inte att lägga till kvantitetstoken.',
        failedUpdateQuantity: 'Det gick inte att uppdatera kvantitetstoken.'
      },
      success: {
        addToken: 'En kvantitetstoken har lagts till.',
        updateToken: 'En kvantitetstoken har uppdaterats.'
      }
    }
  },

  languagesManagement: {
    title: 'Språkhantering',
    view: 'Lägg till språk',
    button: {
      delete: 'Radera',
      edit: 'Redigera'
    },
    form: {
      label: {
        code: 'Koda',
        name: 'Namn'
      },
      update: {
        title: 'Uppdatera språk',
        button: 'Uppdatering'
      },
      add: {
        title: 'Lägg till språk',
        button: 'Spara'
      }
    },
    success: {
      create: 'Språket har lagts till.',
      update: 'Språket har uppdaterats.',
      delete: 'Språket har raderats.',
      createCodeExist:
        'Koden som lades till har redan deklarerats och har uppdaterats framgångsrikt istället.'
    },
    error: {
      notFound: 'Språk-ID hittades inte.',
      fillAllFields: 'Fyll i alla obligatoriska fält.',
      required: {
        code: 'Kod krävs.',
        name: 'Namn krävs.'
      }
    }
  },

  //USER PROFILE//
  userProfile: {
    enrollment: {
      title: 'Mitt Innehåll',
      subTitle1: 'Organisationer',
      subTitle2: 'Grupper',
      subTitle3: 'Kurser och Träningar',
      noDataGroups: 'Du är inte med i några grupper.',
      noDataTrainings: 'Du är inte tillagd på någon träning i den här kursen.'
    },
    personalInfo: {
      title: 'Personlig Information',
      update: 'Uppdatera Information',
      updateSuccess: 'Din information har uppdaterats',
      updateFail: 'Ett fel uppstod när informationen skulle uppdateras'
    },
    password: {
      title: 'Byt Lösenord',
      subTitle1: 'Nuvarande Lösenord',
      subTitle2: 'Nytt Lösenord',
      update: 'Uppdatera Lösenord',
      updateSuccess: 'Ditt lösenord har uppdaterats',
      updateFail: 'Ändring av lösenord misslyckades',
      forgotModal: {
        title: 'Glömt Lösenord',
        message1:
          'En återstäälningslänk kommer skickas till epostadressen som är registrerad för det här kontot.',
        message2: 'Är du säker på att du vill återställa ditt lösenord?',
        messageSuccess:
          'En återställningslänk har skickats till epostadressen som är registrerad för det här kontot.',
        messageFail: 'Ett fel uppstod när återställningslänken skulle skickas.'
      }
    },
    settings: {
      title: 'Inställningar',
      subTitle1: 'Språk',
      subTitle2: 'Tid och Datum',
      timeZone: 'Tidszon',
      dateFormat: 'Datumformat',
      update: 'Uppdatera inställningar',
      updateSuccess: 'Dina inställningar har uppdaterats',
      updateFail: 'Uppdatering misslyckades, försök igen',
      table: 'Uppdatera tabellinställningar'
    }
  },
  //GROUP MANAGEMENT
  groupManagement: {
    title: 'Gruppadministration',
    create: 'Skapa ny grupp',
    edit: 'Redigera grupp',
    delete: 'Radera grupp',
    save: 'Spara grupp',
    update: 'Uppdatera grupp',
    form: {
      title1: 'Gruppnamn och Träning',
      title2: 'Lägg till användare'
    },
    table: {
      title: 'Alla Grupper',
      subTitle: '',
      description: ''
    },
    responses: {
      updateSuccess: 'Grupp uppdaterad',
      updateFail: 'Ett fel uppstod när gruppen skulle uppdateras',
      createSuccess: 'Grupp skapad',
      createFail: 'Ett fel uppstod när gruppen skulle skapas',
      deleteSuccess: 'Grupp raderad',
      deleteFail: 'Ett fel uppstod när gruppen skulle raderas'
    },
    deleteModal: {
      title: 'Radera grupp', //NEEDS TRANSLATION
      message: 'Är du säker på att du vill radera: ' //NEEDS TRANSLATION
    }
  },

  //AUTH//
  //LOGIN//
  login: {
    title: 'Logga in',
    subText: 'För att följa dina resultat i VR',
    errorText1: 'Tyvärr, för närvarande är systemet bara tillgänglig för administratörer.',
    errorText2: 'Inloggning misslyckades, kontrollera användarnamn och lösenord',
    errorText3: 'Obligatoriska fält saknas.',
    errorTextAlt:
      '<strong>Obs:</strong> ' +
      'Om du använder <i>VirtualGrasp</i> kommer du <strong>inte</strong> kunna logga in online. Använd Unity för att autentisera dig och skapa interaktioner. ' +
      'On du vill se din träningsdata i <i>Gleechi Assessment Center</i> kan du logga in som vanligt.',
    button: 'Logga in',
    footer: 'Glömt Lösenord'
  },
  forgotPassword: {
    title: 'Glömt \n Lösenord',
    subText:
      'Fyll i epostadressen som är registrerad för ditt konto. Vi kommer använda den för att skicka en länk för att återställa ditt lösenord.',
    button: 'Skicka Länk',
    buttonAlt: 'Tillbaka till Inloggning',
    success: 'Tack, vi har skickat en länk till adressen du angivit.'
  },
  resetPassword: {
    title: 'Återställ \n Lösenord',
    titleAlt: 'Återställning \n Lyckades',
    subTextSuccess: 'Ditt lösenord har uppdaterats.',
    subTextSuccessAlt:
      '<strong>Obs:</strong> ' +
      'Om du använder <i>VirtualGrasp</i> kommer du <strong>inte</strong> kunna logga in online. Använd Unity för att autentisera dig och skapa interaktioner. ' +
      'On du vill se din träningsdata i <i>Gleechi Assessment Center</i> kan du logga in som vanligt.',
    button: 'Uppdatera Lösenord',
    buttonAlt: 'Logga in',
    footer: 'Till Inloggning',
    invalidToken:
      'Din länk är inte längre aktiv. För att få en ny länk som du kan använda för att återställa ditt lösenord, fyll i din mailadress nedan.',
    resetButton: 'Återställa',
    error: {
      required: 'Detta fält är obligatoriskt.',
      didntMatch: 'De två lösenordsfälten matchade inte.'
    }
  },
  setPassword: {
    title: 'Välj \n Lösenord',
    titleAlt: 'Välj \n Lösenord',
    subTextSuccess: 'Ditt lösenord har uppdaterats.',
    subTextSuccessAlt:
      '<strong>Obs:</strong> ' +
      'Om du använder <i>VirtualGrasp</i> kommer du <strong>inte</strong> kunna logga in online. Använd Unity för att autentisera dig och skapa interaktioner. ' +
      'On du vill se din träningsdata i <i>Gleechi Assessment Center</i> kan du logga in som vanligt.',
    button: 'Välj lösenord',
    buttonAlt: 'Logga in',
    footer: 'Till Inloggning',
    fourPin: 'Ange en 4-siffrig pinkod.',
    repeatFourPin: 'Repetera kod.'
  },

  //TERMS OF SERVICE
  termsOfService: {
    message:
      'Den här webbsidan behöver Cookies för att fungera och för att ge dig en bra upplevelse.',
    link: 'Läs mer här',
    button: 'Tillåt Cookies'
  },

  footer: {
    copyright: 'Upphovsrätt {{ currentYear }}. Alla rättigheter förbehållna.',
    deploymentDetails:
      'Senast distribuerad: {{ commitDate }} | Filial: {{ branchName }} | Hash: {{ commitHash }}'
  }
};
