import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, FormGroup, Grid, Switch } from '@material-ui/core';
import TextInput from 'component/Inputs/TextInput';
import FullScreenModal from 'component/Modals/FullScreenModal';
import UploadFile from 'component/UploadFile';
import { useOrganization } from 'hooks';
import { isValidImage } from 'util';

import UploadLogoWrapper from './UploadLogoWrapper';

const OrganizationModal = (props) => {
  const { updateOrganization, createOrganization, updatingOrganization } = useOrganization();
  const { t } = useTranslation();
  const [organizationSetting, setOrganizationSetting] = useState({
    id: props.selectedOrganization?.id,
    name: props.selectedOrganization?.name,
    avatarBase64: props.selectedOrganization?.avatar,
    tokenEnabled: props.selectedOrganization?.tokenEnabled
  });

  const handleToggleEnabledChange = (event) => {
    setOrganizationSetting({ ...organizationSetting, tokenEnabled: event.target.checked });
  };

  const [formErrors, setFormErrors] = useState({
    name: false
  });

  const isValid = () => {
    let error = false;
    if (typeof organizationSetting.name === 'undefined' || organizationSetting.name === '') {
      error = true;
      setFormErrors({
        ...formErrors,
        name: t('organizationManagement.error.name')
      });
    }
    return error;
  };

  const updateSelectedOrganization = () => {
    if (!isValid()) {
      const variables = {
        id: organizationSetting.id,
        name: organizationSetting.name,
        tokenEnabled: organizationSetting.tokenEnabled
      };

      if (organizationSetting.avatarBase64 !== '') {
        const file = organizationSetting.avatarBase64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/);
        if (file && isValidImage(file[0])) {
          variables['avatarBase64'] = organizationSetting.avatarBase64;
        }
      }

      updateOrganization(variables);
    }
  };

  const createNewOrganization = () => {
    if (!isValid()) {
      const variables = {
        name: organizationSetting.name,
        tokenEnabled: organizationSetting.tokenEnabled
      };
      if (typeof organizationSetting.avatarBase64 !== 'undefined') {
        const file = organizationSetting.avatarBase64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/);
        if (file && isValidImage(file[0])) {
          variables['avatarBase64'] = organizationSetting.avatarBase64;
        }
      }
      createOrganization(variables);
    }
  };

  const handleOnChange = (e) => {
    setOrganizationSetting({ ...organizationSetting, [e.target.name]: e.target.value });
    setFormErrors({
      ...formErrors,
      [e.target.name]: null
    });
  };

  const onFileUpload = ({ base64 }) => {
    setOrganizationSetting({ ...organizationSetting, avatarBase64: base64 });
  };

  return (
    <FullScreenModal
      icon={props.selectedOrganization ? 'edit' : 'add'}
      title={
        props.selectedOrganization
          ? t('organizationManagement.edit')
          : t('organizationManagement.create')
      }
      buttonText={
        props.selectedOrganization
          ? t('organizationManagement.update')
          : t('organizationManagement.save')
      }
      handleCloseModal={props.handleCloseModal}
      handleDone={() => {
        if (props.selectedOrganization) {
          updateSelectedOrganization();
        } else {
          createNewOrganization();
        }
      }}
      saving={updatingOrganization}
      width="40rem">
      <Grid container item style={{ alignContent: 'flex-start' }}>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              required
              label={t('organizationManagement.name')}
              value={organizationSetting.name}
              name="name"
              onChange={(e) => handleOnChange(e)}
              disabled={updatingOrganization}
              underText={formErrors.name}
              error={formErrors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadLogoWrapper src={organizationSetting.avatarBase64} />
            <UploadFile
              disabled={updatingOrganization}
              label={t('organizationManagement.logo')}
              onFileUpload={onFileUpload}
              buttonTitle="Add Image"
              isValid={(fileType) => isValidImage(fileType)}
              invalidFileTypeErrorMessage="Invalid image type"
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={organizationSetting.tokenEnabled}
                    onChange={handleToggleEnabledChange}
                    name="tokenEnabled"
                  />
                }
                label="Token Enabled"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </FullScreenModal>
  );
};

export default OrganizationModal;
