import React, { useState } from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';

const PasswordInput = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl fullWidth error={props.error} disabled={props.disabled} margin="normal">
      <TextField
        style={{ backgroundColor: props.disabled && '#d3d3d340', opacity: props.disabled && '0.7' }}
        size="small"
        margin="none"
        variant="outlined"
        autoComplete="new-password"
        error={props.error}
        required={props.required}
        type={showPassword ? 'text' : 'password'}
        label={props.label}
        name={props.name}
        placeholder={props.label}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleShowPassword()}
                onMouseDown={(e) => e.preventDefault()}
                edge="end">
                {showPassword.password ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {props.underText || props.error ? (
        <FormHelperText id="password-helper-text">{props.underText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default PasswordInput;

PasswordInput.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  underText: PropTypes.string,
  disabled: PropTypes.bool
};
