import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Grid } from '@material-ui/core';
import FullScreenModal from 'component/Modals/FullScreenModal';
import { USER_INFO } from 'graphql/UserInfo';
import { REGISTER_NEW_USER, REGISTER_USER_ORG_INFO, UPDATE_USER } from 'graphql/UserManagement';
import { useUser } from 'hooks';
import { useOrganization } from 'hooks';
import { useUsers } from 'hooks';

import CoursesAndTrainings from './ModalSegments/CoursesAndTrainings';
import CustomActionButtons from './ModalSegments/CustomActionButtons';
import UserDetails from './ModalSegments/UserDetails';

const UserModal = (props) => {
  const { t } = useTranslation();
  const { loading: getUsersLoading } = useUsers();

  const { organizationData } = useOrganization();
  const { user, setUser } = useUser();
  const [userDetails, setUserDetails] = useState({
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    courses: [],
    trainings: [],
    role: ''
  });
  const [formErrors, setFormErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    username: null
  });
  const [response, setResponse] = useState(null);
  const [createAnotherUser, setCreateAnotherUser] = useState(false);

  const filteredRole = props.userToEdit
    ? props.userToEdit.organizationRoles.filter((organizationRole) => {
        return organizationRole.organization.id === organizationData.organization.id;
      })
    : [];

  useEffect(() => {
    if (props.userToEdit) {
      const { courses, trainings, id, ...rest } = props.userToEdit;

      setUserDetails({
        ...rest,
        userId: id,
        role: filteredRole.length !== 0 ? filteredRole[0].permission : '',
        courses: courses.map((course) => course.id),
        trainings: trainings.map((training) => training.id)
      });
    }
  }, []);

  const [getUser, { loading: getUserLoading }] = useLazyQuery(USER_INFO, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: Number(props.userToEdit?.id)
    }
  });

  const [register, { loading: registerUserLoading }] = useMutation(REGISTER_NEW_USER, {
    onCompleted(newUserData) {
      if (newUserData.register.success) {
        registerUserOrgInfo();
      } else {
        Object.keys(newUserData.register.errors).forEach((key) => {
          setFormErrors((currentErrors) => ({
            ...currentErrors,
            [key]: newUserData.register.errors[key][0].message
          }));
        });
      }
    }
  });

  const [registerUserOrgInfo, { loading: registerUserOrgLoading }] = useMutation(
    REGISTER_USER_ORG_INFO,
    {
      variables: {
        courseIds: userDetails.courses.map((course) => Number(course)),
        trainingIds: userDetails.trainings.map((training) => Number(training)),
        orgId: organizationData.organization.id,
        username: userDetails.username,
        role: userDetails.role
      },
      async onCompleted(courseTrainingData) {
        if (courseTrainingData.registerUserOrgInfo.success) {
          if (props.userToEdit?.id === user.id) {
            const request = await getUser(Number(props.userToEdit.id));
            setUser(request.data.getUser);
          }
          await setResponse({
            message: courseTrainingData.registerUserOrgInfo.success
              ? t('userManagement.responses.createSuccess')
              : t('userManagement.responses.createFail'),
            responseType: courseTrainingData.registerUserOrgInfo.success ? 'success' : 'error'
          });
          setTimeout(() => {
            props.onSuccess();
          }, 1000);
        }

        if (courseTrainingData.registerUserOrgInfo.success && createAnotherUser) {
          clearData();
          setTimeout(() => {
            props.onSuccess();
          }, 1000);
        }
      }
    }
  );

  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER, {
    async onCompleted(updateUserData) {
      if (updateUserData.updateUser.success) {
        if (props.userToEdit.id === user.id) {
          const request = await getUser(Number(props.userToEdit.id));
          setUser(request.data.getUser);
        }

        await setResponse({
          message: updateUserData.updateUser.success
            ? t('userManagement.responses.updateSuccess')
            : t('userManagement.responses.updateSuccess'),
          responseType: updateUserData.updateUser.success ? 'success' : 'error'
        });

        setTimeout(() => {
          props.onSuccess();
        }, 1000);
      }

      if (updateUserData.updateUser.error) {
        setFormErrors((currentErrors) => ({
          ...currentErrors,
          username: t('userManagement.responses.usernameInUse')
        }));
      }
    }
  });

  const handleOnChangeInputs = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value
    });
    setFormErrors({
      ...formErrors,
      [e.target.name]: null
    });
  };

  const handleOnChangeCoursesTrainings = (courses, trainings) => {
    setUserDetails({
      ...userDetails,
      courses,
      trainings
    });
  };

  const handleOnSubmit = (value) => {
    setCreateAnotherUser(value.createAnotherUser);
    const { courses, trainings, userId, role, ...rest } = userDetails;
    if (
      userDetails.firstName.length < 1 ||
      userDetails.lastName.length < 1 ||
      userDetails.email.length < 1 ||
      userDetails.username.length < 1
    ) {
      setFormErrors({
        firstName: userDetails.firstName.length < 1 ? t('inputs.required') : null,
        lastName: userDetails.lastName.length < 1 ? t('inputs.required') : null,
        email: userDetails.email.length < 1 ? t('inputs.required') : null,
        username: userDetails.username.length < 1 ? t('inputs.required') : null
      });
    } else {
      if (props.userToEdit) {
        updateUser({
          variables: {
            ...rest,
            role: role,
            userId: userId,
            orgId: filteredRole.length !== 0 ? filteredRole[0].organization.id : null,
            courseIds: courses.map((course) => Number(course)),
            trainingIds: trainings.map((training) => Number(training))
          }
        });
      } else {
        register({
          variables: {
            isStaff: false,
            ...rest
          }
        });
      }
    }
  };

  const clearData = () => {
    setUserDetails({
      userId: '',
      firstName: '',
      lastName: '',
      email: '',
      username: '',
      courses: [],
      trainings: [],
      role: ''
    });
    clearErrors();
  };

  const clearErrors = () => {
    setFormErrors({
      firstName: null,
      lastName: null,
      email: null,
      username: null
    });
  };

  const customActionButtons = (
    <CustomActionButtons
      response={response}
      setResponse={setResponse}
      onSubmit={handleOnSubmit}
      editMode={props.userToEdit}
    />
  );

  const shouldSendResponse = createAnotherUser ? null : response;

  return (
    <FullScreenModal
      icon={props.userToEdit ? 'edit' : 'add'}
      title={props.userToEdit ? t('userManagement.edit') : t('userManagement.create')}
      customActionButtons={customActionButtons}
      type={props.userToEdit ? 'edit' : 'save'}
      handleCloseModal={props.handleCloseModal}
      saving={
        updateUserLoading ||
        registerUserLoading ||
        registerUserOrgLoading ||
        getUsersLoading ||
        getUserLoading
      }
      response={shouldSendResponse}
      width="42rem">
      <Grid container>
        <UserDetails
          userDetails={userDetails}
          handleOnChange={handleOnChangeInputs}
          errors={formErrors}
          editMode={props.userToEdit}
        />

        <CoursesAndTrainings
          userDetails={userDetails}
          handleOnChange={handleOnChangeCoursesTrainings}
          courseMenuItems={props.courseMenuItems}
        />
      </Grid>
    </FullScreenModal>
  );
};

export default UserModal;
