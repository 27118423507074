import React from 'react';
const LogoBlack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="118.985"
      height="14.632"
      viewBox="0 0 118.985 14.632">
      <g id="Gleechi_logo_white_cut" transform="translate(-91.258 -45.732)">
        <path
          id="Path_5"
          data-name="Path 5"
          d="M225.97,173.182h-6.56a.717.717,0,1,0,0,1.433h6.56a.717.717,0,0,0,0-1.433Z"
          transform="translate(-120.836 -120.85)"
          fill="#231F20"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M507.231,59.581a.717.717,0,0,0-.717.717h0V72.062h0a.717.717,0,0,0,1.433,0V60.3A.717.717,0,0,0,507.231,59.581Z"
          transform="translate(-393.753 -13.132)"
          fill="#231F20"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M570.319,286.76h-6.694a.717.717,0,0,0,0,1.433h6.694a.717.717,0,1,0,0-1.433Z"
          transform="translate(-447.227 -228.547)"
          fill="#231F20"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M867.134,59.581a.717.717,0,0,0-.717.717h0V72.062h0a.717.717,0,0,0,1.433,0V60.3A.717.717,0,0,0,867.134,59.581Z"
          transform="translate(-735.019 -13.132)"
          fill="#231F20"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M930.212,59.581h-6.694a.717.717,0,1,0,0,1.433h6.694a.717.717,0,0,0,0-1.433Z"
          transform="translate(-788.484 -13.132)"
          fill="#231F20"
        />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M930.212,173.172h-6.694a.717.717,0,1,0,0,1.433h6.694a.717.717,0,0,0,0-1.433Z"
          transform="translate(-788.484 -120.841)"
          fill="#231F20"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M930.212,286.76h-6.694a.717.717,0,1,0,0,1.433h6.694a.717.717,0,0,0,0-1.433Z"
          transform="translate(-788.484 -228.547)"
          fill="#231F20"
        />
        <path
          id="Path_12"
          data-name="Path 12"
          d="M1227.009,59.581a.717.717,0,0,0-.717.717V72.062a.717.717,0,0,0,1.434,0V60.3A.717.717,0,0,0,1227.009,59.581Z"
          transform="translate(-1076.259 -13.132)"
          fill="#231F20"
        />
        <path
          id="Path_13"
          data-name="Path 13"
          d="M1290.1,59.581H1283.4a.717.717,0,0,0,0,1.433h6.694a.717.717,0,0,0,0-1.433Z"
          transform="translate(-1129.735 -13.132)"
          fill="#231F20"
        />
        <path
          id="Path_14"
          data-name="Path 14"
          d="M1290.1,173.172H1283.4a.717.717,0,0,0,0,1.433h6.694a.717.717,0,0,0,0-1.433Z"
          transform="translate(-1129.735 -120.841)"
          fill="#231F20"
        />
        <path
          id="Path_15"
          data-name="Path 15"
          d="M1290.1,286.76H1283.4a.717.717,0,0,0,0,1.433h6.694a.717.717,0,0,0,0-1.433Z"
          transform="translate(-1129.735 -228.547)"
          fill="#231F20"
        />
        <path
          id="Path_16"
          data-name="Path 16"
          d="M1974.5,59.581a.717.717,0,0,0-.717.717V72.062a.717.717,0,1,0,1.433,0V60.3A.716.716,0,0,0,1974.5,59.581Z"
          transform="translate(-1785.042 -13.132)"
          fill="#231F20"
        />
        <path
          id="Path_17"
          data-name="Path 17"
          d="M2037.574,59.581a.717.717,0,0,0-.717.717h0v5.165h-5.977a.717.717,0,1,0,0,1.433h5.977v5.165a.717.717,0,1,0,1.433,0h0V60.3A.717.717,0,0,0,2037.574,59.581Z"
          transform="translate(-1838.505 -13.132)"
          fill="#231F20"
        />
        <path
          id="Path_18"
          data-name="Path 18"
          d="M2362.089,59.581a.717.717,0,0,0-.717.717V72.062a.717.717,0,0,0,1.434,0V60.3A.717.717,0,0,0,2362.089,59.581Z"
          transform="translate(-2152.563 -13.132)"
          fill="#231F20"
        />
        <path
          id="Path_19"
          data-name="Path 19"
          d="M104.247,56.759a.716.716,0,0,0-1.01.085,5.883,5.883,0,1,1,0-7.593.717.717,0,1,0,1.094-.926l0,0a7.316,7.316,0,1,0,0,9.449l0,0a.718.718,0,0,0-.084-1.011Z"
          transform="translate(0 0)"
          fill="#231F20"
        />
        <path
          id="Path_20"
          data-name="Path 20"
          d="M1599.18,56.77a.717.717,0,0,0-1.01.085,5.883,5.883,0,1,1,0-7.593.717.717,0,1,0,1.094-.926l0,0a7.316,7.316,0,1,0,0,9.449l0,0A.719.719,0,0,0,1599.18,56.77Z"
          transform="translate(-1417.522 -0.01)"
          fill="#231F20"
        />
      </g>
    </svg>
  );
};

export default LogoBlack;
