import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import LightTooltip from 'component/LightTooltip';
import Table from 'component/Table/Table';
import ViewContainer from 'component/ViewContainer/ViewContainer';
import { USER_GROUPS } from 'constants/filters';
import { USERS_NAV_ID } from 'constants/paths';
import { setSelectedNavItem } from 'ducks/nav';
import ALL_USERS from 'graphql/AllUsers';
import { buildBreadcrumbs } from 'helpers/buildBreadcrumbs';
import { useUser } from 'hooks';
import { useOrganization } from 'hooks';
import { useDateFormat } from 'hooks/useDateFormat';
import moment from 'moment-timezone';
import { saveLastRowIndex, scrollToRowIndex } from 'util';

const UserDashboard = () => {
  const dispatch = useDispatch();
  const { isSuperUser, getUserTimezone } = useUser();
  const { t } = useTranslation();
  let history = useHistory();
  const { handleDateFormat } = useDateFormat();
  const { organizationData } = useOrganization();
  const [filteredBy, setFilteredBy] = useState(null);
  const orgId = organizationData.organization.id;
  const { data, error, loading } = useQuery(ALL_USERS, {
    variables: {
      orgId: Number(orgId)
    }
  });

  useEffect(() => {
    dispatch(setSelectedNavItem(USERS_NAV_ID));
  }, []);

  useEffect(() => {
    if (!loading) {
      scrollToRowIndex();
    }
  }, [loading]);

  const columns = [
    {
      name: 'id',
      options: {
        filter: false,
        display: false,
        sort: false
      }
    },
    {
      name: 'Sessions',
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: t('sharedLabels.username'),
      options: {
        filter: false
      }
    },
    {
      name: t('sharedLabels.firstName'),
      options: {
        filter: false
      }
    },
    {
      name: t('sharedLabels.lastName'),
      options: {
        filter: false
      }
    },
    {
      name: t('sharedLabels.lastVrActivity'),
      options: {
        filter: false
      }
    },
    {
      name: t('sharedLabels.email'),
      options: {
        filter: false
      }
    },
    {
      name: t('sharedLabels.groups'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          {
            if (value.length > 0) {
              return (
                <LightTooltip title={t('sharedLabels.groups')} label={value.length} list={value} />
              );
            } else {
              return value.length;
            }
          }
        }
      }
    }
  ];

  let userList = [];
  let rows = [];

  if (data) {
    userList = data.allUsers;
    if (userList) {
      userList
        .filter((user) => (isSuperUser ? user : !user.isSuperuser))
        .map((user) => {
          let lastVRActivity = user.trainee?.lastCreatedSession
            ? moment
                .tz(user.trainee.lastCreatedSession, getUserTimezone())
                .format(handleDateFormat(false))
            : '';
          //Session string looks like this for the table filter - if we wish to enable filter by sessions.
          let hasSession = user.trainee?.hasSession
            ? t('sharedLabels.completedSessions')
            : t('sharedLabels.notCompletedSessions');
          let userObj = null;
          if (filteredBy) {
            user.cohorts.forEach((cohort) => {
              if (cohort.id === filteredBy.id) {
                userObj = user;
              }
            });
          } else {
            userObj = user;
          }
          if (userObj) {
            rows.push([
              user.id,
              hasSession,
              user.username,
              user.firstName,
              user.lastName,
              lastVRActivity,
              user.email,
              user.cohorts
            ]);
          }
        });
    }
  }

  let filterOptions = [{ id: 'all', name: t('filters.allUsers') }];

  if (organizationData) {
    organizationData.organization.courses.forEach((course) => {
      if (course.trainings) {
        course.trainings.forEach((training) => {
          if (training.cohorts) {
            training.cohorts.forEach((cohort) => {
              if (!filterOptions.find((c) => c.id === cohort.id)) {
                filterOptions.push({
                  id: cohort.id,
                  name: cohort.name
                });
              }
            });
          }
        });
      }
    });
  }

  const setFilteredData = (filteredBy) => {
    setFilteredBy(filteredBy);
  };

  const handleUserLink = (data, row) => {
    saveLastRowIndex(row.rowIndex);
    history.push('/user/' + data[0]);
    buildBreadcrumbs({ key: 'users', name: t('users.title'), viewTitle: data[2] });
  };

  const tableConfig = {
    title: data?.allUsers.length + ' ' + t('users.table.title'),
    print: false,
    viewColumns: false,
    search: true,
    download: false,
    filter: false,
    //Table data - rowData MUST have an id present
    originalData: userList,
    rowData: rows,
    columns: columns,
    onRowClick: handleUserLink,
    flexView: true
  };

  return (
    <ViewContainer
      title={t('users.title')}
      loading={loading}
      flexView={true}
      error={error}
      filter={{
        filterType: USER_GROUPS,
        filterLocation: 'USERS',
        filterOptions,
        data,
        setFilteredData: setFilteredData
      }}>
      <Table tableConfig={tableConfig} />
    </ViewContainer>
  );
};

export default UserDashboard;
