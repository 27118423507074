import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ADD_LANGUAGE,
  AVAILABLE_LANGUAGES,
  DELETE_LANGUAGES,
  UPDATE_LANGUAGE
} from 'graphql/Languages';

const LanguagesContext = createContext({});

export const DEFAULT_LANGUAGE_CODE = 'en';

export const LanguagesProvider = ({ children }) => {
  const { t } = useTranslation();
  const [languages, setLanguages] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    localStorage.setItem('i18nextLng', DEFAULT_LANGUAGE_CODE);
    localStorage.setItem('lng', DEFAULT_LANGUAGE_CODE);
  }, []);

  const [getAvailableLanguages] = useLazyQuery(AVAILABLE_LANGUAGES, {
    fetchPolicy: 'no-cache',
    onCompleted(languages) {
      const formattedLanguages = languages.availableLanguages.map(({ id, code, name }) => ({
        id: id,
        code: code,
        name: name[0].toUpperCase() + name.substring(1)
      }));

      if (languages.length !== 0) {
        setLanguages([]);
      }

      setLanguages(formattedLanguages);
    }
  });

  const [addLanguageRequest, { loading: loadingAddinglanguages }] = useMutation(ADD_LANGUAGE, {
    onCompleted(data, { variables: { code } }) {
      if (data.addLanguage.success) {
        getAvailableLanguages();
        setIsSuccess(true);
        const doesTheCodeExists = languages.filter((language) => language.code === code);
        if (doesTheCodeExists.length === 1) {
          toast.success(t('languagesManagement.success.createCodeExist'));
        } else {
          toast.success(t('languagesManagement.success.create'));
        }
      } else {
        toast.error(data.addLanguage.error);
      }
    }
  });

  const [deleteLanguageRequest, { loading: loadingDeletingLanguages }] = useMutation(
    DELETE_LANGUAGES,
    {
      onCompleted(data) {
        if (data.deleteLanguages.success) {
          getAvailableLanguages();
          setIsSuccess(true);
          toast.success(t('languagesManagement.success.delete'));
        } else {
          toast.error(data.deleteLanguages.error);
        }
      }
    }
  );

  const [updateLanguageRequest, { loading: loadingUpdatingLanguages }] = useMutation(
    UPDATE_LANGUAGE,
    {
      onCompleted(data) {
        if (data.updateLanguages.success) {
          getAvailableLanguages();
          setIsSuccess(true);
          toast.success(t('languagesManagement.success.update'));
        } else {
          toast.error(data.updateLanguages.error);
        }
      }
    }
  );

  const languageCodeLists = useMemo(() => {
    if (languages.length !== 0) {
      const codes = [];
      languages.forEach(({ code }) => {
        codes.push(code);
      });
      return codes;
    }
    return [];
  }, [languages]);

  const addLanguage = useCallback(
    ({ code, name }) => {
      addLanguageRequest({
        variables: {
          code,
          name
        }
      });
    },
    [addLanguageRequest]
  );

  const deleteLanguage = useCallback(
    (id) => {
      deleteLanguageRequest({
        variables: {
          langID: [Number(id)]
        }
      });
    },
    [deleteLanguageRequest]
  );

  const updateLanguage = useCallback(
    ({ id, code, name }) => {
      updateLanguageRequest({
        variables: {
          langData: [
            {
              id,
              code,
              name
            }
          ]
        }
      });
    },
    [updateLanguageRequest]
  );

  const loading = useMemo(() => {
    return loadingAddinglanguages || loadingDeletingLanguages || loadingUpdatingLanguages;
  }, [loadingAddinglanguages, loadingDeletingLanguages, loadingUpdatingLanguages]);

  const getLangugeDetailsById = useCallback(
    ({ id }) => {
      const foundLanguage = languages.filter((language) => language.id === id);
      if (foundLanguage.length === 0) {
        toast.error(t('languagesManagement.error.notFound'));
        return;
      }
      return foundLanguage[0];
    },
    [languages]
  );

  const appLanguages = useMemo(() => {
    const expectedCodes = ['en', 'sv'];
    return languages.filter((language) => expectedCodes.indexOf(language.code) !== -1);
  }, [languages]);

  return (
    <LanguagesContext.Provider
      value={{
        loading,
        isSuccess,
        languages,
        appLanguages,
        languageCodeLists,
        setIsSuccess,
        getAvailableLanguages,
        addLanguage,
        deleteLanguage,
        updateLanguage,
        getLangugeDetailsById
      }}>
      {children}
    </LanguagesContext.Provider>
  );
};

export const useLanguages = () => useContext(LanguagesContext);
