export const USER_PATH = '/user/';
export const SESSION_PATH = '/session/';
export const DASHBOARD_PATH = '/dashboard/default/';

//NAV IDs
export const DASH_NAV_ID = 'dashboard';
export const USER_NAV_ID = 'user';
export const USERS_NAV_ID = 'users';
export const COURSE_NAV_ID = 'courses';
export const ADMIN_NAV_ID = 'admin';
export const SETTINGS_NAV_ID = 'settings';
export const LOGOUT_NAV_ID = 'logout';
