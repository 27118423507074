import { DATE_FORMATS } from '../constants/dateFormats';
import { useUser } from '../hooks';

export const useDateFormat = () => {
  const { user } = useUser();
  const handleDateFormat = (withTime) => {
    let parsedDate = user.profile?.dateFormat
      ? DATE_FORMATS.find((date) => date.id === user.profile.dateFormat).name
      : 'YYYY/MM/DD';
    if (withTime) parsedDate += ' - HH:mm';
    return parsedDate;
  };
  return { handleDateFormat };
};
