import { useTranslation } from 'react-i18next';
import { Box, Checkbox } from '@material-ui/core';
import RowButton from 'component/Table/RowButton';
import Table from 'component/Table/Table';

const UserTable = (props) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: 'id',
      options: {
        filter: false,
        display: false,
        sort: false
      }
    },
    {
      name: t('sharedLabels.username'),
      options: {
        filter: false
      }
    },
    {
      name: t('sharedLabels.firstName'),
      options: {
        filter: false
      }
    },
    {
      name: t('sharedLabels.lastName'),
      options: {
        filter: false
      }
    },
    {
      name: t('sharedLabels.email'),
      options: {
        filter: false
      }
    },
    {
      name: '',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => (
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <RowButton
              onClick={() => props.onEditClick(tableMeta.rowData[6])}
              label={t('userManagement.edit')}
            />
          </Box>
        )
      }
    },
    {
      name: 'rowData',
      options: {
        filter: false,
        display: false,
        sort: false
      }
    }
  ];

  const userList = props.data;
  let rows = [];
  const superUserLists = {};
  userList.forEach((user, index) => {
    if (user.isSuperuser) {
      superUserLists[`user-id-${index}`] = user;
    }

    rows.push([user.id, user.username, user.firstName, user.lastName, user.email, '', user]);
  });

  const tableConfig = {
    title: '',
    print: false,
    viewColumns: false,
    search: true,
    download: false,
    filter: false,
    expandableRows: false,
    originalData: userList,
    rowData: rows,
    columns: columns,
    flexView: true,
    selectableRows: 'multiple',
    selectableRowsOnClick: true,
    selectableRowsHideCheckboxes: true,
    selectableRowsHeader: false,
    rowsSelected: props.selectedFilteredUserIds,
    onRowSelectionChange: props.onRowSelectionChange,
    components: {
      TableToolbarSelect: () => null,
      Checkbox: (props) => {
        if (props['data-description'] === 'row-select-header') return <Checkbox {...props} />;
        const index = props['data-index'] === null ? 0 : props['data-index'];
        const user = superUserLists[`user-id-${index}`];
        return typeof user === 'undefined' ? <Checkbox {...props} /> : null;
      }
    }
  };

  return <Table tableConfig={tableConfig} />;
};

export default UserTable;
