import React from 'react';
import { FormControl, FormHelperText, makeStyles, TextField } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props) =>
      props.disabled ? theme.palette.grey['200'] + 50 : theme.palette.background.paper,
    opacity: (props) => props.disabled && '0.7'
  }
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid ' + theme.palette.grey['100'] + '!important'
    },
    '&:hover fieldset': {
      border: (props) => !props.disabled && '2px solid ' + theme.palette.primary.main
    }
  }
}));

const TextInput = (props) => {
  const classes = useStyles(props);
  return (
    <FormControl fullWidth error={props.error} margin="normal" disabled={props.disabled}>
      <CustomTextField
        className={classes.root}
        fullWidth
        size="small"
        margin="none"
        variant={'outlined'}
        error={props.error}
        required={props.required}
        label={props.label}
        name={props.name}
        placeholder={props.label}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        type={props.type ? props.type : 'text'}
      />
      {props.underText || props.error ? (
        <FormHelperText id="input-helper-text">{props.underText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default TextInput;

TextInput.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  underText: PropTypes.string
};
