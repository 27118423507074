import { gql } from '@apollo/client';

const GET_TRAINING_TRANSLATION_BY_ID = gql`
  query training($trainingId: Int!) {
    training(id: $trainingId) {
      id
      name
      details
      translations {
        id
        name
        details
        language {
          code
        }
      }
    }
  }
`;

const ADD_TRAINING_TRANSLATION = gql`
  mutation addTranslation(
    $name: String!
    $details: String
    $languageCode: String!
    $trainingId: Int!
  ) {
    addTranslation(
      name: $name
      details: $details
      languageCode: $languageCode
      trainingId: $trainingId
    ) {
      success
      error
      translation {
        id
        name
        details
      }
    }
  }
`;

const UPDATE_TRAINING_TRANSLATION = gql`
  mutation updateTranslation(
    $id: Int!
    $name: String
    $details: String
    $languageCode: String!
    $trainingId: Int!
  ) {
    updateTranslation(
      translationId: $id
      name: $name
      details: $details
      languageCode: $languageCode
      trainingId: $trainingId
    ) {
      success
      error
      translation {
        id
        name
        details
      }
    }
  }
`;

const DELETE_TRAINING_TRANSLATION = gql`
  mutation deleteTranslation($ids: [Int]!) {
    deleteTranslation(ids: $ids) {
      success
      error
    }
  }
`;

export {
  GET_TRAINING_TRANSLATION_BY_ID,
  ADD_TRAINING_TRANSLATION,
  UPDATE_TRAINING_TRANSLATION,
  DELETE_TRAINING_TRANSLATION
};
