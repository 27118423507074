import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { HelpRounded } from '@material-ui/icons';
import { setSelectedNavItem } from 'ducks/nav';
import { useUser } from 'hooks';
import { useOrganization } from 'hooks';

import NavSlideOut from './NavComponents/NavSlideOut';
import Language from './Language';
import Menu from './Menu';
import OrgSelect from './OrgSelect';
import UserActions from './UserActions';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1201,
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 3px 20px',
    height: '50px',
    width: '100vw',
    position: 'fixed'
  },
  appBar: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: '0 10px 0 0',
    [theme.breakpoints.up('lg')]: {
      padding: '0 40px'
    }
  },
  right: {
    display: 'flex',
    flex: '1 1 0%',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  help: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const NavBar = () => {
  const classes = useStyles();
  const { organizationData } = useOrganization();
  const { isSuperUser, isAdmin } = useUser();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { selected } = useSelector((state) => state.nav);
  const collapse = !useMediaQuery(theme.breakpoints.up('lg'));
  const [showNavSlideOut, setShowNavSlideOut] = useState({
    show: false,
    side: '',
    items: []
  });

  useEffect(() => {
    if (!collapse) handleCloseNavSlideOut();
  }, [collapse]);

  const handleShowNavSlideOut = (items, side) => {
    if (showNavSlideOut.show && !items) {
      handleCloseNavSlideOut();
    } else {
      setShowNavSlideOut({
        show: true,
        side,
        items
      });
    }
  };

  const handleCloseNavSlideOut = () => {
    setShowNavSlideOut({
      show: false,
      side: '',
      items: []
    });
  };

  const handleSetSelected = (id) => {
    dispatch(setSelectedNavItem(id));
  };

  const canSeeMenu = isSuperUser || isAdmin;

  return (
    <Box className={classes.root}>
      <nav id="nav" className={classes.appBar}>
        {collapse && (
          <NavSlideOut
            canSeeMenu={canSeeMenu}
            handleClose={handleCloseNavSlideOut}
            show={showNavSlideOut.show}
            side={showNavSlideOut.side}
            nav={showNavSlideOut.items}
          />
        )}
        <Menu
          canSeeMenu={canSeeMenu}
          orgData={organizationData}
          setSelected={handleSetSelected}
          selected={selected}
          collapse={collapse}
          handleShowNavSlideOut={handleShowNavSlideOut}
        />
        <ul id="nav_list__right" className={classes.right}>
          {canSeeMenu && (
            <OrgSelect collapse={collapse} handleShowNavSlideOut={handleShowNavSlideOut} />
          )}
          {!collapse && (
            <UserActions
              setSelected={handleSetSelected}
              selected={selected}
              collapse={collapse}
              handleShowNavSlideOut={handleShowNavSlideOut}
            />
          )}
          <Language collapse={collapse} handleShowNavSlideOut={handleShowNavSlideOut} />
          <a
            className={classes.help}
            href="https://gleechi.atlassian.net/servicedesk/customer/portal/13"
            target="_blank"
            rel="noreferrer">
            <HelpRounded style={{ fontSize: '38px', alignSelf: 'center', marginLeft: '10px' }} />
          </a>
        </ul>
      </nav>
    </Box>
  );
};

export default NavBar;
