import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChartWidget from 'component/Widgets/ChartWidget';
import TOTAL_TIME_SPENT_GROUPED_BY_TRAINING from 'graphql/TotalTimeSpentGroupedByTraining';
import { useTrainings, useTrainingTranslation } from 'hooks';

import { friendlyTimeDisplayFromMinutes, timeDisplayFromMinutes } from '../../../../util';

const useStyles = makeStyles((theme) => ({
  singleContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  singleSeries: {
    color: theme.palette.primary.main,
    fontSize: '3rem',
    fontWeight: 600,
    flex: '1 1 0%',
    display: 'flex',
    alignItems: 'center'
  },
  unit: {
    fontSize: '1.3rem',
    paddingLeft: '5px',
    paddingRight: '10px'
  },
  singleLabel: {
    borderTop: '1px solid ' + theme.palette.grey['300'],
    padding: '20px 0 0 0'
  }
}));

const TimeSpent = (props) => {
  const classes = useStyles(props);
  const { trainings, getTrainingDetailsById, loadingTrainings } = useTrainings();
  const { getTranslatedNameFromTraining } = useTrainingTranslation();
  const { t } = useTranslation();
  const theme = useTheme();
  const collapseLG = useMediaQuery(theme.breakpoints.only('lg'));
  const collapseMD = useMediaQuery(theme.breakpoints.down('md'));

  const { data, error, loading } = useQuery(TOTAL_TIME_SPENT_GROUPED_BY_TRAINING, {
    variables: {
      ...(props.restrictedUser ? props.userId : props.orgId),
      ...(props.filteredBy && props.cohortId)
    },
    fetchPolicy: 'cache-first'
  });

  let chartData = {
    labels: [],
    series: [],
    percentages: [],
    singleContent: null,
    unit: ''
  };

  let totalTimeSpent = 0;
  let totalTimeByTraining = [];

  if (!loadingTrainings && data && trainings.length !== 0) {
    let sortedTrainings = JSON.parse(JSON.stringify(data.totalMinutesGroupedByTraining)).sort(
      (a, b) => b.totalMinutes - a.totalMinutes
    );

    sortedTrainings.forEach((training, i) => {
      if (i <= 3) {
        const trainingDetails = getTrainingDetailsById({
          id: training.trainingId
        });
        const trainingName = getTranslatedNameFromTraining({ training: trainingDetails });
        chartData.labels.push(trainingName);
        chartData.series.push(training.totalMinutes);
        totalTimeByTraining.push(training.totalMinutes);
        totalTimeSpent += training.totalMinutes;
      } else {
        if (chartData.labels.includes('Other')) {
          chartData.series[4] = chartData.series[4] + training.totalMinutes;
          totalTimeByTraining[4] = totalTimeByTraining[4] + training.totalMinutes;
          totalTimeSpent += training.totalMinutes;
        } else {
          chartData.labels.push('Other');
          chartData.series.push(training.totalMinutes);
          totalTimeByTraining.push(training.totalMinutes);
          totalTimeSpent += training.totalMinutes;
        }
      }
    });

    totalTimeByTraining.forEach((amount, i) => {
      chartData.percentages[i] = Math.round((Number(amount) * 100) / Number(totalTimeSpent));
    });

    if (chartData.series.length === 1)
      chartData.singleContent = friendlyTimeDisplayFromMinutes(chartData.series[0]);

    if (chartData.series.length === 1) {
      const friendlyTime = timeDisplayFromMinutes(chartData.series[0]);
      chartData.singleContent = (
        <Box className={classes.singleContent}>
          <Box className={classes.singleSeries}>
            {friendlyTime.hours && (
              <>
                {friendlyTime.hours}
                <Box className={classes.unit}>hrs</Box>
              </>
            )}
            {friendlyTime.minutes}
            <Box className={classes.unit}>mins</Box>
          </Box>
          <Box className={classes.singleLabel}>{chartData.labels[0]}</Box>
        </Box>
      );
    }
  }

  return (
    <ChartWidget
      height={collapseLG ? '16.85rem' : collapseMD ? '18.65rem' : '20rem'}
      widgetTitle={t('dashboard.timeSpentCard.title')}
      chartType="donut"
      chartData={chartData}
      loading={loading}
      error={error}
      noData={chartData.series.length < 1 && t('dashboard.timeSpentCard.noData')}
    />
  );
};

export default TimeSpent;
