import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, TablePagination } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  pagination: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    border: '1px solid ' + theme.palette.grey['200']
  }
}));

const PaginationBar = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    let dataCopy = [...props.data];
    let newList = [];
    for (let i = 0; i < dataCopy.length; i += rowsPerPage) {
      newList.push(dataCopy.slice(i, i + rowsPerPage));
    }
    props.setData(newList[page] ? newList[page] : []);
  }, [page, rowsPerPage, props.data]);

  return (
    <Box className={classes.pagination}>
      <TablePagination
        labelRowsPerPage={t('table.rowsPerPage')}
        //defaultLabelDisplayedRows={{ from: 'from', of: t('general.of'), count: 'count', page: 'page'}}
        component="div"
        count={props.data.length}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default PaginationBar;
