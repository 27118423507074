import { createContext, useCallback, useMemo, useState } from 'react';

export const PERFORMANCE_FILTER = {
  ALL: 'all',
  PASSED: 'passed',
  NOT_PASSED: 'notPassed'
};

export const EnrolledUsersFilterContext = createContext(PERFORMANCE_FILTER.ALL);

export const EnrolledUsersFilterProvider = ({ children }) => {
  const [performance, setSelectedPerformance] = useState(PERFORMANCE_FILTER.ALL);

  const activePerformance = useMemo(() => {
    return performance;
  }, [performance]);

  const setActivePerformance = useCallback(
    (performance) => {
      setSelectedPerformance(performance);
    },
    [setSelectedPerformance]
  );

  const value = {
    activePerformance,
    setActivePerformance
  };

  return (
    <EnrolledUsersFilterContext.Provider value={value}>
      {children}
    </EnrolledUsersFilterContext.Provider>
  );
};

export default EnrolledUsersFilterProvider;
