import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CssBaseline, makeStyles } from '@material-ui/core';
import Footer from 'component/Footer';
import Loader from 'component/ViewContainer/Loader';
import { setFeedBack } from 'ducks/globalFeedback';
import { useUser } from 'hooks';
import { useAuth } from 'hooks';
import { useOrganization } from 'hooks';
import Feedback from 'layout/MainLayout/Feedback';

import NavBar from '../NavBar';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100vw',
    overflowX: 'hidden'
  },
  content: {
    flex: '1 1 0%',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default
  },
  header: {
    zIndex: 1201,
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
  }
}));

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { feedback } = useSelector((state) => state.globalFeedback);
  const { authenticated } = useAuth();
  const { user } = useUser();
  const { organizationData } = useOrganization();
  const classes = useStyles();

  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    if (feedback && (feedback.length > 0 || Object.keys(feedback).length > 0)) {
      handleSetFeedback(feedback);
    }
  }, [feedback]);

  useEffect(() => {
    if (showFeedback) {
      const timeout = setTimeout(() => {
        clearFeedback();
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showFeedback]);

  const handleSetFeedback = () => {
    setShowFeedback(true);
  };

  const clearFeedback = () => {
    dispatch(setFeedBack(null));
    setShowFeedback(false);
  };

  return (
    <div className={classes.root}>
      {showFeedback && <Feedback onClose={clearFeedback} feedback={feedback} />}
      {authenticated ? (
        <>
          <CssBaseline />
          <NavBar />
          <main className={classes.content}>
            {user && organizationData ? children : <Loader />}
            <Footer />
          </main>
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default MainLayout;
