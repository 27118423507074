import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useUser } from 'hooks';

const useStyles = makeStyles((theme) => ({
  titleBar: {
    backgroundColor: theme.palette.primary.main,
    height: '180px',
    textAlign: 'center',
    color: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px'
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: '50px'
    }
  },
  titleText: {
    paddingTop: '38px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '30px'
    },
    fontSize: '1.5em',
    textTransform: 'uppercase',
    fontWeight: 600
  },
  linksContainer: {
    flex: '1 1 0%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    fontSize: '1.1em'
  },
  link: {
    paddingBottom: '10px',
    margin: '0 20px',
    borderBottom: '5px solid transparent',
    cursor: 'pointer',
    opacity: 0.7,
    '&:hover': {
      opacity: 1
    }
  },
  linkActive: {
    borderBottom: '5px solid ' + theme.palette.background.paper,
    opacity: 1
  },
  divider: {
    width: '1px',
    height: '20px',
    marginBottom: '16px',
    backgroundColor: theme.palette.background.paper + 40
  },
  settingsContainer: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column'
  },
  formContainer: {
    flex: '1 1 0%',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  },
  settings: {
    height: '100%',
    [theme.breakpoints.only('xl')]: {
      width: '20%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '40%'
    },
    [theme.breakpoints.only('md')]: {
      width: '50%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '70%'
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      padding: '0 20px'
    }
  }
}));

const UserSettings = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let history = useHistory();
  const { user } = useUser();
  const [content, setContent] = useState('');

  const navItems = [
    {
      id: 0,
      content: 'enrollment',
      action: () => {
        setContent('enrollment');
        history.push({ pathname: '/profile/enrollment' });
      },
      title: t('userProfile.enrollment.title')
    },
    {
      id: 1,
      content: 'personal',
      action: () => {
        setContent('personal');
        history.push({ pathname: '/profile/user' });
      },
      title: t('userProfile.personalInfo.title')
    },
    {
      id: 2,
      content: 'password',
      action: () => {
        setContent('password');
        history.push({ pathname: '/profile/change-password' });
      },
      title: t('userProfile.password.title')
    },
    {
      id: 3,
      content: 'settings',
      action: () => {
        setContent('settings');
        history.push({ pathname: '/profile/settings' });
      },
      title: t('userProfile.settings.title')
    }
  ];

  useEffect(() => {
    navItems.forEach((nav) => {
      if (window.location.href.indexOf(nav.content) !== -1) {
        nav.action();
      }
    });
  }, []);

  return (
    <Box className={classes.settingsContainer}>
      <Box className={classes.titleBar}>
        <Box className={classes.titleText}>{user?.firstName + ' ' + user?.lastName}</Box>
        <Box className={classes.linksContainer}>
          {navItems.map((item) => {
            return (
              <Box
                key={item.id}
                onClick={item.action}
                className={`${classes.link} ${content === item.content && classes.linkActive} `}>
                {item.title}
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box className={classes.formContainer}>
        <Box className={classes.settings}>{props.children}</Box>
      </Box>
    </Box>
  );
};

export default UserSettings;
