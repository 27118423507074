import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    letterSpacing: '-20',
    fontSize: '0.8rem',
    paddingBottom: '10px'
  }
}));

const SmallTitle = (props) => {
  const classes = useStyles(props);
  return <Box className={classes.root}>{props.title}</Box>;
};

export default SmallTitle;
