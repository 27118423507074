import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import ModalWrapper from '../ModalWrapper';

const ConfirmModal = (props) => {
  const { t } = useTranslation();
  const { message, ...rest } = props;

  return (
    <ModalWrapper
      type="confirm"
      icon="confirm"
      height="auto"
      width="400px"
      buttonText={t('modals.button.textYes')}
      {...rest}>
      <Box>{message}</Box>
    </ModalWrapper>
  );
};

export default ConfirmModal;

ConfirmModal.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  icon: PropTypes.string,
  handleCloseModal: PropTypes.func,
  handleDone: PropTypes.func,
  saving: PropTypes.bool,
  loading: PropTypes.bool,
  response: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      message: PropTypes.string,
      responseType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    })
  ]),
  buttonText: PropTypes.string,
  customActionButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};
