import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    '&:hover': {
      border: '1px solid ' + theme.palette.primary.main,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main + '10'
    }
  },
  unSelected: {
    border: '1px solid ' + theme.palette.grey['200'],
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper
  },
  selected: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main + '10',
    border: '1px solid ' + theme.palette.primary.main
  },
  left: {
    borderRadius: '5px 0 0 5px',
    borderRight: '0px',
    '&:hover': {
      borderRight: '0px'
    }
  },
  selectedLeft: {
    borderLeft: '1px solid ' + theme.palette.primary.main
  },
  right: {
    borderRadius: '0 5px 5px 0',
    '&:hover': {
      borderRight: '1px solid ' + theme.palette.primary.main
    }
  },
  unSelectedRight: {
    borderRight: '1px solid #C4C4C4'
  },
  selectedRight: {
    borderRight: '1px solid ' + theme.palette.primary.main
  },
  disabled: {
    color: theme.palette.secondary.main + 60,
    backgroundColor: theme.palette.grey['200'] + 50,
    border: '1px solid ' + theme.palette.grey['200']
  }
}));

const Switch = (props) => {
  const classes = useStyles(props);

  return (
    <Box
      display="flex"
      direction="row"
      spacing={1}
      alignItems="center"
      height="2.4rem"
      margin="16px 0 8px 0">
      <Box
        onClick={() => props.onClick(props.textLeft.id)}
        className={`
					${classes.root} 
					${classes.left} 
					${props.selected === props.textLeft.id ? classes.selected : classes.unSelected}
					${props.disabled && classes.disabled}
				`}>
        {props.textLeft.name}
      </Box>
      <Box
        onClick={() => props.onClick(props.textRight.id)}
        className={`
					${classes.root} 
					${classes.right}
					${props.selected === props.textRight.id ? classes.selected : classes.unSelected}
					${props.selected === props.textRight.id ? classes.selectedRight : classes.unSelectedRight}
					${props.selected === props.textLeft.id && classes.selectedLeft}
					${props.disabled && classes.disabled}
				`}>
        {props.textRight.name}
      </Box>
    </Box>
  );
};

export default Switch;
