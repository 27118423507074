import { gql } from '@apollo/client';

const DELETE_TRAININGS = gql`
  mutation DeleteTrainings($trainingIds: [Int!]) {
    deleteTrainings(ids: $trainingIds) {
      success
      error
    }
  }
`;

export default DELETE_TRAININGS;
