import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FullScreenModal from '../../../../component/Modals/FullScreenModal';

import Questions from './ModalSegments/Questions/';
import QuizInfo from './ModalSegments/QuizInfo/';

const QuizModal = (props) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({
    name: false,
    trainingId: false,
    questions: []
  });

  const [quizInfo, setQuizInfo] = useState({
    name: '',
    trainingId: ''
  });

  const [questions, setQuestions] = useState([
    {
      text: '',
      choices: [
        {
          text: '',
          correctAnswer: false
        }
      ]
    }
  ]);

  useEffect(() => {
    //ON EDIT - BUILD QUESTION OBJECT
    if (props.selectedQuiz) {
      setQuizInfo({
        name: props.selectedQuiz.name,
        trainingId: props.selectedQuiz.training.id
      });
      if (props.selectedQuiz.quizquestionSet.length > 0) {
        const questions = props.selectedQuiz.quizquestionSet.map((question) => {
          return {
            text: question.text,
            choices: question.quizquestionchoiceSet.map((choice) => {
              return {
                text: choice.choiceText,
                correctAnswer: choice.correctAnswer
              };
            })
          };
        });
        setQuestions(questions);
      }
    }
  }, []);

  const handleClearErrors = () => {
    setErrors({
      name: false,
      trainingId: false,
      questions: []
    });
  };

  const handleSetQuizInfo = (e) => {
    let quizInfoCopy = { ...quizInfo };
    quizInfoCopy[e.target.name] = e.target.value.toString();
    handleClearErrors();
    setQuizInfo(quizInfoCopy);
  };

  const handleBuildQuestions = (e, qIndex, cIndex) => {
    handleClearErrors();
    let questionsCopy = [...questions];
    if (cIndex === null) {
      questionsCopy[qIndex].text = e.target.value;
    } else {
      if (e.target.name === 'correctAnswer') {
        questionsCopy[qIndex].choices.forEach((choice) => {
          choice.correctAnswer = false;
        });
        questionsCopy[qIndex].choices[cIndex].correctAnswer = e.target.checked;
      } else {
        questionsCopy[qIndex].choices[cIndex].text = e.target.value;
      }
    }
    setQuestions(questionsCopy);
  };

  const handleAddQuestion = () => {
    handleClearErrors();
    let questionsCopy = [...questions];
    questionsCopy.push({
      text: '',
      choices: [
        {
          text: '',
          correctAnswer: false
        }
      ]
    });
    setQuestions(questionsCopy);
  };

  const handleRemoveQuestion = (qIndex) => {
    handleClearErrors();
    let questionsCopy = [...questions];
    questionsCopy.splice(qIndex, 1);
    setQuestions(questionsCopy);
  };

  const handleAddChoice = (index) => {
    handleClearErrors();
    let questionsCopy = [...questions];
    questionsCopy[index].choices.push({
      text: '',
      correctAnswer: false
    });
    setQuestions(questionsCopy);
  };

  const handleRemoveChoice = (qIndex, aIndex) => {
    handleClearErrors();
    let questionsCopy = [...questions];
    questionsCopy[qIndex].choices.splice(aIndex, 1);
    setQuestions(questionsCopy);
  };

  const handleSave = () => {
    let error = false;
    let errorList = { ...errors };
    Object.keys(quizInfo).forEach((key) => {
      if (quizInfo[key].length < 1) {
        errorList[key] = true;
        error = true;
      }
    });
    questions.forEach((question, index) => {
      let questionError = false;
      if (question.text.length < 1) questionError = true;
      if (question.choices.find((c) => c.text.length < 1)) questionError = true;
      if (!question.choices.find((c) => c.correctAnswer)) questionError = true;
      if (question.choices.length < 1) questionError = true;
      if (questionError) {
        error = true;
        errorList.questions.push(index);
      }
    });
    if (error) {
      setErrors(errorList);
    } else {
      if (props.selectedQuiz) {
        props.updateQuiz(props.selectedQuiz.id, quizInfo, questions);
      } else {
        props.addQuiz(quizInfo, questions);
      }
    }
  };

  return (
    <FullScreenModal
      icon={props.selectedQuiz ? 'edit' : 'add'}
      title={props.selectedQuiz ? t('quizManagement.edit') : t('quizManagement.create')}
      buttonText={props.selectedQuiz ? t('quizManagement.update') : t('quizManagement.save')}
      type="save"
      handleCloseModal={() => props.handleCloseModal('quiz')}
      handleDone={handleSave}
      saving={props.saving}
      response={props.response}>
      <QuizInfo
        quizInfo={quizInfo}
        trainings={props.trainings ? props.trainings.allTrainings : []}
        setQuizInfo={handleSetQuizInfo}
        errors={errors}
      />
      <Questions
        questions={questions}
        buildQuestions={handleBuildQuestions}
        addQuestion={handleAddQuestion}
        removeQuestion={handleRemoveQuestion}
        addChoice={handleAddChoice}
        removeChoice={handleRemoveChoice}
        errors={errors.questions}
      />
    </FullScreenModal>
  );
};

export default QuizModal;
