import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    fontWeight: 500,
    fontSize: '1.5rem',
    padding: '60px 0 30px 0',
    [theme.breakpoints.up('sm')]: {
      padding: '50px 0 45px 0'
    },
    width: '100%'
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '0.9rem',
    padding: '15px 0 0 0',
    opacity: '0.7'
  }
}));

const Title = (props) => {
  const classes = useStyles(props);
  return (
    <Box className={classes.container}>
      {props.title}
      {props.subTitle && (
        <Grid container item lg={4} md={6} sm={9} xs={12} className={classes.subtitle}>
          {props.subTitle}
        </Grid>
      )}
    </Box>
  );
};

export default Title;
