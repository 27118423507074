import { toast } from 'react-toastify';

import combineProviders from './combineProviders';
import { saveLastRowIndex, scrollToRowIndex } from './table';

function formatDate(date) {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  date = mm + '/' + dd + '/' + yyyy;
  return date;
}

function getLast7Days(reverse = false) {
  let result = [];
  for (let i = 0; i < 7; i++) {
    let d = new Date();
    d.setDate(d.getDate() - i);
    result.push(formatDate(d));
  }
  if (reverse) return result.reverse();
  return result.join(',');
}

function getTimeDifference(earlierDate, laterDate) {
  let oDiff = new Object();

  //  Calculate Differences
  //  -------------------------------------------------------------------  //
  let nTotalDiff = new Date(laterDate).getTime() - new Date(earlierDate).getTime();

  /** uncomment if you want days included **/
  //oDiff.days = Math.floor(nTotalDiff / 1000 / 60 / 60 / 24);
  //nTotalDiff -= oDiff.days * 1000 * 60 * 60 * 24;

  oDiff.hours = Math.floor(nTotalDiff / 1000 / 60 / 60);
  nTotalDiff -= oDiff.hours * 1000 * 60 * 60;

  oDiff.minutes = Math.floor(nTotalDiff / 1000 / 60);
  nTotalDiff -= oDiff.minutes * 1000 * 60;

  oDiff.seconds = Math.floor(nTotalDiff / 1000);
  //  -------------------------------------------------------------------  //

  //  Format Duration
  //  -------------------------------------------------------------------  //
  //  Format Hours
  let hourtext = '00';
  if (oDiff.days > 0) {
    hourtext = String(oDiff.days);
  }
  if (hourtext.length == 1) {
    hourtext = '0' + hourtext;
  }

  //  Format Minutes
  let mintext = '00';
  if (oDiff.minutes > 0) {
    mintext = String(oDiff.minutes);
  }
  if (mintext.length == 1) {
    mintext = '0' + mintext;
  }

  //  Format Seconds
  let sectext = '00';
  if (oDiff.seconds > 0) {
    sectext = String(oDiff.seconds);
  }
  if (sectext.length == 1) {
    sectext = '0' + sectext;
  }

  //  Set Duration (uncomment sec if you want seconds included)
  let sDuration = hourtext + ':' + mintext; //+ ':' + sectext;
  oDiff.duration = sDuration;
  //  -------------------------------------------------------------------  //

  return oDiff;
}

function friendlyTimeDisplayFromMinutes(n) {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (n < 60) return n + ' m';
  return rhours + ' h and ' + rminutes + ' m';
}

//NOTE: WILL CHANGE ALL USES OF FRIENDLY TIME DISPLAY TO THIS FOR STYLING PURPOSES
function timeDisplayFromMinutes(n) {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (n < 60) return { minutes: n };
  return { hours: rhours, minutes: rminutes };
}

function maxValueInArrayOfObjects(array, propertyName) {
  return Math.max.apply(
    Math,
    array.map(function (o) {
      return o[propertyName];
    })
  );
}

function valueExistsInArrayOfObject(arr, property, value) {
  return arr.some(function (el) {
    return el[property] === value;
  });
}

// Use this one for array of objects
function symmetricDiff(otherArray, property) {
  return function (current) {
    return (
      otherArray.filter(function (other) {
        return other[property] == current[property];
      }).length == 0
    );
  };
}

// Compare 2 array of primitives
function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

const getFromIndex = (array, indexes) => {
  return indexes.reduce((result, i) => result.concat(array[i]), []);
};

/* Remove objects from an array given a predicate that can be a list of values.
For example: filterInPlace(array, obj => !toDelete.has((obj.id))) . 'InPlace' because it
keeps the same array instead of generating a new one */
const filterInPlace = (array, predicate) => {
  let end = 0;

  for (let i = 0; i < array.length; i++) {
    const obj = array[i];

    if (predicate(obj)) {
      array[end++] = obj;
    }
  }

  array.length = end;
};

const isValidImage = (type) => {
  const acceptableFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
  return acceptableFileTypes.indexOf(type) !== -1;
};

const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
    toast.error('Failed upload.');
  };
};

export {
  isValidImage,
  getTimeDifference,
  friendlyTimeDisplayFromMinutes,
  timeDisplayFromMinutes,
  maxValueInArrayOfObjects,
  valueExistsInArrayOfObject,
  symmetricDiff,
  arrayEquals,
  getFromIndex,
  getLast7Days,
  filterInPlace,
  combineProviders,
  getBase64,
  saveLastRowIndex,
  scrollToRowIndex
};

export * from './mapTextDataToCsv';
