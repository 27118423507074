import { gql } from '@apollo/client';

export const GET_ORGANIZATIONS = gql`
  query allOrganizations {
    allOrganizations {
      id
      name
      avatar
      s3FileName
      signedUrlExpiration
      tokenEnabled
      token {
        id
        quantity
      }
      courses {
        id
        name
        description
        trainings {
          id
          name
          cohorts {
            id
            name
            user {
              id
              username
            }
          }
          user {
            id
            username
            isSuperuser
            isActive
            email
            cohorts {
              id
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation organizationMutation(
    $id: ID
    $name: String!
    $avatarBase64: String
    $tokenEnabled: Boolean!
  ) {
    organizationMutation(
      id: $id
      name: $name
      avatarBase64: $avatarBase64
      tokenEnabled: $tokenEnabled
    ) {
      success
      error
      organization {
        name
        avatar
      }
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation organizationMutation($name: String!, $avatarBase64: String, $tokenEnabled: Boolean!) {
    organizationMutation(name: $name, avatarBase64: $avatarBase64, tokenEnabled: $tokenEnabled) {
      success
      error
      organization {
        name
        avatar
        s3FileName
        signedUrlExpiration
        tokenEnabled
      }
    }
  }
`;
