import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import { CheckCircleOutlineRounded, RotateRightRounded } from '@material-ui/icons';
import PaginationBar from 'component/PaginationBar';
import PerformanceTable from 'component/PerformanceTable';
import Filter from 'component/ViewFilters';
import { EnrolledUsersFilterProvider } from 'component/ViewFilters/EnrolledUsersFilter/useEnrolledUsersFilter';
import { ENROLLED_USER } from 'constants/filters';
import { useTrainings, useTrainingTranslation } from 'hooks';
import { useUser } from 'hooks';
import { useDateFormat } from 'hooks/useDateFormat';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flex: '1 1 0%'
    },
    [theme.breakpoints.down('sm')]: {
      height: (parsedData) => (parsedData.length > 0 ? '600px' : '200px')
    }
  },
  spacing: {
    height: '10px',
    width: '100%'
  }
}));

const EnrolledUsers = (props) => {
  const { getUserTimezone } = useUser();
  const { t } = useTranslation();
  const { handleDateFormat } = useDateFormat();
  const [parsedData, setParsedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const classes = useStyles(parsedData);
  const { getTranslatedNameFromTraining } = useTrainingTranslation();
  const { getTrainingDetailsById, loadingTrainings } = useTrainings();

  useEffect(() => {
    if (!loadingTrainings) {
      let trainingData = [];
      props.data.forEach((user) => {
        const training = getTrainingDetailsById({
          id: user.trainingId
        });
        trainingData.push({
          training,
          firstName: user.firstName,
          lastName: user.lastName,
          totalPassed: user.totalPassed,
          totalFailed: user.totalFailed,
          passed: user.totalPassed > 0,
          notPassed: user.totalFailed > 0,
          datePassed: moment.tz(user.datePassed, getUserTimezone()).format(handleDateFormat(true))
        });
      });
      setParsedData(trainingData);
    }
  }, [props.data, loadingTrainings]);

  useEffect(() => {
    setFilteredData(parsedData);
  }, [parsedData]);

  const handlePagination = useCallback(() => {
    const parsedPaginatedData = paginatedData.map((row) => {
      return {
        ...row,
        icon: row.passed ? <CheckCircleOutlineRounded /> : <RotateRightRounded />,
        success: row.passed,
        leftContent: [row.firstName + ' ' + row.lastName, row.training.name],
        rightContent: [
          row.passed ? t('sharedLabels.passed') : t('sharedLabels.incomplete'),
          row.passed ? row.datePassed : '--/--/--'
        ]
      };
    });
    setPaginatedData(parsedPaginatedData);
  }, [paginatedData, setPaginatedData]);

  let trainingOptions = [];

  if (props.trainings) {
    trainingOptions = props.trainings.map((training) => {
      return {
        ...training,
        name: getTranslatedNameFromTraining({ training })
      };
    });
  }

  let filterOptions = {
    performance: {
      options: [
        { id: 'all', name: t('sharedLabels.all') },
        { id: 'passed', name: t('sharedLabels.passed') },
        { id: 'notPassed', name: t('sharedLabels.notPassed') }
      ],
      name: 'performance'
    },
    trainings: {
      options: [{ name: t('sharedLabels.allTrainings'), id: 'all' }, ...trainingOptions],
      name: 'training'
    }
  };

  return (
    <EnrolledUsersFilterProvider>
      <Box className={classes.root}>
        <Filter
          filterType={ENROLLED_USER}
          disabled={parsedData.length < 1}
          data={parsedData}
          setFilteredData={setFilteredData}
          filterOptions={filterOptions}
        />
        <Box className={classes.spacing} />
        <PerformanceTable users={paginatedData} />
        {parsedData.length > 0 && <PaginationBar setData={handlePagination} data={filteredData} />}
      </Box>
    </EnrolledUsersFilterProvider>
  );
};

export default EnrolledUsers;
