import { gql } from '@apollo/client';

export const ARCHIVE_USER = gql`
  mutation archiveUser($usernames: [String]!) {
    archiveUser(usernames: $usernames, anonimize: false) {
      success
      error
    }
  }
`;
