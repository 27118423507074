import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SuccessButton from 'component/Buttons/SuccessButton';
import FormSubTitle from 'component/Inputs/FormSubTitle';
import SelectInput from 'component/Inputs/SelectInputs/SelectInput';
import XSmallSpinner from 'component/Spinners/XSmallSpinner';
import Title from 'component/ViewContainer/ViewTitle';
import { DATE_FORMATS } from 'constants/dateFormats';
import { setFeedBack } from 'ducks/globalFeedback';
import { UPDATE_PROFILE } from 'graphql/UpdateProfile';
import { buildTimeZones } from 'helpers/buildTimeZones';
import { useUser } from 'hooks';
import { useLanguages } from 'hooks';

import { useTableOption } from '../../../providers/TableOptionProvider';

const useStyles = makeStyles(() => ({
  marginTop: {
    marginTop: '20px'
  },
  button: {
    marginTop: '40px',
    display: 'flex'
  },
  saving: {
    marginLeft: '20px',
    paddingBottom: '3px',
    display: 'flex',
    alignItems: 'flex-end'
  }
}));

const Settings = (props) => {
  const { isDefaultRowsPerPageHasValue, displayRowOptions, defaultRowsPerPage, updateRowsPerPage } =
    useTableOption();
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const { t, i18n } = useTranslation();
  const { user, setUser, getUserPreferredLanguageId, getUserTimezone } = useUser();
  const [inputs, setInputs] = useState({
    languageId: getUserPreferredLanguageId(),
    timeZone: getUserTimezone(),
    dateFormat: user.profile?.dateFormat
      ? DATE_FORMATS.find((date) => date.id === user.profile.dateFormat).id
      : ''
  });
  const [disableSave, setDisableSave] = useState(true);
  const timeZones = buildTimeZones();
  const { appLanguages, getLangugeDetailsById } = useLanguages();
  const [rowsPerPage, setRowsPerPage] = useState(null);
  useEffect(() => {
    if (isDefaultRowsPerPageHasValue) {
      setRowsPerPage(defaultRowsPerPage);
    }
  }, [isDefaultRowsPerPageHasValue, defaultRowsPerPage]);

  const [updateProfile, { loading, error }] = useMutation(UPDATE_PROFILE, {
    onCompleted(data) {
      if (data.updateProfile.success) {
        dispatch(
          setFeedBack({ message: t('userProfile.settings.updateSuccess'), type: 'success' })
        );
        setDisableSave(true);
        let userCopy = { ...user };
        userCopy.profile = data.updateProfile.user.profile;
        if (userCopy.profile.preferredLanguage.code !== sessionStorage.getItem('manualLang')) {
          sessionStorage.removeItem('manualLang');
        }
        // For Login View to remember language pref
        localStorage.setItem('lng', userCopy.profile.preferredLanguage.code);
        i18n.changeLanguage(userCopy.profile.preferredLanguage.code);
        setUser(userCopy);
      }
      if (data.updateProfile.errors) {
        dispatch(setFeedBack({ message: t('userProfile.settings.updateFail'), type: 'error' }));
      }
    }
  });

  useEffect(() => {
    if (error) {
      dispatch(setFeedBack({ message: t('userProfile.settings.updateFail'), type: 'error' }));
    }
  }, [error]);

  const handleOnchange = (e) => {
    setDisableSave(false);
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = () => {
    const language = getLangugeDetailsById({ id: inputs.languageId });

    updateProfile({
      variables: {
        dateFormat: inputs.dateFormat,
        languageCode: language.code,
        timezone: inputs.timeZone,
        userId: parseInt(user.id, 10)
      }
    });
  };

  const handleUpdateRowsPerPage = useCallback(() => {
    updateRowsPerPage(rowsPerPage);
    toast.success('Successfully updated table settings!');
  }, [rowsPerPage]);

  return (
    <>
      <Grid item xs={12}>
        <Title title={t('userProfile.settings.title')} />
      </Grid>
      <Grid item xs={12}>
        <FormSubTitle title={t('userProfile.settings.subTitle1')} />
      </Grid>
      <Grid item container spacing={3} xs={12}>
        <Grid item sm={6} xs={12}>
          <SelectInput
            required={false}
            label={t('userProfile.settings.subTitle1')}
            menuItems={appLanguages}
            selected={inputs.languageId}
            name="languageId"
            onChange={(e) => handleOnchange(e)}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.marginTop}>
        <FormSubTitle title={t('userProfile.settings.subTitle2')} />
      </Grid>
      <Grid item container spacing={3} xs={12}>
        <Grid item sm={6} xs={12}>
          <SelectInput
            required={false}
            label={t('userProfile.settings.timeZone')}
            menuItems={timeZones}
            selected={inputs.timeZone}
            name="timeZone"
            onChange={(e) => handleOnchange(e)}
            disabled={loading}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <SelectInput
            required={false}
            label={t('userProfile.settings.dateFormat')}
            menuItems={DATE_FORMATS}
            selected={inputs.dateFormat}
            name="dateFormat"
            onChange={(e) => handleOnchange(e)}
            disabled={loading}
          />
        </Grid>
      </Grid>
      {isDefaultRowsPerPageHasValue && rowsPerPage ? (
        <>
          <Grid item xs={6} className={classes.button}>
            <SuccessButton disabled={disableSave || loading} onClick={handleOnSubmit}>
              {t('userProfile.settings.update')}
            </SuccessButton>
            <Box className={classes.saving}>{loading && <XSmallSpinner />}</Box>
          </Grid>
          <Grid item xs={12} className={classes.marginTop}>
            <Title title={t('table.settings.title')} />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              required={false}
              label={t('table.rowsPerPage')}
              menuItems={displayRowOptions}
              selected={rowsPerPage}
              name="rowsPerPage"
              onChange={(e) => setRowsPerPage(e.target.value)}
              disabled={loading}
            />
            <SuccessButton
              disabled={rowsPerPage === defaultRowsPerPage}
              onClick={handleUpdateRowsPerPage}>
              {t('userProfile.settings.table')}
            </SuccessButton>
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default Settings;
