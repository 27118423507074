import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '0.5rem'
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '0.8rem',
    letterSpacing: '0.02rem',
    fontWeight: '600',
    textTransform: 'uppercase'
  }
}));

const FormSubTitle = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{props.title}</Typography>
      {props.subText && <Typography variant="caption">{props.subText}</Typography>}
    </Box>
  );
};

export default FormSubTitle;

FormSubTitle.propTypes = {
  title: PropTypes.string
};
