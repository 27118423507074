import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import DateWidget from 'component/Widgets/DateWidget';
import { useUser } from 'hooks';
import moment from 'moment-timezone';

const LastVrActivity = (props) => {
  const { getUserTimezone } = useUser();
  const { t } = useTranslation();
  let date = null;
  let difference = null;

  const calculateDiffUnix = (mostRecentDate) => {
    if (mostRecentDate > 0) {
      date = {
        day: moment.unix(mostRecentDate).format('DD'),
        month: moment.unix(mostRecentDate).format('MMM'),
        year: moment.unix(mostRecentDate).format('YYYY')
      };
      const diff = moment(Date.now())
        .startOf('day')
        .diff(moment.unix(mostRecentDate).startOf('day'), 'days');
      difference =
        diff > 0
          ? diff > 1
            ? t('general.daysAgo', { count: diff })
            : t('general.dayAgo', { count: diff })
          : t('general.today');
    }
  };

  const mostRecentDate = (type) => {
    let mostRecentDate = 0;
    props.data.forEach((course) => {
      course[type].forEach((item) => {
        if (item.createdAt > mostRecentDate) {
          mostRecentDate = item.createdAt;
        }
      });
    });
    return mostRecentDate;
  };

  if (props.selectedSwitch === 'assessment') {
    if (props.date) {
      date = {
        day: moment.tz(props.date, getUserTimezone()).format('DD'),
        month: moment.tz(props.date, getUserTimezone()).format('MMM'),
        year: moment.tz(props.date, getUserTimezone()).format('YYYY')
      };
      const diff = moment
        .tz(Date.now(), getUserTimezone())
        .startOf('day')
        .diff(moment(props.date).startOf('day'), 'days');
      difference =
        diff > 0
          ? diff > 1
            ? t('general.daysAgo', { count: diff })
            : t('general.dayAgo', { count: diff })
          : t('general.today');
    } else {
      calculateDiffUnix(mostRecentDate('assessments'));
    }
  } else {
    calculateDiffUnix(mostRecentDate('practices'));
  }

  return (
    <Grid item xs={12} sm={4}>
      <DateWidget
        color={props.widgetColor('#3366FF')}
        title={t('userDetails.vrActivityCard.title')}
        subTitle={difference}
        date={date}
        noDataMessage={t('userDetails.vrActivityCard.noData')}
      />
    </Grid>
  );
};

export default LastVrActivity;
