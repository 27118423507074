const buildTableData = (training, isSuperUser) => {
  let tableData = [];

  training.user
    .filter((user) => (isSuperUser ? user : !user.isSuperuser))
    .forEach((user) => {
      tableData.push({
        id: user.id,
        username: user.username,
        email: user.email,
        uniqueSessions: 0,
        totalTime: 0,
        passed: false,
        estimateDuration: false,
        isSuperuser: user.isSuperuser,
        errorsMade: 0,
        cohorts: user.cohorts
      });
    });

  return tableData.map((user) => {
    const foundUser = training.sessions.find((session) => {
      return parseInt(session.userId, 10) === parseInt(user.id, 10);
    });
    if (foundUser) {
      return {
        ...user,
        ...foundUser
      };
    }
    return {
      ...user
    };
  });
};

export default buildTableData;
