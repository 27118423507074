import { gql } from '@apollo/client';

const TRAINING_INFO = gql`
  query getTrainingInfo($trainingId: Int!) {
    training(id: $trainingId) {
      id
      name
      details
      translations {
        id
        name
        details
        language {
          code
        }
      }
      cohorts {
        id
        name
        user {
          id
          username
        }
      }
      user {
        id
        email
        username
        isActive
        isSuperuser
        cohorts {
          id
          name
        }
      }
      sessions {
        id
        name
        createdAt
        completedAt
        isComplete
        category
        eventSet {
          errorSeverity
          performedAt
        }
        user {
          id
          username
          email
          isActive
          isSuperuser
        }
      }
    }
  }
`;

export default TRAINING_INFO;
