import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Grid } from '@material-ui/core';
import WarningModal from 'component/Modals/WarningModal';
import ViewContainer from 'component/ViewContainer/ViewContainer';
import CourseWidget from 'component/Widgets/CourseWidget';
import DELETE_COURSES from 'graphql/DeleteCourses';
import { buildCourseAdminCourseList } from 'helpers/buildCourseUserRoleCourseList';
import { useUser } from 'hooks';
import { useOrganization } from 'hooks';
import CourseModal from 'views/Admin/Course/CourseModal';

const AdminCourse = () => {
  const { t } = useTranslation();
  const { organizationData, setOrganizationData } = useOrganization();
  const { user, isCourseAdmin } = useUser();
  const [data, setData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showCourseModal, setShowCourseModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteResponse, setDeleteResponse] = useState(null);
  const [saving, setSaving] = useState(false);

  let orgDataCopy = JSON.parse(JSON.stringify(organizationData));

  useEffect(() => {
    setData(buildCourseAdminCourseList(organizationData.organization, user, isCourseAdmin));
  }, [organizationData.organization]);

  const [deleteCourse] = useMutation(DELETE_COURSES, {
    onCompleted(coursesDeleted) {
      setDeleteResponse({
        message: coursesDeleted.deleteCourses.success
          ? t('courseManagement.responses.deleteSuccess')
          : coursesDeleted.deleteCourses.error,
        responseType: coursesDeleted.deleteCourses.success ? 'success' : 'error'
      });
      if (coursesDeleted.deleteCourses.success) {
        let updatedCourses = orgDataCopy.organization.courses.filter(
          (course) => course.id !== selectedCourse.id
        );
        orgDataCopy.organization.courses = [...updatedCourses];
        setOrganizationData(orgDataCopy);
      }
      setSaving(false);
    }
  });

  const handleShowCourseModal = (course, value) => {
    setSelectedCourse(course);
    setShowCourseModal(value);
  };

  const handleShowDeleteModal = (course, value) => {
    setSelectedCourse(course);
    setShowDeleteModal(value);
    if (!course) {
      setDeleteResponse(null);
      setSaving(false);
      setSelectedCourse(null);
    }
  };

  const handleDeleteConfirmed = () => {
    setSaving(true);
    deleteCourse({
      variables: {
        courseIds: [Number(selectedCourse.id)]
      }
    });
  };

  return (
    <ViewContainer
      title={t('courseManagement.title')}
      flexView={true}
      actionButton={() => handleShowCourseModal(null, true)}
      actionButtonLabel={t('courseManagement.create')}>
      {showDeleteModal && (
        <WarningModal
          saving={saving}
          response={deleteResponse}
          handleCloseModal={() => handleShowDeleteModal(null, false)}
          message={t('courseManagement.deleteModal.message') + ' ' + selectedCourse?.name}
          title={t('courseManagement.deleteModal.title')}
          handleDone={handleDeleteConfirmed}
        />
      )}
      {showCourseModal && (
        <CourseModal
          saving={false}
          response={null}
          selectedCourse={selectedCourse}
          handleCloseModal={() => {
            handleShowCourseModal(null, false);
            setSelectedCourse(null);
          }}
        />
      )}
      <Grid container item xs={12} spacing={2} style={{ alignContent: 'flex-start' }}>
        {data.map((course) => {
          return (
            <Grid key={course.name} item xs={12} sm={6} md={4}>
              <CourseWidget
                title={course.name}
                description={course.description}
                actionEdit={() => handleShowCourseModal(course, true)}
                actionDelete={() => handleShowDeleteModal(course, true)}
              />
            </Grid>
          );
        })}
      </Grid>
    </ViewContainer>
  );
};

export default AdminCourse;
