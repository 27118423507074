import { gql } from '@apollo/client';

export const ALL_GROUPS = gql`
  query allCohorts($orgId: Int!) {
    allCohorts(orgId: $orgId) {
      id
      name
      organization {
        id
        name
      }
      user {
        id
        username
      }
    }
  }
`;

export const ADD_GROUP = gql`
  mutation addGroup($name: String!, $organizationId: Int!, $userIds: [Int]) {
    addCohort(name: $name, organizationId: $organizationId, userIds: $userIds) {
      success
      error
      cohort {
        id
        user {
          id
          username
        }
        name
        organization {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateGroup($id: Int!, $name: String!, $organizationId: Int!, $userIds: [Int]) {
    updateCohort(id: $id, name: $name, organizationId: $organizationId, userIds: $userIds) {
      success
      error
      cohort {
        id
        user {
          id
          username
        }
        name
        organization {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation deleteGroup($ids: [Int]) {
    deleteCohort(ids: $ids) {
      success
      error
    }
  }
`;
