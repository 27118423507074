import { gql } from '@apollo/client';

const ACTIVITY_WITHIN_DATE_RANGE = gql`
  query activityInRange(
    $start: Date
    $end: Date
    $orgId: Int
    $userId: Int
    $cohortId: Int
    $trainingId: Int
  ) {
    activityInRange(
      startDate: $start
      endDate: $end
      orgId: $orgId
      userId: $userId
      cohortId: $cohortId
      trainingId: $trainingId
    ) {
      day
      totalLogins
      minutesSpent
    }
  }
`;

const ACTIVITY_WITHIN_DATE_RANGE_IN_TRAINING = gql`
  query activityInRange($start: Date, $end: Date, $trainingId: Int, $userId: Int, $cohortId: Int) {
    activityInRange(
      startDate: $start
      endDate: $end
      trainingId: $trainingId
      userId: $userId
      cohortId: $cohortId
    ) {
      day
      totalLogins
      minutesSpent
    }
  }
`;

export { ACTIVITY_WITHIN_DATE_RANGE, ACTIVITY_WITHIN_DATE_RANGE_IN_TRAINING };
