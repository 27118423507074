import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowLinkSmall } from 'assets/images/icons/ArrowLink';
import XSmallSpinner from 'component/Spinners/XSmallSpinner';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    color: (props) =>
      props.noData || props.error
        ? theme.palette.secondary.main + '80'
        : theme.palette.secondary.main,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid ' + theme.palette.grey['200'],
    height: (props) => (props.height ? props.height : '')
  },
  linkIcon: {
    paddingTop: '2px',
    marginRight: '8px'
  },
  title: {
    borderBottom: '1px solid ' + theme.palette.grey['200'],
    fontWeight: 500,
    fontSize: '0.9rem',
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    height: '3.5rem',
    padding: '0 20px'
  },
  contentContainer: {
    padding: '25px 30px',
    height: '12rem',
    overflowY: 'auto',
    display: (props) => (props.loading ? 'flex' : 'block'),
    alignItems: (props) => (props.loading ? 'center' : 'flex-start'),
    justifyContent: (props) => (props.loading ? 'center' : 'flex-start')
  },
  content: {
    paddingBottom: '15px'
  },
  textContent: {
    display: 'flex',
    width: '100%',
    height: '25px',
    justifyContents: 'center',
    color: theme.palette.text.secondary
  },
  total: {
    display: 'flex',
    flex: '1 1 0%',
    justifyContent: 'flex-end'
  },
  progress: {
    borderRadius: '5px'
  },
  loading: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const ProgressListWidget = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>{props.title}</Box>
      <Box className={classes.contentContainer}>
        {props.error ? (
          t('general.errorData')
        ) : props.loading ? (
          <Box className={classes.loading}>
            <XSmallSpinner />
          </Box>
        ) : props.data.length < 1 ? (
          props.noData
        ) : (
          props.data.map((item) => {
            return (
              <Box key={item.id} className={classes.content} onClick={item.action && item.action}>
                <Box className={classes.textContent}>
                  {item.link && (
                    <Link className={classes.linkIcon} to={item.link}>
                      <ArrowLinkSmall />
                    </Link>
                  )}
                  {item.name}
                  <Box className={classes.total}>
                    {props.outOfValues ? item.value + ' out of ' + item.total : item.value}
                  </Box>
                </Box>
                <LinearProgress
                  style={{ borderRadius: '5px' }}
                  variant="determinate"
                  value={(item.value / item.total) * 100}
                  color="primary"
                />
              </Box>
            );
          })
        )}
      </Box>
    </Box>
  );
};

export default ProgressListWidget;
