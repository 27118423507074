import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import XSmallSpinner from 'component/Spinners/XSmallSpinner';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    height: (props) => (props.height ? props.height : '10rem'),
    padding: '20px 20px 20px 20px',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid ' + theme.palette.grey['200']
  },
  title: {
    fontWeight: 500,
    color: theme.palette.secondary.main,
    fontSize: '0.9rem',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    height: '40px'
  },
  subText: {
    color: theme.palette.secondary.main,
    fontSize: '0.8rem',
    fontWeight: 400
  },
  contentContainer: {
    flex: '1 1 0%'
  },
  center: {
    display: 'flex',
    paddingBottom: '20px',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dateContainer: {
    color: theme.palette.primary.main,
    paddingTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  dayText: {
    fontWeight: 600,
    fontSize: '3.2rem',
    paddingRight: '5px'
  },
  monthYearText: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 600,
    fontSize: '1.2rem',
    opacity: '0.7',
    textTransform: 'uppercase'
  }
}));

const DateWidget = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        {props.title}
        <Box className={classes.subText}>{props.subTitle ? props.subTitle : '...'}</Box>
      </Box>
      <Box className={classes.contentContainer}>
        {props.error ? (
          <Box className={classes.center}>{t('general.errorData')}</Box>
        ) : props.loading ? (
          <Box className={classes.center}>
            <XSmallSpinner />
          </Box>
        ) : (
          <Box className={classes.dateContainer}>
            <Box className={classes.dayText}>{props.date ? props.date.day : 'DD'}</Box>
            <Box className={classes.monthYearText}>
              <Box>{props.date ? props.date.month : 'MM'}</Box>
              <Box>{props.date ? props.date.year : 'YY'}</Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DateWidget;

DateWidget.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  date: PropTypes.shape({
    day: PropTypes.string,
    month: PropTypes.string,
    year: PropTypes.string
  })
  //Date Example
  //const date = {
  //	day: moment(props.date).format('DD'),
  //	month: moment(props.date).format('MMM'),
  //	year: moment(props.date).format('YYYY')
  //}
};
