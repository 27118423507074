import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Chip from 'component/Inputs/SelectInputs/Chip';
import SelectInput from 'component/Inputs/SelectInputs/SelectInput';
import { LabelExportCSV } from 'component/Labels';
import FullScreenModal from 'component/Modals/FullScreenModal';
import { useLanguages, useTrainings } from 'hooks';
import { mapTextDataToCsv } from 'util';

const DownloadModal = (props) => {
  let buttonDownloadRef = useRef(null);
  const { t } = useTranslation();
  const { trainings } = useTrainings();
  const { languageCodeLists } = useLanguages();
  const [selectedTrainingGroups, setSelectedTrainingGroups] = useState([]);
  const [selectTextsForDownload, setSelectTextsForDownload] = useState([]);
  const event = new Date();
  const filename = `translations_${event.toISOString()}.csv`;

  const handleOnChangeTrainings = useCallback(
    (e) => {
      setSelectedTrainingGroups(e.target.value);
    },
    [setSelectedTrainingGroups]
  );

  const handleOnDeleteTraining = useCallback(
    (value) => {
      const filteredSelectedTrainingGroups = selectedTrainingGroups.filter(
        (selectedTrainingGroup) => selectedTrainingGroup !== value
      );
      setSelectedTrainingGroups(filteredSelectedTrainingGroups);
    },
    [setSelectedTrainingGroups, selectedTrainingGroups]
  );

  const formatDisplayTrainings = useMemo(() => {
    return trainings.map((training) => {
      const matchLabelsByTrainings = props.labels.filter(
        (label) => label.trainingId === training.id
      );
      return {
        ...training,
        name: `${training.name} (${matchLabelsByTrainings.length})`
      };
    });
  }, [trainings, props]);

  const onHandleDone = useCallback(() => {
    if (selectTextsForDownload.length !== 0) {
      buttonDownloadRef.current.link.click();
      toast.success(t('textManagement.responses.downloadSuccess'), {
        onOpen: () => props.onSuccess()
      });
    } else {
      toast.error(t('textManagement.error.selectTrainingGroups'));
    }
  }, [selectTextsForDownload, buttonDownloadRef]);

  useEffect(() => {
    const filteredTrainingGroups = props.labels.filter(
      (text) => selectedTrainingGroups.indexOf(text.trainingId) !== -1
    );
    setSelectTextsForDownload(mapTextDataToCsv(filteredTrainingGroups, languageCodeLists));
  }, [selectedTrainingGroups]);

  return (
    <FullScreenModal
      width="35rem"
      icon={'add'}
      title={t('textManagement.export.title')}
      buttonText={t('textManagement.export.title')}
      type="save"
      handleCloseModal={() => props.handleCloseModal()}
      handleDone={onHandleDone}
      saving={props.saving}
      response={props.response}>
      <div
        style={{
          marginBottom: selectedTrainingGroups.length === 0 ? '0px' : '70px'
        }}>
        <SelectInput
          multiple={true}
          label="Trainings"
          selected={selectedTrainingGroups}
          name="courses"
          onChange={(e) => handleOnChangeTrainings(e)}
          menuItems={formatDisplayTrainings}
          group={false}
          required={false}
          topMenu={true}
        />
      </div>
      <div>
        <div>
          {formatDisplayTrainings.map((training) => {
            return selectedTrainingGroups.indexOf(training.id) !== -1 ? (
              <Chip
                key={training.id + 1}
                onDelete={handleOnDeleteTraining}
                id={training.id}
                label={training.name}
              />
            ) : null;
          })}
        </div>
        <p>
          {t('textManagement.totalLabels')}: <strong>{selectTextsForDownload.length}</strong>
        </p>
      </div>
      <div
        style={{
          display: 'none'
        }}>
        <LabelExportCSV
          data={selectTextsForDownload}
          buttonText={t('textManagement.export.title')}
          filename={filename}
          buttonDownloadRef={buttonDownloadRef}
        />
      </div>
    </FullScreenModal>
  );
};

export default DownloadModal;
