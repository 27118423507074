import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ARCHIVE_USER } from 'graphql/User';
import { GET_ALL_USERS } from 'graphql/UserManagement';

const UsersContext = createContext({});

const SUCCESSFULLY_ARCHIVE = 'SUCCESSFULLY_ARCHIVE';

export const UsersProvider = ({ children }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState(null);

  const resetStatus = useCallback(() => {
    setStatus(null);
  }, [setStatus]);

  const [
    getUsersRequest,
    { variables, loading: loadingGetUsersRequest, error: getUsersRequestError }
  ] = useLazyQuery(GET_ALL_USERS, {
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      const { isSuperUser } = variables;
      setUsers(response.allUsers.filter((user) => (isSuperUser ? user : !user.isSuperuser)));
    }
  });

  const [
    archiveUserRequest,
    { loading: loadingArchiveUserRequest, error: archiveUserRequestError }
  ] = useMutation(ARCHIVE_USER, {
    onCompleted(response) {
      if (response.archiveUser.success) {
        setStatus(SUCCESSFULLY_ARCHIVE);
        toast.success(t('userManagement.responses.archiveSuccess'));
      } else {
        toast.error(t('userManagement.responses.archiveError'));
      }
    },
    onError(error) {
      console.error(error);
      toast.error(t('userManagement.responses.archiveError'));
    }
  });

  const archiveUsers = useCallback(
    ({ usernames }) => {
      archiveUserRequest({
        variables: {
          usernames
        }
      });
    },
    [archiveUserRequest]
  );

  const activeUsers = useMemo(() => {
    return users.filter((user) => user.isActive);
  }, [users]);

  const loading = useMemo(() => {
    return loadingGetUsersRequest || loadingArchiveUserRequest;
  }, [loadingGetUsersRequest]);

  const error = useMemo(() => {
    return getUsersRequestError || archiveUserRequestError;
  }, [getUsersRequestError]);

  const isSuccessfullyArchive = useMemo(() => {
    return status === SUCCESSFULLY_ARCHIVE;
  }, [status]);

  return (
    <UsersContext.Provider
      value={{
        activeUsers,
        isSuccessfullyArchive,
        users,
        loading,
        loadingArchiveUserRequest,
        getUsersRequestError,
        error,
        getUsersRequest,
        archiveUsers,
        resetStatus
      }}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => useContext(UsersContext);
