import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import {
  AppProvider,
  AuthProvider,
  LanguagesProvider,
  OrganizationProvider,
  OrganizationTokenProvider,
  TrainingsProvider,
  TrainingTranslationProvider,
  UserProvider,
  UsersProvider
} from 'hooks';
import { TableOptionProvider } from 'providers/TableOptionProvider';
import { createStore } from 'redux';

import './translations/i18n';

import CustomApolloProvider from './api/ApolloClient';
import { LogoutProvider } from './api/Logout';
import App from './App';
import config from './config';
import reducer from './ducks';
import * as serviceWorker from './serviceWorker';
import { combineProviders } from './util';

import './assets/scss/style.scss';

const store = createStore(reducer);
const container = document.getElementById('root');
const root = createRoot(container);

const AllProviders = combineProviders([
  [CustomApolloProvider],
  [HashRouter, { basename: config.basename }],
  [Provider, { store }],
  [AppProvider],
  [AuthProvider, { value: false }],
  [UserProvider, { value: null }],
  [OrganizationProvider, { value: null }],
  [OrganizationTokenProvider],
  [TableOptionProvider],
  [LanguagesProvider],
  [TrainingsProvider],
  [UsersProvider],
  [TrainingTranslationProvider],
  [LogoutProvider]
]);

root.render(
  <AllProviders>
    <App />
  </AllProviders>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
