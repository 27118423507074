import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import ViewContainer from 'component/ViewContainer/ViewContainer';
import { USER_GROUPS } from 'constants/filters';
import { gridSpacing } from 'ducks/constant';
import GET_TRAINING_SESSION_STATS from 'graphql/getTrainingSessionStats';
import TRAINING_INFO from 'graphql/TrainingInfo';
import { useUser } from 'hooks';
import { useTrainingTranslation } from 'hooks';
import TotalSessionsTime from 'views/Dashboard/TrainingDetails/TotalSessionsTime';

import TopMistakes from './CommonErrors/TopMistakes';
import TopObjects from './CommonErrors/TopObjects';
import LastWeekActivity from './LastWeekActivity';
import OverallPerformance from './OverallPerformance';
import SessionPerDay from './SessionsPerDay';
import TrainingTable from './TrainingTable';

const TrainingDetails = () => {
  const { getTranslatedNameFromTraining } = useTrainingTranslation();
  const { t } = useTranslation();
  const params = useParams();
  let trainingId = params.trainingId;
  const { user, restrictedUser } = useUser();
  const [filteredBy, setFilteredBy] = useState(false);

  const [getTrainingInfo, { data, error, loading }] = useLazyQuery(TRAINING_INFO);
  const [getTrainingSessionStats, { data: trainingSessionStats }] = useLazyQuery(
    GET_TRAINING_SESSION_STATS
  );

  let cohortId = {
    cohortId: Number(filteredBy?.id)
  };
  let userId = {
    userId: Number(user.id)
  };

  const handleGetTrainingInfo = (id) => {
    getTrainingInfo({
      variables: {
        trainingId: id,
        ...(filteredBy && cohortId)
      },
      fetchPolicy: 'no-cache'
    });
  };

  const handleGetTrainingSessionStats = (id) => {
    getTrainingSessionStats({
      variables: {
        trainingId: id
      },
      fetchPolicy: 'no-cache'
    });
  };

  useEffect(() => {
    handleGetTrainingInfo(trainingId);
    handleGetTrainingSessionStats(trainingId);
  }, [params.trainingId]);

  const setFilteredData = (filteredBy) => {
    setFilteredBy(filteredBy);
  };

  if (data && trainingSessionStats) {
    data.training.sessions = trainingSessionStats.trainingSessionStats;
  }

  let filterOptions = [{ id: 'all', name: 'All Users' }];

  if (data) {
    data.training.cohorts.forEach((cohort) => {
      if (!filterOptions.find((c) => c.id === cohort.id)) {
        filterOptions.push({
          id: cohort.id,
          name: cohort.name
        });
      }
    });
  }

  const trainingName = data ? getTranslatedNameFromTraining({ training: data?.training }) : '';

  return (
    <ViewContainer
      title={trainingName}
      loading={loading}
      error={error}
      filter={{
        filterType: USER_GROUPS,
        filterLocation: 'TRAINING',
        data,
        filterOptions: filterOptions,
        setFilteredData: setFilteredData
      }}>
      <Grid container spacing={gridSpacing}>
        <Grid container spacing={2} item xs={12}>
          <Grid item container xs={12} lg={6} xl={5}>
            {!restrictedUser && (
              <>
                <Grid item xs={12} sm={6} style={{ paddingRight: '1.5rem' }}>
                  <OverallPerformance filteredBy={filteredBy} training={data?.training} />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <TotalSessionsTime data={data} />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: !restrictedUser && '1.2rem' }}>
              <LastWeekActivity
                cohortId={cohortId}
                userId={userId}
                restrictedUser={restrictedUser}
                filteredBy={filteredBy}
                title={t('trainingDetails.activityCard.title')}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} xl={7}>
            <SessionPerDay
              cohortId={cohortId}
              filteredBy={filteredBy}
              userId={userId}
              restrictedUser={restrictedUser}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TopObjects
              cohortId={cohortId}
              filteredBy={filteredBy}
              trainingId={trainingId}
              userId={userId}
              restrictedUser={restrictedUser}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TopMistakes
              cohortId={cohortId}
              filteredBy={filteredBy}
              trainingId={trainingId}
              userId={userId}
              restrictedUser={restrictedUser}
            />
          </Grid>
        </Grid>
        {!restrictedUser && (
          <Grid item xs={12} style={{ marginRight: '20px' }}>
            <TrainingTable data={data} filteredBy={filteredBy} />
          </Grid>
        )}
      </Grid>
    </ViewContainer>
  );
};

export default TrainingDetails;
