import { gql } from '@apollo/client';

const DELETE_COURSES = gql`
  mutation DeleteCourses($courseIds: [Int!]) {
    deleteCourses(ids: $courseIds) {
      success
      error
    }
  }
`;

export default DELETE_COURSES;
