import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SelectInput from 'component/Inputs/SelectInputs/SelectInput';

const useStyles = makeStyles(() => ({
  root: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const UserGroupsFilter = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const [group, setSelectedGroup] = useState('all');
  const { path } = useRouteMatch();
  const label = path.includes('training') ? t('filters.users') : t('filters.groups');

  useEffect(() => {
    let userLength = 0;
    let trainingsList = [];
    let filteredBy = null;
    if (props.data) {
      if (group === 'all') {
        userLength = 0;
        trainingsList = [];
        filteredBy = null;
      } else {
        filteredBy = props.filterOptions.find((option) => option.id === group);
        //TODO - make const 'DASH'
        if (props.filterLocation === 'DASH') {
          props.data.organization.courses.forEach((course) => {
            course.trainings.forEach((training) => {
              training.cohorts.forEach((cohort) => {
                if (cohort.id === group) {
                  userLength = userLength + cohort.user.length;
                  if (!trainingsList.find((item) => item.id === training.id)) {
                    trainingsList.push(training);
                  }
                }
              });
            });
          });
        }
      }
    }

    //TODO ADD COLLAPSED FILTER

    if (props.filterLocation === 'DASH') {
      props.setFilteredData(userLength, trainingsList, filteredBy);
    } else {
      props.setFilteredData(filteredBy);
    }
  }, [group]);

  return (
    <Box className={classes.root}>
      <SelectInput
        disabled={props.disabled || props.filterOptions.length < 1}
        label={label}
        selected={group}
        onChange={(e) => setSelectedGroup(e.target.value)}
        menuItems={props.filterOptions}
      />
    </Box>
  );
};

export default UserGroupsFilter;
