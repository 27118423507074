import { gql } from '@apollo/client';

const COURSE_DETAILS = gql`
  query coursePerformanceAllStudents($courseId: Int, $category: String) {
    coursePerformanceAllStudents(courseId: $courseId, category: $category) {
      courseId
      courseName
      courseDescription
      trainingStats {
        firstName
        lastName
        username
        userId
        trainingId
        trainingName
        trainingDetails
        totalPassed
        totalFailed
        datePassed
      }
    }
  }
`;

export default COURSE_DETAILS;
