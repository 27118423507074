import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { TRANSLATIONS_EN } from './en/translations';
import { TRANSLATIONS_SV } from './sv/translations';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN
      },
      sv: {
        translation: TRANSLATIONS_SV
      }
    },
    fallbackLng: 'en'
  });

export default i18n;
