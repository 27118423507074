import React from 'react';
import Chart from 'react-apexcharts';
import { COLOR_LIST_CHART, COLOR_LIST_CHART_BORDER } from 'constants/colors';
import PropTypes from 'prop-types';

const StackedBarChart = (props) => {
  let options = {
    chart: {
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true
        }
      }
    },
    colors: props.colors ? (props.colors.bar ? props.colors.bar : props.colors) : COLOR_LIST_CHART,
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => {
        return Math.floor(val);
      }
    },
    stroke: {
      show: true,
      width: 1,
      colors: props.colors
        ? props.colors.border
          ? props.colors.border
          : props.colors
        : COLOR_LIST_CHART_BORDER
    },
    yaxis: {
      title: { text: props.yUnits?.title },
      type: props.yUnits?.type,
      min: 0,
      forceNiceScale: true,
      decimalsInFloat: false,
      labels: {
        style: {
          width: '20px'
        }
      }
    },
    xaxis: {
      title: { text: props.xUnits?.title },
      type: props.xUnits?.type,
      categories: props.categories,
      labels: {
        datetimeFormatter: {
          year: 'yyyy',
          month: 'dd MMM',
          day: 'dd MMM',
          hour: 'HH:mm'
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetY: 0
    },
    fill: {
      opacity: 1
    }
  };

  return (
    <Chart options={options} series={props.series} type="bar" height={'100%'} width={'100%'} />
  );
};

export default StackedBarChart;

StackedBarChart.propTypes = {
  series: PropTypes.array,
  categories: PropTypes.array,
  yUnits: PropTypes.object,
  xUnits: PropTypes.object,
  colors: PropTypes.object
};
