import React from 'react';
import { Box, LinearProgress, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircleOutline } from '@material-ui/icons';
import XSmallSpinner from 'component/Spinners/XSmallSpinner';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '230px',
    border: '1px solid ' + theme.palette.grey[200],
    borderRadius: '5px',
    padding: '20px'
  },
  title: {
    fontWeight: '500',
    color: theme.palette.primary.main
  },
  contentContainer: {
    height: '132px',
    overflowY: 'auto'
  },
  centerSelf: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  trainingContainer: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main + 10,
    borderRadius: '5px',
    padding: '5px 8px',
    marginBottom: '5px',
    justifyContent: 'center'
  },
  trainingText: {
    flex: '1 1 0%'
  },
  noData: {
    marginTop: '15px',
    opacity: '0.5'
  }
}));

const CourseAndTrainings = (props) => {
  const theme = useTheme();
  const classes = useStyles(props);
  const progressValue = (props.data.totalPassed / props.data.trainings.length) * 100;
  return (
    <Box className={classes.root}>
      <Box className={classes.title}>{props.data.name}</Box>
      {props.loading ? (
        <Box className={classes.centerSelf}>
          <XSmallSpinner />
        </Box>
      ) : props.data.trainings.length > 0 ? (
        <>
          <LinearProgress
            style={{ borderRadius: '5px', margin: '15px 0' }}
            variant="determinate"
            value={props.data.trainings.length > 0 ? progressValue : 0}
            color="primary"
          />
          <Box className={classes.contentContainer}>
            {props.data.trainings.map((training, i) => {
              return (
                <Box key={i} className={classes.trainingContainer}>
                  <Box className={classes.trainingText}>{training.name}</Box>
                  {training.assessmentApproved && (
                    <CheckCircleOutline style={{ color: theme.palette.primary.main }} />
                  )}
                </Box>
              );
            })}
          </Box>
        </>
      ) : (
        <Box className={classes.noData}>{props.noData}</Box>
      )}
    </Box>
  );
};

export default CourseAndTrainings;
