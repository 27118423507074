const NAV_SET_NAV = 'NAV_SET_NAV';

const initialState = {
  selected: ''
};

export const setSelectedNavItem = (id) => {
  return { type: NAV_SET_NAV, id };
};

export const nav = (state = initialState, action) => {
  switch (action.type) {
    case NAV_SET_NAV:
      window.sessionStorage.setItem('navItem', action.id);
      return {
        ...state,
        selected: action.id
      };
    default:
      return state;
  }
};
