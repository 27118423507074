import React, { Suspense, useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import Loader from 'component/Loader/Loader';
import { AuthRoute, ProtectedRoute } from 'component/RedirectRoutes';
import { AnimatePresence } from 'framer-motion';
import { useUser } from 'hooks';
import { useAuth } from 'hooks';
import { useOrganization } from 'hooks';
import MainLayout from 'layout/MainLayout';
import AdminPanel from 'views/Admin/AdminPanel';
import AdminCourse from 'views/Admin/Course';
import AdminGroupManagement from 'views/Admin/GroupManagement';
import LanguagesManagement from 'views/Admin/LanguagesManagement';
import AdminOrganizationManagement from 'views/Admin/OrganizationManagement';
import AdminQuizManagement from 'views/Admin/QuizManagement';
import AdminTextManagement from 'views/Admin/TextManagement';
import AdminTraining from 'views/Admin/Training';
import AdminUserManagement from 'views/Admin/UserManagement';
import Course from 'views/Dashboard/Course';
import DashboardDefault from 'views/Dashboard/Default';
import DashboardTrainingDetails from 'views/Dashboard/TrainingDetails';
import DashboardUsers from 'views/Dashboard/User';
import DashboardUserDetails from 'views/Dashboard/UserDetails';
import LoginViews from 'views/Login';
import ForgotPassword from 'views/Login/ForgotPassword';
import Login from 'views/Login/Login';
import ResetUpdatePassword from 'views/Login/ResetUpdatePassword';
import UserSettings from 'views/UserSettings';
import ChangePassword from 'views/UserSettings/Views/ChangePassword';
import Enrollment from 'views/UserSettings/Views/Enrollment';
import PersonalInformation from 'views/UserSettings/Views/PersonalInformation';
import Settings from 'views/UserSettings/Views/Settings';

const Routes = () => {
  const location = useLocation();
  const { authenticated } = useAuth();
  const { organizationData } = useOrganization();
  const { user, isSuperUser, isAdmin, isCourseAdmin } = useUser();
  const showRoutes = isSuperUser || isAdmin || isCourseAdmin;

  useEffect(() => {
    if (user && organizationData) {
      window.gtag('event', 'user_info', {
        user_role_ids: user ? user.organizationRoles.map((role) => role.id) : '',
        user_role: isSuperUser
          ? 'gleechi_user'
          : isAdmin
          ? 'admin'
          : isCourseAdmin
          ? 'course_admin'
          : 'student',
        organization: organizationData?.organization.name,
        page_path: location.pathname + location.search + location.hash
      });
    }
  }, [user]);

  useEffect(() => {
    if (organizationData) {
      window.gtag('event', 'organization', {
        organization: organizationData?.organization.name
      });
    }
  }, [organizationData]);

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash
    });
  }, [location]);

  return (
    <AnimatePresence>
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <Switch>
            <ProtectedRoute
              path="/"
              exact
              component={DashboardDefault}
              auth={authenticated}
              showRoute={true}>
              <Redirect to="/dashboard/default" />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/dashboard/default"
              component={DashboardDefault}
              auth={authenticated}
              showRoute={true}
            />
            <ProtectedRoute
              exact
              path="/dashboard/users"
              component={DashboardUsers}
              auth={authenticated}
              showRoute={showRoutes}
            />
            <ProtectedRoute
              path="/user/:userId"
              component={DashboardUserDetails}
              auth={authenticated}
              showRoute={true}
              exact
            />
            <ProtectedRoute
              path="/courses/:courseId"
              component={Course}
              auth={authenticated}
              showRoute={showRoutes}
              exact
            />
            <ProtectedRoute
              path="/training/:trainingId"
              component={DashboardTrainingDetails}
              auth={authenticated}
              showRoute={true}
              exact
            />
            <ProtectedRoute
              path="/admin"
              component={AdminPanel}
              auth={authenticated}
              showRoute={showRoutes}
              exact
            />
            <ProtectedRoute
              path="/admin/users"
              exact
              component={AdminUserManagement}
              showRoute={showRoutes}
              auth={authenticated}
            />
            <ProtectedRoute
              path="/admin/courses"
              exact
              component={AdminCourse}
              showRoute={showRoutes}
              auth={authenticated}
            />
            <ProtectedRoute
              path="/admin/trainings"
              exact
              component={AdminTraining}
              showRoute={showRoutes}
              auth={authenticated}
            />
            <ProtectedRoute
              path="/admin/quizzes"
              exact
              component={AdminQuizManagement}
              showRoute={showRoutes}
              auth={authenticated}
            />
            <ProtectedRoute
              path="/admin/texts"
              exact
              component={AdminTextManagement}
              showRoute={showRoutes}
              auth={authenticated}
            />
            <ProtectedRoute
              path="/admin/groups"
              exact
              component={AdminGroupManagement}
              showRoute={showRoutes}
              auth={authenticated}
            />
            <ProtectedRoute
              path="/admin/organization"
              exact
              component={AdminOrganizationManagement}
              showRoute={isSuperUser}
              auth={authenticated}
            />
            <ProtectedRoute
              path="/admin/languages"
              exact
              component={LanguagesManagement}
              showRoute={isSuperUser}
              auth={authenticated}
            />
            <ProtectedRoute
              path="/profile"
              exact
              component={UserSettings}
              showRoute={true}
              auth={authenticated}
            />
            <ProtectedRoute
              path={[
                '/profile/enrollment',
                '/profile/user',
                '/profile/change-password',
                '/profile/settings'
              ]}
              showRoute={true}
              auth={authenticated}>
              <UserSettings>
                <Switch>
                  <ProtectedRoute
                    path="/profile/enrollment"
                    exact
                    component={Enrollment}
                    showRoute={true}
                    auth={authenticated}
                  />
                  <ProtectedRoute
                    path="/profile/user"
                    exact
                    component={PersonalInformation}
                    showRoute={true}
                    auth={authenticated}
                  />
                  <ProtectedRoute
                    path="/profile/change-password"
                    exact
                    component={ChangePassword}
                    showRoute={true}
                    auth={authenticated}
                  />
                  <ProtectedRoute
                    path="/profile/settings"
                    exact
                    component={Settings}
                    showRoute={true}
                    auth={authenticated}
                  />
                </Switch>
              </UserSettings>
            </ProtectedRoute>
            <LoginViews>
              <Switch>
                <AuthRoute path="/login" component={Login} auth={authenticated} />
                <AuthRoute
                  path="/forgot-password"
                  component={ForgotPassword}
                  auth={authenticated}
                />
                <AuthRoute
                  path="/password-reset"
                  component={ResetUpdatePassword}
                  auth={authenticated}
                />
                <AuthRoute
                  path="/password-set"
                  component={ResetUpdatePassword}
                  auth={authenticated}
                />
              </Switch>
            </LoginViews>
          </Switch>
        </MainLayout>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
