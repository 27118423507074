import React, { useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { CloseOutlined, ExpandLess, ExpandMore } from '@material-ui/icons';

import './styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    listStyleType: 'none',
    padding: '0px'
  },
  overlay: {
    position: 'absolute',
    top: '0',
    left: 0,
    zIndex: '24',
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.secondary.main + 50
  },
  topBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '50px',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    color: theme.palette.background.paper
  },
  topBarLeft: {
    justifyContent: 'flex-end'
  },
  close: {
    backgroundColor: theme.palette.primary.light,
    height: '50px',
    width: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: '1.5em'
  },
  list: {
    position: 'absolute',
    zIndex: '24',
    width: '80vw',
    [theme.breakpoints.only('sm')]: {
      width: '60vw'
    },
    [theme.breakpoints.only('md')]: {
      width: '40vw'
    },
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    top: '0',
    padding: '50px 10px 100px 10px'
  },
  listItem: {
    borderRadius: '5px',
    padding: '0 10px',
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.background.default
    }
  },
  listItemGroup: {
    paddingLeft: '30px'
  },
  listItemText: {
    flex: '1 1 0%'
  },
  group: {
    display: 'flex',
    flexDirection: 'column'
  },
  groupTitle: {
    background: theme.palette.background.default,
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    opacity: 0.8,
    margin: '4px 0',
    padding: '16px 16px',
    cursor: 'pointer'
  },
  groupContent: {
    height: '200px',
    overflowY: 'auto',
    listStyleType: 'none',
    padding: '0px'
  },
  noData: {
    opacity: 0.5,
    padding: '0 20px',
    fontWeight: 500,
    fontStyle: 'italic'
  }
}));

const SlideOut = (props) => {
  const classes = useStyles();
  const [hoveredOption, setHoveredOption] = useState(null);
  const [openGroup, setOpenGroup] = useState([]);

  const handleSetOpenGroup = (id) => {
    let openGroupCopy = [...openGroup];
    if (openGroupCopy.includes(id)) openGroupCopy = openGroupCopy.filter((group) => group !== id);
    else openGroupCopy.push(id);
    setOpenGroup(openGroupCopy);
  };
  const menuContent = (
    <ul className={classes.root} id="nav_slide-out__list">
      {props.nav.map((nav, i) => {
        return nav.navItems ? (
          <li className={classes.group} key={i + nav.name}>
            <p onClick={() => handleSetOpenGroup(i)} className={classes.groupTitle}>
              {nav.name}
              {openGroup.includes(i) ? <ExpandLess /> : <ExpandMore />}
            </p>
            {openGroup.includes(i) &&
              (nav.navItems.length > 0 ? (
                <ul className={classes.groupContent}>
                  {nav.navItems.map((item, key) => {
                    return (
                      <li
                        key={key}
                        className={`${classes.listItem} ${classes.listItemGroup}`}
                        onClick={() => item.action(item)}>
                        <p>{item.name}</p>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className={classes.noData}>{nav.noData}</p>
              ))}
          </li>
        ) : (
          <li
            key={i + nav.name}
            onMouseEnter={() => setHoveredOption(i)}
            onMouseLeave={() => setHoveredOption(null)}
            onClick={() => nav.action(nav)}
            className={classes.listItem}>
            <p className={classes.listItemText}>{nav.name}</p>
            <div>{nav.hoverIcon && hoveredOption === i ? nav.hoverIcon : nav.icon}</div>
          </li>
        );
      })}
    </ul>
  );

  const topBar = (
    <Box
      id="nav_slide-out__close"
      className={`${classes.topBar} ${props.side === 'left' && classes.topBarLeft}`}>
      <Box onClick={() => props.handleClose()} className={classes.close}>
        <CloseOutlined />
      </Box>
    </Box>
  );

  return (
    <>
      {props.show && <Box className={classes.overlay} onClick={() => props.handleClose()} />}
      <nav
        id="nav_slide-out__left"
        className={`${classes.list} ${
          props.side === 'left' ? 'nav-custom-slideInLeft' : 'nav-custom-slideOutLeft'
        }`}>
        {topBar}
        {menuContent}
      </nav>
      <nav
        id="nav_slide-out__right"
        className={`${classes.list} ${
          props.side === 'right' ? 'nav-custom-slideInRight' : 'nav-custom-slideOutRight'
        }`}>
        {topBar}
        {menuContent}
      </nav>
    </>
  );
};

export default SlideOut;
