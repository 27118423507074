export const saveLastRowIndex = (index) => {
  window.localStorage.setItem('tableLastSelectedRowIndex', index);
};

export const scrollToRowIndex = () => {
  setTimeout(() => {
    const index = window.localStorage.getItem('tableLastSelectedRowIndex');

    if (index) {
      const element = `tr:nth-of-type(${index})`;

      if (document.querySelector(element)) {
        document.querySelector(element).scrollIntoView();
      }
    }
  }, 100);
};
