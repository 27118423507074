import React, { useEffect, useState } from 'react';
import { useCustomEventListener } from 'react-custom-events';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Box, Grid, makeStyles } from '@material-ui/core';
import SmallTitle from 'component/Titles/SmallTitle';
import ViewContainer from 'component/ViewContainer/ViewContainer';
import BasicWidget from 'component/Widgets/BasicWidget';
import { ON_ORGANIZATION_CHANGE } from 'constants';
import { USER_GROUPS } from 'constants/filters';
import { DASH_NAV_ID } from 'constants/paths';
import { setSelectedNavItem } from 'ducks/nav';
import ALL_USERS from 'graphql/AllUsers';
import { buildBreadcrumbs } from 'helpers/buildBreadcrumbs';
import { buildCourseAdminCourseList } from 'helpers/buildCourseUserRoleCourseList';
import { useUser } from 'hooks';
import { useOrganization } from 'hooks';
import { useTrainings } from 'hooks';
import LatestActivity from 'views/Dashboard/Default/LatestActivity';
import SessionPerTraining from 'views/Dashboard/Default/SessionsPerTraining';
import TimeSpent from 'views/Dashboard/Default/TimeSpent';
import TotalUsersPassed from 'views/Dashboard/Default/TotalUsersPassed';

import LastWeekActivity from './LastWeekActivity';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  },
  gridContainer: {
    alignContent: 'flex-start'
  },
  gridItem: {
    alignContent: 'flex-start',
    paddingTop: '16px',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '24px',
      paddingTop: '0px'
    }
  },
  right: {
    paddingRight: '0px !important'
  },
  rightSM: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px !important'
    }
  },
  rightXS: {
    [theme.breakpoints.only('xs')]: {
      paddingRight: '0px !important'
    }
  },
  rightMulti: {
    [theme.breakpoints.only('xs')]: {
      paddingRight: '0px !important'
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: '8px !important'
    },
    [theme.breakpoints.only('lg')]: {
      paddingRight: '0px !important'
    },
    [theme.breakpoints.only('xl')]: {
      paddingRight: '8px !important'
    }
  },
  trainingsTitle: {
    marginBottom: '-15px'
  },
  trainingsContainer: {
    height: '17.5rem',
    overflowY: 'auto',
    paddingLeft: '8px',
    marginBottom: '8px'
  },
  trainingsItem: {
    padding: '8px 16px 8px 0px !important',
    [theme.breakpoints.only('xs')]: {
      padding: '8px 0px !important'
    }
  },
  trainingsItemNoPadding: {
    padding: '8px 0px !important'
  }
}));

const Default = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { organizationData } = useOrganization();
  const { user, restrictedUser, isCourseAdmin } = useUser();
  const { getAllTrainings, loadingTrainings } = useTrainings();
  const [totalUsersFiltered, setTotalUsersFiltered] = useState(null);
  const [totalTrainingsFiltered, setTotalTrainingsFiltered] = useState(null);
  const [filteredBy, setFilteredBy] = useState(null);
  let totalUsers = null;

  let totalTrainings = 0;
  let totalCourses = null;

  let totalPracticeSessionsEachTraining = {};
  let totalAssessmentSessionsEachTraining = {};

  let trainingIds = [];

  let orgId = {
    orgId: Number(organizationData.organization.id)
  };
  let cohortId = {
    cohortId: Number(filteredBy?.id)
  };
  let trainingId = {
    trainingId: totalTrainingsFiltered && Number(totalTrainingsFiltered[0].id)
  };
  let userId = {
    userId: Number(user.id)
  };

  useEffect(() => {
    dispatch(setSelectedNavItem(DASH_NAV_ID));
    buildBreadcrumbs('clear');
    getAllTrainings({
      variables: {
        orgId: organizationData.organization.id
      }
    });
  }, []);

  useCustomEventListener(ON_ORGANIZATION_CHANGE, ({ organizationId }) => {
    getAllTrainings({
      variables: {
        orgId: organizationId
      }
    });
  });

  const {
    data: dataUsers,
    error: errorUsers,
    loading: loadingUsers
  } = useQuery(ALL_USERS, {
    variables: {
      orgId: Number(organizationData.organization.id)
    }
  });

  let filterOptions = [{ id: 'all', name: t('filters.allUsers') }];

  if (organizationData) {
    let courseData = buildCourseAdminCourseList(organizationData.organization, user, isCourseAdmin);

    if (dataUsers) {
      totalUsers = dataUsers.allUsers.length;
      totalCourses = courseData.length;

      /*** COURSE LAYER ***/
      courseData.map((course) => {
        totalTrainings += course.trainings.length;
        let totalAssessment = 0;
        /*** TRAININGS LAYER ***/
        course.trainings.map((training) => {
          trainingIds.push(training.id);
          let totalPractice = 0;
          totalAssessmentSessionsEachTraining[training.name] = totalAssessment;
          totalPracticeSessionsEachTraining[training.name] = totalPractice;
        });
      });
    }

    courseData.forEach((course) => {
      course.trainings.forEach((training) => {
        training.cohorts &&
          training.cohorts.forEach((cohort) => {
            if (!filterOptions.find((c) => c.id === cohort.id)) {
              filterOptions.push({
                id: cohort.id,
                name: cohort.name
              });
            }
          });
      });
    });
  }

  const setFilteredData = (users, trainings, filteredBy) => {
    setFilteredBy(filteredBy);
    if (filteredBy) {
      setTotalUsersFiltered(users);
      setTotalTrainingsFiltered(trainings);
    } else {
      setTotalUsersFiltered(null);
      setTotalTrainingsFiltered(null);
    }
  };

  let allTrainings = [];

  if (organizationData?.organization) {
    organizationData.organization.courses.forEach((course) => {
      course.trainings.forEach((training) => allTrainings.push(training));
    });
  }

  return (
    <ViewContainer
      title={t('dashboard.title')}
      loading={loadingUsers || loadingTrainings}
      flexView={true}
      error={errorUsers}
      filter={
        !restrictedUser && {
          filterType: USER_GROUPS,
          filterLocation: 'DASH',
          filterOptions,
          data: organizationData,
          setFilteredData: setFilteredData
        }
      }>
      <Box className={classes.root}>
        <Grid container item xs={12} lg={5} spacing={2} className={classes.gridContainer}>
          {restrictedUser ? (
            <>
              <Grid item xs={12} sm={6} lg={12} xl={6} className={classes.right}>
                <BasicWidget
                  title={t('dashboard.fullReport.title')}
                  hideValue={true}
                  link={'/user/' + user.id}
                  action={() =>
                    buildBreadcrumbs({
                      key: 'dash',
                      name: t('dashboard.title'),
                      viewTitle: user.username
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} className={classes.trainingsTitle}>
                <SmallTitle title={t('dashboard.trainingsCard.title')} />
              </Grid>
              <Grid container spacing={0} xs={12} className={classes.trainingsContainer}>
                {user?.trainings ? (
                  user.trainings.map((training, i) => {
                    return (
                      <Grid
                        key={i + training.id}
                        item
                        xs={12}
                        sm={6}
                        className={
                          i % 2 === 0 ? classes.trainingsItem : classes.trainingsItemNoPadding
                        }>
                        <BasicWidget
                          height="100px"
                          title={training.name}
                          hideValue={true}
                          link={false}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item xs={12} sm={6} lg={12} xl={6} className={classes.right}>
                    <BasicWidget
                      title={t('dashboard.noTrainingData')}
                      hideValue={true}
                      noData={true}
                      link={false}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} className={classes.right}>
                <TimeSpent
                  height="18rem"
                  filteredBy={filteredBy}
                  trainings={totalTrainingsFiltered}
                  userId={userId}
                  restrictedUser={restrictedUser}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item sm={6} xs={12} className={classes.rightXS}>
                <BasicWidget
                  title={t('dashboard.usersCard.title')}
                  value={totalUsersFiltered ? totalUsersFiltered : totalUsers}
                  link="/dashboard/users"
                />
              </Grid>
              <Grid item sm={6} xs={12} className={classes.right}>
                <BasicWidget
                  title={t('dashboard.trainingsCard.title')}
                  value={totalTrainingsFiltered ? totalTrainingsFiltered.length : totalTrainings}
                  subText={t('dashboard.trainingsCard.subText', {
                    count: totalCourses ? totalCourses : 0
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={12} xl={6} className={classes.rightMulti}>
                <LastWeekActivity
                  restrictedUser={restrictedUser}
                  filteredBy={filteredBy}
                  orgId={orgId}
                  cohortId={cohortId}
                  trainingId={trainingId}
                  userId={userId}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={restrictedUser ? 12 : 6}
                lg={12}
                xl={restrictedUser ? 12 : 6}
                className={classes.right}>
                <TimeSpent
                  filteredBy={filteredBy}
                  trainings={totalTrainingsFiltered}
                  orgId={orgId}
                  cohortId={cohortId}
                  userId={userId}
                  restrictedUser={restrictedUser}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={12}
          lg={7}
          spacing={2}
          className={classes.gridContainer + ' ' + classes.gridItem}>
          <Grid
            item
            md={6}
            sm={restrictedUser ? 12 : 6}
            xs={12}
            className={restrictedUser ? classes.rightSM : classes.rightXS}>
            <SessionPerTraining
              filteredBy={filteredBy}
              orgId={orgId}
              cohortId={cohortId}
              userId={userId}
              restrictedUser={restrictedUser}
            />
          </Grid>
          {restrictedUser ? (
            <Grid item md={6} sm={restrictedUser ? 12 : 6} xs={12} className={classes.right}>
              <LastWeekActivity
                restrictedUser={restrictedUser}
                height="11rem"
                userId={userId}
                cohortId={cohortId}
                trainingId={trainingId}
              />
            </Grid>
          ) : (
            <Grid item sm={6} xs={12} className={classes.right}>
              <TotalUsersPassed
                filteredBy={filteredBy}
                cohortId={cohortId}
                trainingIds={trainingIds}
                allTrainings={allTrainings}
              />
            </Grid>
          )}
          <Grid item xs={12} className={classes.right}>
            <LatestActivity
              filteredBy={filteredBy}
              orgId={orgId}
              cohortId={cohortId}
              userId={userId}
              restrictedUser={restrictedUser}
            />
          </Grid>
        </Grid>
      </Box>
    </ViewContainer>
  );
};

export default Default;
