import 'moment-timezone';

import { TIME_ZONES } from '../constants/timeZones';

export const buildTimeZones = () => {
  const moment = require('moment-timezone');
  return Object.keys(TIME_ZONES).map((zone) => {
    return { id: zone, name: 'GMT' + moment().tz(zone).format('Z') + ' ' + TIME_ZONES[zone] };
  });
};
