import React, { useEffect, useMemo, useState } from 'react';
import { emitCustomEvent } from 'react-custom-events';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import GreyFlag from 'assets/images/icons/GreyFlag';
import SwedenFlag from 'assets/images/icons/SwedenFlag';
import UkFlag from 'assets/images/icons/UkFlag';
import { ON_LANGUAGE_CHANGE } from 'constants';
import { useUser } from 'hooks';
import { useLanguages } from 'hooks';

import NavDropdown from '../NavComponents/NavDropdown';

const iconMatchFlag = ({ code }) => {
  const flags = {
    en: <UkFlag />,
    sv: <SwedenFlag />
  };
  return flags[code] ? flags[code] : <GreyFlag />;
};

const Language = (props) => {
  const { i18n } = useTranslation();
  const { user } = useUser();

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    i18n.changeLanguage(item.value);
    emitCustomEvent(ON_LANGUAGE_CHANGE);
    sessionStorage.setItem('manualLang', item.value);
  };

  const defaultLanguageItem = {
    icon: iconMatchFlag({ code: 'en' }),
    value: 'en',
    name: 'English',
    id: '1',
    action: handleSelectItem
  };

  const [selectedItem, setSelectedItem] = useState(defaultLanguageItem);

  const { languages, appLanguages, getAvailableLanguages } = useLanguages();

  useEffect(() => {
    if (languages.length === 0) {
      getAvailableLanguages();
    }
  }, [languages, getAvailableLanguages]);

  useEffect(() => {
    let language;
    if (
      window.location.href.indexOf('login') !== -1 ||
      window.location.href.indexOf('forgot-password') !== -1 ||
      window.location.href.indexOf('password-reset') !== -1 ||
      window.location.href.indexOf('password-set') !== -1
    ) {
      if (window.sessionStorage.getItem('manualLang')) {
        language = dropdownLanguageItems.find(
          (lang) => lang.value === sessionStorage.getItem('manualLang')
        );
      } else if (window.localStorage.getItem('lng')) {
        language = dropdownLanguageItems.find((lang) => lang.value === localStorage.getItem('lng'));
      } else if (window.localStorage.getItem('i18nextLng')) {
        language = dropdownLanguageItems.find(
          (lang) => lang.value === localStorage.getItem('i18nextLng').split('-')[0]
        );
      }
      if (typeof language === 'undefined') {
        language = defaultLanguageItem;
      }
      setSelectedItem(language);
    }
  }, []);

  useEffect(() => {
    if (user) {
      let languageFromProfile;
      if (sessionStorage.getItem('manualLang')) {
        languageFromProfile = dropdownLanguageItems.find(
          (lang) => lang.value === sessionStorage.getItem('manualLang')
        );
        setSelectedItem(languageFromProfile);
      } else {
        if (user.profile?.preferredLanguage?.code) {
          languageFromProfile = dropdownLanguageItems.find(
            (lang) => lang.value === user.profile.preferredLanguage.code
          );
        } else {
          if (localStorage.getItem('i18nextLng')) {
            languageFromProfile = dropdownLanguageItems.find(
              (lang) => lang.value === localStorage.getItem('i18nextLng').split('-')[0]
            );
          }
        }
        if (typeof language === 'undefined') {
          languageFromProfile = defaultLanguageItem;
        }
        setSelectedItem(languageFromProfile);
      }
    }
  }, [user]);

  const dropdownLanguageItems = useMemo(() => {
    return appLanguages.map((language) => {
      return {
        icon: iconMatchFlag({ code: language.code }),
        value: language.code,
        name: language.name,
        id: language.id,
        action: handleSelectItem
      };
    });
  }, [appLanguages]);

  return selectedItem ? (
    <Box
      style={{ cursor: 'pointer' }}
      onClick={() => props.collapse && props.handleShowNavSlideOut(dropdownLanguageItems, 'right')}>
      <NavDropdown collapse={props.collapse} nav={dropdownLanguageItems}>
        {selectedItem.icon}
      </NavDropdown>
    </Box>
  ) : (
    <GreyFlag />
  );
};

export default Language;
