import React, { useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteForeverRounded, EditRounded } from '@material-ui/icons';

import './styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid ' + theme.palette.grey['200'],
    position: 'relative',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    height: '8rem',
    [theme.breakpoints.down('md')]: {
      height: '6rem'
    },
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.palette.secondary.main + 20 + ' 0px 0px 20px'
    }
  },
  actionsContainer: {
    borderRadius: '0 5px 5px 0',
    height: '100%',
    width: '65px',
    position: 'relative',
    overflow: 'hidden'
  },
  actionsSlideOut: {
    backgroundColor: theme.palette.primary.light,
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  actionsEdit: {
    flex: '1 1 0%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter
    }
  },
  actionsDelete: {
    flex: '1 1 0%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light
    }
  },
  content: {
    padding: '30px',
    display: 'flex',
    height: '100%',
    flex: '1 1 0%',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  title: {
    fontWeight: 500,
    flex: '1 1 0%',
    fontSize: '1.6rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.3rem'
    },
    color: theme.palette.background.paper
  },
  description: {
    color: theme.palette.background.paper
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  }
}));

const CourseWidget = (props) => {
  const theme = useTheme();
  const largeScreens = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles(props);
  const [showActions, setShowActions] = useState(false);

  const title = props.title?.length > 25 ? props.title.substring(0, 22) + '...' : props.title;
  const description =
    props.description?.length > 55 ? props.description.substring(0, 52) + '...' : props.description;

  return (
    <Box
      className={classes.root}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}>
      <Box
        className={` ${
          showActions ? 'course-widget-custom-fadeIn' : 'course-widget-custom-fadeOut'
        } ${classes.overlay}`}
      />
      <Box className={classes.content}>
        <Box className={classes.title}>{title}</Box>
        <Box className={classes.description}>
          {largeScreens ? props.description && description : null}
        </Box>
      </Box>

      <Box className={classes.actionsContainer}>
        <Box
          className={` ${
            showActions ? 'course-widget-custom-slideInRight' : 'course-widget-custom-slideOutRight'
          } ${classes.actionsSlideOut}`}>
          <Box className={classes.actionsEdit} onClick={props.actionEdit}>
            <EditRounded />
          </Box>
          <Box className={classes.actionsDelete} onClick={props.actionDelete}>
            <DeleteForeverRounded />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CourseWidget;
