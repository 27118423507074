import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ModalWrapper from '../ModalWrapper';

const useStyles = makeStyles(() => ({
  warningText: {
    color: '#E54C41',
    fontSize: '0.8rem',
    fontWeight: '300',
    marginTop: '15px'
  }
}));

const WarningModal = (props) => {
  const { t } = useTranslation();
  const { message, ...rest } = props;
  const classes = useStyles(props);

  return (
    <ModalWrapper
      type="warning"
      icon="warning"
      height="auto"
      width="400px"
      buttonText={t('modals.button.textYes')}
      {...rest}>
      <Box>{message}</Box>
      <Box className={classes.warningText}>{t('modals.warning')}</Box>
    </ModalWrapper>
  );
};

export default WarningModal;

ModalWrapper.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  icon: PropTypes.string,
  handleCloseModal: PropTypes.func,
  handleDone: PropTypes.func,
  saving: PropTypes.bool,
  loading: PropTypes.bool,
  response: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      message: PropTypes.string,
      responseType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    })
  ]),
  buttonText: PropTypes.string,
  customActionButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};
