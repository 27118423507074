import React, { createContext, useCallback, useContext } from 'react';
import cookie from 'react-cookies';
import { useHistory } from 'react-router';
import { useAuth } from 'hooks';

const LogoutApi = createContext(false);

export const LogoutProvider = ({ children }) => {
  const { setAuthenticated } = useAuth();
  let history = useHistory();

  const logout = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    cookie.remove('token');
    cookie.remove('refreshToken');
    setAuthenticated(false);
    if (
      window.location.href.indexOf('login') === -1 &&
      window.location.href.indexOf('password-reset') === -1 &&
      window.location.href.indexOf('forgot-password') === -1 &&
      window.location.href.indexOf('password-reset') === -1 &&
      window.location.href.indexOf('password-set') === -1
    ) {
      setAuthenticated(false);
      history.push({ pathname: '/login' });
    }
  }, []);

  return <LogoutApi.Provider value={{ logout }}>{children}</LogoutApi.Provider>;
};

export const useLogout = () => useContext(LogoutApi);
