import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import ChartWidget from 'component/Widgets/ChartWidget/';
import WeekCarousel from 'component/Widgets/CustomHeaders/WeekCarousel';
import { ASSESSMENT_COLOR, PRACTICE_COLOR } from 'constants/colors';
import { AVG_TIME_PER_WEEKDAY } from 'graphql/UserTrainingsInfo';
import { useOrganization } from 'hooks';
import { useDateFormat } from 'hooks/useDateFormat';
import moment from 'moment-timezone';

const WeekdayChart = (props) => {
  const { organizationData } = useOrganization();

  const { t } = useTranslation();
  const [dataIndex, setDataIndex] = useState(null);
  const [lastDataIndex, setLastDataIndex] = useState(null);
  const [data, setData] = useState();
  const [series, setSeries] = useState();
  const { handleDateFormat } = useDateFormat();
  const [getAvgChartData, { loading: avgChartDataLoading }] = useLazyQuery(AVG_TIME_PER_WEEKDAY, {
    fetchPolicy: 'no-cache',
    onCompleted(avgChartData) {
      let index = dataIndex;
      if (!index) {
        index = avgChartData.averageTimePerWeekday[0].data.length - 1;
        setDataIndex(index);
        setLastDataIndex(avgChartData.averageTimePerWeekday[0].data.length - 1);
      }
      setData(avgChartData.averageTimePerWeekday);
      setSeries([
        {
          name: props.user.username,
          data: avgChartData.averageTimePerWeekday[0].data[index].timeSpent
        },
        {
          name: t('userDetails.weeklyActivityCard.allUsers'),
          data: avgChartData.averageTimePerWeekday[1].data[index].timeSpent
        }
      ]);
    }
  });

  const queryVariables = {
    start: moment().subtract(3, 'months').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    userId: parseInt(props.user.id),
    category: props.selectedSwitch,
    orgId:
      props.selectedFilter.course.length < 1 && props.selectedFilter.training.length < 1
        ? parseInt(organizationData.organization.id)
        : null,
    courseId: props.selectedFilter.course.length > 0 ? props.selectedFilter.course : null,
    trainingId: props.selectedFilter.training.length > 0 ? props.selectedFilter.training : null
  };

  useEffect(() => {
    getAvgChartData({ variables: queryVariables });
  }, []);

  useEffect(() => {
    getAvgChartData({ variables: queryVariables });
  }, [props.selectedFilter, props.selectedSwitch]);

  const onClick = (value) => {
    let dataIndexCopy = dataIndex;
    if (value === 'left' && dataIndexCopy !== 0) dataIndexCopy--;
    else if (value === 'right' && dataIndexCopy !== lastDataIndex) dataIndexCopy++;
    setDataIndex(dataIndexCopy);
    setSeries([
      {
        name: props.user.username,
        data: data[0].data[dataIndexCopy].timeSpent
      },
      {
        name: t('userDetails.weeklyActivityCard.allUsers'),
        data: data[1].data[dataIndexCopy].timeSpent
      }
    ]);
  };

  let seriesColors = [ASSESSMENT_COLOR, ASSESSMENT_COLOR + 60];
  if (props.selectedSwitch === 'practice') {
    seriesColors = [PRACTICE_COLOR, PRACTICE_COLOR + 70];
  }
  const categories = [
    t('charts.weekDays.mon'),
    t('charts.weekDays.tue'),
    t('charts.weekDays.wed'),
    t('charts.weekDays.thur'),
    t('charts.weekDays.fri'),
    t('charts.weekDays.sat'),
    t('charts.weekDays.sun')
  ];

  let hasData = false;
  let customHeader;

  if (series) {
    if (series[0].data.find((time) => time > 0) || series[1].data.find((time) => time > 0))
      hasData = true;
    customHeader = (
      <WeekCarousel
        onClick={onClick}
        start={moment(data[0].data[dataIndex].start).format(handleDateFormat(false))}
        end={moment(data[0].data[dataIndex].end).format(handleDateFormat(false))}
        currentIndex={dataIndex}
        lastIndex={lastDataIndex}
      />
    );
  }

  return (
    <Grid item xs={12} lg={6} xl={7} style={{ flex: '1 1 0%' }}>
      <ChartWidget
        height="21.5rem"
        widgetTitle={t('userDetails.weeklyActivityCard.title')}
        customHeader={customHeader}
        chartType="bar"
        series={series}
        categories={categories}
        loading={!series || avgChartDataLoading}
        noDataMessage={!hasData ? t('userDetails.weeklyActivityCard.noData') : ''}
        yUnits={{ title: t('sharedLabels.minutes') }}
        colors={seriesColors}
      />
    </Grid>
  );
};

export default WeekdayChart;
