import { useEffect } from 'react';
import { toast } from 'react-toastify';

const Feedback = (props) => {
  useEffect(() => {
    if (props.feedback) {
      if (props.feedback.type === 'success') {
        toast.success(props.feedback.message);
      }
      if (props.feedback.type === 'error') {
        toast.error(props.feedback.message);
      }
    }
  }, [props]);

  return null;
};

export default Feedback;
