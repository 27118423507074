import { gql } from '@apollo/client';

const IMPORT_LABEL_CSV = gql`
  mutation importLabel($trainingId: Int!, $labelCsv: Upload, $isOverwrite: Boolean) {
    importLabel(trainingId: $trainingId, labelCsv: $labelCsv, isOverwrite: $isOverwrite) {
      success
      errors
    }
  }
`;

export default IMPORT_LABEL_CSV;
