import React from 'react';

export const UserGroupIconBlue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.209"
      height="20.626"
      viewBox="0 0 19.209 17.626">
      <g id="userGroupBlack" transform="translate(-33.536 -111.187)">
        <g id="personBlack" transform="translate(0 48)">
          <path
            id="Path_34"
            data-name="Path 34"
            d="M1067.056,134.868a3.8,3.8,0,1,1,2.682-1.113A3.8,3.8,0,0,1,1067.056,134.868Zm0-6.183a2.395,2.395,0,1,0,1.689.7A2.393,2.393,0,0,0,1067.056,128.685Z"
            transform="translate(-1027.414 -60.092)"
            fill="#2e3c77"
          />
          <path
            id="Path_35"
            data-name="Path 35"
            d="M1034.132,253.685a.7.7,0,0,1-.7-.7,4.7,4.7,0,1,0-9.405,0,.7.7,0,1,1-1.4,0,6.1,6.1,0,1,1,12.208,0,.7.7,0,0,1-.7.7Z"
            transform="translate(-989.088 -172.892)"
            fill="#2e3c77"
          />
        </g>
        <g id="personBlack-2" data-name="personBlack" transform="translate(7 44)">
          <path
            id="Path_34-2"
            data-name="Path 34"
            d="M1067.056,134.868a3.8,3.8,0,1,1,2.682-1.113A3.8,3.8,0,0,1,1067.056,134.868Zm0-6.183a2.395,2.395,0,1,0,1.689.7A2.393,2.393,0,0,0,1067.056,128.685Z"
            transform="translate(-1027.414 -60.092)"
            fill="#2e3c77"
          />
          <path
            id="Path_35-2"
            data-name="Path 35"
            d="M1034.132,253.685a.7.7,0,0,1-.7-.7,4.7,4.7,0,0,0-4.7-4.7,5.626,5.626,0,0,0-2.21.386,3.342,3.342,0,0,0-1.621,1.075c-.611.729-.526-.752-.236-1,.576-1.343,2.571-1.862,4.068-1.862a6.1,6.1,0,0,1,6.1,6.1.7.7,0,0,1-.7.7Z"
            transform="translate(-989.088 -172.892)"
            fill="#2e3c77"
          />
        </g>
      </g>
    </svg>
  );
};

export const UserGroupIconBlack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.209"
      height="20.626"
      viewBox="0 0 19.209 17.626">
      <g id="userGroupBlack" transform="translate(-33.536 -111.187)">
        <g id="personBlack" transform="translate(0 48)">
          <path
            id="Path_34"
            data-name="Path 34"
            d="M1067.056,134.868a3.8,3.8,0,1,1,2.682-1.113A3.8,3.8,0,0,1,1067.056,134.868Zm0-6.183a2.395,2.395,0,1,0,1.689.7A2.393,2.393,0,0,0,1067.056,128.685Z"
            transform="translate(-1027.414 -60.092)"
            fill="#231f20"
          />
          <path
            id="Path_35"
            data-name="Path 35"
            d="M1034.132,253.685a.7.7,0,0,1-.7-.7,4.7,4.7,0,1,0-9.405,0,.7.7,0,1,1-1.4,0,6.1,6.1,0,1,1,12.208,0,.7.7,0,0,1-.7.7Z"
            transform="translate(-989.088 -172.892)"
            fill="#231f20"
          />
        </g>
        <g id="personBlack-2" data-name="personBlack" transform="translate(7 44)">
          <path
            id="Path_34-2"
            data-name="Path 34"
            d="M1067.056,134.868a3.8,3.8,0,1,1,2.682-1.113A3.8,3.8,0,0,1,1067.056,134.868Zm0-6.183a2.395,2.395,0,1,0,1.689.7A2.393,2.393,0,0,0,1067.056,128.685Z"
            transform="translate(-1027.414 -60.092)"
            fill="#231f20"
          />
          <path
            id="Path_35-2"
            data-name="Path 35"
            d="M1034.132,253.685a.7.7,0,0,1-.7-.7,4.7,4.7,0,0,0-4.7-4.7,5.626,5.626,0,0,0-2.21.386,3.342,3.342,0,0,0-1.621,1.075c-.611.729-.526-.752-.236-1,.576-1.343,2.571-1.862,4.068-1.862a6.1,6.1,0,0,1,6.1,6.1.7.7,0,0,1-.7.7Z"
            transform="translate(-989.088 -172.892)"
            fill="#231f20"
          />
        </g>
      </g>
    </svg>
  );
};
