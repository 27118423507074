import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import ViewContainer from 'component/ViewContainer/ViewContainer';
import { USER_DETAILS } from 'constants/filters';
import { gridSpacing } from 'ducks/constant';
import { setTableFilters, setViewFilters } from 'ducks/userDetails';
import { USER_SELF_TRAININGS_INFO, USER_TRAININGS_INFO } from 'graphql/UserTrainingsInfo';
import { useTrainings, useUser } from 'hooks';
import SessionsModal from 'views/Dashboard/UserDetails/SessionsModal';
import TotalSessionsTime from 'views/Dashboard/UserDetails/TotalSessionsTime';

import buildPerformance from './helpers/buildPerformance';
import buildUserData from './helpers/buildUserData';
import filterData from './helpers/filterData';
import LastVrActivity from './LastVrActivity';
import Progress from './Progress';
import SessionsPerformance from './SessionsPerformance';
import SessionsTable from './SessionsTable';
import WeekdayChart from './WeekdayChart';

const UserDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userDetailsViewFilters } = useSelector((state) => state.userDetails);
  const { loadingTrainings } = useTrainings();
  const { user, restrictedUser } = useUser();
  const [lazyLoading, setLazyLoading] = useState(true);
  const [showSessionsModal, setShowSessionsModal] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const userId = useParams().userId;

  const [getUserSelfInfo, { data: dataUserSelf, error: errorUserSelf, loading: loadingUserSelf }] =
    useLazyQuery(USER_SELF_TRAININGS_INFO, {
      variables: {
        userId: Number(user.id)
      },
      onCompleted() {
        setLazyLoading(false);
      },
      fetchPolicy: 'no-cache'
    });

  const [getUser, { data, error, loading }] = useLazyQuery(USER_TRAININGS_INFO, {
    variables: {
      userId: Number(userId)
    },
    onCompleted() {
      setLazyLoading(false);
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (restrictedUser) {
      getUserSelfInfo();
    } else {
      getUser();
    }
    return () => {
      if (window.location.href.indexOf('session') === -1) {
        dispatch(
          setTableFilters({
            startDate: { name: 'All', id: 'all' },
            completionDate: { name: 'All', id: 'all' },
            performance: { name: 'All', id: 'all' }
          })
        );
        dispatch(
          setViewFilters({
            course: '',
            training: '',
            filter: '',
            selectedSwitch: 'assessment'
          })
        );
      }
    };
  }, []);

  let userData = null;

  if (data) {
    userData = data.getUser;
  }

  if (dataUserSelf) {
    userData = dataUserSelf.getUserSelfInfo;
  }

  const setSelectedFilters = (filter, type) => {
    if (type === 'switch') {
      handleSetSelectedSwitch(filter);
    } else {
      if (filter.target.name === 'course') {
        handleSetFilters({
          ...userDetailsViewFilters,
          course: filter.target.value,
          training: '',
          filter: 'course'
        });
      } else {
        handleSetFilters({
          ...userDetailsViewFilters,
          training: filter.target.value,
          filter: 'training'
        });
      }
    }
  };

  const handleSetFilters = (filters) => {
    dispatch(setViewFilters(filters));
  };

  const handleSetSelectedSwitch = (selected) => {
    handleSetFilters({
      ...userDetailsViewFilters,
      selectedSwitch: selected
    });
  };

  const handleWidgetColor = (color) => {
    if (userDetailsViewFilters.selectedSwitch === 'assessment') return color;
    else return '#BEBEBE';
  };

  const loadingData = lazyLoading || loading || loadingUserSelf || loadingTrainings;
  const parsedUser = buildUserData(userData);
  const filteredTrainings = filterData(userData, parsedUser, userDetailsViewFilters);
  const performanceData = buildPerformance(
    filteredTrainings,
    userDetailsViewFilters.selectedSwitch
  );
  const title = !loadingData && userData ? userData.firstName + ' ' + userData.lastName : '';

  let trainingList = [];
  if (userData && userDetailsViewFilters?.course.length > 0) {
    userData.courses
      .find((course) => course.id === userDetailsViewFilters.course)
      .trainings.forEach((training) => {
        userData.trainings.forEach((t) => {
          if (t.id === training.id) trainingList.push(training);
        });
      });
  }

  const filterOptions = {
    courses: {
      options: userData ? userData.courses : [],
      name: 'course',
      filterTitle: t('sharedLabels.courses')
    },
    trainings: {
      options: userDetailsViewFilters
        ? userDetailsViewFilters.course.length > 0
          ? trainingList
          : userData
          ? userData.trainings
          : []
        : [],
      name: 'training',
      filterTitle: t('sharedLabels.trainings')
    },
    assessmentSwitch: {
      options: [
        { name: t('sharedLabels.assessment'), id: 'assessment' },
        { name: t('sharedLabels.practice'), id: 'practice' }
      ],
      name: 'selectedSwitch',
      filterTitle: t('sharedLabels.assessment') + ' / ' + t('sharedLabels.practice')
    }
  };

  return (
    <ViewContainer
      title={!loadingData && userData?.username}
      subTitle={title}
      loading={loadingData}
      error={error || errorUserSelf}
      filter={{
        filterType: USER_DETAILS,
        filterOptions,
        selectedFilter: userDetailsViewFilters,
        setFilteredData: setSelectedFilters
      }}>
      {showSessionsModal && (
        <SessionsModal
          sessionId={sessionId}
          handleCloseModal={() => {
            setShowSessionsModal(false);
            setSessionId(null);
          }}
        />
      )}
      <Grid container spacing={gridSpacing}>
        <Grid item container xs={12} lg={6} xl={5}>
          <Grid container spacing={gridSpacing}>
            <Progress
              userDetailsViewFilters={userDetailsViewFilters}
              performance={performanceData}
              widgetColor={handleWidgetColor}
            />
            <LastVrActivity
              data={filteredTrainings}
              date={userData?.trainee?.lastCreatedSession}
              widgetColor={handleWidgetColor}
              selectedSwitch={userDetailsViewFilters.selectedSwitch}
            />
            <TotalSessionsTime
              filteredTrainings={filteredTrainings}
              selectedSwitch={userDetailsViewFilters.selectedSwitch}
            />
            <SessionsPerformance performance={performanceData} widgetColor={handleWidgetColor} />
          </Grid>
        </Grid>
        <WeekdayChart
          user={userData}
          selectedSwitch={userDetailsViewFilters.selectedSwitch}
          selectedFilter={userDetailsViewFilters}
        />
        <SessionsTable
          setSessionId={setSessionId}
          setShowSessionsModal={setShowSessionsModal}
          filteredTrainings={filteredTrainings}
          selectedFilter={userDetailsViewFilters}
          selectedSwitch={userDetailsViewFilters.selectedSwitch}
        />
      </Grid>
    </ViewContainer>
  );
};

export default UserDetails;
