import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TotalTimeSpent from 'component/Widgets/TotalTimeWidget';
import { useUser } from 'hooks';
import buildTableData from 'views/Dashboard/TrainingDetails/helpers/buildTableData';

const TotalSessionsTime = (props) => {
  const { t } = useTranslation();
  const { isSuperUser } = useUser();
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (props.data) {
      let totalTime = 0;
      const data = buildTableData(props.data.training, isSuperUser);
      data.forEach((user) => {
        return (totalTime += user.totalTime);
      });
      setDuration(totalTime);
    }
  }, [props.data]);

  return <TotalTimeSpent title={t('trainingDetails.totalTimeSpent.title')} timeSpent={duration} />;
};

export default TotalSessionsTime;
