import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import BasicModal from 'component/Modals/BasicModal';
import { useUsers } from 'hooks';

const UserArchiveModal = (props) => {
  const { t } = useTranslation();
  const { archiveUsers, loadingArchiveUserRequest } = useUsers();

  const onHandleDone = useCallback(() => {
    const usernames = props.users.map((user) => user.username);
    archiveUsers({ usernames });
  }, [archiveUsers, props]);

  return (
    <BasicModal
      width="45rem"
      icon={'add'}
      title={t('userManagement.archive')}
      buttonText={t('userManagement.archive')}
      type="warning"
      handleCloseModal={() => props.handleCloseModal()}
      handleDone={onHandleDone}
      saving={props.saving}
      disabled={loadingArchiveUserRequest}
      response={props.response}>
      <p>{t('userManagement.archiveQuestion')}</p>
      <table>
        <thead>
          <td>{t('sharedLabels.username')}</td>
          <td>{t('sharedLabels.email')}</td>
        </thead>
        <tbody>
          {props.users.map(({ id, username, email }) => (
            <tr key={id}>
              <td>{username}</td>
              <td>{email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </BasicModal>
  );
};

export default UserArchiveModal;
