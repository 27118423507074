import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmButton from 'component/Buttons/ConfirmButton';
import Filter from 'component/ViewFilters';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Breadcrumbs from './Breadcrumbs';
import Feedback from './Feedback';
import Loader from './Loader';

export const ButtonGroupSpacer = styled.div`
  button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0px;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: (props) => (props.hidePadding ? '' : '15px'),
    [theme.breakpoints.up('sm')]: {
      padding: (props) => (props.hidePadding ? '' : '40px')
    }
  },
  flexView: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column'
  },
  flexChild: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column'
  },
  titleContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  breadCrumbsContainer: {
    fontWeight: 500,
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: (props) => (props.filter ? '60px 0 10px 0' : '60px 0 30px 0')
    },
    [theme.breakpoints.up('md')]: {
      padding: '50px 0 45px 0'
    },
    width: '100%'
  },
  actionContainer: {
    padding: '30px 0px 30px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.only('xl')]: {
      width: '50%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '70%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: '0px'
    }
  },
  button: {
    alignSelf: 'center'
  }
}));

const ViewContainer = (props) => {
  const classes = useStyles(props);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    if (props.error) {
      if (props.error !== 'User is not authenticated') {
        handleSetFeedback({
          message: props.error.toString(),
          type: 'error'
        });
      }
    }
  }, [props.error]);

  useEffect(() => {
    if (props.feedback && (props.feedback.length > 0 || Object.keys(props.feedback).length > 0)) {
      handleSetFeedback(props.feedback);
    }
  }, [props.feedback]);

  useEffect(() => {
    if (showFeedback) {
      setTimeout(() => {
        clearFeedback();
      }, 3000);
    }
  }, [showFeedback]);

  const handleSetFeedback = (obj) => {
    setShowFeedback(true);
    setFeedback(obj);
  };

  const clearFeedback = () => {
    props.clearFeedback && props.clearFeedback();
    setShowFeedback(false);
    setFeedback(null);
  };

  return (
    <Box className={`${(loading || props.flexView) && classes.flexView} ${classes.root}`}>
      {!props.hideTitle && (
        <Box className={classes.titleContainer}>
          <Box className={classes.breadCrumbsContainer}>
            <Breadcrumbs loading={props.loading} title={props.title} subTitle={props.subTitle} />
          </Box>
          {!props.loading && props.filter && (
            <Box className={classes.actionContainer}>
              <Filter
                filterType={props.filter.filterType}
                filterOptions={props.filter.filterOptions}
                setFilteredData={props.filter.setFilteredData}
                filterLocation={props.filter.filterLocation}
                data={props.filter.data}
                selectedFilter={props.filter.selectedFilter}
                loading={loading}
              />
            </Box>
          )}
          {props.actionButton ? (
            <Box className={classes.actionContainer}>
              <Box className={classes.button}>
                <ConfirmButton
                  disabled={props.loading || props.actionButtonLoading}
                  size="medium"
                  onClick={props.actionButton}>
                  {props.actionButtonLabel}
                </ConfirmButton>
              </Box>
            </Box>
          ) : null}
          {props.actionButtons ? (
            <Box className={classes.actionContainer}>
              <Box className={classes.button}>{props.actionButtons}</Box>
            </Box>
          ) : null}
        </Box>
      )}
      {props.error ? null : loading ? (
        <Loader />
      ) : (
        <Box className={props.flexView && classes.flexChild}>{props.children}</Box>
      )}
      {showFeedback && <Feedback onClose={clearFeedback} feedback={feedback} />}
    </Box>
  );
};

export default ViewContainer;

ViewContainer.propTypes = {
  flexView: PropTypes.bool,
  removePadding: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
