import { gql } from '@apollo/client';

const SESSION_INFO = gql`
  query getSessionInfo($sessionId: Int!) {
    session(id: $sessionId) {
      id
      name
      category
      createdAt
      completedAt
      user {
        id
        username
      }
      training {
        id
        name
      }
      eventSet {
        id
        category
        description
        object {
          id
          name
        }
        errorSeverity
        performedAt
      }
    }
  }
`;

export default SESSION_INFO;
