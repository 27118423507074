import React from 'react';
import { useTranslation } from 'react-i18next';
import RowButton from 'component/Table/RowButton';
import { default as TableComponent } from 'component/Table/Table';
import { useLanguages } from 'hooks';
import styled from 'styled-components';

const WrapOption = styled.div`
  display: flex;
`;

const StyledButton = styled.div`
  margin-right: 20px;
`;

const Table = ({ onEditLanguage }) => {
  const { t } = useTranslation();
  const { loading, deleteLanguage, languages } = useLanguages();

  const onDeleteLanguage = (id) => deleteLanguage(id);

  const tableConfig = {
    title: '',
    print: false,
    viewColumns: false,
    search: true,
    download: false,
    filter: false,
    rowData: languages,
    columns: [
      {
        name: 'id'
      },
      {
        name: 'code'
      },
      {
        name: 'name'
      },
      {
        name: 'id',
        options: {
          filter: false,
          customBodyRender: (value) => {
            const id = value;
            return (
              <WrapOption>
                <StyledButton>
                  <RowButton
                    disable={loading}
                    onClick={() => onDeleteLanguage(id)}
                    label={t('languagesManagement.button.delete')}
                  />
                </StyledButton>
                <RowButton
                  disable={loading}
                  onClick={() => onEditLanguage({ id })}
                  label={t('languagesManagement.button.edit')}
                />
              </WrapOption>
            );
          }
        }
      }
    ],
    flexView: true
  };

  return <TableComponent tableConfig={tableConfig} />;
};

export default Table;
