import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Tooltip as MuiTooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InfoWidget from 'component/Widgets/InfoWidget';
import GET_TRAINING_PERFORMANCE_GROUPED_BY_TRAINING from 'graphql/getTrainingsPerformanceGroupedByTraining';

import buildTableData from '../helpers/buildTableData';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    fontSize: 14,
    padding: '20px',
    marginLeft: '20px'
  }
}))(MuiTooltip);

const OverallPerformance = (props) => {
  const { t } = useTranslation();
  const params = useParams();

  const { data: passedUsers, loading } = useQuery(GET_TRAINING_PERFORMANCE_GROUPED_BY_TRAINING, {
    variables: {
      trainingIds: [Number(params.trainingId)],
      category: 'assessment',
      ...(props.filteredBy && props.cohortId)
    },
    fetchPolicy: 'no-cache'
  });

  let users = [];
  if (!loading && props.training) {
    users = buildTableData(props.training, true);
  }

  const displayText = () => {
    const getFilteredUsers = () => {
      if (props.filteredBy) {
        const filteredUsers = [];
        users.forEach((user) =>
          user.cohorts.forEach((cohort) => {
            if (cohort.id === props.filteredBy.id) {
              filteredUsers.push(user);
            }
          })
        );
        return filteredUsers;
      }
      return users;
    };

    const getFilteredUsersCount = () => {
      return getFilteredUsers()?.length;
    };

    const getPassedUsernames = () => {
      if (props.filteredBy) {
        return getFilteredUsers().filter((user) => {
          if (user.passed) {
            return {
              ['username']: user?.username
            };
          }
        });
      }
      const usernames = passedUsers?.trainingStatisticsIndividual?.map((value) => {
        return {
          ['username']: value?.username
        };
      });
      return usernames;
    };

    const getTotalPassed = () => {
      return getPassedUsernames()?.length;
    };

    const getTotalPercent = () => {
      const result = Math.round((getTotalPassed() / getFilteredUsersCount()) * 100);
      return isNaN(result) ? 0 : `${result}%`;
    };

    if (getTotalPassed() === 0) return <Box>{t('trainingDetails.performanceCard.noData')}</Box>;

    return (
      <Box>
        <LightTooltip
          style={{ cursor: 'pointer' }}
          title={
            <>
              {getPassedUsernames()?.length !== 0 ? (
                <>
                  <Typography variant={'h6'}>
                    {t('trainingDetails.performanceCard.tooltip')}
                  </Typography>
                  {getPassedUsernames()?.map((value, key) => (
                    <p style={{ fontWeight: 300 }} key={key}>
                      {' '}
                      {value.username}
                    </p>
                  ))}
                </>
              ) : (
                <Typography variant={'h6'}>
                  {t('trainingDetails.performanceCard.noData')}
                </Typography>
              )}
            </>
          }>
          <b>{getTotalPassed()}</b>
        </LightTooltip>{' '}
        {t('trainingDetails.performanceCard.subText', {
          percentage: getTotalPercent(),
          totalUsers: getFilteredUsersCount()
        })}
      </Box>
    );
  };

  return <InfoWidget title={t('trainingDetails.performanceCard.title')} subText={displayText} />;
};

export default OverallPerformance;
