import { gql } from '@apollo/client';

export const UPDATE_CREATE_LABEL = gql`
  mutation updateText($key: String!, $newKey: String, $trainingId: Int!, $textData: [LabelInput]!) {
    updateLabel(key: $key, newKey: $newKey, trainingId: $trainingId, labelData: $textData) {
      success
      error
    }
  }
`;
