import { gql } from '@apollo/client';

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      success
      errors
      token
      refreshToken
      user {
        pk
        id
        username
        firstName
        lastName
        isSuperuser
        isActive
        email
        cohorts {
          id
          name
        }
        organizationRoles {
          id
          permission
          organization {
            id
            name
          }
        }
        courses {
          id
          name
        }
        trainings {
          id
          name
          course {
            id
            name
          }
        }
        profile {
          timezone
          preferredLanguage {
            id
            code
            name
          }
          dateFormat
        }
        organizations {
          id
          name
          avatar
          courses {
            id
            name
            description
            trainings {
              id
              name
              cohorts {
                id
                name
                user {
                  id
                  username
                }
              }
              user {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export default LOGIN;
