import { gql } from '@apollo/client';

const ADD_COURSES = gql`
  mutation addCourses($data: [CourseInput]) {
    addCourses(data: $data) {
      success
      error
      courses {
        id
        name
        description
        trainings {
          id
          name
          details
        }
      }
    }
  }
`;

export default ADD_COURSES;
