import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowLink } from 'assets/images/icons/ArrowLink';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.dark,
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    borderRadius: '5px',
    padding: '30px',
    height: '10.5rem',
    [theme.breakpoints.down('md')]: {
      height: '8rem'
    },
    width: '100%',
    border: '1px solid ' + theme.palette.grey['100']
  },
  link: {
    textDecoration: 'none',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    flex: '1 1 0%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  leftContent: {
    flex: '1 1 0%'
  },
  rightContent: {
    paddingLeft: '20px'
  },
  title: {
    fontWeight: 400,
    fontSize: '1.2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    },
    color: theme.palette.text.secondary
  },
  description: {
    paddingTop: '8px',
    color: theme.palette.text.secondary
  }
}));

const NoDataActionWidget = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={classes.root}>
      <Link className={classes.link} to={props.link && props.link}>
        <Box className={classes.content}>
          <Box className={classes.leftContent}>
            <Box className={classes.title}>{props.title}</Box>
            <Box className={classes.description}>{props.description}</Box>
          </Box>
          <Box className={classes.rightContent}>
            {props.link && props.icon ? props.icon : <ArrowLink />}
          </Box>
        </Box>
      </Link>
    </Box>
  );
};

export default NoDataActionWidget;
