import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import TextInput from 'component/Inputs/TextInput';
import FullScreenModal from 'component/Modals/FullScreenModal';
import { useLanguages } from 'hooks';

const Modal = ({ handleCloseModal, selectedLanguage }) => {
  const { t } = useTranslation();
  const { isSuccess, addLanguage, updateLanguage, loading } = useLanguages();
  const [errors, setErrors] = useState({});

  const language = useMemo(() => {
    if (selectedLanguage === null) {
      return {
        id: '',
        code: '',
        name: ''
      };
    }
    return selectedLanguage;
  }, [selectedLanguage]);

  const [code, setCode] = useState(language.code);

  const [name, setName] = useState(language.name);

  const onCodeChange = (e) => {
    setCode(e.target.value);
  };

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const isValid = () => {
    if (code === '' || name === '') {
      const baseErrors = {};
      if (code === '') {
        baseErrors['code'] = t('languagesManagement.error.required.code');
      }
      if (name === '') {
        baseErrors['name'] = t('languagesManagement.error.required.name');
      }
      setErrors(baseErrors);
      toast.error(t('languagesManagement.error.fillAllFields'));
      return false;
    }
    return true;
  };

  const onAddLanguage = () => {
    if (isValid()) {
      addLanguage({ code, name });
    }
  };

  const onUpdateLanguage = () => {
    if (isValid()) {
      updateLanguage({
        id: language.id,
        code,
        name
      });
    }
  };

  const handleSave = () => {
    if (selectedLanguage === null) {
      onAddLanguage();
    }
    if (selectedLanguage !== null) {
      onUpdateLanguage();
    }
  };

  const reset = () => {
    setCode('');
    setName('');
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess]);

  return (
    <FullScreenModal
      loading={loading}
      width="35rem"
      icon={selectedLanguage ? 'edit' : 'add'}
      title={
        selectedLanguage
          ? t('languagesManagement.form.update.title')
          : t('languagesManagement.form.add.title')
      }
      buttonText={
        selectedLanguage
          ? t('languagesManagement.form.update.button')
          : t('languagesManagement.form.add.button')
      }
      type="save"
      handleCloseModal={handleCloseModal}
      handleDone={handleSave}>
      {selectedLanguage !== null ? <div>{language.id}</div> : null}
      <TextInput
        label={t('languagesManagement.form.label.code')}
        value={code}
        name="text"
        required={true}
        error={errors.code ? errors.code : ''}
        underText={errors.code ? errors.code : null}
        onChange={onCodeChange}
      />
      <TextInput
        label={t('languagesManagement.form.label.name')}
        value={name}
        name="text"
        required={true}
        error={errors.name ? errors.name : ''}
        underText={errors.name ? errors.name : null}
        onChange={onNameChange}
      />
    </FullScreenModal>
  );
};

export default Modal;
