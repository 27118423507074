import { gql } from '@apollo/client';

const ALL_ORG_COURSES = gql`
  query allCoursersFromOrg($orgId: Int!) {
    allCoursesFromOrg(orgId: $orgId) {
      id
      name
      description
      trainings {
        id
        name
        details
      }
    }
  }
`;

export default ALL_ORG_COURSES;
