import React, { useState } from 'react';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Close, FilterList } from '@material-ui/icons';

import * as filterTypes from '../../constants/filters';
import ConfirmButton from '../Buttons/ConfirmButton';

import UserSessionsFilter from './UserSessionsFilter';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  button: {
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  filterContainer: {
    position: 'absolute',
    zIndex: 101,
    background: theme.palette.background.paper,
    width: '30rem',
    height: '20rem',
    right: 0,
    marginTop: '20px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    borderRadius: '5px',
    textAlign: 'left',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column'
  },
  filterTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  title: {
    flex: '1 1 0%'
  },
  close: {
    cursor: 'pointer'
  },
  filter: {
    flex: '1 1 0%'
  }
}));

const FilterWrapper = (props) => {
  const classes = useStyles(props);
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState(null);
  let filter;
  switch (props.filterType) {
    case filterTypes.USER_SESSIONS:
      filter = (
        <UserSessionsFilter
          data={props.rowData}
          cols={props.columns}
          setFilters={setFilters}
          filters={filters}
        />
      );
      break;
    default:
      filter = null;
  }

  const handleApply = () => {
    props.setFilters(filters);
    setShowFilter(false);
  };

  return (
    <Box className={classes.root}>
      <IconButton className={classes.button} onClick={() => setShowFilter(!showFilter)}>
        <FilterList />
      </IconButton>
      {showFilter && (
        <Box className={classes.filterContainer}>
          <Box className={classes.filterTitle}>
            <Typography className={classes.title} variant="h6">
              Filters
            </Typography>
            <Close className={classes.close} onClick={() => setShowFilter(false)} />
          </Box>
          <Box className={classes.filter}>{filter}</Box>
          <ConfirmButton size="small" onClick={handleApply}>
            Apply Filters
          </ConfirmButton>
        </Box>
      )}
    </Box>
  );
};

export default FilterWrapper;
