import { gql } from '@apollo/client';

const GET_TRAINING_PERFORMANCE_GROUPED_BY_TRAINING = gql`
  query statsTrainingIndividual(
    $trainingIds: [Int!]
    $category: String
    $userId: Int
    $cohortId: Int
  ) {
    trainingStatisticsIndividual(
      trainingIds: $trainingIds
      category: $category
      userId: $userId
      cohortId: $cohortId
    ) {
      trainingId
      trainingName
      username
      totalPassed
      totalFailed
    }
  }
`;

export default GET_TRAINING_PERFORMANCE_GROUPED_BY_TRAINING;
