import { combineReducers } from 'redux';

import { auth } from './auth';
import customizationReducer from './customizationReducer';
import { globalFeedback } from './globalFeedback';
import { nav } from './nav';
import { userDetails } from './userDetails';

const reducer = combineReducers({
  customization: customizationReducer,
  userDetails,
  nav,
  globalFeedback,
  auth
});

export default reducer;
