import { gql } from '@apollo/client';

export const ALL_QUIZZES = gql`
  query allQuizzes($orgId: Int!) {
    allQuizzes(orgId: $orgId) {
      id
      name
      training {
        id
        name
        course {
          id
          name
        }
      }
      quizquestionSet {
        id
        text
        quizquestionchoiceSet {
          id
          choiceText
          correctAnswer
        }
      }
    }
  }
`;

export const ADD_QUIZ = gql`
  mutation addQuiz($quizData: QuizInput!, $questions: [QuizQuestionInput]) {
    addQuiz(quizData: $quizData, questions: $questions) {
      success
      error
    }
  }
`;

export const UPDATE_QUIZ = gql`
  mutation updateQuiz($id: Int!, $quizData: QuizInput!, $questions: [QuizQuestionInput]) {
    updateQuiz(id: $id, quizData: $quizData, questions: $questions) {
      success
      error
    }
  }
`;

export const DELETE_QUIZ = gql`
  mutation deleteQuiz($id: Int!) {
    deleteQuiz(id: $id) {
      success
      error
    }
  }
`;
