import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import DonutChart from 'component/Widgets/ChartWidget/Charts/DonutChart';
import PropTypes from 'prop-types';

import DrawerWrapper from '../Drawer/DrawerWrapper';
import WidgetWrapper from '../WidgetWrapper';

import BarChart from './Charts/BarChart';
import HorizontalBarChart from './Charts/HorizontalBarChart';
import StackedBarChart from './Charts/StackedBarChart';

const ChartWidget = (props) => {
  const [widgetDrawerContent, setWidgetDrawerContent] = useState(null);

  let chartType;

  let customOnClick = false;
  if (props.widgetDrawer) {
    customOnClick = setWidgetDrawerContent;
  }

  switch (props.chartType) {
    case 'bar':
      chartType = (
        <BarChart
          series={props.series}
          categories={props.categories}
          noDataMessage={props.noDataMessage}
          yUnits={props.yUnits}
          xUnits={props.xUnits}
          colors={props.colors}
          distributed={props.distributed}
          customOnClick={customOnClick}
        />
      );
      break;
    case 'hBar':
      chartType = (
        <HorizontalBarChart
          series={props.series}
          categories={props.categories}
          noDataMessage={props.noDataMessage}
          yUnits={props.yUnits}
          xUnits={props.xUnits}
          colors={props.colors}
          distributed={props.distributed}
          customOnClick={customOnClick}
        />
      );
      break;
    case 'stackedBar':
      chartType = (
        <StackedBarChart
          series={props.series}
          categories={props.categories}
          noDataMessage={props.noDataMessage}
          yUnits={props.yUnits}
          xUnits={props.xUnits}
          colors={props.colors}
          distributed={props.distributed}
          customOnClick={customOnClick}
        />
      );
      break;
    case 'donut':
      chartType = (
        <DonutChart
          horizontal={props.horizontal}
          chartData={props.chartData}
          noDataMessage={props.noDataMessage}
          yUnits={props.yUnits}
          xUnits={props.xUnits}
          colors={props.colors}
          distributed={props.distributed}
          customOnClick={customOnClick}
        />
      );
      break;
    default:
      chartType = (
        <BarChart
          series={props.series}
          categories={props.categories}
          noDataMessage={props.noDataMessage}
          yUnits={props.yUnits}
          xUnits={props.xUnits}
          colors={props.colors}
          distributed={props.distributed}
          customOnClick={{
            hasCustomOnClick: !!props.widgetDrawer,
            onClick: setWidgetDrawerContent
          }}
        />
      );
  }

  return (
    <WidgetWrapper
      height={props.height}
      title={props.widgetTitle}
      customHeader={props.customHeader}
      loading={props.loading}
      error={props.error}
      noData={props.noData}
      information={props.information}>
      {props.widgetDrawer && (
        <DrawerWrapper
          onClick={setWidgetDrawerContent}
          content={widgetDrawerContent}
          type={props.widgetDrawer}
        />
      )}
      <Box style={{ height: '100%', width: '100%', position: 'relative', padding: '10px' }}>
        {props.noDataMessage && (
          <Box
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#fefefe95',
              zIndex: '1'
            }}>
            {props.noDataMessage}
          </Box>
        )}
        {chartType}
      </Box>
    </WidgetWrapper>
  );
};

export default ChartWidget;

ChartWidget.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string,
  height: PropTypes.string,
  subTitle: PropTypes.string,
  series: PropTypes.array,
  categories: PropTypes.array,
  noDataMessage: PropTypes.string,
  yUnits: PropTypes.object,
  xUnits: PropTypes.object,
  colors: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};
