import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormSubTitle from 'component/Inputs/FormSubTitle';
import SelectInput from 'component/Inputs/SelectInputs/SelectInput';
import TextInput from 'component/Inputs/TextInput';

const useStyles = makeStyles(() => ({
  paddingBottom: {
    paddingBottom: '1.2rem'
  },
  paddingRight: {
    paddingRight: '1.2rem'
  }
}));

const useUserDetails = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let roleOptions = [
    { id: 'admin', name: 'Admin' },
    { id: 'course-admin', name: 'Course Admin' },
    { id: 'user', name: 'User' }
  ];

  return (
    <Grid container item xs={12} className={classes.paddingBottom}>
      <Grid item xs={12}>
        <FormSubTitle title={t('userManagement.form.title1')} />
      </Grid>

      <Grid item xs={6} className={classes.paddingRight}>
        <TextInput
          required={true}
          label={t('sharedLabels.firstName')}
          value={props.userDetails.firstName}
          name="firstName"
          onChange={props.handleOnChange}
          underText={props.errors.firstName}
          error={props.errors.firstName}
        />
      </Grid>
      <Grid item xs={6} className={classes.paddingRight}>
        <TextInput
          required={true}
          label={t('sharedLabels.lastName')}
          value={props.userDetails.lastName}
          name="lastName"
          onChange={props.handleOnChange}
          underText={props.errors.lastName}
          error={props.errors.lastName}
        />
      </Grid>
      <Grid item xs={12} className={classes.paddingRight}>
        <TextInput
          required={true}
          label={t('sharedLabels.email')}
          value={props.userDetails.email}
          name="email"
          onChange={props.handleOnChange}
          underText={props.errors.email}
          error={props.errors.email}
          disabled={!!props.editMode}
        />
      </Grid>
      <Grid item xs={6} className={classes.paddingRight}>
        <TextInput
          required={true}
          label={t('sharedLabels.username')}
          value={props.userDetails.username}
          name="username"
          onChange={props.handleOnChange}
          underText={props.errors.username}
          error={props.errors.username}
        />
      </Grid>
      <Grid item xs={6} className={classes.paddingRight}>
        <SelectInput
          multiple={false}
          label={t('sharedLabels.role')}
          selected={props.userDetails.role}
          name="role"
          onChange={props.handleOnChange}
          menuItems={roleOptions}
          group={false}
          required={true}
          topMenu={true}
          canSelectNone={false}
        />
      </Grid>
    </Grid>
  );
};

export default useUserDetails;
