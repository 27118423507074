import React, { useEffect, useState } from 'react';
import {
  ClickAwayListener,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    display: 'flex',
    position: 'relative',
    height: '22px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  list: {
    zIndex: '22',
    width: '220px',
    maxHeight: '250px',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    marginTop: '50px',
    position: 'absolute',
    right: '-10px',
    top: 0,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 20px'
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.background.default
    }
  },
  groupTitle: {
    opacity: 0.5,
    margin: '4px 0',
    padding: '0 16px',
    cursor: 'default'
  },
  noData: {
    opacity: 0.5,
    padding: '0 20px',
    fontWeight: 500,
    fontStyle: 'italic'
  }
}));

const NavDropdown = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);

  useEffect(() => {
    if (props.collapse) setOpen(false);
  }, [props.collapse]);

  const navItems = props.nav.navItems ? props.nav.navItems : props.nav;

  return (
    <li className={classes.root} onClick={() => !props.collapse && setOpen(!open)}>
      {props.children}
      {open && !props.collapse && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <List component="nav" className={classes.list}>
            {navItems.length > 0 ? (
              navItems.map((item, i) => {
                return (
                  <ListItem
                    className={classes.listItem}
                    key={i}
                    button
                    onMouseEnter={() => setHoveredOption(i)}
                    onMouseLeave={() => setHoveredOption(null)}
                    onClick={() => item.action(item)}>
                    <ListItemText primary={item.name} />
                    <ListItemIcon>
                      {item.hoverIcon && hoveredOption === i ? item.hoverIcon : item.icon}
                    </ListItemIcon>
                  </ListItem>
                );
              })
            ) : (
              <p className={classes.noData}>{props.noDataText}</p>
            )}
          </List>
        </ClickAwayListener>
      )}
    </li>
  );
};

export default NavDropdown;
