import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import { AddIconMedium } from 'assets/images/icons/AddIcon';
import { EnrolledUsersFilterContext } from 'component/ViewFilters/EnrolledUsersFilter/useEnrolledUsersFilter';
import { useTrainingTranslation } from 'hooks';

import { PERFORMANCE_FILTER } from '../ViewFilters/EnrolledUsersFilter/useEnrolledUsersFilter';

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    flex: '1 1 0%',
    position: 'relative'
  },
  bodyContent: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    overflowY: 'auto'
  },
  row: {
    position: 'relative',
    display: 'flex',
    borderRadius: '5px',
    backgroundColor: theme.palette.background.paper,
    padding: '10px 20px',
    marginBottom: '10px',
    border: '1px solid ' + theme.palette.grey['200'],
    justifyContent: 'space-between'
  },
  boxShadow: {
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px'
  },
  text: {
    fontWeight: 600,
    color: theme.palette.secondary.main
  },
  subText: {
    fontWeight: 400,
    color: theme.palette.text.secondary
  },

  success: {
    color: theme.palette.success.main
  },
  fail: {
    color: theme.palette.error.main
  },
  icon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  noData: {
    textDecoration: 'none',
    padding: '15px 25px',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper + 90
  },
  rightContent: {
    width: '300px'
  }
}));

const PerformanceTable = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { activePerformance } = useContext(EnrolledUsersFilterContext);
  const { getTranslatedNameFromTraining } = useTrainingTranslation();

  const UserDetails = ({ user }) => {
    return (
      <div>
        <span className={classes.text}>{user.firstName + ' ' + user.lastName}</span>
        <br />
        <span className={classes.subText}>
          {getTranslatedNameFromTraining({ training: user.training })}
        </span>
      </div>
    );
  };

  const UserPassed = ({ user }) => {
    return (
      <span>
        Passed: <span className={classes.success}>{user.totalPassed}</span>
      </span>
    );
  };

  const UserFailed = ({ user }) => {
    return (
      <span>
        Failed: <span className={classes.fail}>{user.totalFailed}</span>
      </span>
    );
  };

  const UserDatePassed = ({ user }) => {
    return (
      <span>
        Date Passed: <span className={classes.success}>{user.datePassed}</span>
      </span>
    );
  };

  const RenderUser = ({ user }) => {
    return (
      <Box className={`${classes.row} ${classes.boxShadow}`}>
        {activePerformance === PERFORMANCE_FILTER.ALL ? (
          <>
            <UserDetails user={user} />
            <div className={classes.rightContent}>
              <UserPassed user={user} />
              <br />
              <UserDatePassed user={user} />
              <br />
              <UserFailed user={user} />
            </div>
          </>
        ) : null}
        {activePerformance === PERFORMANCE_FILTER.PASSED ? (
          <>
            <UserDetails user={user} />
            <div className={classes.rightContent}>
              <UserPassed user={user} />
              <br />
              <UserDatePassed user={user} />
            </div>
          </>
        ) : null}
        {activePerformance === PERFORMANCE_FILTER.NOT_PASSED ? (
          <>
            <UserDetails user={user} />
            <div className={classes.rightContent}>
              <UserFailed user={user} />
            </div>
          </>
        ) : null}
      </Box>
    );
  };

  return (
    <Box className={classes.bodyContainer}>
      <Box className={classes.bodyContent}>
        {props.users.length !== 0 ? (
          <>
            {props.users.map((user, i) => (
              <RenderUser key={i} user={user} />
            ))}
          </>
        ) : (
          <Link className={`${classes.row} ${classes.noData}`} to="/admin/users">
            <Box className={classes.noDataText}>
              <div>{t('course.noDataEnrolledUsers')}</div>
            </Box>
            <Box className={classes.noDataIcon}>
              <AddIconMedium />
            </Box>
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default PerformanceTable;
