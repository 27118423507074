import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from 'component/Table/Table';
import { useOrganizationToken, useUser } from 'hooks';
import moment from 'moment-timezone';

const OrganizationTokenTransactionsTable = () => {
  const { transactions, loadingViewTransactionsRequest } = useOrganizationToken();
  const { getUserTimezone } = useUser();
  const { t } = useTranslation();

  const columns = [
    {
      name: 'Quantity'
    },
    {
      name: 'Description'
    },
    {
      name: 'CreatedAt'
    },
    {
      name: 'Username'
    }
  ];

  const rows =
    transactions.length !== 0
      ? transactions.toReversed().map((transaction) => {
          return [
            transaction.quantity,
            transaction.description,
            moment.tz(transaction.createdAt, getUserTimezone()).format('MM/DD/YYYY - HH:mm'),
            transaction.user.username
          ];
        })
      : [];

  const tableConfig = {
    title: t('organizationManagement.token.tokenHistory'),
    print: false,
    viewColumns: false,
    search: true,
    download: false,
    filter: false,
    rowData: rows,
    columns: columns,
    flexView: true
  };

  return (
    <div style={{ height: '50vh' }}>
      {loadingViewTransactionsRequest ? (
        <div>{t('organizationManagement.token.loading')}</div>
      ) : null}
      {!loadingViewTransactionsRequest && transactions.length === 0 ? (
        <div>{t('organizationManagement.token.noTransactions')}</div>
      ) : null}
      {!loadingViewTransactionsRequest && transactions.length !== 0 ? (
        <Table tableConfig={tableConfig} />
      ) : null}
    </div>
  );
};

export default OrganizationTokenTransactionsTable;
