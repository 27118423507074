import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { AssignmentLateOutlined, CheckCircleRounded, RotateRightRounded } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { ArrowLinkSmall } from 'assets/images/icons/ArrowLink';
import TableWidget from 'component/Widgets/TableWidget';
import LATEST_ACTIVITIES from 'graphql/LatestActivities';
import { buildBreadcrumbs } from 'helpers/buildBreadcrumbs';
import { useDateFormat } from 'hooks/useDateFormat';
import moment from 'moment-timezone';

const LatestActivity = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleDateFormat } = useDateFormat();
  let history = useHistory();

  const { data, error, loading } = useQuery(LATEST_ACTIVITIES, {
    variables: {
      limit: 5,
      ...(props.restrictedUser ? props.userId : props.orgId),
      ...(props.filteredBy && props.cohortId)
    },
    fetchPolicy: 'no-cache'
  });

  const headers = {
    icon: {
      value: ' ',
      icon: true
    },
    ...(!props.restrictedUser && { user: t('sharedLabels.user') }),
    course: t('sharedLabels.course'),
    training: t('sharedLabels.training'),
    sessionType: t('sharedLabels.sessionType'),
    time: t('sharedLabels.time'),
    status: ' '
  };

  let rows = [];

  const handleRowOnClick = (user) => {
    history.push('/user/' + user.id);
    buildBreadcrumbs({
      key: 'dash',
      name: 'Dashboard',
      viewTitle: props.restrictedUser ? data?.latestActivities[0].username : user.user
    });
  };

  if (data) {
    data.latestActivities.map((row) => {
      let status;
      if (row.isComplete) {
        status = (
          <Tooltip placement="left" title="Complete">
            <CheckCircleRounded style={{ color: theme.palette.success.main }} />
          </Tooltip>
        );
      } else {
        if (
          moment(new Date()).diff(moment(row.completedAt), 'minutes') > 20 ||
          (row.completedAt === null &&
            moment(new Date()).diff(moment(row.createdAt), 'minutes') > 20)
        ) {
          status = (
            <Tooltip placement="left" title="Incomplete">
              <AssignmentLateOutlined style={{ color: theme.palette.text.hint }} />
            </Tooltip>
          );
        } else {
          status = (
            <Tooltip placement="left" title="In Progress">
              <RotateRightRounded style={{ color: theme.palette.primary.main }} />
            </Tooltip>
          );
        }
      }

      rows.push({
        icon: {
          value: (
            <Box style={{ paddingTop: '7px' }}>
              <ArrowLinkSmall />
            </Box>
          ),
          icon: true
        },
        id: row.userId,
        ...(!props.restrictedUser && { user: row.username }),
        course: row.courseName,
        training: row.trainingName,
        sessionType: row.sessionCategory,
        time: moment(row.createdAt).format(handleDateFormat(true)),
        status
      });
    });
  }

  return (
    <TableWidget
      rowOnClick={handleRowOnClick}
      title={t('dashboard.table.title')}
      rows={rows}
      headers={headers}
      error={error}
      loading={loading}
      noData={rows.length < 1 && t('dashboard.table.titleAlt')}
      height="22.6rem"
    />
  );
};

export default LatestActivity;
