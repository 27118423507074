import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import XSmallSpinner from 'component/Spinners/XSmallSpinner';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid ' + theme.palette.grey['200']
  },
  title: {
    fontWeight: 500,
    fontSize: '0.9rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '4.5rem',
    padding: '0 20px'
  },
  subText: {
    fontSize: '0.8rem',
    fontWeight: 400
  },
  contentContainer: {
    width: '100%',
    padding: '15px 0 0 0',
    height: (props) => (props.height ? props.height : '6rem'),
    display: (props) => (props.loading ? 'flex' : 'block'),
    alignItems: (props) => (props.loading ? 'center' : 'flex-start'),
    justifyContent: (props) => (props.loading ? 'center' : 'flex-start')
  },
  chart: {
    width: '100%',
    padding: 0
  },
  center: {
    display: 'flex',
    paddingBottom: '20px',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const TimeLineWidget = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const theme = useTheme();

  let chartData = {
    options: {
      chart: {
        stacked: false,
        sparkline: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: [theme.palette.warning.main],
      stroke: {
        curve: 'smooth',
        width: 3
      },
      xaxis: {
        labels: {
          show: true
        },
        categories: props.data?.xAxisCategories ? props.data.xAxisCategories : []
      }
    },
    series: [
      {
        name: '',
        data: props.data?.seriesData ? props.data.seriesData : []
      }
    ]
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        {props.title}
        <Box className={classes.subText}>{props.subText}</Box>
      </Box>
      <Box className={classes.contentContainer}>
        {props.error ? (
          <Box className={classes.center}>{t('general.errorData')}</Box>
        ) : props.loading ? (
          <Box className={classes.center}>
            <XSmallSpinner />
          </Box>
        ) : (
          <ReactApexChart className={classes.chart} {...chartData} type="area" height="100%" />
        )}
      </Box>
    </Box>
  );
};

export default TimeLineWidget;
