const SET_FEEDBACK = 'SET_FEEDBACK';

const initialState = {
  feedback: null
};

export const setFeedBack = (feedback) => {
  return { type: SET_FEEDBACK, feedback };
};

export const globalFeedback = (state = initialState, action) => {
  switch (action.type) {
    case SET_FEEDBACK:
      return {
        ...state,
        feedback: action.feedback
      };
    default:
      return state;
  }
};
