import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowLink } from 'assets/images/icons/ArrowLink';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    color: (props) => (props.link ? theme.palette.background.paper : theme.palette.secondary.main),
    backgroundColor: (props) =>
      props.noData || props.error
        ? theme.palette.background.paper + '60'
        : props.link
        ? theme.palette.primary.main
        : theme.palette.background.paper,
    cursor: (props) => (props.link ? 'pointer' : 'default'),
    borderRadius: '5px',
    height: (props) => (props.height ? props.height : '100%'),
    width: '100%',
    border: '1px solid ' + theme.palette.grey['200']
  },
  linkContainer: {
    textDecoration: 'none',
    height: '100%',
    width: '100%',
    padding: '30px',
    display: 'flex',
    alignItems: 'center'
  },
  baseContainer: {
    height: '100%',
    width: '100%',
    padding: '30px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  value: {
    fontWeight: 600,
    fontSize: '1.6rem',
    paddingBottom: '15px',
    color: (props) => (props.link ? theme.palette.background.paper : theme.palette.primary.main)
  },
  title: {
    fontWeight: (props) => (props.noData || props.link ? 300 : 400),
    fontSize: '1.6rem',
    color: (props) =>
      props.noData || props.error
        ? theme.palette.secondary.main + '80'
        : props.link
        ? theme.palette.background.paper
        : theme.palette.secondary.main
  },
  subText: {
    paddingTop: '8px',
    color: (props) => (props.link ? theme.palette.background.paper : theme.palette.secondary.main)
  },
  linkIcon: {
    display: 'flex',
    flex: '1 1 0%',
    justifyContent: 'flex-end'
  }
}));

const BasicWidget = (props) => {
  const classes = useStyles(props);
  const widgetContent = (
    <>
      {!props.hideValue && <Box className={classes.value}>{props.value ? props.value : '0'}</Box>}
      <Box className={classes.title}>{props.title}</Box>
      {props.subText && <Box className={classes.subText}>{props.subText}</Box>}
    </>
  );

  return (
    <Box className={classes.root}>
      {props.link ? (
        <Link
          className={classes.linkContainer}
          to={props.link}
          onClick={props.action && props.action}>
          <Box>{widgetContent}</Box>
          {props.link && (
            <Box className={classes.linkIcon}>
              <ArrowLink />
            </Box>
          )}
        </Link>
      ) : (
        <Box className={classes.baseContainer}>{widgetContent}</Box>
      )}
    </Box>
  );
};

export default BasicWidget;
