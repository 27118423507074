import { gql } from '@apollo/client';

export const AVAILABLE_LANGUAGES = gql`
  query availableLanguages {
    availableLanguages {
      id
      code
      name
    }
  }
`;

export const ADD_LANGUAGE = gql`
  mutation addLanguage($code: String!, $name: String!) {
    addLanguage(code: $code, name: $name) {
      success
      error
      language {
        id
        code
        name
      }
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation updateLanguages($langData: [LanguageInput]!) {
    updateLanguages(data: $langData) {
      success
      error
    }
  }
`;

export const DELETE_LANGUAGES = gql`
  mutation deleteLanguages($langID: [Int]!) {
    deleteLanguages(ids: $langID) {
      success
      error
    }
  }
`;
