import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AddCircleTwoTone,
  CheckCircleOutlineTwoTone,
  Close,
  Edit,
  ErrorOutlined,
  WarningTwoTone
} from '@material-ui/icons';
import { setFeedBack } from 'ducks/globalFeedback';
import PropTypes from 'prop-types';

import ConfirmButton from '../Buttons/ConfirmButton';
import ErrorButton from '../Buttons/ErrorButton';
import XSmallSpinner from '../Spinners/XSmallSpinner';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: (props) => props.height,
    maxHeight: (props) => props.height,
    minWidth: (props) => props.width,
    maxWidth: (props) => props.width,
    borderRadius: (props) => (props.height === '100vh' ? '0px' : '5px')
  },
  bannerHeader: {
    backgroundColor: (props) =>
      props.type === 'warning' ? theme.palette.error.main : theme.palette.primary.main,
    height: '64px',
    padding: '20px',
    position: 'relative'
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: (props) =>
      props.type === 'warning' ? theme.palette.error.light : theme.palette.primary.light,
    height: '64px',
    width: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: '1.5em',
    color: theme.palette.background.paper
  },
  bannerFooter: {
    padding: '10px',
    display: 'flex',
    height: '64px',
    justifyContent: 'space-between'
  },
  icon: {
    marginRight: '8px',
    fontSize: '1.3rem'
  },
  cancel: {
    fontWeight: '400',
    opacity: '0.8',
    fontSize: '0.8rem',
    paddingLeft: '20px',
    cursor: 'pointer'
  },
  title: {
    color: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    width: 'full',
    alignItems: 'center',
    fontWeight: '500',
    fontSize: '1rem'
  },
  saving: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    padding: '3px 15px'
  },
  content: {
    padding: '30px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  feedBack: {
    zIndex: 1,
    margin: theme.spacing(1),
    position: 'absolute',
    top: 0,
    right: 0
  },
  loading: {
    position: 'absolute',
    height: '90%',
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  errorText: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    color: theme.palette.error.main
  },
  errorIcon: {
    marginLeft: '5px'
  }
}));

const ModalWrapper = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.response?.responseType === 'error') {
      dispatch(setFeedBack({ message: props.response?.message, type: 'error' }));
    }
    if (props.response?.responseType === 'success') {
      dispatch(setFeedBack({ message: props.response?.message, type: 'success' }));
    }
    const timeId = setTimeout(() => {
      if (props.response?.responseType === 'success') {
        props.handleCloseModal('close');
      }
    }, 2000);

    return () => {
      clearTimeout(timeId);
    };
  }, [props.response]);

  let icon;
  switch (props.icon) {
    case 'warning':
      icon = <WarningTwoTone className={classes.icon} />;
      break;
    case 'edit':
      icon = <Edit className={classes.icon} />;
      break;
    case 'add':
      icon = <AddCircleTwoTone className={classes.icon} />;
      break;
    case 'confirm':
      icon = <CheckCircleOutlineTwoTone className={classes.icon} />;
      break;
    default:
      icon = '';
      break;
  }

  const title = (
    <Box className={classes.title}>
      {icon}
      {props.title}
    </Box>
  );

  const handleClose = (value) => {
    setError(null);
    props.handleCloseModal(value);
  };

  return (
    <Dialog
      onClose={() => handleClose('close')}
      open={true}
      scroll="paper"
      classes={{ paper: classes.root }}>
      <DialogTitle className={classes.bannerHeader}>
        {title}
        <Box onClick={() => handleClose('close')} className={classes.close}>
          <Close />
        </Box>
      </DialogTitle>
      <DialogContent dividers={true} classes={{ dividers: classes.content }}>
        {props.loading ? (
          <Box className={classes.loading}>
            <CircularProgress size={70} />
          </Box>
        ) : (
          props.children
        )}
      </DialogContent>
      {props.hideFooter ? null : (
        <DialogActions className={classes.bannerFooter}>
          {error ? (
            <Box className={classes.errorText}>
              {props.response?.message}
              <ErrorOutlined className={classes.errorIcon} />
            </Box>
          ) : props.saving ? (
            <Box className={classes.saving}>
              <XSmallSpinner />
            </Box>
          ) : (
            <>
              <Box
                className={classes.cancel}
                onClick={() => {
                  if (!props.disabled) {
                    handleClose('cancel');
                  }
                }}>
                {t('general.cancel')}
              </Box>
              {props.customActionButtons ? (
                props.customActionButtons
              ) : props.type === 'warning' ? (
                <ErrorButton
                  onClick={() => props.handleDone()}
                  variant="outlined"
                  disabled={props.disabled}>
                  {props.buttonText}
                </ErrorButton>
              ) : (
                <ConfirmButton onClick={() => props.handleDone()}>
                  {props.buttonText}{' '}
                </ConfirmButton>
              )}
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ModalWrapper;

ModalWrapper.propTypes = {
  //MODAL TYPE - decides modal header color and button colors
  type: PropTypes.string,
  title: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  //IF NEW ICON IS NEEDED ADD TO SWITCH
  icon: PropTypes.string,
  handleDone: PropTypes.func,
  handleCloseModal: PropTypes.func,
  saving: PropTypes.bool,
  loading: PropTypes.bool,
  buttonText: PropTypes.string,
  customActionButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  response: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      message: PropTypes.string,
      responseType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    })
  ])
};
