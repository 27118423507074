import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import RowButton from 'component/Table/RowButton';
import Table from 'component/Table/Table';
import { useTrainingTranslation } from 'hooks';

const TrainingTable = (props) => {
  const { t } = useTranslation();
  const { getTranslatedNameFromTraining, getTranslatedDetailsFromTraining } =
    useTrainingTranslation();

  const columns = [
    {
      name: t('sharedLabels.name'),
      options: {
        filter: false,
        customBodyRender: (training) => getTranslatedNameFromTraining({ training })
      }
    },
    {
      name: t('sharedLabels.details'),
      options: {
        filter: false,
        customBodyRender: (training) => getTranslatedDetailsFromTraining({ training })
      }
    },
    {
      name: t('sharedLabels.totalUsers'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          return value.length;
        }
      }
    },
    {
      name: t('sharedLabels.course'),
      options: {
        filter: false
      }
    },
    {
      name: 'data',
      options: {
        display: false,
        filter: false
      }
    },
    {
      name: '',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box style={{ display: 'flex', justifyContent: 'space-between', width: '400px' }}>
              {tableMeta.rowData[4].translations.length !== 0 ? (
                <RowButton
                  onClick={() =>
                    props.actionEditTranslation({
                      training: {
                        ...tableMeta.rowData[4],
                        action: 'EDIT_TRANSLATION'
                      }
                    })
                  }
                  label={t('trainingManagement.translation.edit')}
                />
              ) : (
                <RowButton
                  onClick={() =>
                    props.actionAddTranslation({
                      training: {
                        ...tableMeta.rowData[4],
                        action: 'ADD_TRANSLATION'
                      }
                    })
                  }
                  label={t('trainingManagement.translation.add')}
                />
              )}
              <RowButton
                onClick={() => props.actionEdit(tableMeta.rowData[4], true)}
                label={t('trainingManagement.edit')}
              />
              <RowButton
                onClick={() => props.actionDelete(tableMeta.rowData[4], true)}
                label={t('trainingManagement.delete')}
                type="delete"
              />
            </Box>
          );
        }
      }
    }
  ];

  let rows = props.data
    ? props.data.map((training) => {
        return [training, training, training.user, training.course.name, training, ''];
      })
    : [];

  const tableConfig = {
    title: t('trainingManagement.table.title'),
    print: false,
    viewColumns: false,
    search: true,
    download: false,
    filter: false,
    rowData: rows,
    columns: columns,
    flexView: true
  };

  return <Table tableConfig={tableConfig} />;
};

export default TrainingTable;
