import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmButton from 'component/Buttons/ConfirmButton';
import ViewContainer from 'component/ViewContainer/ViewContainer';
import { useOrganization, useOrganizationToken } from 'hooks';

import OrganizationModal from './OrganizationModal';
import OrganizationModalManageToken from './OrganizationModalManageToken';
import OrganizationTable from './OrganizationTable';

const OrganizationManagement = () => {
  const { t } = useTranslation();
  const {
    loadingOrganizations,
    getAllOrganizations,
    isSuccessfullyUpdated,
    isSuccessfullyCreated,
    resetStatus
  } = useOrganization();
  const {
    viewTransactions,
    getTokenDetails,
    resetTransactions,
    isSuccessfullyAdded: isTokenSuccessfullyAdded,
    isSuccessfullyUpdated: isTokenSuccessfullyUpdated
  } = useOrganizationToken();
  const [showModal, setShowModal] = useState(false);
  const [showManageTokenModal, setShowManageTokenModal] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  useEffect(() => {
    getAllOrganizations();
  }, []);

  const handleOnEdit = useCallback(
    ({ organization }) => {
      setShowModal(true);
      setSelectedOrganization(organization);
    },
    [setShowModal]
  );

  const handleCreateNewOrganization = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setSelectedOrganization(null);
  }, [setShowModal]);

  const handleManageToken = useCallback(
    ({ organization }) => {
      setSelectedOrganization(organization);
      setShowManageTokenModal(true);
      viewTransactions({
        organizationId: parseInt(organization.id, 10)
      });
      getTokenDetails({
        organizationId: parseInt(organization.id, 10)
      });
    },
    [setShowManageTokenModal]
  );

  const handleCloseModalManageToken = useCallback(() => {
    setShowManageTokenModal(false);
    setSelectedOrganization(null);
    if (isTokenSuccessfullyAdded || isTokenSuccessfullyUpdated) {
      resetTransactions();
      resetStatus();
      getAllOrganizations();
    }
  }, [
    setShowManageTokenModal,
    setSelectedOrganization,
    resetTransactions,
    resetStatus,
    getAllOrganizations,
    isTokenSuccessfullyAdded,
    isTokenSuccessfullyUpdated
  ]);

  useEffect(() => {
    if (isSuccessfullyUpdated || isSuccessfullyCreated) {
      resetStatus();
      setShowModal(false);
      getAllOrganizations();
    }
  }, [isSuccessfullyUpdated, isSuccessfullyCreated, setShowModal, resetStatus]);

  return (
    <ViewContainer
      title={t('organizationManagement.title')}
      flexView={true}
      loading={loadingOrganizations}
      actionButtons={() => (
        <>
          <ConfirmButton
            size="small"
            disabled={loadingOrganizations}
            onClick={handleCreateNewOrganization}>
            {t('organizationManagement.modal.add')}
          </ConfirmButton>
        </>
      )}>
      {showModal ? (
        <OrganizationModal
          selectedOrganization={selectedOrganization}
          handleCloseModal={handleCloseModal}
        />
      ) : null}
      {showManageTokenModal ? (
        <OrganizationModalManageToken
          selectedOrganization={selectedOrganization}
          handleCloseModal={handleCloseModalManageToken}
        />
      ) : null}
      <OrganizationTable handleOnEdit={handleOnEdit} handleManageToken={handleManageToken} />
    </ViewContainer>
  );
};

export default OrganizationManagement;
