import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useOrganization } from 'hooks';

import WarningModal from '../../../component/Modals/WarningModal';
import ViewContainer from '../../../component/ViewContainer/ViewContainer';
import ALL_TRAININGS from '../../../graphql/AllTrainings';
import { ADD_QUIZ, ALL_QUIZZES, DELETE_QUIZ, UPDATE_QUIZ } from '../../../graphql/QuizManagement';

import QuizModal from './QuizModal';
import QuizTable from './QuizTable';

const AdminQuizManagement = () => {
  const { organizationData } = useOrganization();
  const { t } = useTranslation();
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [setDeleteQuizModalText] = useState(null);
  const [saving, setSaving] = useState(false);
  const [response, setResponse] = useState(null);

  //QUERIES
  const {
    data: quizData,
    loading,
    error
  } = useQuery(ALL_QUIZZES, {
    variables: {
      orgId: organizationData.organization.id
    }
  });

  const { data: trainings } = useQuery(ALL_TRAININGS, {
    fetchPolicy: 'no-cache',
    variables: {
      orgId: organizationData.organization.id
    }
  });

  //MUTATIONS
  const [addQuiz, { error: addError }] = useMutation(ADD_QUIZ, {
    refetchQueries: [
      { query: ALL_QUIZZES, variables: { orgId: organizationData.organization.id } }
    ],
    onCompleted(addData) {
      setResponse({
        message: addData.addQuiz.success
          ? t('quizManagement.responses.createSuccess')
          : t('quizManagement.responses.createFail'),
        responseType: addData.addQuiz.success ? 'success' : 'error'
      });
      handleClearStates();
    }
  });

  const [updateQuiz, { error: updateError }] = useMutation(UPDATE_QUIZ, {
    refetchQueries: [
      { query: ALL_QUIZZES, variables: { orgId: organizationData.organization.id } }
    ],
    onCompleted(updateData) {
      setResponse({
        message: updateData.updateQuiz.success
          ? t('quizManagement.responses.updateSuccess')
          : t('quizManagement.responses.updateFail'),
        responseType: updateData.updateQuiz.success ? 'success' : 'error'
      });
      handleClearStates();
    }
  });

  const [deleteQuiz, { error: deleteError }] = useMutation(DELETE_QUIZ, {
    refetchQueries: [
      { query: ALL_QUIZZES, variables: { orgId: organizationData.organization.id } }
    ],
    onCompleted(deleteData) {
      setResponse({
        message: deleteData.deleteQuiz.success
          ? t('quizManagement.responses.deleteSuccess')
          : t('quizManagement.responses.deleteFail'),
        responseType: deleteData.deleteQuiz.success ? 'success' : 'error'
      });
      handleClearStates();
    }
  });

  const handleAddQuiz = (nameAndTraining, questions) => {
    setSaving(true);
    addQuiz({
      variables: {
        quizData: {
          name: nameAndTraining.name,
          trainingId: parseInt(nameAndTraining.trainingId)
        },
        questions: questions
      }
    });
  };

  const handleUpdateQuiz = (quizId, nameAndTraining, questions) => {
    setSaving(true);
    updateQuiz({
      variables: {
        id: parseInt(quizId),
        quizData: {
          name: nameAndTraining.name,
          trainingId: parseInt(nameAndTraining.trainingId)
        },
        questions: questions
      }
    });
  };

  const handleDeleteConfirmed = () => {
    setSaving(true);
    deleteQuiz({
      variables: {
        id: selectedQuiz.id
      }
    });
  };

  //HANDLING FUNCTIONS
  const handleClearStates = () => {
    setSaving(false);
    setSelectedQuiz(null);
    setDeleteQuizModalText(null);
  };

  const handleShowModal = (quiz, type, value) => {
    if (quiz) setSelectedQuiz(quiz);
    if (type === 'quiz') setShowQuizModal(value);
    if (type === 'delete') setShowDeleteModal(value);
    if (!value) {
      handleClearStates();
      setResponse(null);
    }
  };

  const handleCloseModal = (type) => {
    handleShowModal(null, type, false);
  };

  return (
    <ViewContainer
      title={t('quizManagement.title')}
      loading={loading}
      flexView={true}
      error={error || addError || updateError || deleteError}
      actionButton={() => handleShowModal(null, 'quiz', true)}
      actionButtonLabel={t('quizManagement.create')}>
      {showDeleteModal && (
        <WarningModal
          saving={saving}
          response={response}
          handleCloseModal={() => handleCloseModal('delete')}
          message={t('quizManagement.deleteModal.message') + ' ' + selectedQuiz?.name}
          title={t('quizManagement.deleteModal.title')}
          handleDone={handleDeleteConfirmed}
        />
      )}
      {showQuizModal && (
        <QuizModal
          saving={saving}
          response={response}
          trainings={trainings}
          selectedQuiz={selectedQuiz}
          handleCloseModal={handleCloseModal}
          addQuiz={handleAddQuiz}
          updateQuiz={handleUpdateQuiz}
        />
      )}
      <QuizTable quizData={quizData?.allQuizzes} handleShowModal={handleShowModal} />
    </ViewContainer>
  );
};

export default AdminQuizManagement;
