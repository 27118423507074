import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: (props) =>
      props.type === 'delete' ? theme.palette.error.main : theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 500,
    textTransform: 'uppercase'
  }
}));

const RowButton = (props) => {
  const classes = useStyles(props);
  return (
    <Box className={classes.root} onClick={props.onClick}>
      {props.label}
    </Box>
  );
};

export default RowButton;
