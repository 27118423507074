import React, { useCallback, useEffect, useState } from 'react';
import { useCustomEventListener } from 'react-custom-events';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import WarningModal from 'component/Modals/WarningModal';
import ViewContainer from 'component/ViewContainer/ViewContainer';
import { ON_LANGUAGE_CHANGE } from 'constants';
import ALL_ORG_COURSES from 'graphql/AllOrgCourses';
import ALL_USERS from 'graphql/AllUsers';
import DELETE_TRAININGS from 'graphql/DeleteTrainings';
import { useUser } from 'hooks';
import { useOrganization } from 'hooks';
import { useTrainings, useTrainingTranslation } from 'hooks';

import TrainingModal from './TrainingModal';
import TrainingTable from './TrainingTable';
import TrainingTranslationsModal from './TrainingTranslationsModal';

const AdminTraining = () => {
  const { t } = useTranslation();
  const { organizationData } = useOrganization();
  const { user, isCourseAdmin } = useUser();
  const [selectedTraining, setSelectedTraining] = useState(null);
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [showTranslationModal, setShowTranslationModal] = useState(false);
  const [response, setResponse] = useState(null);
  const [saving, setSaving] = useState(false);
  const [trainingData, setTrainingData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteResponse, setDeleteResponse] = useState(null);
  const { clearTrainingTranslations, getTranslatedNameFromTraining } = useTrainingTranslation();
  const { getAllTrainings, trainings, loadingTrainings, errorTrainings } = useTrainings();

  useEffect(() => {
    getAllTrainings({
      variables: {
        orgId: organizationData.organization.id
      }
    });
  }, []);

  useEffect(() => {
    setTrainingData([]);
    if (isCourseAdmin) {
      let courseAdminTrainings = [];
      user.trainings.forEach((training) => {
        const foundTraining = trainings.find((t) => t.id === training.id);
        if (foundTraining) courseAdminTrainings.push(foundTraining);
      });
      setTrainingData(courseAdminTrainings);
    } else {
      setTrainingData(trainings);
    }
  }, [trainings, isCourseAdmin, user, setTrainingData]);

  useCustomEventListener(ON_LANGUAGE_CHANGE, () => {
    getAllTrainings({
      variables: {
        orgId: organizationData.organization.id
      }
    });
  });

  const {
    data: dataUsers,
    error: errorUsers,
    loading: loadingUsers
  } = useQuery(ALL_USERS, {
    variables: {
      orgId: Number(organizationData.organization.id)
    }
  });

  const {
    data: dataCourses,
    error: errorCourses,
    loading: loadingCourses
  } = useQuery(ALL_ORG_COURSES, {
    variables: {
      orgId: Number(organizationData.organization.id)
    }
  });

  const [deleteTrainings] = useMutation(DELETE_TRAININGS, {
    onCompleted(trainingsDeleted) {
      if (trainingsDeleted.deleteTrainings.success) {
        let trainingDataCopy = [...trainingData].filter(
          (training) => training.id !== selectedTraining.id
        );
        setTrainingData(trainingDataCopy);
      }
      setDeleteResponse({
        message: trainingsDeleted.deleteTrainings.success
          ? t('trainingManagement.responses.deleteSuccess')
          : trainingsDeleted.deleteTrainings.error,
        responseType: trainingsDeleted.deleteTrainings.success ? 'success' : 'error'
      });
      setSaving(false);
    }
  });

  const handleShowTrainingModal = (training, value) => {
    if (training) {
      setSelectedTraining({
        ...training,
        name: getTranslatedNameFromTraining({ training })
      });
    }
    setShowTrainingModal(value);
  };

  const handleShowDeleteModal = (training, value) => {
    setShowDeleteModal(value);
    if (!training) {
      setDeleteResponse(null);
      setSaving(false);
      setSelectedTraining(null);
    } else {
      setSelectedTraining(training);
    }
  };

  const handleDeleteConfirmed = () => {
    setSaving(true);
    deleteTrainings({
      variables: {
        trainingIds: [Number(selectedTraining.id)]
      }
    });
  };

  const handleShowTranslationModal = useCallback(
    ({ training }) => {
      setSelectedTraining(training);
      setShowTranslationModal(true);
    },
    [setSelectedTraining, setShowTranslationModal]
  );

  const handleCloseTranslationModal = useCallback(() => {
    setSelectedTraining(null);
    setShowTranslationModal(false);
    clearTrainingTranslations();
  }, [setSelectedTraining, setShowTranslationModal, clearTrainingTranslations]);

  return (
    <ViewContainer
      title={t('trainingManagement.title')}
      flexView={true}
      feedback={response}
      loading={loadingTrainings || loadingUsers || loadingCourses}
      error={errorTrainings || errorUsers || errorCourses}
      clearFeedback={() => setResponse({})}
      actionButton={() => setShowTrainingModal(true)}
      actionButtonLabel={t('trainingManagement.create')}>
      {showDeleteModal ? (
        <WarningModal
          saving={saving}
          response={deleteResponse}
          handleCloseModal={() => handleShowDeleteModal(null, false)}
          message={t('trainingManagement.deleteModal.message') + ' ' + selectedTraining?.name}
          title={t('trainingManagement.deleteModal.title')}
          handleDone={handleDeleteConfirmed}
        />
      ) : null}
      {showTrainingModal ? (
        <TrainingModal
          optionsData={{ users: dataUsers.allUsers, courses: dataCourses?.allCoursesFromOrg }}
          selectedTraining={selectedTraining}
          handleCloseModal={() => handleShowTrainingModal(null, false)}
          setTrainingData={setTrainingData}
          trainingData={trainingData}
        />
      ) : null}
      {showTranslationModal ? (
        <TrainingTranslationsModal
          handleCloseModal={handleCloseTranslationModal}
          selectedTraining={selectedTraining}
        />
      ) : null}
      <TrainingTable
        data={trainingData}
        actionEdit={handleShowTrainingModal}
        actionDelete={handleShowDeleteModal}
        actionEditTranslation={handleShowTranslationModal}
        actionAddTranslation={handleShowTranslationModal}
      />
    </ViewContainer>
  );
};

export default AdminTraining;
