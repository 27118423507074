import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, useTheme } from '@material-ui/core';
import TextInput from 'component/Inputs/TextInput';

const QuestionWrapper = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Grid container item xs={12} style={{ position: 'relative', marginBottom: '50px' }}>
      <div
        style={{
          height: '100%',
          width: '8px',
          backgroundColor: props.error ? theme.palette.error.main : theme.palette.primary.main,
          position: 'absolute',
          marginLeft: '-30px'
        }}
      />
      <Grid item xs={12}>
        <TextInput
          required={false}
          label={t('quizManagement.form.question')}
          value={props.text}
          name="text"
          onChange={(e) => props.buildQuestions(e, props.qIndex, null)}
        />
      </Grid>
      {props.children}
    </Grid>
  );
};

export default QuestionWrapper;
