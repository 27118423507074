import { gql } from '@apollo/client';

const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      success
      errors
      token
      refreshToken
    }
  }
`;

export default REFRESH_TOKEN;
