import { gql } from '@apollo/client';

const ALL_USERS = gql`
  query getAllUsers($orgId: Int!) {
    allUsers(orgId: $orgId) {
      id
      username
      email
      firstName
      lastName
      dateJoined
      lastLogin
      isSuperuser
      trainings {
        id
        name
        details
        translations {
          id
          name
          details
          language {
            code
          }
        }
      }
      trainee {
        hasSession
        lastCreatedSession
      }
      cohorts {
        id
        name
      }
    }
  }
`;

export default ALL_USERS;
