import React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    color: (props) =>
      props.backgroundColor ? theme.palette.background.paper : theme.palette.secondary.main,
    backgroundColor: (props) =>
      props.backgroundColor ? props.color : theme.palette.background.paper,
    cursor: (props) => (props.link ? 'pointer' : 'default'),
    borderRadius: '5px',
    height: (props) => (props.height ? props.height : '10rem'),
    width: '100%',
    border: '1px solid ' + theme.palette.grey['200'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  value: {
    fontWeight: 600,
    fontSize: '3.2rem',
    color: (props) => (props.backgroundColor ? theme.palette.background.paper : props.color),
    flex: '1 1 0%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '35px'
  },
  title: {
    fontWeight: (props) => (props.noData || props.link ? 300 : 400),
    fontSize: '0.9rem',
    textAlign: 'center',
    padding: '15px 0'
  },
  tooltip: {
    position: 'absolute',
    zIndex: '10',
    top: 0,
    right: 0,
    padding: '10px',
    cursor: 'pointer'
  }
}));

const KpiWidget = (props) => {
  const classes = useStyles(props);
  return (
    <Box className={classes.root}>
      {props.information && (
        <Box className={classes.tooltip}>
          <Tooltip title={props.information}>
            <InfoOutlined />
          </Tooltip>
        </Box>
      )}
      <Box className={classes.value}>{props.value}</Box>
      <Box className={classes.title}>{props.text}</Box>
    </Box>
  );
};

export default KpiWidget;
