import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import RowButton from 'component/Table/RowButton';

import Table from '../../../../component/Table/Table';

const QuizTable = (props) => {
  const { t } = useTranslation();
  const columns = [
    {
      name: 'id',
      options: {
        filter: false,
        display: false,
        sort: false
      }
    },
    {
      name: t('sharedLabels.quiz'),
      options: {
        filter: false
      }
    },
    {
      name: t('sharedLabels.course'),
      options: {
        filter: false
      }
    },
    {
      name: t('sharedLabels.training'),
      options: {
        filter: false
      }
    },
    {
      name: '',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => (
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <RowButton
              onClick={() => props.handleShowModal(tableMeta.rowData[5], 'quiz', true)}
              label={t('quizManagement.edit')}
            />
            <RowButton
              onClick={() => props.handleShowModal(tableMeta.rowData[5], 'delete', true)}
              label={t('quizManagement.delete')}
              type="delete"
            />
          </Box>
        )
      }
    },
    {
      name: 'data',
      options: {
        display: false,
        filter: false
      }
    }
  ];

  let rows = props.quizData
    ? props.quizData.map((quiz) => {
        return [quiz.id, quiz.name, quiz.training.course.name, quiz.training.name, '', quiz];
      })
    : [];

  const tableConfig = {
    title: '',
    print: false,
    viewColumns: false,
    search: true,
    download: false,
    filter: false,
    rowData: rows,
    columns: columns,
    flexView: true
  };

  return <Table tableConfig={tableConfig} />;
};

export default QuizTable;
