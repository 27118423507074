import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import TimeLineWidget from 'component/Widgets/TimeLineWidget';
import { ACTIVITY_WITHIN_DATE_RANGE } from 'graphql/ActivityWithinDateRange';
import { buildLastWeekActivity } from 'helpers/buildLastWeekActivity';
import moment from 'moment-timezone';

import { friendlyTimeDisplayFromMinutes } from '../../../../util';

const LastWeekActivity = (props) => {
  const theme = useTheme();
  const collapseLG = useMediaQuery(theme.breakpoints.only('lg'));
  const { t } = useTranslation();

  const today = moment().add(1, 'd').format('YYYY-MM-DD');
  let dateFrom = moment().subtract(7, 'd').format('YYYY-MM-DD');

  let data = null;
  let totalTime;

  const {
    data: dataOrg,
    error,
    loading
  } = useQuery(ACTIVITY_WITHIN_DATE_RANGE, {
    variables: {
      start: dateFrom,
      end: today,
      ...(props.restrictedUser ? props.userId : props.orgId),
      ...(props.filteredBy && props.cohortId),
      ...(props.filteredBy && props.trainingId)
    },
    fetchPolicy: 'no-cache'
  });

  if (dataOrg) {
    data = buildLastWeekActivity(dataOrg);
  }

  totalTime = (
    <Box style={{ display: 'flex' }}>
      {t('dashboard.activityCard.subText')}
      <Box style={{ fontWeight: 700, padding: '0 5px' }}>
        {friendlyTimeDisplayFromMinutes(data?.totalMinutesSpent)}
      </Box>
    </Box>
  );

  return (
    <TimeLineWidget
      height={collapseLG ? '11rem' : props.restrictedUser ? '11rem' : '14rem'}
      error={error}
      loading={loading}
      title={t('dashboard.activityCard.title')}
      subText={totalTime}
      data={data?.chartData}
    />
  );
};

export default LastWeekActivity;
