import React, { useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import FilterButton from 'component/Buttons/FilterButton';
import UserDetailsFilter from 'component/ViewFilters/UserDetailsFilter';
import UserGroupsFilter from 'component/ViewFilters/UserGroupsFilter';
import * as filterTypes from 'constants/filters';

import EnrolledUsersFilter from './EnrolledUsersFilter';

const Filter = (props) => {
  const theme = useTheme();
  const collapse = useMediaQuery(theme.breakpoints.only('xs'));
  const [showFilter, setShowFilter] = useState(false);

  const filterButton = (
    <FilterButton onClick={() => setShowFilter(!showFilter)} disabled={props.disabled} />
  );

  let filter;
  switch (props.filterType) {
    case filterTypes.ENROLLED_USER:
      filter = (
        <EnrolledUsersFilter
          collapse={collapse}
          filterButton={filterButton}
          data={props.data}
          setFilteredData={props.setFilteredData}
          filterOptions={props.filterOptions}
          disabled={props.disabled}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
        />
      );
      break;
    case filterTypes.USER_GROUPS:
      filter = (
        <UserGroupsFilter
          collapse={collapse}
          filterButton={filterButton}
          data={props.data}
          setFilteredData={props.setFilteredData}
          filterOptions={props.filterOptions}
          disabled={props.disabled}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          filterLocation={props.filterLocation}
        />
      );
      break;
    case filterTypes.USER_DETAILS:
      filter = (
        <UserDetailsFilter
          collapse={collapse}
          filterButton={filterButton}
          setFilteredData={props.setFilteredData}
          filterOptions={props.filterOptions}
          selectedFilter={props.selectedFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          loading={props.loading}
        />
      );
      break;
    default:
      filter = null;
  }

  return filter;
};

export default Filter;
