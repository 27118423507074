import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';

import Alert from '../Alert';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: 1,
    margin: theme.spacing(1),
    position: 'absolute',
    top: 0,
    right: 0
  }
}));

export default function Feedback(props) {
  const { type, message } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setOpen(false);
    }, 10000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  if (!open) {
    return null;
  } else {
    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="feedback-title"
          aria-describedby="feedback-description"
          classes={{
            paper: classes.dialog
          }}>
          <Alert variant="filled" severity={type}>
            {message}
          </Alert>
        </Dialog>
      </div>
    );
  }
}
