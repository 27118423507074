import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    width: '100%',
    backgroundColor: theme.palette.grey['300'],
    padding: '10px 20px',
    fontWeight: 500,
    wordBreak: 'break-word',
    whiteSpace: 'normal'
  },
  textContent: {
    paddingBottom: '10px',
    height: '78%',
    overflowY: 'auto'
  },
  text: {
    padding: '8px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #f0f2f8'
  },
  name: {
    width: '85%',
    fontSize: '0.8rem'
  },
  value: {
    color: (props) => props.data.color,
    fontWeight: 600,
    fontSize: '1.1rem'
  },
  nameOther: {
    width: '85%',
    fontSize: '0.7rem'
  },
  valueOther: {
    color: (props) => props.data.color,
    fontWeight: 600,
    fontSize: '0.9rem'
  },
  opacity: {
    width: '85%',
    opacity: '0.7'
  },
  totalValue: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    fontSize: '0.9rem'
  }
}));

const ListData = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  let sum = 0;
  return (
    <>
      <Box className={classes.title}>{props.data.title}</Box>
      <Box className={classes.textContent}>
        {props.data.tooltipData.map((data) => {
          sum += data.value;
          return (
            <Box key={data.name} className={classes.text}>
              <Box className={classes.name}>{data.name}</Box>
              <Box className={classes.value}>{data.value}</Box>
            </Box>
          );
        })}
        <Box className={classes.text}>
          <Box className={classes.nameOther}>{t('general.other')}</Box>
          <Box className={classes.valueOther}>{props.data.y - sum}</Box>
        </Box>
      </Box>
      <Box className={`${classes.text}`}>
        <Box className={classes.nameOther}>{t('general.total')}</Box>
        <Box className={classes.totalValue}>{props.data.y}</Box>
      </Box>
    </>
  );
};

export default ListData;
