import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import FormSubTitle from '../../../../../../component/Inputs/FormSubTitle';
import SelectInput from '../../../../../../component/Inputs/SelectInputs/SelectInput';
import TextInput from '../../../../../../component/Inputs/TextInput';
import { gridSpacing } from '../../../../../../ducks/constant';

const QuizInfo = (props) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12}>
        <FormSubTitle
          title={t('quizManagement.form.title1')}
          subText={'* ' + t('quizManagement.form.subText1')}
        />
      </Grid>
      <Grid container spacing={gridSpacing} item xs={12}>
        <Grid item xs={7}>
          <TextInput
            required={true}
            label={t('quizManagement.form.quizName')}
            value={props.quizInfo.name}
            name="name"
            onChange={props.setQuizInfo}
            error={props.errors?.name}
          />
        </Grid>
        <Grid item xs={5}>
          <SelectInput
            label={t('sharedLabels.trainings')}
            selected={props.quizInfo.trainingId}
            name="trainingId"
            onChange={props.setQuizInfo}
            menuItems={props.trainings}
            required={true}
            error={props.errors?.trainingId}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuizInfo;
