import React, { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ConfirmButton from 'component/Buttons/ConfirmButton';
import { useLanguages } from 'hooks';

const LabelExportCSV = ({ filename, data, buttonText, buttonDownloadRef }) => {
  const { languages } = useLanguages();
  const { t } = useTranslation();
  const isEmpty = useMemo(() => data.length === 0, [data]);

  const onClick = (event, done) => {
    if (!isEmpty) {
      buttonDownloadRef.current.link.click();
      done();
    } else {
      toast.error(t('textManagement.error.selectTrainingGroups'));
    }
  };

  const formattedHeaders = useMemo(() => {
    if (languages.length !== 0) {
      const dynamicLanguages = languages.map(({ name, code }) => {
        return {
          label: `${name}(${code})`,
          key: code
        };
      });
      return [
        { label: 'Key', key: 'key' },
        { label: 'Id', key: 'id' }
      ].concat(dynamicLanguages);
    }
    return [];
  }, [languages]);

  return (
    <>
      <CSVLink
        asyncOnClick={true}
        ref={buttonDownloadRef}
        filename={filename}
        data={data}
        headers={formattedHeaders}
      />
      <ConfirmButton size="small" onClick={onClick}>
        {buttonText}
      </ConfirmButton>
    </>
  );
};

export default LabelExportCSV;
