import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ADD_TOKEN, GET_TOKEN, GET_TOKEN_TRANSATIONS, UPDATE_TOKEN } from 'graphql/Token';

const OrganizationTokenContext = createContext({});

const SUCCESSFULLY_ADDED = 'SUCCESSFULLY_ADDED';
const SUCCESSFULLY_UPDATED = 'SUCCESSFULLY_UPDATED';

export const OrganizationTokenProvider = ({ children }) => {
  const { t } = useTranslation();
  const [token, setToken] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [status, setStatus] = useState(null);

  const resetTransactions = useCallback(() => {
    setTransactions([]);
  }, [setTransactions]);

  const resetToken = useCallback(() => {
    setToken(null);
  }, [setToken]);

  const resetStatus = useCallback(() => {
    setStatus(null);
  }, [setStatus]);

  const [viewTransactionsRequest, { loading: loadingViewTransactionsRequest }] = useLazyQuery(
    GET_TOKEN_TRANSATIONS,
    {
      onCompleted(response) {
        setTransactions(response.tokenTransactions);
      },
      onError() {
        toast.error(t('organizationManagement.token.error.failedFetchingTransactions'));
      }
    }
  );

  const [getTokenRequest, { loading: loadingGetTokenRequest }] = useLazyQuery(GET_TOKEN, {
    onCompleted(response) {
      setToken(response.token);
    },
    onError(response) {
      if (response.data.token !== null) {
        setToken(null);
        toast.error(t('organizationManagement.token.error.failedFetchingToken'));
      }
    }
  });

  const [addTokenRequest, { loading: loadingAddTokenRequest }] = useMutation(ADD_TOKEN, {
    onCompleted(response) {
      if (response.addToken.success) {
        setToken(response.addToken.token);
        setTransactions(response.addToken.token.transactions);
        setStatus(SUCCESSFULLY_ADDED);
        toast.success(t('organizationManagement.token.success.addToken'));
      }
      if (response.addToken.error) {
        toast.success(response.addToken.error);
      }
    },
    onError(response) {
      console.error('response: ', response);
      toast.error(t('organizationManagement.token.error.failedAddToken'));
    }
  });

  const [updateTokenRequest, { loading: loadingUpdateTokenRequest }] = useMutation(UPDATE_TOKEN, {
    onCompleted(response) {
      if (response.updateToken.success) {
        setToken(response.updateToken.token);
        setTransactions(response.updateToken.token.transactions);
        setStatus(SUCCESSFULLY_UPDATED);
        toast.success(t('organizationManagement.token.success.updateToken'));
      }
      if (response.updateToken.error) {
        toast.success(response.updateToken.error);
      }
    },
    onError(response) {
      console.error('response: ', response);
      toast.error(t('organizationManagement.token.error.failedUpdateQuantity'));
    }
  });

  const addToken = useCallback(
    ({ organizationId, quantity }) => {
      addTokenRequest({
        variables: {
          org: organizationId,
          qty: quantity
        }
      });
    },
    [addTokenRequest]
  );

  const updateToken = useCallback(
    ({ organizationId, quantity }) => {
      updateTokenRequest({
        variables: {
          org: organizationId,
          qty: quantity
        }
      });
    },
    [updateTokenRequest]
  );

  const viewTransactions = useCallback(
    ({ organizationId }) => {
      setTransactions([]);
      viewTransactionsRequest({
        variables: {
          org: organizationId
        }
      });
    },
    [setTransactions, viewTransactionsRequest]
  );

  const getTokenDetails = useCallback(
    ({ organizationId }) => {
      getTokenRequest({
        variables: {
          org: organizationId
        }
      });
    },
    [getTokenRequest]
  );

  const isSuccessfullyAdded = useMemo(() => {
    return status === SUCCESSFULLY_ADDED;
  }, [status]);

  const isSuccessfullyUpdated = useMemo(() => {
    return status === SUCCESSFULLY_UPDATED;
  }, [status]);

  const isEmptyToken = useMemo(() => {
    return token === null;
  }, [token]);

  const loading = useMemo(() => {
    return (
      loadingAddTokenRequest ||
      loadingGetTokenRequest ||
      loadingUpdateTokenRequest ||
      loadingViewTransactionsRequest
    );
  }, [
    loadingAddTokenRequest,
    loadingGetTokenRequest,
    loadingUpdateTokenRequest,
    loadingViewTransactionsRequest
  ]);

  return (
    <OrganizationTokenContext.Provider
      value={{
        isEmptyToken,
        isSuccessfullyAdded,
        isSuccessfullyUpdated,
        loadingViewTransactionsRequest,
        transactions,
        token,
        loading,
        resetStatus,
        getTokenDetails,
        resetToken,
        addToken,
        updateToken,
        viewTransactions,
        resetTransactions
      }}>
      {children}
    </OrganizationTokenContext.Provider>
  );
};

export const useOrganizationToken = () => useContext(OrganizationTokenContext);
