import { useState } from 'react';

const usePasswordMatch = () => {
  const [error, setError] = useState(false);
  const onBlurPasswordCheck = (passwordOne, passwordTwo) => {
    if (passwordOne.length > 0 && passwordTwo.length > 0) {
      if (passwordOne !== passwordTwo) {
        setError(true);
      } else {
        setError(false);
      }
    }
  };
  return { error, onBlur: onBlurPasswordCheck };
};

export default usePasswordMatch;
