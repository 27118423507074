export const buildBreadcrumbs = (crumb) => {
  let breadcrumbs = [];
  if (crumb === 'clear') {
    window.localStorage.removeItem('breadcrumb');
  } else {
    if (JSON.parse(window.localStorage.getItem('breadcrumb'))) {
      breadcrumbs = JSON.parse(window.localStorage.getItem('breadcrumb'));
      if (crumb) {
        breadcrumbs.push(crumb);
      } else {
        breadcrumbs.pop();
      }
      window.localStorage.setItem('breadcrumb', JSON.stringify(breadcrumbs));
    } else {
      if (crumb) {
        window.localStorage.setItem('breadcrumb', JSON.stringify([crumb]));
      }
    }
  }
};
