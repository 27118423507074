import React, { useEffect, useState } from 'react';
import { useCookies, withCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useLazyQuery } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { jssPreset, StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { useOrganization } from 'hooks';
import { useAuth } from 'hooks';
import { create } from 'jss';
import rtl from 'jss-rtl';

import { useLogout } from './api/Logout';
import Cookies from './component/Cookies';
import { setSelectedNavItem } from './ducks/nav';
import { USER_INFO, USER_SELF_INFO } from './graphql/UserInfo';
import { useUser } from './hooks';
import Routes from './Routes';
import theme from './themes';

function loadLocaleData(locale) {
  switch (locale) {
    case 'fr':
      return import('./compiled-lang/fr.json');
    case 'ro':
      return import('./compiled-lang/ro.json');
    case 'zh':
      return import('./compiled-lang/zh.json');
    default:
      return import('./compiled-lang/en.json');
  }
}

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const App = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { setAuthenticated } = useAuth();
  const { user, setUser } = useUser();
  const { getOrganization } = useOrganization();
  const { logout } = useLogout();
  const customization = useSelector((state) => state.customization);
  const [messages, setMessages] = useState();
  const [showCookies, setShowCookies] = useState(true);
  const [cookies] = useCookies(['token']);

  const buildUserData = (user) => {
    if (sessionStorage.getItem('manualLang')) {
      i18n.changeLanguage(sessionStorage.getItem('manualLang'));
    } else {
      if (user.profile?.preferredLanguage?.code) {
        i18n.changeLanguage(user.profile.preferredLanguage.code);
      }
    }
    if (user.profile?.preferredLanguage?.code) {
      //For Login View to remember language pref
      localStorage.setItem('lng', user.profile.preferredLanguage.code);
    }
    getOrganization();
  };

  const [getUser, { error: userError }] = useLazyQuery(USER_INFO, {
    onCompleted(data) {
      if (data.getUser) {
        setUser(data.getUser);
        buildUserData(data.getUser);
      }
    }
  });

  const [getUserSelfInfo, { data: userSelfData, refetch }] = useLazyQuery(USER_SELF_INFO, {
    onCompleted(data) {
      if (data.getUserSelfInfo) {
        setUser(data.getUserSelfInfo);
      }
    }
  });

  useEffect(() => {
    if (user) {
      if (userSelfData) {
        buildUserData(userSelfData.getUserSelfInfo);
      } else {
        refetch();
      }
    }
  }, [user, userSelfData]);

  useEffect(() => {
    loadLocaleData(customization.locale).then((d) => {
      setMessages(d.default);
    });
  }, [customization]);

  useEffect(() => {
    if (window.sessionStorage.getItem('navItem')) {
      dispatch(setSelectedNavItem(window.sessionStorage.getItem('navItem')));
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('allowCookies')) {
      setShowCookies(false);
    }
    if (
      (window.location.href.indexOf('password-reset/') !== -1 &&
        window.location.href.split('password-reset/')[1].length > 0) ||
      (window.location.href.indexOf('password-set/') !== -1 &&
        window.location.href.split('password-set/')[1].length > 0)
    ) {
      logout();
    } else if (
      !user &&
      cookies['token'] &&
      localStorage.getItem('orgId') &&
      localStorage.getItem('userId') &&
      localStorage.getItem('role')
    ) {
      if (JSON.parse(localStorage.getItem('role')) === 'user') {
        getUserSelfInfo({
          variables: {
            userId: Number(localStorage.getItem('userId'))
          }
        });
      } else {
        getUser({
          variables: {
            userId: Number(localStorage.getItem('userId'))
          }
        });
      }
      setAuthenticated(true);
    } else {
      logout();
    }
  }, []);

  useEffect(() => {
    if (userError) {
      handleLogout();
    }
  }, [userError]);

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      {messages && (
        <IntlProvider locale={customization.locale} defaultLocale="en" messages={messages}>
          <CssBaseline />
          <StylesProvider jss={jss}>
            <ThemeProvider theme={theme(customization)}>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              {showCookies && <Cookies setShowCookies={setShowCookies} />}
              <Routes />
            </ThemeProvider>
          </StylesProvider>
        </IntlProvider>
      )}
    </>
  );
};

export default withCookies(App);
