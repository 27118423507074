import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  loader: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    paddingTop: '3rem'
  }
}));

const Loader = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  return (
    <Box className={classes.loader}>
      <CircularProgress size={100} />
      <Typography className={classes.text}>{t('general.loadingContent')}</Typography>
    </Box>
  );
};

export default Loader;
