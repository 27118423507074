import React from 'react';

export const AdminIconBlue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.615"
      height="19.626"
      viewBox="0 0 18.615 17.626">
      <g id="Group_26" data-name="Group 26" transform="translate(-32.427 -132.177)">
        <path
          id="admin_panel_settings_FILL0_wght600_GRAD0_opsz48"
          d="M14.411,20.951h-.192c-.057,0,.1.133,0,.09A10.25,10.25,0,0,1,7.3,11.306V7.2a1.729,1.729,0,0,1,.3-.979,1.651,1.651,0,0,1,.788-.639l5.429-2.023a1.722,1.722,0,0,1,1.192,0l5.429,2.023a1.711,1.711,0,0,1,.8.639,1.687,1.687,0,0,1,.309.979c.193,2.478-1.629,1.115-1.7-.021h0L14.411,5.2h0L8.982,7.176h0v4.13a8.016,8.016,0,0,0,.532,2.981,9.118,9.118,0,0,0,1.331,2.342,8.105,8.105,0,0,0,1.746,1.65,6.683,6.683,0,0,0,1.778.9,2.634,2.634,0,0,0,.479.841c.234.291.185.451,0,.494S14.567,20.951,14.411,20.951Zm0-8.772Z"
          transform="translate(25.127 128.727)"
          fill="#2e3c77"
        />
        <g id="personBlack" transform="translate(5.297 68.99)">
          <path
            id="Path_34"
            data-name="Path 34"
            d="M1067.056,134.868a3.8,3.8,0,1,1,2.682-1.113A3.8,3.8,0,0,1,1067.056,134.868Zm0-6.183a2.395,2.395,0,1,0,1.689.7A2.393,2.393,0,0,0,1067.056,128.685Z"
            transform="translate(-1027.414 -60.092)"
            fill="#2e3c77"
          />
          <path
            id="Path_35"
            data-name="Path 35"
            d="M1034.132,253.685a.7.7,0,0,1-.7-.7,4.7,4.7,0,1,0-9.405,0,.7.7,0,1,1-1.4,0,6.1,6.1,0,1,1,12.208,0,.7.7,0,0,1-.7.7Z"
            transform="translate(-989.088 -172.892)"
            fill="#2e3c77"
          />
        </g>
      </g>
    </svg>
  );
};

export const AdminIconBlack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.615"
      height="19.626"
      viewBox="0 0 18.615 17.626">
      <path
        id="admin_panel_settings_FILL0_wght600_GRAD0_opsz48"
        d="M14.411,20.951h-.192c-.057,0,.1.133,0,.09A10.25,10.25,0,0,1,7.3,11.306V7.2a1.729,1.729,0,0,1,.3-.979,1.651,1.651,0,0,1,.788-.639l5.429-2.023a1.722,1.722,0,0,1,1.192,0l5.429,2.023a1.711,1.711,0,0,1,.8.639,1.687,1.687,0,0,1,.309.979c.193,2.478-1.629,1.115-1.7-.021h0L14.411,5.2h0L8.982,7.176h0v4.13a8.016,8.016,0,0,0,.532,2.981,9.118,9.118,0,0,0,1.331,2.342,8.105,8.105,0,0,0,1.746,1.65,6.683,6.683,0,0,0,1.778.9,2.634,2.634,0,0,0,.479.841c.234.291.185.451,0,.494S14.567,20.951,14.411,20.951Zm0-8.772Z"
        transform="translate(-7.3 -3.45)"
        fill="#231f20"
      />
      <g id="personBlack" transform="translate(-27.13 -63.187)">
        <path
          id="Path_34"
          data-name="Path 34"
          d="M1067.056,134.868a3.8,3.8,0,1,1,2.682-1.113A3.8,3.8,0,0,1,1067.056,134.868Zm0-6.183a2.395,2.395,0,1,0,1.689.7A2.393,2.393,0,0,0,1067.056,128.685Z"
          transform="translate(-1027.414 -60.092)"
          fill="#231f20"
        />
        <path
          id="Path_35"
          data-name="Path 35"
          d="M1034.132,253.685a.7.7,0,0,1-.7-.7,4.7,4.7,0,1,0-9.405,0,.7.7,0,1,1-1.4,0,6.1,6.1,0,1,1,12.208,0,.7.7,0,0,1-.7.7Z"
          transform="translate(-989.088 -172.892)"
          fill="#231f20"
        />
      </g>
    </svg>
  );
};
