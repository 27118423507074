import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext({});
export const DEFAULT_TIMEZONE = 'Europe/Amsterdam';
export const DEFAULT_LANGUAGE_ID = 1; // English

export const UserProvider = ({ value, children }) => {
  const [user, setUser] = useState(value);

  const isCourseAdmin =
    user?.organizationRoles.length > 0 &&
    user.organizationRoles.find((role) => role.permission === 'course-admin');
  const isAdmin =
    user?.organizationRoles.length > 0 &&
    user.organizationRoles.find((role) => role.permission === 'admin');
  const isSuperUser = user?.isSuperuser;
  const restrictedUser = !isAdmin && !isSuperUser && !isCourseAdmin;

  const getUserTimezone = () => {
    return user.profile !== null ? user.profile.timezone : DEFAULT_TIMEZONE;
  };

  const getUserPreferredLanguageId = () => {
    return user.profile !== null ? user.profile.preferredLanguage.id : DEFAULT_LANGUAGE_ID;
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        getUserTimezone,
        getUserPreferredLanguageId,
        isSuperUser,
        restrictedUser,
        isCourseAdmin,
        isAdmin
      }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
