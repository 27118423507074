import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, useTheme } from '@material-ui/core';
import KpiWidget from 'component/Widgets/KpiWidget';

const SessionsPerformance = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const performance = [
    {
      text: t('userDetails.sessionsPerformance.passed'),
      performance: props.performance.sessionsPassed,
      color: theme.palette.success.main,
      backgroundColor: true,
      information: t('tooltips.sessionsPassed')
    },
    {
      text: t('userDetails.sessionsPerformance.incomplete'),
      performance: props.performance.sessionsIncomplete,
      color: theme.palette.text.hint,
      backgroundColor: false,
      information: t('tooltips.sessionsIncomplete')
    },
    {
      text: t('userDetails.sessionsPerformance.failed'),
      performance: props.performance.sessionsFailed,
      color: theme.palette.error.main,
      backgroundColor: false,
      information: t('tooltips.sessionsFailed')
    }
  ];

  return performance.map((widget) => {
    return (
      <Grid key={widget.text} item xs={12} sm={4}>
        <KpiWidget
          text={widget.text}
          value={widget.performance}
          color={widget.color}
          information={widget.information}
          backgroundColor={widget.backgroundColor}
        />
      </Grid>
    );
  });
};

export default SessionsPerformance;
