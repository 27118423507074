const buildPerformance = (trainings, selectedSwitch) => {
  let performanceObj = {
    totalTrainings: 0,
    passedTrainings: 0,
    sessionsPassed: 0,
    sessionsIncomplete: 0,
    sessionsFailed: 0
  };

  trainings.forEach((training) => {
    performanceObj.totalTrainings++;
    if (selectedSwitch === 'assessment') {
      training.assessmentApproved && performanceObj.passedTrainings++;
      training.assessments.forEach((assessment) => {
        assessment.performance === 'pass' && performanceObj.sessionsPassed++;
        assessment.performance === 'noData' && performanceObj.sessionsIncomplete++;
        assessment.performance === 'fail' && performanceObj.sessionsFailed++;
      });
    } else {
      training.practiceApproved && performanceObj.passedTrainings++;
      training.practices.forEach((practice) => {
        practice.performance === 'pass' && performanceObj.sessionsPassed++;
        practice.performance === 'noData' && performanceObj.sessionsIncomplete++;
        practice.performance === 'fail' && performanceObj.sessionsFailed++;
      });
    }
  });

  return performanceObj;
};

export default buildPerformance;
