import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import SelectInput from 'component/Inputs/SelectInputs/SelectInput';
import Switch from 'component/Switch/Switch';
import CollapsedFilter from 'component/ViewFilters/CollapsedFilter';
import { useTrainingTranslation } from 'hooks';

const UserDetailsFilter = (props) => {
  const { getTranslatedNameFromTraining } = useTrainingTranslation();
  const { t } = useTranslation();
  const handleSwitch = (selected) => {
    props.setFilteredData(selected, 'switch');
  };
  const handleCollapsedFiltering = (name, value) => {
    if (name === 'selectedSwitch') {
      handleSwitch(value.id);
    } else {
      props.setFilteredData({ target: { name, value: value.id } }, 'collapsed');
    }
  };

  const trainingOptions = props.filterOptions.trainings.options.map((option) => {
    return {
      ...option,
      name: getTranslatedNameFromTraining({ training: option })
    };
  });

  return (
    <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      {props.collapse ? (
        <>
          {props.filterButton}
          <CollapsedFilter
            showFilter={props.showFilter}
            setShowFilter={props.setShowFilter}
            filterOptions={props.filterOptions}
            setFilters={handleCollapsedFiltering}
            selectedFilters={{
              course: props.selectedFilter.course,
              training: props.selectedFilter.training,
              selectedSwitch: props.selectedFilter.selectedSwitch
            }}
          />
        </>
      ) : (
        <Box style={{ width: '100%', display: 'flex' }}>
          <Box style={{ flex: '1 1 0%', paddingRight: '10px' }}>
            <SelectInput
              multiple={false}
              label={
                props.selectedFilter.course?.length > 0
                  ? t('sharedLabels.courses')
                  : props.filterOptions.courses.options.length + ' ' + t('sharedLabels.courses')
              }
              selected={props.selectedFilter.course}
              name="course"
              onChange={(e) => props.setFilteredData(e, 'drop')}
              menuItems={props.filterOptions.courses.options}
              disabled={props.filterOptions.courses.options.length < 1}
              group={false}
              required={false}
              canSelectNone={true}
            />
          </Box>
          <Box style={{ flex: '1 1 0%', paddingRight: '10px' }}>
            <SelectInput
              multiple={false}
              label={
                props.selectedFilter.training?.length > 0
                  ? t('sharedLabels.trainings')
                  : trainingOptions.length + ' ' + t('sharedLabels.trainings')
              }
              selected={props.selectedFilter.training}
              name="training"
              onChange={(e) => props.setFilteredData(e, 'drop')}
              menuItems={trainingOptions}
              disabled={trainingOptions.length < 1}
              group={false}
              required={false}
              canSelectNone={true}
            />
          </Box>
          <Switch
            menuItems={props.filterOptions.assessmentSwitch.options}
            textLeft={props.filterOptions.assessmentSwitch.options[0]}
            textRight={props.filterOptions.assessmentSwitch.options[1]}
            selected={props.selectedFilter.selectedSwitch}
            onClick={handleSwitch}
            disabled={props.loading}
          />
        </Box>
      )}
    </Box>
  );
};

export default UserDetailsFilter;
