import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import RowButton from 'component/Table/RowButton';
import Table from 'component/Table/Table';
import { useOrganization, useUser } from 'hooks';

const OrganizationTable = (props) => {
  const { isSuperUser, isCourseAdmin } = useUser();
  const { organizations } = useOrganization();
  const { t } = useTranslation();

  const columns = [
    {
      name: 'Id',
      options: {
        filter: false
      }
    },
    {
      name: 'Name',
      options: {
        filter: false
      }
    },
    {
      name: 'Logo',
      options: {
        filter: false,
        customBodyRender: (avatar) => {
          return <img src={avatar} height="45px" width="auto" alt="logo" />;
        }
      }
    },
    {
      name: 'Token Quantity',
      options: {
        filter: false,
        customBodyRender: (organization) => {
          return organization.token ? organization.token.quantity : 0;
        }
      }
    },
    {
      name: '',
      options: {
        filter: false,
        customBodyRender: (_, tableMeta) => {
          return (
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <RowButton
                onClick={() => {
                  props.handleOnEdit({ organization: tableMeta.rowData[3] });
                }}
                label={t('organizationManagement.table.edit')}
              />
              {isSuperUser || isCourseAdmin ? (
                <RowButton
                  onClick={() => {
                    props.handleManageToken({ organization: tableMeta.rowData[3] });
                  }}
                  label={t('organizationManagement.token.manageToken')}
                />
              ) : null}
            </Box>
          );
        }
      }
    }
  ];

  const rows =
    organizations.length !== 0
      ? organizations.map((organization) => {
          return [
            organization.id,
            organization.name,
            organization.avatar,
            organization,
            organization
          ];
        })
      : [];

  const tableConfig = {
    title: t('organizationManagement.table.title'),
    print: false,
    viewColumns: false,
    search: true,
    download: false,
    filter: false,
    rowData: rows,
    columns: columns,
    flexView: true
  };

  return <Table tableConfig={tableConfig} />;
};

export default OrganizationTable;
