import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ViewContainer from 'component/ViewContainer/ViewContainer';
import { useLanguages } from 'hooks';

import Modal from './Modal';
import Table from './Table';

const LanguagesManagement = () => {
  const { loading, isSuccess, setIsSuccess, languages, getAvailableLanguages } = useLanguages();
  const { t } = useTranslation();
  const [toggleModal, setToggleModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const closeModal = () => setToggleModal(false);

  const openModal = () => setToggleModal(true);

  const handleCloseModal = () => {
    closeModal();
    setSelectedLanguage(null);
  };

  const onEditLanguage = useCallback(
    ({ id }) => {
      const foundLanguage = languages.filter((language) => {
        return language.id === id;
      });
      if (foundLanguage.length === 0) {
        toast.error(t('languagesManagement.error.notFound'));
        return;
      }
      setSelectedLanguage(foundLanguage[0]);
      openModal();
    },
    [languages, selectedLanguage]
  );

  const onAddLanguage = () => {
    openModal();
  };

  useEffect(() => {
    getAvailableLanguages();
  }, []);

  useEffect(() => {
    if (isSuccess && toggleModal) {
      setIsSuccess(false);
      closeModal();
    }
  }, [isSuccess, toggleModal, setIsSuccess]);

  return (
    <ViewContainer
      actionButtonLoading={loading}
      title={t('languagesManagement.title')}
      flexView={true}
      actionButton={() => onAddLanguage()}
      actionButtonLabel={t('languagesManagement.view')}>
      {toggleModal ? (
        <Modal handleCloseModal={handleCloseModal} selectedLanguage={selectedLanguage} />
      ) : null}
      <Table onEditLanguage={onEditLanguage} />
    </ViewContainer>
  );
};

export default LanguagesManagement;
