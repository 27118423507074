const textCsvCleanUp = (text) => {
  return text.replaceAll('"', '""');
};

export const mapTextDataToCsv = (labels, languageCodeLists) => {
  const getText = (raw, code) => {
    const language = raw.texts.filter((text) => text.languageCode === code)[0];
    return language && language.text ? textCsvCleanUp(language.text) : '';
  };
  return labels.map((label) => {
    const matchTextByCodes = {};
    languageCodeLists.forEach((languageCodeList) => {
      matchTextByCodes[languageCodeList] = getText(label, languageCodeList);
    });
    return {
      ...{
        key: label.key,
        id: label.id
      },
      ...matchTextByCodes
    };
  });
};
