const buildUserData = (user) => {
  let userData = [];
  if (user) {
    userData = user.trainings.map((training) => {
      let assessmentApproved = false;
      training.sessions.forEach((session) => {
        if (session.eventSet.length > 0) {
          const hardErrors = session.eventSet.filter((eventSet) => eventSet.errorSeverity === 2);
          if (session.isComplete && session.completedAt) {
            if (hardErrors.length < 1) {
              if (session.category === 'assessment') {
                assessmentApproved = true;
              }
            }
          }
        }
      });
      return {
        id: training.id,
        name: training.name,
        assessmentApproved,
        course: training.course
      };
    });
  }
  return userData;
};

export default buildUserData;
