import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Radio } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';

import TextInput from '../../../../../../../component/Inputs/TextInput';

const QuestionChoices = (props) => {
  const { t } = useTranslation();
  const handleChecked = (e) => {
    props.buildQuestions(e, props.qIndex, props.cIndex);
  };
  return (
    <Grid item container xs={12} direction="row" alignItems="center">
      <Grid item xs={1}>
        <Radio
          style={{ marginTop: '10px' }}
          color="primary"
          name="correctAnswer"
          checked={props.correctAnswer}
          onChange={handleChecked}
        />
      </Grid>
      <Grid item xs={10}>
        <TextInput
          required={false}
          label={t('quizManagement.form.choice')}
          value={props.text}
          name="text"
          onChange={(e) => props.buildQuestions(e, props.qIndex, props.cIndex)}
        />
      </Grid>
      <Grid item xs={1}>
        <Cancel
          style={{ margin: '15px 0 0 20px' }}
          onClick={() => props.removeChoice(props.qIndex, props.cIndex)}
        />
      </Grid>
    </Grid>
  );
};

export default QuestionChoices;
