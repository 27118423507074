import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FormSubTitle from '../../../../../component/Inputs/FormSubTitle';
import Chip from '../../../../../component/Inputs/SelectInputs/Chip';
import SelectInput from '../../../../../component/Inputs/SelectInputs/SelectInput';

const useStyles = makeStyles({
  paddingBottom: {
    paddingBottom: '1.2rem'
  },
  paddingRight: {
    paddingRight: '1.2rem',
    alignSelf: 'flex-start'
  }
});

const CoursesAndTrainings = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleOnChangeCourse = (value) => {
    let trainings = [...props.userDetails.trainings];
    props.courseMenuItems.forEach((course) => {
      if (!value.includes(course.id)) {
        course.children.forEach((child) => {
          if (trainings.includes(child.id)) {
            trainings.splice(trainings.indexOf(child.id), 1);
          }
        });
      }
    });
    props.handleOnChange(value, trainings);
  };

  const handleOnChangeTraining = (e) => {
    props.handleOnChange(props.userDetails.courses, e.target.value);
  };

  const handleOnDeleteChip = (value, name) => {
    if (name === 'courses') {
      handleOnChangeCourse(props.userDetails.courses.filter((item) => item !== value));
    } else {
      props.handleOnChange(
        props.userDetails.courses,
        props.userDetails.trainings.filter((item) => item !== value)
      );
    }
  };

  let disableTrainings = true;

  const filteredMenuItems = props.courseMenuItems.filter((course) => {
    if (props.userDetails.courses.length > 0 && props.userDetails.courses.includes(course.id)) {
      if (course.children.length > 0) {
        disableTrainings = false;
      }
      return course;
    }
  });

  return (
    <Grid container item className={classes.paddingBottom}>
      <Grid item xs={12}>
        <FormSubTitle title={t('userManagement.form.title3')} />
      </Grid>
      <Grid container item xs={6} className={classes.paddingRight}>
        <Grid item xs={12}>
          <SelectInput
            multiple={true}
            label={t('sharedLabels.courses')}
            selected={props.userDetails.courses}
            name="courses"
            onChange={(e) => handleOnChangeCourse(e.target.value)}
            menuItems={props.courseMenuItems}
            group={false}
            required={false}
            topMenu={true}
          />
        </Grid>
        <Grid item xs={12}>
          {props.userDetails.courses.length > 0 ? (
            props.userDetails.courses.map((course, i) => {
              let foundCourse = props.courseMenuItems.find((item) => item.id === course);
              if (foundCourse) {
                return (
                  <Chip
                    key={foundCourse.id + i}
                    onDelete={handleOnDeleteChip}
                    id={foundCourse.id}
                    label={foundCourse.name}
                    name="courses"
                    color={foundCourse.color}
                  />
                );
              }
            })
          ) : (
            <Typography style={{ color: '#00000055' }}>
              {t('inputs.select.noCoursesSelected')}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container item xs={6} className={classes.paddingRight}>
        <Grid item xs={12}>
          <SelectInput
            multiple={true}
            label={t('sharedLabels.trainings')}
            selected={props.userDetails.trainings}
            name="trainings"
            onChange={handleOnChangeTraining}
            menuItems={filteredMenuItems}
            group={true}
            required={false}
            disabled={disableTrainings}
            topMenu={true}
          />
        </Grid>
        <Grid container item xs={12}>
          {props.userDetails.trainings.length > 0 ? (
            filteredMenuItems.map((group, i) => {
              return (
                <Grid key={i} item xs={12}>
                  {group.children.map((child, i) => {
                    if (props.userDetails.trainings.includes(child.id)) {
                      return (
                        <Chip
                          key={child.id + i}
                          onDelete={handleOnDeleteChip}
                          id={child.id}
                          label={child.name}
                          name="trainings"
                          color={group.color}
                        />
                      );
                    }
                  })}
                </Grid>
              );
            })
          ) : (
            <Typography style={{ color: '#00000055' }}>
              {t('inputs.select.noTrainingsSelected')}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CoursesAndTrainings;
