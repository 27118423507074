import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styled from 'styled-components';

import 'react-lazy-load-image-component/src/effects/blur.css';

const StyledUploadLogoWrapper = styled.div`
  margin-bottom: 10px;
  img {
    max-height: 100px;
  }
`;

const UploadLogoWrapper = ({ src = null }) => {
  if (src === null) {
    return null;
  }
  return (
    <StyledUploadLogoWrapper>
      {src !== '' ? (
        <LazyLoadImage delayTime={1000} src={src} effect="blur" />
      ) : (
        <div>Logo failed to load</div>
      )}
    </StyledUploadLogoWrapper>
  );
};

export default UploadLogoWrapper;
