import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

const TableOption = createContext(false);
const DEFAULT_ROWS_PER_PAGE = 10;
const ROWS_OPTIONS = [10, 25, 50, 100];

export const TableOptionProvider = ({ children }) => {
  const [sortOrder, setSortOrder] = useState(null);
  const [rowsPerPage, setDefaultRowsPerPage] = useState(null);
  const [rowsOptions] = useState(ROWS_OPTIONS);
  const displayRowOptions = useMemo(() => {
    const formatRowOptions = [];
    rowsOptions.forEach((value) => {
      formatRowOptions.push({
        id: value,
        name: value,
        value: value
      });
    });
    return formatRowOptions;
  }, [rowsOptions]);

  const updateSortOrder = useCallback(
    ({ changedColumn, direction }) => {
      setSortOrder({ changedColumn, direction });
      const option = JSON.stringify({
        name: changedColumn,
        direction
      });
      window.localStorage.setItem('tableSortOrder', option);
    },
    [setSortOrder]
  );

  const getSortOrder = () => {
    let tableSortOrder = window.localStorage.getItem('tableSortOrder');
    tableSortOrder = JSON.parse(tableSortOrder);
    return tableSortOrder;
  };

  const updateRowsPerPage = useCallback(
    (newRowsPerPageValue) => {
      setDefaultRowsPerPage(newRowsPerPageValue);
      window.localStorage.setItem('rowsPerPage', newRowsPerPageValue);
    },
    [setDefaultRowsPerPage]
  );

  const defaultRowsPerPage = useMemo(() => {
    return rowsPerPage;
  }, [rowsPerPage]);

  const isDefaultRowsPerPageHasValue = useMemo(() => {
    return rowsPerPage;
  }, [rowsPerPage]);

  useEffect(() => {
    const rowsPerPage = window.localStorage.getItem('rowsPerPage');
    if (rowsPerPage) {
      setDefaultRowsPerPage(rowsPerPage);
    } else {
      setDefaultRowsPerPage(DEFAULT_ROWS_PER_PAGE);
    }
  }, [setDefaultRowsPerPage]);

  return (
    <TableOption.Provider
      value={{
        sortOrder,
        updateSortOrder,
        getSortOrder,
        isDefaultRowsPerPageHasValue,
        defaultRowsPerPage,
        displayRowOptions,
        updateRowsPerPage,
        rowsOptions
      }}>
      {children}
    </TableOption.Provider>
  );
};

export const useTableOption = () => useContext(TableOption);
