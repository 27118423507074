import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SuccessButton from 'component/Buttons/SuccessButton';
import FormSubTitle from 'component/Inputs/FormSubTitle';
import PasswordInput from 'component/Inputs/PasswordInput';
import ConfirmModal from 'component/Modals/ConfirmModal';
import XSmallSpinner from 'component/Spinners/XSmallSpinner';
import Title from 'component/ViewContainer/ViewTitle';
import { setFeedBack } from 'ducks/globalFeedback';
import { CHANGE_PASSWORD } from 'graphql/ChangePassword';
import { SEND_PASSWORD_RESET_LINK } from 'graphql/forgotPassword';
import { useUser } from 'hooks';
import usePasswordMatch from 'hooks/usePasswordMatch';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: '20px'
  },
  button: {
    marginTop: '40px',
    display: 'flex'
  },
  saving: {
    marginLeft: '20px',
    paddingBottom: '3px',
    display: 'flex',
    alignItems: 'flex-end'
  },
  oldPassword: {
    position: 'relative'
  },
  forgotPassword: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  modalText: {
    paddingTop: '10px',
    fontWeight: '500'
  }
}));

const ChangePassword = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const { user } = useUser();
  const { t } = useTranslation();
  const passwordMatch = usePasswordMatch();
  const [disableSave, setDisableSave] = useState(true);
  const [inputs, setInputs] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordTwo: ''
  });
  const [passwordError, setPasswordError] = useState(null);
  const [invalidPassword, setInvalidPassword] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [response, setResponse] = useState(null);

  const [passwordChange, { loading, error }] = useMutation(CHANGE_PASSWORD, {
    onCompleted(data) {
      if (data.passwordChange.success) {
        dispatch(
          setFeedBack({ message: t('userProfile.password.updateSuccess'), type: 'success' })
        );
        setInputs({
          oldPassword: '',
          newPassword: '',
          newPasswordTwo: ''
        });
        setDisableSave(true);
      }
      if (data.passwordChange.errors) {
        if (data.passwordChange.errors.newPassword2) {
          setPasswordError(data.passwordChange.errors.newPassword2[0].message);
        }
        if (data.passwordChange.errors.oldPassword) {
          setInvalidPassword(data.passwordChange.errors.oldPassword[0].message);
        }
      }
    }
  });

  const [sendPasswordResetEmail, { loading: passwordResetLoading }] = useMutation(
    SEND_PASSWORD_RESET_LINK,
    {
      onCompleted(passwordResetData) {
        setResponse({
          message: passwordResetData.sendPasswordResetEmail.success
            ? t('userProfile.password.forgotModal.messageSuccess')
            : t('userProfile.password.forgotModal.messageFail'),
          responseType: passwordResetData.sendPasswordResetEmail.success ? 'success' : 'error'
        });
      }
    }
  );

  useEffect(() => {
    if (error) {
      dispatch(setFeedBack({ message: t('userProfile.password.updateFail'), type: 'error' }));
    }
  }, [error]);

  const handleOnChange = (e) => {
    setDisableSave(false);
    setPasswordError(null);
    setInvalidPassword(null);
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  const handleOnSubmit = () => {
    passwordChange({
      variables: {
        oldPassword: inputs.oldPassword,
        newPassword1: inputs.newPassword,
        newPassword2: inputs.newPasswordTwo
      }
    });
  };

  const handleForgotPassword = () => {
    sendPasswordResetEmail({ variables: { email: user.email } });
  };

  return (
    <>
      {showModal && (
        <ConfirmModal
          saving={passwordResetLoading}
          response={response}
          handleCloseModal={() => {
            setShowModal(false);
            setResponse(null);
          }}
          message={
            <Box>
              {t('userProfile.password.forgotModal.message1')}
              <Box className={classes.modalText}>
                {t('userProfile.password.forgotModal.message2')}
              </Box>
            </Box>
          }
          title={t('userProfile.password.forgotModal.title')}
          handleDone={handleForgotPassword}
        />
      )}
      <Grid item xs={12}>
        <Title title={t('userProfile.password.title')} />
      </Grid>
      <Grid item xs={12}>
        <FormSubTitle title={t('userProfile.password.subTitle1')} />
      </Grid>
      <Grid item container xs={12} spacing={3}>
        <Grid item sm={6} xs={12} className={classes.oldPassword}>
          <PasswordInput
            required={false}
            label={t('userProfile.password.subTitle1')}
            value={inputs.oldPassword}
            name="oldPassword"
            onChange={(e) => handleOnChange(e)}
            disabled={loading}
            error={invalidPassword}
            underText={invalidPassword ? invalidPassword : ' '}
          />
          <Box className={classes.forgotPassword} onClick={() => setShowModal(true)}>
            {t('userProfile.password.forgotModal.title')}?
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.marginTop}>
        <FormSubTitle title={t('userProfile.password.subTitle2')} />
      </Grid>
      <Grid item container xs={12} spacing={3}>
        <Grid item sm={6} xs={12}>
          <PasswordInput
            required={false}
            label={t('userProfile.password.subTitle2')}
            value={inputs.newPassword}
            name="newPassword"
            onChange={(e) => handleOnChange(e)}
            onBlur={() => passwordMatch.onBlur(inputs.newPassword, inputs.newPasswordTwo)}
            error={passwordError || passwordMatch.error || invalidPassword}
            underText={
              passwordError
                ? passwordError
                : passwordMatch.error
                ? t('inputs.password.passwordSubText3')
                : t('inputs.password.passwordSubText1')
            }
            disabled={loading}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PasswordInput
            required={false}
            label={t('sharedLabels.password2')}
            value={inputs.newPasswordTwo}
            name="newPasswordTwo"
            onChange={(e) => handleOnChange(e)}
            onBlur={() => passwordMatch.onBlur(inputs.newPassword, inputs.newPasswordTwo)}
            error={passwordError || passwordMatch.error || invalidPassword}
            underText={
              passwordError
                ? ' '
                : passwordMatch.error
                ? ' '
                : t('inputs.password.passwordSubText2')
            }
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Grid item xs={6} className={classes.button}>
        <SuccessButton disabled={disableSave || loading} onClick={handleOnSubmit}>
          {t('userProfile.password.update')}
        </SuccessButton>
        <Box className={classes.saving}>{loading && <XSmallSpinner />}</Box>
      </Grid>
    </>
  );
};

export default ChangePassword;
