import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PinField from 'react-pin-field';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Box, Button, FormHelperText, Grid, Typography } from '@material-ui/core';
import { setResetPasswordInvalidToken } from 'ducks/auth';
import { useApp, useAuth } from 'hooks';
import styled from 'styled-components';

import Container from './Container';

const StyledPinFieldWrapper = styled.div`
  display: flex;
  gap: 10px;
  input {
    flex: 1 1 0;
    width: 0;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    text-align: center;
    height: 50px;
    ${(props) => {
      return props.error
        ? `
        border-color: #d54d4c;
        color: #d54d4c;
      `
        : '';
    }}
  }
`;

const StyledTitle = styled.div`
  margin-bottom: 5px;
  font-size: 0.8;
`;

const PATH_NAME_RESET_PASSWORD = 'password-reset';
const PATH_NAME_SET_PASSWORD = 'password-set';

const ResetUpdatePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { parentPathName } = useApp();
  const {
    errorMessage,
    resetStatus,
    resetErrorMessage,
    passwordReset,
    passwordSet,
    loading,
    isSuccessfullyUpdatedPassword,
    isSuccessfullySetPassword,
    isErrorUpdatingPasswordExpiredToken,
    isErrorSettingPasswordExpiredToken
  } = useAuth();
  const isRouteNameResetPassword = parentPathName === PATH_NAME_RESET_PASSWORD;
  const isRouteNameSetPassword = parentPathName === PATH_NAME_SET_PASSWORD;
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isErrorUpdatingPasswordExpiredToken || isErrorSettingPasswordExpiredToken) {
      dispatch(setResetPasswordInvalidToken(true));
      history.push({ pathname: '/forgot-password' });
    }
  }, [isErrorUpdatingPasswordExpiredToken, isErrorSettingPasswordExpiredToken]);

  const onReset = () => {
    resetStatus();
    resetErrorMessage();
    setPassword('');
    setRepeatPassword('');
    setError(false);
    setMatch(false);
    passwordRef.current[0].focus();
    passwordRef.current.forEach((input) => (input.value = ''));
    repeatPasswordRef.current.forEach((input) => (input.value = ''));
  };

  const onFooterClick = () => {
    onReset();
    history.push({ pathname: '/login' });
  };

  const passwordRef = useRef(null);
  const repeatPasswordRef = useRef(null);
  const [match, setMatch] = useState(false);

  const onComplete = ({ code, name }) => {
    if (name === 'password') {
      setPassword(code);
      repeatPasswordRef.current[0].focus();
    }
    if (name === 'repeatPassword') {
      setRepeatPassword(code);
    }
  };

  const success = useMemo(() => {
    return isSuccessfullyUpdatedPassword || isSuccessfullySetPassword;
  }, [isSuccessfullyUpdatedPassword, isSuccessfullySetPassword]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setError(false);
      resetStatus();
      resetErrorMessage();
      if (success) {
        history.push({ pathname: '/login' });
      } else {
        if (password === '' && repeatPassword === '') {
          setError(t('resetPassword.error.required'));
          return;
        }
        if (match) {
          const routePaths = {
            [PATH_NAME_RESET_PASSWORD]: 'password-reset/',
            [PATH_NAME_SET_PASSWORD]: 'password-set/'
          };
          const url = routePaths[parentPathName];
          const token = window.location.href.split(url)[1];

          const variables = {
            token,
            password,
            repeatPassword
          };

          if (isRouteNameResetPassword) {
            passwordReset(variables);
          }
          if (isRouteNameSetPassword) {
            passwordSet(variables);
          }
        }
      }
    },
    [match, success, resetStatus, password, repeatPassword]
  );

  useEffect(() => {
    if (password !== '' && repeatPassword !== '') {
      if (password !== repeatPassword) {
        setMatch(false);
        setError(t('resetPassword.error.didntMatch'));
      } else {
        setError(false);
        setMatch(true);
      }
    }
  }, [password, setPassword, repeatPassword, setRepeatPassword]);

  const ExtraAction = ({ loading }) => {
    return (
      <>
        {' '}
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => onReset()}
          disabled={loading}>
          {t('resetPassword.resetButton')}
        </Button>
      </>
    );
  };

  const title = {
    [PATH_NAME_RESET_PASSWORD]: (
      <Typography color="textPrimary" gutterBottom variant="h2">
        {success ? t('resetPassword.titleAlt') : t('resetPassword.title')}
      </Typography>
    ),
    [PATH_NAME_SET_PASSWORD]: (
      <Typography color="textPrimary" gutterBottom variant="h2">
        {success ? t('setPassword.titleAlt') : t('setPassword.title')}
      </Typography>
    )
  };

  const actionButton = {
    [PATH_NAME_RESET_PASSWORD]: t('resetPassword.button'),
    [PATH_NAME_SET_PASSWORD]: t('setPassword.button')
  };

  const footerText = {
    [PATH_NAME_RESET_PASSWORD]: t('resetPassword.footer'),
    [PATH_NAME_SET_PASSWORD]: t('setPassword.footer')
  };

  return (
    <Container
      title={title[parentPathName]}
      buttonText={success ? t('login.button') : actionButton[parentPathName]}
      buttonColor={success ? '' : 'primary'}
      onSubmit={onSubmit}
      footerText={success ? '' : footerText[parentPathName]}
      onFooterClick={onFooterClick}
      loading={loading}
      success={success}
      extraAction={!success ? <ExtraAction loading={loading} /> : null}>
      {success ? (
        <>
          <Typography
            variant="h6"
            style={{
              paddingBottom: '1.1rem',
              paddingTop: '1rem',
              color: '#388e3c'
            }}>
            {actionButton[parentPathName]}
          </Typography>
          <Typography
            variant="h6"
            style={{
              fontWeight: '400'
            }}>
            <Trans i18nKey="resetPassword.subTextSuccessAlt" />
          </Typography>
        </>
      ) : (
        <>
          <Grid container style={{ position: 'relative', left: '-5px' }}>
            <Grid xs={6}>
              <StyledTitle>{t('setPassword.fourPin')}</StyledTitle>
              <StyledPinFieldWrapper
                className="MuiInputBase-root MuiOutlinedInput-root Mui-error MuiInputBase-fullWidth MuiInputBase-formControl"
                error={error !== false || errorMessage !== ''}>
                <PinField
                  className="MuiInputBase-input MuiOutlinedInput-input MuiOutlinedInput-notchedOutline"
                  onComplete={(code) => onComplete({ code, name: 'password' })}
                  onChange={() => {
                    setError(false);
                    resetErrorMessage();
                  }}
                  length={4}
                  validate="0123456789"
                  inputMode="numeric"
                  ref={passwordRef}
                  type="password"
                  disabled={loading}
                />
              </StyledPinFieldWrapper>
            </Grid>
            <Grid xs={6}>
              <div style={{ position: 'relative', left: '10px' }}>
                <StyledTitle>{t('setPassword.repeatFourPin')}</StyledTitle>
                <StyledPinFieldWrapper
                  className="MuiInputBase-root MuiOutlinedInput-root Mui-error MuiInputBase-fullWidth MuiInputBase-formControl"
                  error={error !== false || errorMessage !== ''}>
                  <PinField
                    className="MuiInputBase-input MuiOutlinedInput-input MuiOutlinedInput-notchedOutline"
                    onComplete={(code) => onComplete({ code, name: 'repeatPassword' })}
                    onChange={() => {
                      setError(false);
                      resetErrorMessage();
                    }}
                    length={4}
                    validate="0123456789"
                    inputMode="numeric"
                    ref={repeatPasswordRef}
                    type="password"
                    disabled={loading}
                  />
                </StyledPinFieldWrapper>
              </div>
            </Grid>
          </Grid>
          <Box style={{ height: '0' }}>
            {error && (
              <FormHelperText id="password-helper-text" error={true}>
                {error}
              </FormHelperText>
            )}
            {errorMessage !== '' && (
              <FormHelperText id="password-helper-text" error={true}>
                {errorMessage}
              </FormHelperText>
            )}
          </Box>
        </>
      )}
    </Container>
  );
};

export default ResetUpdatePassword;
