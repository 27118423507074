import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import ConfirmButton from 'component/Buttons/ConfirmButton';
import TextInput from 'component/Inputs/TextInput';
import FullScreenModal from 'component/Modals/FullScreenModal';
import { useOrganizationToken } from 'hooks';

import OrganizationTokenTransactionsTable from '../OrganizationTokenTransactionsTable';

const OrganizationModalManageToken = ({ selectedOrganization, handleCloseModal }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const {
    loading,
    token,
    isEmptyToken,
    addToken,
    updateToken,
    isSuccessfullyAdded,
    isSuccessfullyUpdated,
    resetStatus
  } = useOrganizationToken();

  const [formErrors, setFormErrors] = useState({
    name: false
  });

  const isValid = () => {
    let error = false;
    if (value === '') {
      error = true;
      setFormErrors({
        ...formErrors,
        name: t('organizationManagement.token.error.quantityIsRequired')
      });
      return error;
    }
    if (isNaN(parseInt(value, 10))) {
      error = true;
      setFormErrors({
        ...formErrors,
        name: t('organizationManagement.token.error.quantityShouldBeNumber')
      });
      return error;
    }
  };

  const handleOnAdd = useCallback(() => {
    if (!isValid()) {
      addToken({
        organizationId: parseInt(selectedOrganization.id, 10),
        quantity: value
      });
    }
  }, [addToken, value, selectedOrganization]);

  const handleUpdate = useCallback(() => {
    if (!isValid()) {
      updateToken({
        organizationId: parseInt(selectedOrganization.id, 10),
        quantity: value
      });
    }
  }, [updateToken, value, selectedOrganization]);

  const handleOnChange = (e) => {
    e.preventDefault();
    if (isSuccessfullyAdded || isSuccessfullyUpdated) {
      resetStatus();
    }
    setValue(e.target.value);
    setFormErrors({
      ...formErrors,
      [e.target.name]: false
    });
  };

  const renderTokenSummary = () => {
    if (isEmptyToken) return <div>{t('organizationManagement.token.noTokenAdded')}</div>;
    return (
      <div>
        {t('organizationManagement.token.tokenQuantity')}: {token.quantity}
      </div>
    );
  };

  useEffect(() => {
    if (isSuccessfullyAdded || isSuccessfullyUpdated) {
      setValue('');
    }
  }, [isSuccessfullyAdded, isSuccessfullyUpdated, setValue]);

  return (
    <FullScreenModal
      hideFooter
      handleCloseModal={handleCloseModal}
      title={t('organizationManagement.token.manageToken')}
      width="60rem">
      <Grid container item style={{ alignContent: 'flex-start' }}>
        <Grid item container spacing={3} style={{ marginBottom: '20px' }}>
          <Grid item xs={12}>
            <Typography variant="h4">{selectedOrganization.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            {renderTokenSummary()}
            <TextInput
              required
              label={t('organizationManagement.token.quantity')}
              value={value}
              name="name"
              onChange={handleOnChange}
              disabled={loading}
              underText={formErrors.name}
              error={formErrors.name}
            />
            <ConfirmButton disabled={loading} onClick={handleOnAdd}>
              {t('organizationManagement.token.addToken')}
            </ConfirmButton>{' '}
            {!isEmptyToken ? (
              <ConfirmButton disabled={loading} onClick={handleUpdate}>
                {t('organizationManagement.token.updateToken')}
              </ConfirmButton>
            ) : null}
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <OrganizationTokenTransactionsTable />
          </Grid>
        </Grid>
      </Grid>
    </FullScreenModal>
  );
};

export default OrganizationModalManageToken;
