import React from 'react';
import { Chip as MuiChip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const Chip = (props) => {
  const StyledChip = withStyles(() => ({
    root: {
      color: '#FFF',
      border: `1px solid ${props.color}`,
      backgroundColor: `${props.color}`,
      fontSize: '0.8rem',
      height: '1.8rem',
      margin: '0.25rem 0.5rem 0.25rem 0'
    },
    deleteIcon: {
      color: props.color,
      height: '1.1rem',
      width: '1.1rem'
    }
  }))(MuiChip);

  return props.disabled ? (
    <StyledChip label={props.label} />
  ) : (
    <StyledChip label={props.label} onDelete={() => props.onDelete(props.id, props.name)} />
  );
};

export default Chip;

Chip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  name: PropTypes.string,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string
};
