import React from 'react';
import { Link } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowLink } from 'assets/images/icons/ArrowLink';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    cursor: 'pointer',
    borderRadius: '5px',
    padding: '30px',
    height: '10.5rem',
    [theme.breakpoints.down('md')]: {
      height: '6rem'
    },
    width: '100%',
    border: '1px solid ' + theme.palette.grey['200']
  },
  link: {
    textDecoration: 'none',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    flex: '1 1 0%'
  },
  leftContent: {
    flex: '1 1 0%'
  },
  rightContent: {
    display: 'flex',
    paddingLeft: '20px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    paddingTop: (props) => (props.description ? 0 : '10px'),
    fontWeight: 500,
    fontSize: '1.6rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.3rem'
    },
    color: theme.palette.background.paper
  },
  description: {
    paddingTop: '8px',
    color: theme.palette.background.paper
  },
  footer: {
    textAlign: 'right',
    color: theme.palette.background.paper,
    width: '100%',
    paddingTop: '10px',
    height: '20px',
    fontSize: '0.8rem'
  }
}));

const LinkWidget = (props) => {
  const theme = useTheme();
  const largeScreens = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles(props);

  const title = props.title?.length > 25 ? props.title.substring(0, 22) + '...' : props.title;
  const description =
    props.description?.length > 55 ? props.description.substring(0, 52) + '...' : props.description;

  return (
    <Box className={classes.root}>
      <Link
        className={classes.link}
        to={props.link && props.link}
        onClick={props.action && props.action}>
        <Box className={classes.content}>
          <Box className={classes.leftContent}>
            <Box className={classes.title}>{title}</Box>
            <Box className={classes.description}>
              {largeScreens ? props.description && description : props.footer}
            </Box>
          </Box>
          <Box className={classes.rightContent}>{props.link && <ArrowLink />}</Box>
        </Box>
        {largeScreens && props.footer && <Box className={classes.footer}>{props.footer}</Box>}
      </Link>
    </Box>
  );
};

export default LinkWidget;
