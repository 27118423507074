import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser($userId: Int!, $firstName: String, $lastName: String) {
    updateUser(userId: $userId, firstName: $firstName, lastName: $lastName) {
      success
      error
    }
  }
`;
