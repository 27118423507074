import React from 'react';
import { Tooltip as MuiTooltip, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    fontSize: 14,
    padding: '20px',
    marginLeft: '20px'
  }
}))(MuiTooltip);

const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer'
  },
  title: {
    fontWeight: 600
  },
  listItem: {
    fontWeight: 300
  }
}));

const LightTooltip = (props) => {
  const classes = useStyles();
  return (
    <CustomTooltip
      className={classes.root}
      title={
        <>
          <Typography variant={'h6'} className={classes.title}>
            {props.title}
          </Typography>
          {props.list.map((item, i) => (
            <p className={classes.listItem} key={item + i}>
              {item.name}
            </p>
          ))}
        </>
      }>
      <b>{props.label}</b>
    </CustomTooltip>
  );
};

export default LightTooltip;
