import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    justifyContent: 'center'
  },
  wrapper: {
    minHeight: '100vh',
    alignContent: 'center',
    display: 'grid'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));

const Loader = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid className={classes.root}>
      <Grid className={classes.wrapper}>
        <Grid item sm={12} md={12} lg={12} align="center">
          <CircularProgress size={100} />
        </Grid>
        <Grid item sm={12} md={12} lg={12} align="center">
          <Typography>{t('general.loadingContent')}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Loader;
