import { gql } from '@apollo/client';

export const USER_TRAININGS_INFO = gql`
  query getUser($userId: Int!) {
    getUser(id: $userId) {
      id
      username
      firstName
      lastName
      email
      cohorts {
        id
        name
      }
      organizationRoles {
        permission
        organization {
          id
          name
        }
      }
      trainee {
        lastCreatedSession
      }
      courses {
        id
        name
        trainings {
          id
          name
        }
      }
      trainings {
        id
        name
        translations {
          id
          name
          details
          language {
            code
          }
        }
        course {
          id
          name
        }
        sessions {
          id
          category
          name
          completedAt
          createdAt
          isComplete
          eventSet {
            description
            object {
              id
              name
            }
            errorSeverity
            performedAt
          }
          category
          totalSteps
        }
      }
    }
  }
`;

export const USER_SELF_TRAININGS_INFO = gql`
  query getUserSelfInfo($userId: Int) {
    getUserSelfInfo(userId: $userId) {
      id
      username
      firstName
      lastName
      email
      cohorts {
        id
        name
      }
      organizationRoles {
        permission
        organization {
          id
          name
        }
      }
      trainee {
        lastCreatedSession
      }
      courses {
        id
        name
        trainings {
          id
          name
        }
      }
      trainings {
        id
        name
        course {
          id
          name
        }
        translations {
          id
          name
          details
          language {
            code
          }
        }
        sessions {
          id
          category
          name
          completedAt
          createdAt
          isComplete
          eventSet {
            description
            object {
              id
              name
            }
            errorSeverity
            performedAt
          }
          category
          totalSteps
        }
      }
    }
  }
`;

export const AVG_TIME_PER_WEEKDAY = gql`
  query averageTimePerWeekday(
    $start: Date!
    $end: Date!
    $userId: Int!
    $category: String!
    $orgId: Int
    $courseId: Int
    $trainingId: Int
  ) {
    averageTimePerWeekday(
      startDate: $start
      endDate: $end
      userId: $userId
      category: $category
      orgId: $orgId
      courseId: $courseId
      trainingId: $trainingId
    ) {
      name
      data {
        start
        end
        timeSpent
      }
    }
  }
`;
