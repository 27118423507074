import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Box } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import Table from 'component/Table/Table';
import { buildBreadcrumbs } from 'helpers/buildBreadcrumbs';
import { useUser } from 'hooks';

import { friendlyTimeDisplayFromMinutes } from '../../../../util';
import buildTableData from '../helpers/buildTableData';

const TrainingTable = (props) => {
  let history = useHistory();
  const { isSuperUser } = useUser();
  const { t } = useTranslation();

  let tableConfig = {};
  let tableData = [];

  if (props.data) {
    tableData = buildTableData(props.data.training, isSuperUser);

    const columns = [
      {
        name: 'id',
        options: {
          filter: false,
          display: false,
          sort: false
        }
      },
      {
        name: t('sharedLabels.username'),
        options: {
          filter: false
        }
      },
      {
        name: t('sharedLabels.email'),
        options: {
          filter: false
        }
      },
      {
        id: 'totalUniqueSessions',
        name: t('sharedLabels.totalUniqueSessions'),
        options: {
          filter: false,
          customBodyRender: (value) => (value ? value : 0)
        }
      },
      {
        name: t('sharedLabels.totalTimeSpent'),
        options: {
          filter: false,
          customBodyRender: (value, tableMeta) => {
            if (typeof value === 'undefined') return '0 m';
            let duration = friendlyTimeDisplayFromMinutes(value);
            if (tableMeta.rowData[6]) duration = '~ ' + duration;
            return duration;
          }
        }
      },
      {
        name: t('sharedLabels.passed'),
        options: {
          filter: false,
          customBodyRender: (value) => (
            <Box
              style={{
                width: '50px',
                display: 'flex',
                maxHeight: '0px',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              {value ? <CheckCircleOutline style={{ color: '#06AC69' }} /> : ''}
            </Box>
          )
        }
      },
      {
        name: 'estimateDuration',
        options: {
          filter: false,
          display: false,
          sort: false
        }
      },
      {
        name: t('sharedLabels.errorsMade'),
        options: {
          filter: false,
          sort: true
        }
      }
    ];

    let rows = [];

    tableData.forEach((row) => {
      rows.push([
        row.id,
        row.username,
        row.email,
        row.totalUniqueSessions,
        row.totalTimeSpent,
        row.passed,
        row.estimateDuration,
        row.errorsMade,
        row.cohorts
      ]);
    });

    if (props.filteredBy) {
      rows = rows.filter((row) => {
        if (row[8].length !== 0) {
          const isExist = row[8].find((x) => x.id === props.filteredBy.id);
          if (isExist) {
            return row;
          }
        }
      });
    }

    const sortedRows = rows.sort((a, b) => {
      return b[4] - a[4];
    });

    const handleUserLink = (data) => {
      history.push('/user/' + data[0]);
      buildBreadcrumbs({
        key: 'training',
        name: props.data.training.name,
        id: props.data.training.id,
        viewTitle: data[1]
      });
    };

    tableConfig = {
      print: false,
      viewColumns: false,
      search: true,
      download: false,
      filter: false,
      expandableRows: false,
      selectableRows: false,
      title: t('trainingDetails.table.title') + ' ' + rows.length,
      originalData: tableData,
      rowData: sortedRows,
      columns: columns,
      onRowClick: handleUserLink
    };
  }

  return <Table tableConfig={tableConfig} />;
};

export default TrainingTable;
