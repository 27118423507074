import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import ConfirmButton from 'component/Buttons/ConfirmButton';
import FormSubTitle from 'component/Inputs/FormSubTitle';

import QuestionChoices from './QuestionBlock/QuestionChoices';
import QuestionFooter from './QuestionBlock/QuestionFooter';
import QuestionWrapper from './QuestionBlock/QuestionWrapper';

const Questions = (props) => {
  const { t } = useTranslation();
  return (
    <Grid container style={{ marginTop: '20px' }}>
      <Grid item xs={12} style={{ marginBottom: '15px' }}>
        <FormSubTitle
          title={t('quizManagement.form.title2') + ' (' + props.questions.length + ')'}
          subText={'* ' + t('quizManagement.form.subText2')}
        />
      </Grid>
      {props.questions.map((question, qIndex) => {
        let error = props.errors.includes(qIndex);
        return (
          <QuestionWrapper
            key={qIndex}
            text={question.text}
            buildQuestions={props.buildQuestions}
            qIndex={qIndex}
            error={error}>
            {question.choices.map((choice, cIndex) => {
              return (
                <QuestionChoices
                  key={cIndex}
                  correctAnswer={choice.correctAnswer}
                  text={choice.text}
                  qIndex={qIndex}
                  cIndex={cIndex}
                  buildQuestions={props.buildQuestions}
                  removeChoice={props.removeChoice}
                />
              );
            })}
            <QuestionFooter
              error={error}
              qIndex={qIndex}
              addChoice={props.addChoice}
              removeQuestion={props.questions.length > 1 && props.removeQuestion}
            />
          </QuestionWrapper>
        );
      })}
      <ConfirmButton
        color="primary"
        onClick={() => props.addQuestion()}
        style={{ marginTop: props.questions.length < 1 && '50px' }}>
        <AddCircle style={{ marginRight: '10px' }} />
        {t('quizManagement.form.addQuestion')}
      </ConfirmButton>
    </Grid>
  );
};

export default Questions;
