import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import WarningModal from 'component/Modals/WarningModal';
import ViewContainer from 'component/ViewContainer/ViewContainer';
import ALL_USERS from 'graphql/AllUsers';
import { ALL_GROUPS, DELETE_GROUP } from 'graphql/GroupManagement';
import { useOrganization, useTrainingTranslation } from 'hooks';

import GroupModal from './GroupModal';
import GroupTable from './GroupTable';

const AdminGroupManagement = () => {
  const { getTranslatedNameFromTraining } = useTrainingTranslation();
  const { organizationData } = useOrganization();
  const { t } = useTranslation();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [setResponse] = useState(null);
  const [saving, setSaving] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteResponse, setDeleteResponse] = useState(null);

  const {
    data: dataUsers,

    loading: loadingUsers
  } = useQuery(ALL_USERS, {
    variables: {
      orgId: Number(organizationData.organization.id)
    }
  });

  const { loading: loadingGroups, error } = useQuery(ALL_GROUPS, {
    fetchPolicy: 'no-cache',
    variables: {
      orgId: Number(organizationData.organization.id)
    },
    onCompleted(cohortData) {
      if (cohortData?.allCohorts.length > 0) {
        setGroupData(cohortData.allCohorts);
      }
    }
  });

  const [deleteGroup] = useMutation(DELETE_GROUP, {
    onCompleted(groupDeleted) {
      if (groupDeleted.deleteCohort.success) {
        let groupDataCopy = [...groupData].filter((group) => group.id !== selectedGroup.id);
        setGroupData(groupDataCopy);
      }
      setDeleteResponse({
        message: groupDeleted.deleteCohort.success
          ? t('groupManagement.responses.deleteSuccess')
          : groupDeleted.deleteCohort.error,
        responseType: groupDeleted.deleteCohort.success ? 'success' : 'error'
      });
      setSaving(false);
    }
  });

  let trainingOptions = [];

  if (organizationData) {
    organizationData.organization.courses.map((course) => {
      course.trainings.map((training) => {
        const trainingName = getTranslatedNameFromTraining({ training });
        trainingOptions.push({ id: training.id, name: trainingName });
      });
    });
  }

  const handleShowGroupModal = (group, value) => {
    setSelectedGroup(group);
    setShowGroupModal(value);
  };

  const handleShowDeleteModal = (group, value) => {
    setShowDeleteModal(value);
    if (!group) {
      setDeleteResponse(null);
      setSaving(false);
      setSelectedGroup(null);
    } else {
      setSelectedGroup(group);
    }
  };

  const handleDeleteConfirmed = () => {
    setSaving(true);
    deleteGroup({
      variables: {
        ids: [Number(selectedGroup.id)]
      }
    });
  };

  const loading = loadingGroups || loadingUsers;
  console.log('groupData: ', groupData);
  return (
    <ViewContainer
      title={t('groupManagement.title')}
      loading={loading}
      flexView={true}
      error={error}
      clearFeedback={() => setResponse({})}
      actionButton={() => setShowGroupModal(true)}
      actionButtonLabel={t('groupManagement.create')}>
      {showDeleteModal && (
        <WarningModal
          saving={saving}
          response={deleteResponse}
          handleCloseModal={() => handleShowDeleteModal(null, false)}
          message={t('groupManagement.deleteModal.message') + ' ' + selectedGroup?.name}
          title={t('groupManagement.deleteModal.title')}
          handleDone={handleDeleteConfirmed}
        />
      )}
      {showGroupModal && (
        <GroupModal
          trainingOptions
          optionsData={{ users: dataUsers.allUsers, trainings: trainingOptions }}
          selectedGroup={selectedGroup}
          setGroupData={setGroupData}
          groupData={groupData}
          handleCloseModal={() => {
            handleShowGroupModal(null, false);
          }}
        />
      )}
      <GroupTable
        data={groupData}
        actionEdit={handleShowGroupModal}
        actionDelete={handleShowDeleteModal}
      />
    </ViewContainer>
  );
};

export default AdminGroupManagement;
