export const buildCourseAdminCourseList = (org, user, isCourseAdmin) => {
  let courseList = [];
  let courseData;

  org.courses.forEach((course) => {
    if (user.courses.find((c) => c.id === course.id)) {
      courseList.push(course);
    }
  });

  if (isCourseAdmin) {
    courseData = courseList;
  } else {
    courseData = org.courses;
  }

  return courseData;
};
