import React, { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useLocalStorage from 'react-use-localstorage';
import { useMutation } from '@apollo/client';
import { Box, TextField, Typography } from '@material-ui/core';
import { useOrganization } from 'hooks';
import { useAuth } from 'hooks';

import { LOGOUT_NAV_ID } from '../../constants/paths';
import { setSelectedNavItem } from '../../ducks/nav';
import { useUser } from '../../hooks';

import LOGIN from './../../graphql/Login';
import Container from './Container';

const Login = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { setAuthenticated } = useAuth();
  const { setUser } = useUser();
  const { setOrganizationData } = useOrganization();
  const [loginAction] = useMutation(LOGIN);
  const [userStorage] = useLocalStorage('user');
  const [error, setError] = useState(null);
  const [inputValues, setInputValues] = useState({
    username: '',
    password: ''
  });

  const [loading, setLoading] = useState(false);

  let history = useHistory();

  let orgId = null;

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    dispatch(setSelectedNavItem(LOGOUT_NAV_ID));
  }, []);

  useEffect(() => {
    if (success) {
      history.push({ pathname: '/dashboard/default' });
      window.sessionStorage.removeItem('manualLang');
      window.sessionStorage.setItem('navItem', 'dashboard');
      setAuthenticated(true);
    } else {
      if (userStorage) {
        setAuthenticated(true);
      }
    }
  }, [success]);

  const clearData = () => {
    setInputValues({
      username: '',
      password: ''
    });
  };

  function onSubmit(e) {
    e.preventDefault();
    setError(null);
    if (inputValues.username.length > 0 && inputValues.password.length > 0) {
      setLoading(true);
      try {
        loginAction({
          variables: {
            username: inputValues.username,
            password: inputValues.password
          }
        }).then((response) => {
          if (response.data.tokenAuth.success) {
            setUser(response.data.tokenAuth.user);
            let orgDataObj = response.data.tokenAuth.user.organizations[0];
            let orgData = {
              organization: orgDataObj,
              allOrgs: response.data.tokenAuth.user.organizations
            };
            orgId = response.data.tokenAuth.user.organizations[0].id;
            setOrganizationData(orgData);
            response.data.tokenAuth.user.id = response.data.tokenAuth.user.pk;
            setUser(response.data.tokenAuth.user);
            localStorage.setItem(
              'role',
              JSON.stringify(response.data.tokenAuth.user.organizationRoles[0].permission)
            );
            localStorage.setItem('orgId', orgId);
            localStorage.setItem('userId', JSON.stringify(response.data.tokenAuth.user.pk));
            if (response.data.tokenAuth.user.profile?.preferredLanguage?.code) {
              localStorage.setItem(
                'lng',
                response.data.tokenAuth.user.profile.preferredLanguage.code
              );
              i18n.changeLanguage(response.data.tokenAuth.user.profile.preferredLanguage.code);
            }
            cookie.save('token', response.data.tokenAuth.token);
            cookie.save('refreshToken', response.data.tokenAuth.refreshToken);
            setLoading(false);
            setSuccess(true);
          } else {
            setError(t('login.errorText2'));
          }
          setLoading(false);
        });
      } catch (err) {
        setError(t('login.errorText2'));
        setLoading(false);
      }
    } else {
      setError(t('login.errorText3'));
    }
  }

  const onChange = (e) => {
    setError(false);
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const onFooterClick = () => {
    clearData();
    history.push('/forgot-password');
  };

  const title = (
    <>
      <Typography color="textPrimary" gutterBottom variant="h2">
        {t('login.title')}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {t('login.subText')}
      </Typography>
    </>
  );

  let errorMessage;

  if (error)
    errorMessage = (
      <>
        <Box>{error}</Box>
        <Trans i18nKey="login.errorTextAlt" />
      </>
    );

  return (
    <Container
      title={title}
      buttonText={t('login.button')}
      buttonColor="primary"
      onSubmit={onSubmit}
      footerText={t('login.footer')}
      onFooterClick={onFooterClick}
      loading={loading}
      success={success}
      error={errorMessage}>
      <TextField
        fullWidth
        autoFocus
        autoComplete="true"
        label={t('sharedLabels.username') + '*'}
        margin="normal"
        name="username"
        type="username"
        value={inputValues.username}
        variant="outlined"
        id="username"
        error={error}
        onChange={(e) => onChange(e)}
      />
      <TextField
        fullWidth
        autoComplete="true"
        label={t('sharedLabels.password') + '*'}
        margin="normal"
        name="password"
        value={inputValues.password}
        type="password"
        id="password"
        variant="outlined"
        error={error}
        onChange={(e) => onChange(e)}
      />
    </Container>
  );
};

export default Login;
