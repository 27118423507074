import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const ProtectedRoute = ({ showRoute, auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          showRoute ? (
            <Component {...props} {...rest} />
          ) : (
            <Redirect to="/dashboard/default" />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export const AuthRoute = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !auth ? <Component {...props} {...rest} /> : <Redirect to="/dashboard/default" />
      }
    />
  );
};
