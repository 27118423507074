import React, { cloneElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';
import { COLOR_LIST } from 'constants/colors';
import MUIDataTable, { ExpandButton } from 'mui-datatables';
import { useTableOption } from 'providers/TableOptionProvider';

import Chip from '../Inputs/SelectInputs/Chip';
import FilterWrapper from '../TableFilters/FilterWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: (tableConfig) =>
      tableConfig.hideBoxShadow ? '' : 'rgba(0, 0, 0, 0.05) 0px 0px 20px',
    border: '1px solid ' + theme.palette.grey['200'],
    borderRadius: (tableConfig) => (tableConfig.footer ? '5px 5px 0 0' : '5px'),
    height: (tableConfig) => (tableConfig.flexView ? '100%' : 'auto'),
    maxHeight: (tableConfig) => (tableConfig.flexView ? '100%' : 'auto'),
    width: '100%',
    position: 'relative'
  },
  filterChipWrapper: {
    height: '40px',
    width: '1px',
    position: 'relative'
  },
  filterChipContainer: {
    position: 'absolute',
    right: '-45px',
    bottom: '5px',
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto'
  },
  filterChipTitle: {
    marginRight: '8px'
  },
  filterChipName: {
    textTransform: 'capitalize'
  }
}));

const Table = (props) => {
  const {
    getSortOrder,
    updateSortOrder,
    isDefaultRowsPerPageHasValue,
    defaultRowsPerPage,
    rowsOptions,
    updateRowsPerPage
  } = useTableOption();

  const { t } = useTranslation();
  const theme = useTheme();
  const { tableConfig } = props;
  const [tableWidth, setTableWidth] = useState();
  const ref = useRef(null);
  let showFilterchips =
    tableConfig.filters &&
    Object.keys(tableConfig.filters)
      .map((key) => tableConfig.filters[key].id !== 'all')
      .includes(true);

  useEffect(() => {
    setTableWidth(ref.current.offsetWidth);
    const handleResize = () => {
      setTableWidth(ref.current.offsetWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDeleteChip = (id, name) => {
    let filterCopy = { ...tableConfig.filters };
    filterCopy[name].id = 'all';
    filterCopy[name].name = 'All';
    tableConfig.setFilters(filterCopy);
  };

  const classes = useStyles(tableConfig);
  const customTheme = createMuiTheme({
    typography: {
      fontFamily: `"Poppins", "Roboto", "Arial", sans-serif`
    },
    palette: {
      primary: {
        main: theme.palette.primary.main
      }
    },
    overrides: {
      MuiPaper: {
        elevation4: {
          borderRadius: tableConfig.footer ? '5px 5px 0 0' : '5px',
          boxShadow: 'none'
        }
      },
      MuiTable: {
        root: {
          borderCollapse: 'separate'
        }
      },
      MuiMenu: {
        paper: {
          height: '120px'
        }
      },
      MuiToolbar: {
        regular: {
          display: 'flex',
          minHeight: '3rem !important',
          maxHeight: showFilterchips ? 'auto' : '3rem !important',
          alignItems: showFilterchips ? 'flex-start' : 'center'
        }
      },
      MUIDataTableToolbar: {
        left: {
          paddingBottom: '8px'
        },
        actions: {
          paddingBottom: '8px',
          flex: 'none'
        }
      },
      MUIDataTable: {
        paper: {
          position: tableConfig.flexView ? 'absolute' : 'relative',
          width: '100%',
          height: tableConfig.flexView ? '100%' : '600px',
          display: 'flex',
          flexDirection: 'column',
          padding: '0.5rem 0'
        },
        responsiveBase: {
          flex: '1 1 0%'
        }
      },
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(even)': {
            backgroundColor: theme.palette.background.default
          },
          '&:nth-child(odd)': {
            backgroundColor: theme.palette.background.grey
          }
        }
      },
      MuiTableCell: {
        footer: {
          border: 'none'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          borderTop: '1px solid ' + theme.palette.grey['200']
        }
      },
      MUIDataTableHeadCell: {
        root: {
          backgroundColor: theme.palette.background.paper + ' !important',
          borderTop: '1px solid ' + theme.palette.grey['200'],
          borderBottom: '1px solid ' + theme.palette.grey['200'],
          textAlign: 'left',
          fontWeight: '600 !important',
          color: theme.palette.text.secondary
        },
        data: {
          whiteSpace: tableConfig.sideScroll ? 'nowrap' : 'wrap'
        },
        fixedHeader: {
          backgroundColor: theme.palette.background.paper + ' !important',
          borderTop: '1px solid ' + theme.palette.grey['200'],
          borderBottom: '1px solid ' + theme.palette.grey['200'],
          paddingTop: 0,
          paddingBottom: 0
        },
        toolButton: {
          textAlign: 'left',
          fontWeight: '600 !important',
          color: theme.palette.text.secondary,
          margin: '10px 0',
          fontSize: '0.8rem'
        }
      },
      MUIDataTableBodyCell: {
        root: {
          whiteSpace: tableConfig.sideScroll ? 'nowrap' : 'wrap',
          maxWidth: '250px',
          cursor: tableConfig.onRowClick ? 'pointer' : 'default',
          fontSize: '0.8rem'
        }
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          visibility: 'hidden'
        },
        headerCell: {
          backgroundColor: theme.palette.background.paper + ' !important',
          borderTop: '1px solid ' + theme.palette.grey['200'],
          borderBottom: '1px solid ' + theme.palette.grey['200']
        }
      },
      MUIDataTablePagination: {
        root: {
          marginBottom: '-8px',
          padding: '10px 0'
        },
        tableCellContainer: {
          borderTop: '1px solid ' + theme.palette.grey['200']
        }
      },
      MuiTypography: {
        h6: {
          fontWeight: 400,
          fontSize: '0.85rem',
          color: theme.palette.secondary.main,
          paddingTop: showFilterchips ? '10px' : '0px'
        }
      }
    }
  });

  const options = {
    textLabels: {
      pagination: {
        rowsPerPage: t('table.rowsPerPage'),
        displayRows: t('general.of')
      }
    },
    rowsSelected: tableConfig.rowsSelected,
    rowsPerPage: defaultRowsPerPage,
    rowsPerPageOptions: rowsOptions,
    print: tableConfig.print,
    viewColumns: tableConfig.viewColumns,
    search: tableConfig.search,
    download: tableConfig.download,
    filter: false,
    expandableRows: tableConfig.expandableRows,
    expandableRowsHeader: false,
    expandableRowsOnClick: tableConfig.expandableRows,
    setRowProps: (row) => {
      const foundIdIndex = tableConfig.columns.indexOf(
        tableConfig.columns.find((col) => col.name === 'id')
      );
      const foundRow = tableConfig.originalData?.find((item) => item.id === row[foundIdIndex]);
      if (foundRow?.isSuperuser) {
        return {
          style: { backgroundColor: theme.palette.primary.main + '30' }
        };
      }
    },
    isRowExpandable: (dataIndex) => {
      return tableConfig.expandableRows && tableConfig.expandableRowList.includes(dataIndex);
    },
    rowsExpanded: [],
    renderExpandableRow: (rowData) => {
      if (tableConfig.expandableRows) {
        const foundIdIndex = tableConfig.columns.indexOf(
          tableConfig.columns.find((col) => col.name === 'id')
        );
        const expandData = tableConfig.originalData.find(
          (item) => item.id === rowData[foundIdIndex]
        );
        return cloneElement(tableConfig.expandableComponent, {
          expandData,
          colSpan: rowData.length + 1
        });
      }
    },
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
      console.log(curExpanded, allExpanded, rowsExpanded),
    onRowSelectionChange: tableConfig.onRowSelectionChange,
    selectableRows: tableConfig.selectableRows,
    onRowClick: tableConfig.onRowClick && tableConfig.onRowClick,
    onColumnSortChange: (changedColumn, direction) => {
      updateSortOrder({ changedColumn, direction });
    },
    sortOrder: getSortOrder(),
    customToolbar: () =>
      tableConfig.customFilter && (
        <Box style={{ display: 'inline-block' }}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <FilterWrapper
              filterType={tableConfig.customFilter}
              rowData={tableConfig.originalData}
              columns={tableConfig.columns}
              setFilters={tableConfig.setFilters}
            />
            {showFilterchips && (
              <Box className={classes.filterChipWrapper}>
                <Box
                  className={classes.filterChipContainer}
                  style={{ width: tableWidth - 50 + 'px' }}>
                  {Object.keys(tableConfig.filters).map((key, i) => {
                    const chipName = key.replace(/([A-Z])/g, ' $1').trim();
                    if (tableConfig.filters[key].id !== 'all')
                      return (
                        <Chip
                          key={key}
                          onDelete={handleDeleteChip}
                          id={i}
                          name={key}
                          label={chipName + ': ' + tableConfig.filters[key].name}
                          color={COLOR_LIST[0]}
                        />
                      );
                  })}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      ),
    onChangeRowsPerPage: (numberOfRows) => updateRowsPerPage(numberOfRows)
  };

  const components = {
    ExpandButton: (props) => {
      if (tableConfig.expandableRowList.includes(props.dataIndex))
        return <ExpandButton {...props} />;
      return <div style={{ width: '24px' }} />;
    }
  };

  return (
    <Box ref={ref} className={classes.root}>
      <MuiThemeProvider theme={customTheme}>
        {isDefaultRowsPerPageHasValue ? (
          <MUIDataTable
            labelRowsPerPage={t('table.rowsPerPage')}
            title={tableConfig.title}
            data={tableConfig.rowData}
            columns={tableConfig.columns}
            options={{ ...options, ...tableConfig.options }}
            components={{ ...components, ...tableConfig.components }}
          />
        ) : (
          <></>
        )}
      </MuiThemeProvider>
    </Box>
  );
};

export default Table;
