import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Box, makeStyles } from '@material-ui/core';
import LinkTitle from 'component/Titles/LinkTitle';
import SmallTitle from 'component/Titles/SmallTitle';
import ViewContainer from 'component/ViewContainer/ViewContainer';
import { USER_GROUPS } from 'constants/filters';
import { COURSE_NAV_ID } from 'constants/paths';
import { setSelectedNavItem } from 'ducks/nav';
import ALL_USERS from 'graphql/AllUsers';
import COURSE_DETAILS from 'graphql/CourseDetails';
import { buildBreadcrumbs } from 'helpers/buildBreadcrumbs';
import { useUser } from 'hooks';
import { useOrganization } from 'hooks';
import EnrolledUsers from 'views/Dashboard/Course/EnrolledUsers';
import Trainings from 'views/Dashboard/Course/Trainings';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.only('xl')]: {
      width: '45%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '50%'
    },
    [theme.breakpoints.only('md')]: {
      width: '70%'
    },
    [theme.breakpoints.up('md')]: {
      borderRight: '1px solid ' + theme.palette.grey['200'],
      paddingRight: '20px',
      marginRight: '20px',
      height: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '40px',
      width: '100%'
    }
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.only('xl')]: {
      width: '50%'
    },
    [theme.breakpoints.only('lg')]: {
      width: '50%'
    },
    [theme.breakpoints.only('md')]: {
      width: '30%'
    },
    [theme.breakpoints.up('md')]: {
      height: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '40px',
      width: '100%'
    }
  }
}));

const Course = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { organizationData } = useOrganization();
  const { restrictedUser } = useUser();
  const classes = useStyles();
  const params = useParams();
  let courseId = params.courseId;
  let trainings = [];
  const [courseData, setCourseData] = useState(null);
  const [filteredCourseData, setFilteredCourseData] = useState(null);
  const [lazyLoading, setLazyLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState([{ id: 'all', name: t('filters.allUsers') }]);

  const [getCourseDetails, { data, error, loading }] = useLazyQuery(COURSE_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      courseId: Number(courseId),
      category: 'assessment',
      fetchPolicy: 'no-cache'
    },
    onCompleted(data) {
      if (data) {
        getAllUsers();
      }
    }
  });

  const [getAllUsers] = useLazyQuery(ALL_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      orgId: organizationData.organization.id
    },
    onCompleted(dataUsers) {
      if (dataUsers) {
        let courseCopy = JSON.parse(JSON.stringify(data.coursePerformanceAllStudents));
        let groupList = [];
        courseCopy.trainingStats.map((training) => {
          const foundUser = dataUsers.allUsers.find((user) => user.username === training.username);
          if (foundUser) {
            foundUser.cohorts.forEach((cohort) => {
              if (!groupList.find((group) => group.name === cohort.name)) {
                groupList.push({ id: cohort.id, name: cohort.name });
              }
            });
          }
          return (training.cohorts = foundUser ? foundUser.cohorts : []);
        });

        setFilterOptions([...filterOptions, ...groupList]);
        setCourseData(courseCopy);
        setLazyLoading(false);
      }
    }
  });

  useEffect(() => {
    dispatch(setSelectedNavItem(COURSE_NAV_ID));
    buildBreadcrumbs('clear');
    getCourseDetails();
  }, []);

  if (organizationData) {
    const foundCourse = organizationData.organization.courses.find(
      (course) => course.id === courseId
    );
    if (foundCourse) {
      foundCourse.trainings.forEach((training) => {
        trainings.push({
          ...training,
          userTotal: training?.user ? training.user.length : 0
        });
      });
    }
  }

  const setFilteredData = (filteredBy) => {
    let courseCopy = JSON.parse(JSON.stringify(courseData));
    if (filteredBy) {
      courseCopy.trainingStats = courseCopy.trainingStats.filter((training) => {
        let foundCohort = training.cohorts.find((cohort) => cohort.name === filteredBy.name);
        return !!foundCohort;
      });
      setFilteredCourseData(courseCopy);
    } else {
      setFilteredCourseData(null);
    }
  };

  return (
    <ViewContainer
      title={!lazyLoading && !loading && courseData?.courseName}
      subTitle={!lazyLoading && !loading && courseData?.courseDescription}
      loading={loading || lazyLoading}
      flexView={true}
      error={error}
      filter={
        !restrictedUser && {
          filterType: USER_GROUPS,
          filterLocation: 'COURSE',
          filterOptions,
          data,
          setFilteredData: setFilteredData
        }
      }>
      <Box className={classes.root}>
        <Box className={classes.leftContent}>
          <LinkTitle title={t('course.enrolledUsers')} />
          <EnrolledUsers
            data={filteredCourseData ? filteredCourseData.trainingStats : courseData?.trainingStats}
            trainings={trainings}
          />
        </Box>
        <Box className={classes.rightContent}>
          <SmallTitle title={t('course.trainingOverviews')} />
          <Trainings
            trainings={trainings}
            data={data}
            courseId={courseId}
            courseName={data?.coursePerformanceAllStudents?.courseName}
          />
        </Box>
      </Box>
    </ViewContainer>
  );
};

export default Course;
