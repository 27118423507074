import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from 'ducks/constant';

import ListData from './ListData';

import './styles.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 30,
    pointerEvents: (props) => (props.content ? 'auto' : 'none')
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    overflow: 'hidden'
  },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.secondary.main + '70'
  },
  content: {
    backgroundColor: 'white',
    width: '85%',
    height: '100%',
    top: 0,
    position: 'absolute',
    [theme.breakpoints.up('lg')]: {
      width: '55%',
      marginLeft: drawerWidth
    }
  }
}));

const DrawerWrapper = (props) => {
  const classes = useStyles(props);
  let content;
  switch (props.type) {
    case 'chartList':
      content = <ListData data={props.content} />;
      break;
    default:
      content = <ListData data={props.content} />;
  }
  return (
    <Box onClick={() => props.onClick(null)} className={classes.root}>
      <Box className={classes.container}>
        <Box
          className={` ${props.content ? 'chart-custom-fadeIn' : 'chart-custom-fadeOut'} ${
            classes.overlay
          }`}
        />
        <Box
          className={` ${
            props.content ? 'chart-custom-slideInRight' : 'chart-custom-slideOutRight'
          } ${classes.content}`}>
          {props.content && content}
        </Box>
      </Box>
    </Box>
  );
};

export default DrawerWrapper;
