import React from 'react';
const UkFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 25">
      <defs>
        <clipPath id="clip-path">
          <circle
            id="Ellipse_4"
            data-name="Ellipse 4"
            cx="12.5"
            cy="12.5"
            r="12.5"
            transform="translate(132 465)"
            fill="#012169"
          />
        </clipPath>
      </defs>
      <g id="UK" transform="translate(-132 -465)" clipPath="url(#clip-path)">
        <circle
          id="Ellipse_3"
          data-name="Ellipse 3"
          cx="12.5"
          cy="12.5"
          r="12.5"
          transform="translate(132 465)"
          fill="#012169"
        />
        <g id="Group_5" data-name="Group 5" transform="translate(67.787 463.857) rotate(10)">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M0,0H3.75V35.5H0Z"
            transform="translate(89.215 -13.711) rotate(45)"
            fill="#fff"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M0,0H1.875V35.5H0Z"
            transform="translate(89.815 -13.111) rotate(45)"
            fill="#c8102e"
          />
        </g>
        <g
          id="Group_4"
          data-name="Group 4"
          transform="matrix(0.985, -0.174, 0.174, 0.985, 34.916, 467.966)">
          <path
            id="Path_3"
            data-name="Path 3"
            d="M53.275,56.784h3.75v35.5h-3.75Z"
            transform="translate(14.614 14.785) rotate(-45)"
            fill="#fff"
          />
          <path
            id="Path_4"
            data-name="Path 4"
            d="M50.74,52.392h1.875v35.5H50.74Z"
            transform="translate(20.113 15.372) rotate(-45)"
            fill="#c8102e"
          />
        </g>
        <g id="Group_3" data-name="Group 3" transform="translate(126.625 459.75)">
          <g id="Group_2" data-name="Group 2" transform="translate(0.125)">
            <rect
              id="Rectangle_3"
              data-name="Rectangle 3"
              width="5.625"
              height="35.5"
              transform="translate(15)"
              fill="#fff"
            />
            <rect
              id="Rectangle_5"
              data-name="Rectangle 5"
              width="5.625"
              height="35.5"
              transform="translate(35.5 15) rotate(90)"
              fill="#fff"
            />
          </g>
          <g id="Group_1" data-name="Group 1">
            <rect
              id="Rectangle_4"
              data-name="Rectangle 4"
              width="3.5"
              height="35.5"
              transform="translate(35.5 16) rotate(90)"
              fill="#c8102e"
            />
            <rect
              id="Rectangle_8"
              data-name="Rectangle 8"
              width="3.5"
              height="35.5"
              transform="translate(16)"
              fill="#c8102e"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default UkFlag;
