import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Box, Grid, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Chip from 'component/Chip';
import CourseAndTrainings from 'component/CourseAndTrainings';
import FormSubTitle from 'component/Inputs/FormSubTitle';
import OrganizationRoles from 'component/OrganizationRoles';
import Title from 'component/ViewContainer/ViewTitle';
import { USER_SELF_TRAININGS_INFO, USER_TRAININGS_INFO } from 'graphql/UserTrainingsInfo';
import { useUser } from 'hooks';

import buildUserData from '../helpers/buildCoursesAndTrainings';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '15px'
  },
  contentContainer: {
    marginBottom: '20px'
  },
  noDataGroups: {
    opacity: '0.5'
  }
}));

const Enrollment = (props) => {
  const { user, restrictedUser } = useUser();
  const classes = useStyles(props);
  const { t } = useTranslation();
  const theme = useTheme();

  const { data: dataUserSelf, loading: loadingUserSelf } = useQuery(USER_SELF_TRAININGS_INFO, {
    variables: {
      userId: Number(user.id)
    },
    fetchPolicy: 'cache-first',
    skip: !restrictedUser
  });

  const { data, loading } = useQuery(USER_TRAININGS_INFO, {
    variables: {
      userId: user.id
    },
    fetchPolicy: 'cache-first',
    skip: restrictedUser
  });

  let userData = null;

  if (data) {
    userData = data.getUser;
  }

  if (dataUserSelf) {
    userData = dataUserSelf.getUserSelfInfo;
  }

  const parsedUser = buildUserData(userData);

  let courses = user.courses.map((course) => ({ ...course, trainings: [], totalPassed: 0 }));

  parsedUser.map((training) => {
    let foundCourse = courses.find((course) => course.name === training.course.name);
    if (foundCourse) {
      foundCourse.trainings.push(training);
      if (training.assessmentApproved) {
        foundCourse.totalPassed++;
      }
    }
  });

  return (
    <>
      <Title title={t('userProfile.enrollment.title')} />
      <Grid item xs={12} className={classes.title}>
        <FormSubTitle title={t('userProfile.enrollment.subTitle1')} />
      </Grid>
      <Grid item container xs={12} spacing={2} className={classes.contentContainer}>
        {user.organizationRoles.map((role, i) => {
          const rolePermission = role.permission.split('-').join(' ');
          return (
            <Grid key={role.organization.name + i} item xs={12} sm={6}>
              <OrganizationRoles name={role.organization.name} role={rolePermission} />
            </Grid>
          );
        })}
      </Grid>
      <Grid item xs={12} className={classes.title}>
        <FormSubTitle title={t('userProfile.enrollment.subTitle2')} />
      </Grid>
      <Grid item xs={12} className={classes.contentContainer}>
        {user.cohorts.length > 0 ? (
          user.cohorts.map((group, i) => {
            return (
              <Chip
                key={group.name + i}
                label={group.name}
                disabled={true}
                color={theme.palette.primary.main}
              />
            );
          })
        ) : (
          <Box className={classes.noDataGroups}>{t('userProfile.enrollment.noDataGroups')}</Box>
        )}
      </Grid>
      <Grid item xs={12} className={classes.title}>
        <FormSubTitle title={t('userProfile.enrollment.subTitle3')} />
      </Grid>
      <Grid item container xs={12} spacing={2} className={classes.contentContainer}>
        {courses.map((course, i) => {
          return (
            <Grid key={course.name + i} item xs={12} lg={6}>
              <CourseAndTrainings
                loading={loading || loadingUserSelf}
                data={course}
                noData={t('userProfile.enrollment.noDataTrainings')}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Enrollment;
