import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import ProgressListWidget from 'component/Widgets/ProgressListWidget';
import GET_TRAINING_PERFORMANCE_GROUPED_BY_TRAINING from 'graphql/getTrainingsPerformanceGroupedByTraining';
import { buildBreadcrumbs } from 'helpers/buildBreadcrumbs';
import { useTrainings, useTrainingTranslation } from 'hooks';

const TotalUsersPassed = (props) => {
  const { t } = useTranslation();
  const { trainings, getTrainingDetailsById, loadingTrainings } = useTrainings();
  const { getTranslatedNameFromTraining } = useTrainingTranslation();
  const toNumbers = (arr) => arr.map(Number);
  const { data, error, loading } = useQuery(GET_TRAINING_PERFORMANCE_GROUPED_BY_TRAINING, {
    variables: {
      trainingIds: toNumbers(props.trainingIds),
      category: 'assessment',
      ...(props.filteredBy && props.cohortId)
    },
    fetchPolicy: 'no-cache'
  });

  let formattedTrainings = [];

  if (!loadingTrainings && data && trainings.length !== 0) {
    data.trainingStatisticsIndividual.forEach((training) => {
      const foundTrainingFromAll = props.allTrainings.find(
        (t) => Number(t.id) === training.trainingId
      );

      const foundTraining = formattedTrainings.find((t) => t.id === training.trainingId);
      if (foundTraining) {
        if (training.totalPassed > 0) {
          foundTraining.value++;
        }
      } else {
        let totalUsersByCohort = foundTrainingFromAll?.user.filter((user) => {
          if (user.cohorts && user.cohorts.length !== 0) {
            return user.cohorts.find((cohort) => Number(cohort.id) === props.cohortId.cohortId);
          }
        });

        const trainingDetails = getTrainingDetailsById({
          id: training.trainingId
        });
        const trainingName = getTranslatedNameFromTraining({ training: trainingDetails });
        formattedTrainings.push({
          name: trainingName,
          value: training.totalPassed > 0 ? 1 : 0,
          total:
            totalUsersByCohort?.length > 0
              ? totalUsersByCohort.length
              : foundTrainingFromAll
              ? foundTrainingFromAll.user.length
              : 0,
          id: training.trainingId,
          link: '/training/' + training.trainingId,
          action: () =>
            buildBreadcrumbs({ key: 'dash', name: 'Dashboard', viewTitle: trainingName })
        });
      }
    });
  }

  return (
    <ProgressListWidget
      title={t('dashboard.usersPassedCard.title')}
      data={formattedTrainings}
      noData={t('dashboard.usersPassedCard.noData')}
      error={error}
      loading={loading}
      outOfValues={true}
    />
  );
};

export default TotalUsersPassed;
