import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardContent, Divider, makeStyles, Typography } from '@material-ui/core';
import { CheckCircleOutlineRounded } from '@material-ui/icons';

import Feedback from '../../component/Feedback';
import XSmallSpinner from '../../component/Spinners/XSmallSpinner';

import LogoBlack from './../../assets/images/logo-black';

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'pre-line',
    backgroundColor: theme.palette.common.black,
    height: '100vh',
    width: '100vw',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.background.paper
    }
  },
  cardMobile: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      borderRadius: '0px',
      boxShadow: 'none !important'
    }
  },
  content: {
    padding: '1rem',
    width: '440px',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      height: '100%'
    }
  },
  title: {
    display: 'flex',
    width: '100%',
    height: '5rem'
  },
  logo: {
    paddingTop: '10px'
  },
  formInputs: {
    height: '12rem',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  formButton: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: '2rem'
  },
  forgot: {
    display: 'flex',
    height: '15px',
    textDecoration: 'none',
    padding: '5px 16px',
    cursor: 'pointer'
  },
  language: {
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

const Container = (props) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (sessionStorage.getItem('manualLang')) {
      i18n.changeLanguage(sessionStorage.getItem('manualLang'));
    } else if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'));
    }
  }, []);

  return (
    <Box className={classes.root}>
      <Card className={classes.cardMobile}>
        <CardContent>
          <Box className={classes.content}>
            <Box className={classes.title}>
              <Box style={{ flex: '1 1 0%' }}>{props.title}</Box>
              <Box className={classes.logo}>
                <LogoBlack />
              </Box>
            </Box>
            <form onSubmit={(e) => props.onSubmit(e)}>
              <Box className={classes.formInputs}>{props.children}</Box>
              <Box className={classes.formButton}>
                <>
                  <Box style={{ flex: '1 1 0%' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color={props.buttonColor}
                      disabled={props.loading}>
                      {props.buttonText}
                    </Button>{' '}
                    {props.extraAction ? props.extraAction : null}
                  </Box>
                  {props.loading ? (
                    <XSmallSpinner />
                  ) : props.success ? (
                    <CheckCircleOutlineRounded style={{ color: '#388e3c' }} fontSize="large" />
                  ) : null}
                </>
              </Box>
            </form>
          </Box>
          <Divider style={{ margin: '10px 0' }} />
          <Typography
            variant="subtitle2"
            color="secondary"
            onClick={props.onFooterClick}
            className={classes.forgot}>
            {props.footerText}
          </Typography>
        </CardContent>
      </Card>
      {props.error && <Feedback type="error" message={props.error} />}
    </Box>
  );
};

export default Container;
