import React, { useMemo } from 'react';
import cookie from 'react-cookies';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

import { parseJwt } from '../helpers/parseJwt';

import REFRESH_TOKEN from './../graphql/RefreshToken';
import { useLogout } from './Logout';

const CustomApolloProvider = (props) => {
  const { logout } = useLogout();
  const uri = process.env.REACT_APP_GRAPHQL_URI + 'graphql/';

  const uploadLink = createUploadLink({
    uri: uri
  });

  const client = useMemo(() => {
    const cache = new InMemoryCache({
      resultCaching: false,
      typePolicies: {
        Training: {
          fields: {
            session: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              }
            }
          }
        },
        Session: {
          fields: {
            event: {
              merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming);
              }
            }
          }
        }
      }
    });

    const renewTokenClient = new ApolloClient({
      link: uploadLink,
      cache: cache
    });

    const refreshLink = setContext(async () => {
      let token = cookie.load('token');
      if (token) {
        const currentNumericDate = Math.round(Date.now() / 1000);
        const parsedToken = parseJwt(token);
        if (currentNumericDate >= parsedToken.exp) {
          token = await renewTokenClient
            .mutate({
              mutation: REFRESH_TOKEN,
              variables: { refreshToken: cookie.load('refreshToken') }
            })
            .then((res) => {
              if (res.data.refreshToken.success) {
                cookie.save('token', res.data.refreshToken.token, { path: '/' });
                cookie.save('refreshToken', res.data.refreshToken.refreshToken, { path: '/' });
                return res.data.refreshToken.token;
              } else {
                logout();
              }
            })
            .catch((err) => {
              logout();
              return err;
            });
        }
      }
      return { token };
    });

    const authLink = new ApolloLink((operation, forward) => {
      const { token } = operation.getContext();
      operation.setContext(({ headers }) => ({
        headers: {
          Authorization: `Bearer ${token}`,
          Referer: window.location.href,
          ...headers
        }
      }));
      return forward(operation);
    });

    return new ApolloClient({
      link: ApolloLink.from([refreshLink, authLink.concat(uploadLink)]),
      cache: cache,
      watchQuery: {
        fetchPolicy: 'cache-first'
      },
      query: {
        fetchPolicy: 'cache-first'
      }
    });
  }, []);

  return (
    <ApolloProvider client={client} {...props}>
      {props.children}
    </ApolloProvider>
  );
};

export default CustomApolloProvider;
