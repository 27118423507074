import React from 'react';
import { Chip as MuiChip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const Chip = (props) => {
  const StyledChip = withStyles({
    outlined: {
      color: props.color,
      border: `1px solid ${props.color}`,
      backgroundColor: `${props.color}15`,
      fontSize: '0.8rem',
      height: '1.8rem',
      margin: '0.25rem 0.5rem 0.25rem 0',
      maxWidth: '250px',
      wordBreak: 'truncate',
      textTransform: 'capitalize'
    },
    deleteIcon: {
      color: props.color,
      height: '1.1rem',
      width: '1.1rem'
    }
  })(MuiChip);

  return props.disabled ? (
    <StyledChip label={props.label} variant="outlined" />
  ) : (
    <StyledChip
      label={props.label}
      onDelete={() => props.onDelete(props.id, props.name)}
      variant="outlined"
    />
  );
};

export default Chip;

Chip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  name: PropTypes.string,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.string
};
