import moment from 'moment-timezone';

import { maxValueInArrayOfObjects, valueExistsInArrayOfObject } from '../util';

export const buildLastWeekActivity = (data) => {
  let totalMinutesSpent = 0;

  let chartData = {
    seriesData: [],
    xAxisCategories: [],
    yAxisMax: maxValueInArrayOfObjects(data.activityInRange, 'minutesSpent') + 1
  };

  if (data.activityInRange.length > 0) {
    for (let i = 7; i >= 0; i--) {
      let start = moment().subtract(i, 'd');
      chartData.xAxisCategories.push(start.format('YYYY-MM-DD'));
      if (valueExistsInArrayOfObject(data.activityInRange, 'day', start.format('YYYY-MM-DD'))) {
        let dayObj = data.activityInRange.find((obj) => {
          return obj.day === start.format('YYYY-MM-DD');
        });
        chartData.seriesData.push(Math.floor(dayObj.minutesSpent));
        totalMinutesSpent += dayObj.minutesSpent;
      } else chartData.seriesData.push(Math.floor(0));
    }
  } else {
    chartData.xAxisCategories.push(0, 0, 0, 0, 0, 0, 0);
    chartData.seriesData.push(0, 0, 0, 0, 0, 0, 0);
  }

  return { chartData, totalMinutesSpent };
};
