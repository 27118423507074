import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';

import ConfirmButton from '../Buttons/ConfirmButton';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: theme.palette.common.black + 95,
    height: '100vh',
    width: '100vw',
    zIndex: '9999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    margin: 0,
    padding: 0
  },
  container: {
    backgroundColor: 'white',
    padding: '20px 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.8rem',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      margin: '2rem',
      width: '50%',
      borderRadius: '10px',
      fontSize: '0.9rem',
      padding: '30px'
    },
    [theme.breakpoints.up('xl')]: {
      margin: '0 2rem 10rem 2rem',
      width: '30%',
      borderRadius: '10px',
      fontSize: '0.9rem',
      padding: '30px'
    }
  },
  message: {
    paddingRight: '20px',
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      flex: '1 1 0%'
    }
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer'
  }
}));

const Cookies = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const setAllowCookies = () => {
    localStorage.setItem('allowCookies', true);
    props.setShowCookies(false);
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.message}>
          {t('termsOfService.message')}{' '}
          <a
            className={classes.link}
            target="_blank"
            href="https://www.gleechi.com/cookie-policy"
            rel="noreferrer">
            {t('termsOfService.link')}
          </a>
        </Box>
        <Box className={classes.button}>
          <ConfirmButton size="small" onClick={() => setAllowCookies()}>
            {' '}
            {t('termsOfService.button')}{' '}
          </ConfirmButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Cookies;
