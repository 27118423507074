import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';

const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const location = useLocation();
  const getParentPathName = () => {
    let pathname = location.pathname.substring(1).split('/');
    if (pathname.length === 0) return null;

    return pathname[0];
  };
  return (
    <AppContext.Provider
      value={{
        parentPathName: getParentPathName()
      }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
