const filterData = (user, parsedData, selectedFilter) => {
  let filteredData = parsedData;

  const filterByCourse = () => {
    const foundCourse = user.courses.find((course) => course.id === selectedFilter.course);
    filteredData = parsedData.filter((training) => {
      if (foundCourse && foundCourse.trainings.find((t) => t.id === training.id)) {
        return training;
      }
    });
  };

  const filterByTraining = () => {
    filteredData = parsedData.filter((training) => training.id === selectedFilter.training);
  };

  if (selectedFilter.filter === 'course' && selectedFilter.course !== '') {
    filterByCourse();
  } else if (selectedFilter.filter === 'training') {
    if (selectedFilter.training === '' && selectedFilter.course.length > 0) {
      filterByCourse();
    } else if (selectedFilter.training === '' && selectedFilter.course.length < 1) {
      filteredData = parsedData;
    } else {
      filterByTraining();
    }
  }

  return filteredData;
};

export default filterData;
