export const gridSpacing = 3;
export const drawerWidth = 280;
export const colorList = [
  '#3366FF',
  '#9933FF',
  '#FF3333',
  '#FF6633',
  '#FF3399',
  '#FFCC33',
  '#26BF4D'
];
