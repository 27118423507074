import { gql } from '@apollo/client';

export const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $dateFormat: String
    $languageCode: String
    $timezone: String
    $userId: Int
  ) {
    updateProfile(
      dateFormat: $dateFormat
      languageCode: $languageCode
      timezone: $timezone
      userId: $userId
    ) {
      success
      error
      user {
        profile {
          timezone
          preferredLanguage {
            id
            code
            name
          }
          dateFormat
        }
      }
    }
  }
`;
