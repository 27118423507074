import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import TotalTimeSpent from 'component/Widgets/TotalTimeWidget';

const TotalSessionsTime = (props) => {
  const { t } = useTranslation();
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    let totalTime = 0;
    props.filteredTrainings.forEach((training) => {
      const data =
        props.selectedSwitch === 'assessment' ? training.assessments : training.practices;
      if (data.length > 0) {
        data.forEach((d) => {
          return (totalTime += d.duration);
        });
      }
    });
    setDuration(totalTime);
  }, [props.filteredTrainings, props.selectedSwitch]);

  return (
    <Grid item xs={12} sm={4}>
      <TotalTimeSpent title={t('trainingDetails.totalTimeSpent.title')} timeSpent={duration} />
    </Grid>
  );
};

export default TotalSessionsTime;
