import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormSubTitle from 'component/Inputs/FormSubTitle';
import TextInput from 'component/Inputs/TextInput';
import FullScreenModal from 'component/Modals/FullScreenModal';
import { ADD_GROUP, UPDATE_GROUP } from 'graphql/GroupManagement';
import { useOrganization } from 'hooks';
import { UsersTable } from 'views/Admin/components';

const useStyles = makeStyles({
  paddingBottom: {
    paddingBottom: '1.2rem'
  },
  paddingRight: {
    paddingRight: '1.2rem',
    alignSelf: 'flex-start'
  }
});

const GroupModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [response, setResponse] = useState(null);
  const { organizationData } = useOrganization();
  const [groupDetails, setGroupDetails] = useState({
    name: props.selectedGroup ? props.selectedGroup.name : '',
    users: props.selectedGroup ? props.selectedGroup.user.map((user) => user.id) : []
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    users: false
  });

  const [updateGroup, { loading: updateGroupLoading }] = useMutation(UPDATE_GROUP, {
    onCompleted(groupUpdated) {
      setResponse({
        message: groupUpdated.updateCohort.success
          ? t('groupManagement.responses.updateSuccess')
          : groupUpdated.updateCohort.error,
        responseType: groupUpdated.updateCohort.success ? 'success' : 'error'
      });
      const groupCopy = [...props.groupData];
      let foundGroup = props.groupData.find(
        (group) => group.id === groupUpdated.updateCohort.cohort.id
      );
      foundGroup.name = groupUpdated.updateCohort.cohort.name;
      foundGroup.user = groupUpdated.updateCohort.cohort.user;
      props.setGroupData(groupCopy);
      clearErrors();
    }
  });

  const [addGroup, { loading: addGroupLoading }] = useMutation(ADD_GROUP, {
    onCompleted(groupAdded) {
      setResponse({
        message: groupAdded.addCohort.success
          ? t('groupManagement.responses.createSuccess')
          : groupAdded.addCohort.error,
        responseType: groupAdded.addCohort.success ? 'success' : 'error'
      });
      props.setGroupData([groupAdded.addCohort.cohort, ...props.groupData]);
      clearErrors();
    }
  });

  const canSave = () => {
    let error = false;
    let formErrorsCopy = { ...formErrors };
    Object.keys(groupDetails).forEach((key) => {
      if (key !== 'users' && groupDetails[key].length < 1) {
        formErrorsCopy[key] = true;
        error = true;
      }
    });
    setFormErrors(formErrorsCopy);
    return error;
  };

  const handleUpdateGroup = () => {
    if (!canSave()) {
      updateGroup({
        variables: {
          id: Number(props.selectedGroup.id),
          name: groupDetails.name,
          organizationId: Number(organizationData.organization.id),
          userIds: groupDetails.users.map((user) => Number(user))
        }
      });
    }
  };

  const handleAddGroup = () => {
    if (!canSave()) {
      addGroup({
        variables: {
          name: groupDetails.name,
          organizationId: Number(organizationData.organization.id),
          userIds: groupDetails.users.map((user) => Number(user))
        }
      });
    }
  };

  const handleOnChangeInputs = (e) => {
    if (e.target.value.includes('all') && e.target.name === 'users') {
      setGroupDetails({
        ...groupDetails,
        [e.target.name]: props.optionsData.users.map((user) => user.id)
      });
    } else if (e.target.value.includes('clear') && e.target.name === 'users') {
      setGroupDetails({
        ...groupDetails,
        [e.target.name]: []
      });
    } else {
      setGroupDetails({
        ...groupDetails,
        [e.target.name]: e.target.value
      });
    }

    setFormErrors({
      ...formErrors,
      [e.target.name]: false
    });
  };

  const clearErrors = () => {
    setFormErrors({
      name: false,
      details: false,
      course: false,
      users: false
    });
  };

  const onRowSelectionChange = (currentRowsSelected, allRowsSelected, rowsSelected) => {
    const selectedUsers = [];
    rowsSelected.forEach((row) => {
      props.optionsData.users.forEach((user, index) => {
        if (index === row) {
          selectedUsers.push(user.id);
        }
      });
    });
    setGroupDetails({
      ...groupDetails,
      users: selectedUsers
    });
  };

  return (
    <FullScreenModal
      icon={props.selectedGroup ? 'edit' : 'add'}
      title={props.selectedGroup ? t('groupManagement.edit') : t('groupManagement.create')}
      buttonText={props.selectedGroup ? t('groupManagement.update') : t('groupManagement.save')}
      handleCloseModal={props.handleCloseModal}
      handleDone={props.selectedGroup ? handleUpdateGroup : handleAddGroup}
      saving={updateGroupLoading || addGroupLoading}
      response={response}
      width="42rem">
      <Grid container>
        <Grid item xs={12}>
          <FormSubTitle title={t('groupManagement.form.title1')} />
        </Grid>
        <Grid item xs={6} className={classes.paddingRight}>
          <TextInput
            required={true}
            label={t('sharedLabels.groupName')}
            value={groupDetails.name}
            name="name"
            onChange={handleOnChangeInputs}
            underText={formErrors.name}
            error={formErrors.name}
          />
        </Grid>
        <Grid container item xs={12} style={{ paddingTop: '23px', paddingBottom: '23px' }}>
          <UsersTable
            headerTitle={t('groupManagement.form.title2')}
            userData={props.optionsData.users}
            usersSelected={groupDetails.users}
            onRowSelectionChange={onRowSelectionChange}
          />
        </Grid>
      </Grid>
    </FullScreenModal>
  );
};

export default GroupModal;
