import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import ButtonGroup from 'component/Buttons/ButtonGroup';
import SelectInput from 'component/Inputs/SelectInputs/SelectInput';
import TextInput from 'component/Inputs/TextInput';
import CollapsedFilter from 'component/ViewFilters/CollapsedFilter';

import { EnrolledUsersFilterContext, PERFORMANCE_FILTER } from './useEnrolledUsersFilter';

const EnrolledUsersFilter = (props) => {
  const { activePerformance, setActivePerformance } = useContext(EnrolledUsersFilterContext);
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [training, setSelectedTraining] = useState('all');

  useEffect(() => {
    const filteredBySearch = props.data.filter((row) => {
      if (
        (row.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
          row.lastName.toLowerCase().includes(searchValue.toLowerCase())) &&
        (training === 'all' || Number(row.training.id) === Number(training)) &&
        (activePerformance === PERFORMANCE_FILTER.ALL ||
          (activePerformance === PERFORMANCE_FILTER.PASSED && row.passed) ||
          (activePerformance === PERFORMANCE_FILTER.NOT_PASSED && row.notPassed))
      ) {
        return row;
      }
    });
    props.setFilteredData(filteredBySearch);
  }, [searchValue, activePerformance, training]);

  const handleButtonClick = useCallback(
    (id) => {
      setActivePerformance(id);
    },
    [setActivePerformance]
  );

  const handleSelectTraining = (e) => {
    setSelectedTraining(e.target.value);
  };

  const handleCollapsedFiltering = (name, filter) => {
    if (name === 'performance') handleButtonClick(filter.id);
    if (name === 'training') setSelectedTraining(filter.id);
  };

  return (
    <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <Box style={{ width: props.collapse ? '40%' : '25%' }}>
        <TextInput
          label={t('filters.searchUser')}
          name="search"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          disabled={props.disabled}
        />
      </Box>

      {props.collapse ? (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'end',
              paddingBottom: '8px'
            }}>
            {props.filterButton}
          </Box>
          <CollapsedFilter
            showFilter={props.showFilter}
            setShowFilter={props.setShowFilter}
            filterOptions={props.filterOptions}
            setFilters={handleCollapsedFiltering}
            selectedFilters={{ performance: activePerformance, training: training }}
          />
        </>
      ) : (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              paddingBottom: '8.2px'
            }}>
            <ButtonGroup
              disabled={props.disabled}
              onClick={handleButtonClick}
              selected={activePerformance}
              options={props.filterOptions.performance.options}
            />
          </Box>
          <Box style={{ width: '30%' }}>
            <SelectInput
              disabled={props.disabled || props.filterOptions.trainings.options.length < 1}
              label={t('sharedLabels.trainings')}
              selected={training}
              onChange={handleSelectTraining}
              menuItems={props.filterOptions.trainings.options}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default EnrolledUsersFilter;
