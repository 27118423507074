import React from 'react';
import PropTypes from 'prop-types';

import ModalWrapper from '../ModalWrapper';

const FullScreenModal = (props) => {
  const { children, ...rest } = props;
  return (
    <ModalWrapper height="100vh" width={props.width ? props.width : '45rem'} {...rest}>
      {children}
    </ModalWrapper>
  );
};

export default FullScreenModal;

FullScreenModal.propTypes = {
  type: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  handleCloseModal: PropTypes.func,
  handleDone: PropTypes.func,
  saving: PropTypes.bool,
  loading: PropTypes.bool,
  response: PropTypes.shape({
    message: PropTypes.string,
    responseType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }),
  buttonText: PropTypes.string,
  customActionButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};
