export default {
  basename: '/', // only at build time to set, like ///able-pro/react/default
  rtlLayout: false,
  i18n: 'en',
  jwt: {
    secret: 'SECRET-KEY',
    timeout: '1 days'
  },
  auth0: {
    client_id: 'HvYn25WaEHb7v5PBT7cTYe98XATStX3r',
    domain: 'demo-localhost.us.auth0.com'
  }
};
